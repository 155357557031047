import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import InsuranceIndicators from './InsuranceIndicators/InsuranceIndicators';
import { getReportBudgetDatas } from '../../service/reportServices';
import TreeMapUnited from '../components/TreeMapUnited/TreeMapUnited';
import Comment from '../components/Comment/Comment';
import { useAppStore } from '../../context/Tools';
import UrDungiinUzuulelt from '../components/urDungiinUzuulelt/UrDungiinUzuulelt';
import Banner from '../united-budget/UnitedBudgetBanner';
import Cards from '../united-budget/UnitedBudgetCards';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import BannerTitle from '../../components/bannerTitle/BannerTitle';

export default function SocialInsurance() {
  const [searchParams] = useSearchParams();

  const year = searchParams.get('year');
  const month = searchParams.get('month');

  const { serverDate } = useAppStore();

  const cancelRef = useRef();

  const [budgetData, setBudgetData] = useState([]);
  const [typeOfExpenditure, setTypeOfExpenditure] = useState('eza');
  const [data, setData] = useState(false);

  const getTreeMapData = () => {
    const params = {
      typeOfExpenditure,
      refBudgetTypeId: 5,
      year: year || serverDate.getFullYear(),
      month: month || 1,
    };
    getReportBudgetDatas(params, cancelRef).then(result => {
      setBudgetData(result);
    });
  };

  useEffect(getTreeMapData, [year, month, typeOfExpenditure]);

  return (
    <>
      <MetaInfo title="Нийгмийн даатгалын сан - Шилэн данс" />
      <Banner
        title={<BannerTitle title="Нийгмийн даатгалын сангийн төсөв" />}
      />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <Cards />
          <UrDungiinUzuulelt
            budgetType={5}
            title="НИЙГМИЙН ДААТГАЛЫН САНГИЙН ҮР ДҮНГИЙН ҮЗҮҮЛЭЛТ /жилээр/"
          />
          <TreeMapUnited
            setData={setData}
            data={data}
            setTypeOfExpenditure={setTypeOfExpenditure}
            typeOfExpenditure={typeOfExpenditure}
            budgetData={budgetData}
            getData={getTreeMapData}
            Title="НИЙГМИЙН ДААТГАЛЫН САНГИЙН ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар/"
          />
          <InsuranceIndicators />
          <Comment />
        </Col>
      </Row>
    </>
  );
}
