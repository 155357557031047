import { getRequest } from './Service';

const serviceBaseUrl = '/gap-training-service';

export const serviceTrainingLegal = `${serviceBaseUrl}/trainingLegals`;
export const serviceTrainingResearchBudgets = `${serviceBaseUrl}/trainingResearchBudgets`;
export const serviceTrainingResearchManuals = `${serviceBaseUrl}/trainingResearchManuals`;
export const serviceTrainingManuals = `${serviceBaseUrl}/trainingManuals`;
export const serviceTrainingVideos = `${serviceBaseUrl}/trainingVideos`;
export const serviceTrainingDictionaries = `${serviceBaseUrl}/trainingDictionaries`;

export const getTrainingLegals = async params =>
  await getRequest(serviceTrainingLegal, params);

export const getTrainingResearchBudgets = async params =>
  await getRequest(serviceTrainingResearchBudgets, params);

export const getTrainingResearchManuals = async params =>
  await getRequest(serviceTrainingResearchManuals, params);

export const getTrainingManuals = async params =>
  await getRequest(serviceTrainingManuals, params);

export const getTrainingVideos = async params =>
  await getRequest(serviceTrainingVideos, params);

export const getTrainingDictionaries = async params =>
  await getRequest(serviceTrainingDictionaries, params);
