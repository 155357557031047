import { STEP } from '../constants/OrgConstant';
import { getRequest } from './Service';

const serviceBaseUrl = '/gap-report-service';
const serviceOrganizationBaseUrl = '/gap-organization-service';

const serviceOrganizationUrl = `${serviceOrganizationBaseUrl}/orgs`;
const serviceOrganizationByFields = `${serviceOrganizationUrl}/findOrgListByRequestedFields`;
const serviceDashboardOfRefTypeUrl = `${serviceOrganizationBaseUrl}/orgs/portalDashboardOfRefType`;
const serviceDashboardOfBudgetGovernorUrl = `${serviceOrganizationBaseUrl}/orgs/portalDashboardOfBudgetGovernor`;
const serviceDashboardOfIsicsUrl = `${serviceOrganizationBaseUrl}/orgs/portalDashboardOfIsics`;
const serviceOrgResultStatistic = `${serviceBaseUrl}/reportStatics/getOrgResultStatistic`;
const serviceOrganizationCountByRefCity = `${serviceOrganizationUrl}/countByRefCity`;

export const filterActiveOrg = {
  status: true,
  isActive: true,
  isWish: false,
  step: STEP.APPROVED,
};

export const getOrganizations = async (params, cancelRef) => {
  const search = { ...filterActiveOrg, ...params?.search };
  const newParams = { ...params, search };
  return await getRequest(serviceOrganizationUrl, newParams, cancelRef);
};

export const getOrganization = async orgId =>
  await getRequest(`${serviceOrganizationUrl}/${orgId}`);

export const getOrganizationsByFields = async (params, cancelRef) => {
  const search = { ...filterActiveOrg, ...params?.search };
  const newParams = { ...params, search };
  if (!params?.requestedFields) {
    newParams.requestedFields = 'id,name';
  }
  return await getRequest(serviceOrganizationByFields, newParams, cancelRef);
};

export const DashboardOfRefTypeGetService = async (params, cancelRef) =>
  await getRequest(serviceDashboardOfRefTypeUrl, params, cancelRef);

export const DashboardOfBudgetGovernor = async params =>
  await getRequest(serviceDashboardOfBudgetGovernorUrl, params);

export const DashboardOfIsics = async params =>
  await getRequest(serviceDashboardOfIsicsUrl, params);

export const getOrgResultStatistic = async params =>
  await getRequest(serviceOrgResultStatistic, params);

export const getOrgCountByRefCity = async params =>
  await getRequest(serviceOrganizationCountByRefCity, params);

export { serviceOrgResultStatistic };
