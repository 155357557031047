import React, { useState, useContext, useRef, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import locale from 'antd/es/date-picker/locale/mn_MN';

import {
  BarChartOutlined,
  TableOutlined,
  FilterFilled,
} from '@ant-design/icons';
import {
  Row,
  Typography,
  Col,
  DatePicker,
  Space,
  Button,
  Table,
  Skeleton,
  Select,
} from 'antd';
import { Option } from 'antd/lib/mentions';
import moment from 'moment';
import styles from './styles/style.module.scss';
import { YEAR_MONTH_FORMAT } from '../../constants/Constant';
import { ToolsContext } from '../../context/Tools';
import {
  getSupplierByEconomyCategory,
  getSupplierByEconomyTopTen,
} from '../../service/reportServices';
import { convertCurrencyFormat } from '../../tools/Tools';

const { Text } = Typography;
const { RangePicker } = DatePicker;

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');

export default function Charts() {
  const { serverDate } = useContext(ToolsContext);

  const cancelRefSupplierByEconomyCategory = useRef();
  const cancelSupplierByEconomyTopTen = useRef();

  const [loading, setLoading] = useState(false);
  const [loadingBar, setLoadingBar] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowBar, setIsBarShow] = useState(true);
  const [infoByTEZ, setInfoByTEZ] = useState([]);
  const [infoByTopTen, setInfoByTopTen] = useState([]);
  const [filterDate, setFilterDate] = useState({
    dateStart: `${serverDate.getFullYear()}-01`,
    dateEnd: moment(serverDate).format(YEAR_MONTH_FORMAT),
  });
  const [type, setType] = useState('1');

  const infoData = infoByTEZ;
  const donutChartLabelsTemp = [];
  const donutChartSeriesTemp = [];
  const donutChartData = [];

  const barChartLabelsTemp = [];
  const barChartSeriesTemp = [];
  const barChartData = [];

  const barColumns = [
    {
      title: 'Бэлтгэн нийлүүлэгч',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Тоо',
      dataIndex: 'totalAmountByThousand',
      key: 'totalAmountByThousand',
      align: 'right',
      fixed: 'right',
    },
  ];

  const onChange = (date, dateString) => {
    setFilterDate({
      dateStart: dateString[0],
      dateEnd: dateString[1],
    });
  };

  let isDataByDonut = 0;
  infoData.forEach((item, key) => {
    donutChartLabelsTemp.push(item.name);
    donutChartSeriesTemp.push(item.amount);
    if (item.amount) {
      isDataByDonut = 1;
    }
    donutChartData.push({ name: item.name, value: item.amount, index: key });
  });

  let isDataByBar = 0;
  infoByTopTen.forEach((item, key) => {
    barChartLabelsTemp.push(item.name);
    barChartSeriesTemp.push(
      convertCurrencyFormat(item.totalAmountByThousand).replaceAll(',', '')
    );
    if (item.totalAmountByThousand) {
      isDataByBar = 1;
    }

    barChartData.push({
      name: item.name,
      value: item.totalAmountByThousand,
      totalAmountByThousand: convertCurrencyFormat(item.totalAmountByThousand),
      index: key,
    });
  });

  const donutChartColumns = [
    {
      title: 'Нэр',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Төсөвт өртөг',
      dataIndex: 'value',
      key: 'value',
      align: 'right',
      render: value => convertCurrencyFormat(value),
    },
  ];

  const classification = {
    series: donutChartSeriesTemp,
    options: {
      labels: donutChartLabelsTemp,
      chart: {
        type: 'donut',
        width: 500,
      },
      legend: {
        width: 250,
        horizontalAlign: 'left',
        itemMargin: { vertical: 0 },
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              position: 'bottom',
              width: '100%',
              horizontalAlign: 'left',
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            legend: {
              position: 'right',
              width: 250,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            legend: {
              position: 'bottom',
              width: '100%',
            },
          },
        },
      ],
    },
  };

  const topSuppliers = {
    series: [
      {
        name: 'нийт төсөвт өртөг',
        data: barChartSeriesTemp,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 500,
          style: {
            colors: [],
            fontSize: '8px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 15,
          rotate: 0,
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      stroke: {
        size: 2,
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      xaxis: {
        categories: barChartLabelsTemp,
      },
      colors: ['#004a9f'],
    },
  };

  useEffect(() => {
    setLoading(true);
    setLoadingBar(true);

    getSupplierByEconomyCategory(
      {
        dateStart: filterDate.dateStart,
        dateEnd: filterDate.dateEnd,
        type,
      },
      cancelRefSupplierByEconomyCategory
    )
      .then(result => {
        setInfoByTEZ(result);
      })
      .finally(() => {
        setLoading(false);
      });

    getSupplierByEconomyTopTen(
      {
        dateStart: filterDate.dateStart,
        dateEnd: filterDate.dateEnd,
        type,
      },
      cancelSupplierByEconomyTopTen
    )
      .then(result => {
        (result || []).forEach(z => {
          // myangachilah
          z.totalAmountByThousand = Number(z.totalAmount || 0) / 1000;
        });
        setInfoByTopTen(result);
      })
      .finally(() => {
        setLoadingBar(false);
      });
  }, [filterDate, type]);

  return (
    <>
      <Space size="large">
        <Button
          type="link"
          icon={<FilterFilled className={styles.filterIcon} />}
        >
          Шүүлтүүр
        </Button>
      </Space>
      <Space>
        <RangePicker
          allowClear
          onChange={onChange}
          showToday
          size="large"
          format={YEAR_MONTH_FORMAT}
          defaultValue={[
            moment(filterDate.dateStart),
            moment(filterDate.dateEnd),
          ]}
          locale={{
            ...locale,
            lang: {
              ...locale.lang,
              now: 'Current Time',
              ok: 'Submit',
            },
          }}
          picker="month"
          disabledDate={current => current.isBefore(moment('2023-01-01'))}
        />
      </Space>
      <Space>
        <Select
          value={type}
          size="large"
          style={{
            width: 200,
            marginLeft: 10,
          }}
          onChange={e => setType(e)}
        >
          <Option value="0">Бүгд</Option>
          <Option value="1">Хувийн байгууллага</Option>
        </Select>
      </Space>
      <Row gutter={[30, 30]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <div className={styles.allSpaces}>
            <div className={styles.headerDiv}>
              <Text className={styles.text}>
                Зарлагын ТОП 10 эдийн засгийн ангилал
              </Text>
            </div>
            <Row className={styles.filterRow} justify="end">
              <Space size={15}>
                <TableOutlined
                  className="icon"
                  onClick={() => setIsShow(true)}
                />
                <BarChartOutlined
                  className="icon"
                  onClick={() => setIsShow(false)}
                />
              </Space>
            </Row>
            {loading ? (
              <Skeleton active />
            ) : (
              <>
                {isShow ? (
                  <Table
                    size="small"
                    rowKey={obj => obj.index}
                    columns={donutChartColumns}
                    dataSource={donutChartData}
                    pagination={false}
                  />
                ) : (
                  <div className="donutCustome">
                    {isDataByDonut ? (
                      <ReactApexChart
                        options={classification.options}
                        series={classification.series}
                        type="donut"
                        height="500"
                      />
                    ) : (
                      <Row justify="center">Өгөгдөл олдсонгүй</Row>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <div className={styles.allSpaces}>
            <div className={styles.headerDiv}>
              <Text className={styles.text}>
                Улсын хэмжээнд ТОП 10 бэлтгэн нийлүүлэгчид /мөнгөн дүн /мян.төг/
              </Text>
            </div>
            <Row className={styles.filterRow} justify="end">
              <Space size={15}>
                <TableOutlined
                  className="icon"
                  onClick={() => setIsBarShow(true)}
                />
                <BarChartOutlined
                  className="icon"
                  onClick={() => setIsBarShow(false)}
                />
              </Space>
            </Row>
            {loadingBar ? (
              <Skeleton active />
            ) : (
              <>
                {isShowBar ? (
                  <Table
                    size="small"
                    rowKey={obj => obj.index}
                    dataSource={barChartData}
                    columns={barColumns}
                    pagination={false}
                  />
                ) : (
                  <div>
                    {isDataByBar ? (
                      <ReactApexChart
                        options={topSuppliers.options}
                        series={topSuppliers.series}
                        type="bar"
                        height="500"
                      />
                    ) : (
                      <Row justify="center">Өгөгдөл олдсонгүй</Row>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

