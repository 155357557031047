export const chartId = 'columnChartResultIndicators';
export const chartData = [
  {
    category: '2015',
    first: 40,
    second: 55,
    third: 60,
  },
  {
    category: '2016',
    first: 30,
    second: 78,
    third: 69,
  },
  {
    category: '2017',
    first: 27,
    second: 40,
    third: 45,
  },
  {
    category: '2018',
    first: 50,
    second: 33,
    third: 22,
  },
];
export const seriesOne = 'Нийт орлого';
export const seriesTwo = 'Нийт зарлага';
export const seriesThree = 'Ашиг алдагдал';
export const TreeChartsId = 'TreeMapsUnitedExecusion';
export const TreeChartsData = [
  {
    name: 'Татварын орлого',
    key: 1,
    value: 287857.4,
    color: '#004A9F',
    value2: 'Жилийн батлагдсан төсөв:',
    value3: 'Гүйцэтгэл:',
    number: 180943.2,
    percent: 37,
    children: [
      {
        name: 'Төсвийн орлого',
        key: 3,
        children: [
          { name: 'A1-1', value: 687 },
          { name: 'A1-2', value: 148 },
        ],
      },
      {
        name: 'Тэнцэл',
        key: 4,
        children: [
          { name: 'A2-1', value: 220 },
          { name: 'A2-2', value: 480 },
          { name: 'A2-3', value: 150 },
        ],
      },
      {
        name: 'Засгийн газрын өр',
        key: 5,
        children: [
          { name: 'A3-1', value: 200 },
          { name: 'A3-2', value: 320 },
        ],
      },
    ],
  },
  {
    name: 'Татварын бус орлого',
    key: 3,
    value: 287857.4,
    color: '#00B8D5',
    value2: 'Жилийн батлагдсан төсөв:',
    value3: 'Гүйцэтгэл:',
    number: 180943.2,
    percent: 37,
    children: [
      {
        name: 'B1',
        key: 6,
        children: [
          { name: 'B1-1', value: 220 },
          { name: 'B1-2', value: 150 },
          { name: 'B1-3', value: 199 },
          { name: 'B1-4', value: 481 },
        ],
      },
      {
        name: 'B2',
        key: 7,
        children: [
          { name: 'B2-1', value: 210 },
          { name: 'B2-3', value: 150 },
        ],
      },
      {
        name: 'B3',
        key: 8,
        children: [
          { name: 'B3-1', value: 320 },
          { name: 'B3-2', value: 310 },
        ],
      },
    ],
  },
];
export const TreeChartsDataCost = [
  {
    name: 'ТӨСВИЙН НИЙТ ЗАРЛАГА',
    key: 'B',
    color: '#00B8D5',
    children: [
      {
        name: 'НИЙТ ЗАРЛАГА',
        key: 1,
        children: [
          {
            name: 'УРСГАЛ ЗАРДАЛ',
            key: 1 - 1,
            children: [
              {
                name: 'БАРАА, АЖИЛ ҮЙЛЧИЛГЭЭНИЙ ЗАРДАЛ',
                key: 1 - 1 - 1,
                children: [
                  {
                    name: 'Цалин хөлс болон нэмэгдэл урамшил',
                    key: 1 - 1 - 1 - 1,
                    children: [
                      {
                        name: 'Үндсэн цалин',
                        key: 1 - 1 - 1 - 1 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Нэмэгдэл',
                        key: 1 - 1 - 1 - 1 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Унаа хоолны хөнгөлөлт',
                        key: 1 - 1 - 1 - 1 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Урамшуулал',
                        key: 1 - 1 - 1 - 1 - 4,
                        value: 1000,
                      },
                      {
                        name: 'Гэрээт ажлын хөлс',
                        key: 1 - 1 - 1 - 1 - 5,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Ажил олгогчоос нийгмийн даатгалд төлөх шимтгэл',
                    key: 1 - 1 - 1 - 2,
                    children: [
                      {
                        name: 'Тэтгэврийн даатгал',
                        key: 1 - 1 - 1 - 2 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Тэтгэмжийн даатгал',
                        key: 1 - 1 - 1 - 2 - 2,
                        value: 1000,
                      },
                      {
                        name: 'ҮОМШӨ-ний даатгал',
                        key: 1 - 1 - 1 - 2 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Ажилгүйдлийн даатгал',
                        key: 1 - 1 - 1 - 2 - 4,
                        value: 1000,
                      },
                      {
                        name: 'Эрүүл мэндийн даатгал',
                        key: 1 - 1 - 1 - 2 - 5,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Байр ашиглалттай холбоотой тогтмол зардал',
                    key: 1 - 1 - 1 - 3,
                    children: [
                      {
                        name: 'Гэрэл, цахилгаан',
                        key: 1 - 1 - 1 - 3 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Түлш, халаалт',
                        key: 1 - 1 - 1 - 3 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Цэвэр, бохир ус',
                        key: 1 - 1 - 1 - 3 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Байрны түрээс',
                        key: 1 - 1 - 1 - 3 - 4,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Хангамж, бараа материалын зардал',
                    key: 1 - 1 - 1 - 4,
                    children: [
                      {
                        name: 'Бичиг хэрэг',
                        key: 1 - 1 - 1 - 4 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Тээвэр, шатахуун',
                        key: 1 - 1 - 1 - 4 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Шуудан, холбоо, интернэтийн төлбөр',
                        key: 1 - 1 - 1 - 4 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Ном, хэвлэл',
                        key: 1 - 1 - 1 - 4 - 4,
                        value: 1000,
                      },
                      {
                        name: 'Хог хаягдал зайлуулах, хортон мэрэгчдийн устгал, ариутгал',
                        key: 1 - 1 - 1 - 4 - 5,
                        value: 1000,
                      },
                      {
                        name: 'Бага үнэтэй, түргэн элэгдэх, ахуйн эд зүйлс',
                        key: 1 - 1 - 1 - 4 - 6,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Нормативт зардал',
                    key: 1 - 1 - 1 - 5,
                    children: [
                      {
                        name: 'Эм, бэлдмэл, эмнэлгийн хэрэгсэл',
                        key: 1 - 1 - 1 - 5 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Хоол, хүнс',
                        key: 1 - 1 - 1 - 5 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Нормын хувцас, зөөлөн эдлэл',
                        key: 1 - 1 - 1 - 5 - 3,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Эд хогшил, урсгал засварын зардал',
                    key: 1 - 1 - 1 - 6,
                    children: [
                      {
                        name: 'Багаж, техник, хэрэгсэл',
                        key: 1 - 1 - 1 - 6 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Тавилга',
                        key: 1 - 1 - 1 - 6 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Хөдөлмөр хамгааллын хэрэглэл',
                        key: 1 - 1 - 1 - 6 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Урсгал засвар',
                        key: 1 - 1 - 1 - 6 - 4,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Томилолт, зочны зардал',
                    key: 1 - 1 - 1 - 7,
                    children: [
                      {
                        name: 'Гадаад албан томилолт',
                        key: 1 - 1 - 1 - 7 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Дотоод албан томилолт',
                        key: 1 - 1 - 1 - 7 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Зочин төлөөлөгч хүлээн авах',
                        key: 1 - 1 - 1 - 7 - 3,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Бусдаар гүйцэтгүүлсэн ажил, үйлчилгээний төлбөр, хураамж',
                    key: 1 - 1 - 1 - 8,
                    children: [
                      {
                        name: 'Бусдаар гүйцэтгүүлсэн бусад нийтлэг ажил үйлчилгээний төлбөр хураамж',
                        key: 1 - 1 - 1 - 8 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Аудит, баталгаажуулалт, зэрэглэл тогтоох',
                        key: 1 - 1 - 1 - 8 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Даатгалын үйлчилгээ',
                        key: 1 - 1 - 1 - 8 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Тээврийн хэрэгслийн татвар',
                        key: 1 - 1 - 1 - 8 - 4,
                        value: 1000,
                      },
                      {
                        name: 'Тээврийн хэрэгслийн оношлогоо',
                        key: 1 - 1 - 1 - 8 - 5,
                        value: 1000,
                      },
                      {
                        name: 'Мэдээлэл, технологийн үйлчилгээ',
                        key: 1 - 1 - 1 - 8 - 6,
                        value: 1000,
                      },
                      {
                        name: 'Газрын төлбөр',
                        key: 1 - 1 - 1 - 8 - 7,
                        value: 1000,
                      },
                      {
                        name: 'Банк, санхүүгийн байгууллагын үйлчилгээний хураамж',
                        key: 1 - 1 - 1 - 8 - 8,
                        value: 1000,
                      },
                      {
                        name: 'Улсын мэдээллийн маягт хэвлэх, бэлтгэх',
                        key: 1 - 1 - 1 - 8 - 9,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Бараа үйлчилгээний бусад зардал',
                    key: 1 - 1 - 1 - 9,
                    children: [
                      {
                        name: 'Бараа үйлчилгээний бусад зардал',
                        key: 1 - 1 - 1 - 9 - 1,
                        value: 1000,
                      },
                      {
                        name: ' Хичээл үйлдвэрлэлийн дадлага хийх',
                        key: 1 - 1 - 1 - 9 - 2,
                        value: 1000,
                      },
                    ],
                  },
                ],
              },
              {
                name: 'ХҮҮ',
                key: 1 - 1 - 2,
                children: [
                  {
                    name: 'Гадаад зээлийн үйлчилгээний төлбөр',
                    key: 1 - 1 - 2 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Дотоод зээлийн үйлчилгээний төлбөр',
                    key: 1 - 1 - 2 - 2,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'ТАТААС',
                key: 1 - 1 - 3,
                children: [
                  {
                    name: 'Төрийн өмчит байгууллагад олгох татаас',
                    key: 1 - 1 - 3 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Хувийн хэвшлийн байгууллагад олгох татаас',
                    key: 1 - 1 - 3 - 2,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'УРСГАЛ ШИЛЖҮҮЛЭГ',
                key: 1 - 1 - 4,
                children: [
                  {
                    name: 'Засгийн газрын урсгал шилжүүлэг',
                    key: 1 - 1 - 4 - 1,
                    children: [
                      {
                        name: 'Засгийн газрын дотоод шилжүүлэг',
                        key: 1 - 1 - 4 - 1 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Засгийн газрын гадаад шилжүүлэг',
                        key: 1 - 1 - 4 - 1 - 2,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Бусад урсгал шилжүүлэг',
                    key: 1 - 1 - 4 - 2,
                    children: [
                      {
                        name: 'Нийгмийн даатгалын тэтгэвэр тэтгэмж',
                        key: 1 - 1 - 4 - 2 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Нийгмийн халамжийн тэтгэвэр, тэтгэмж',
                        key: 1 - 1 - 4 - 2 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Ажил олгогчоос олгох бусад тэтгэмж, урамшуулал',
                        key: 1 - 1 - 4 - 2 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Төрөөс иргэдэд олгох тэтгэмж, урамшуулал',
                        key: 1 - 1 - 4 - 2 - 4,
                        value: 1000,
                      },
                      {
                        name: 'Ээлжийн амралтаар нутаг явах унааны хөнгөлөлт',
                        key: 1 - 1 - 4 - 2 - 5,
                        value: 1000,
                      },
                      {
                        name: 'Тэтгэвэрт гарахад олгох нэг удаагийн мөнгөн тэтгэмж',
                        key: 1 - 1 - 4 - 2 - 6,
                        value: 1000,
                      },
                      {
                        name: 'Хөдөө орон нутагт тогтвор суурьшилтай ажилласан албан хаагчдад төрөөс үзүүлэх дэмжлэг',
                        key: 1 - 1 - 4 - 2 - 7,
                        value: 1000,
                      },
                      {
                        name: 'Нэг удаагийн тэтгэмж, шагнал урамшуулал',
                        key: 1 - 1 - 4 - 2 - 8,
                        value: 1000,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'ХӨРӨНГИЙН ЗАРДАЛ',
            key: 1 - 2,
            children: [
              { name: 'Барилга байгууламж', key: 1 - 2 - 1, value: 1000 },
              { name: 'Их засвар', key: 1 - 2 - 2, value: 1000 },
              { name: 'Тоног төхөөрөмж', key: 1 - 2 - 3, value: 1000 },
              { name: 'Бусад хөрөнгө', key: 1 - 2 - 4, value: 1000 },
              { name: 'Стратегийн нөөц хөрөнгө', key: 1 - 2 - 5, value: 1000 },
            ],
          },
        ],
      },
      {
        name: 'ЭPГЭЖ ТӨЛӨГДӨХ ТӨЛБӨРИЙГ ХАССАН ЦЭВЭР ЗЭЭЛ',
        key: 2,
        children: [
          { name: 'Эргэж төлөгдөх зээл', key: 2 - 1, value: 10000 },
          {
            name: 'Гадаадын төслийн зээлээс санхүүжих зээл',
            key: 2 - 2,
            value: 10000,
          },
        ],
      },
    ],
  },
];

export const TreeChartsDataIncome = [
  {
    name: 'ТӨСВИЙН НИЙТ ОРЛОГО',
    key: 'A',
    color: '#004594',
    children: [
      { name: 'ИРЭЭДҮЙН ӨВ САН', key: 3 - 1, value: 1000 },
      {
        name: 'ТОГТВОРЖУУЛАЛТЫН САН',
        key: 3 - 2,
        children: [
          {
            name: 'Аж ахуйн нэгжийн орлогын албан татвар',
            key: 3 - 2 - 1,
            value: 1000,
          },
          {
            name: 'Ашигт малтмалын нөөц ашигласны төлбөр',
            key: 3 - 2 - 2,
            value: 1000,
          },
          {
            name: 'Өсөн нэмэгдэх нөөцийн төлбөр',
            key: 3 - 2 - 3,
            value: 1000,
          },
        ],
      },
      {
        name: 'НИЙТ ТЭНЦВЭРЖҮҮЛСЭН ОРЛОГО БА ТУСЛАМЖИЙН ДҮН',
        key: 3 - 3,
        children: [
          {
            name: 'Татварын орлого',
            key: 3 - 3 - 1,
            children: [
              {
                name: 'Орлогын албан татвар',
                key: 3 - 3 - 1 - 1,
                children: [
                  {
                    name: 'Хувь хүний орлогын албан татвар',
                    key: 3 - 3 - 1 - 1 - 1,
                    children: [
                      {
                        name: 'Цалин, хөдөлмөрийн хөлс, шагнал, урамшуулал болон тэдгээртэй адилтгах хөдөлмөр эрхлэлтийн орлого',
                        key: 3 - 3 - 1 - 1 - 1 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Үйл ажиллагааны орлого',
                        key: 3 - 3 - 1 - 1 - 1 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Хөрөнгийн орлого',
                        key: 3 - 3 - 1 - 1 - 1 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Хөрөнгө борлуулсны орлого',
                        key: 3 - 3 - 1 - 1 - 1 - 4,
                        value: 1000,
                      },
                      {
                        name: 'Шинжлэх ухаан, утга зохиол, урлагийн бүтээл туурвих, шинэ бүтээл, бүтээгдэхүүний болон ашигтай загвар зохион бүтээх, спортын тэмцээн, урлагийн тоглолт зохион байгуулах, тэдгээрт оролцох замаар олсон орлого, тэдгээртэй адилтгах бусад орлого',
                        key: 3 - 3 - 1 - 1 - 1 - 5,
                        value: 1000,
                      },
                      {
                        name: 'Урлагийн тоглолт, спортын тэмцээний шагнал, наадмын бай шагнал',
                        key: 3 - 3 - 1 - 1 - 1 - 6,
                        value: 1000,
                      },
                      {
                        name: 'Шууд бус орлого',
                        key: 3 - 3 - 1 - 1 - 1 - 7,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Хувь хүний орлогын албан татварын буцаан олголт',
                    key: 3 - 3 - 1 - 1 - 2,
                    children: [
                      {
                        name: 'Хувь хүний орлогын албан татварын буцаан олголт',
                        key: 3 - 3 - 1 - 1 - 2 - 1,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Орлогыг нь тухай бүр тодорхойлох боломжгүй ажил, үйлчилгээ хувиараа эрхлэгч иргэний орлогын албан татвар',
                    key: 3 - 3 - 1 - 1 - 3,
                    children: [
                      {
                        name: 'Орлогыг нь тухай бүр тодорхойлох боломжгүй ажил, үйлчилгээ хувиараа эрхлэгч иргэний орлогын албан татвар',
                        key: 3 - 3 - 1 - 1 - 3 - 1,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'ААН-ын орлогын албан татвар',
                    key: 3 - 3 - 1 - 1 - 4,
                    children: [
                      {
                        name: 'Орлогыг нь тухай бүр тодорхойлох боломжгүй ажил, үйлчилгээ хувиараа эрхлэгч иргэний орлогын албан татвар',
                        key: 3 - 3 - 1 - 1 - 4 - 1,
                        value: 1000,
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Нийгмийн даатгалын орлого',
                key: 3 - 3 - 1 - 2,
                children: [
                  {
                    name: 'Тэтгэврийн даатгалын шимтгэл',
                    key: 3 - 3 - 1 - 2 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Тэтгэмжийн даатгалын шимтгэл',
                    key: 3 - 3 - 1 - 2 - 2,
                    value: 1000,
                  },
                  {
                    name: 'ҮОМШ өвчний даатгалын шимтгэл',
                    key: 3 - 3 - 1 - 2 - 3,
                    value: 1000,
                  },
                  {
                    name: 'Ажилгүйдлийн даатгалын шимтгэл',
                    key: 3 - 3 - 1 - 2 - 4,
                    value: 1000,
                  },
                  {
                    name: 'ЭМД-ын хураамжийн шимтгэл',
                    key: 3 - 3 - 1 - 2 - 5,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Хөрөнгийн албан татвар',
                key: 3 - 3 - 1 - 3,
                children: [
                  {
                    name: 'Үл хөдлөх эд хөрөнгийн албан татвар',
                    key: 3 - 3 - 1 - 3 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Бууны албан татвар',
                    key: 3 - 3 - 1 - 3 - 2,
                    value: 1000,
                  },
                  {
                    name: 'Автотээврийн болон өөрөө явагч хэрэгслийн албан татвар',
                    key: 3 - 3 - 1 - 3 - 3,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Нэмэгдсэн өртгийн албан татвар',
                key: 3 - 3 - 1 - 4,
                children: [
                  {
                    name: 'Дотоодын барааны НӨАТ',
                    key: 3 - 3 - 1 - 4 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Импортын барааны НӨАТ',
                    key: 3 - 3 - 1 - 4 - 2,
                    value: 1000,
                  },
                  {
                    name: 'НӨАТ-ын буцаан олголт',
                    key: 3 - 3 - 1 - 4 - 3,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Онцгой албан татвар',
                key: 3 - 3 - 1 - 5,
                children: [
                  {
                    name: 'Дотоодын архи, дарсны онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Дотоодын тамхины онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 2,
                    value: 1000,
                  },
                  {
                    name: 'Дотоодын пивоны онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 3,
                    value: 1000,
                  },
                  {
                    name: 'Импортын архи, дарсны онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 4,
                    value: 1000,
                  },
                  {
                    name: 'Импортын тамхины онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 5,
                    value: 1000,
                  },
                  {
                    name: 'Импортын пивоны онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 6,
                    value: 1000,
                  },
                  {
                    name: 'Суудлын автомашины онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 7,
                    value: 1000,
                  },
                  {
                    name: 'Автобензин, дизелийн түлшний онцгой албан татвар',
                    key: 3 - 3 - 1 - 5 - 8,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Тусгай зориулалтын орлого',
                key: 3 - 3 - 1 - 6,
                children: [
                  {
                    name: 'Автобензин, дизелийн түлшний албан татвар',
                    key: 3 - 3 - 1 - 6 - 1,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Гадаад үйл ажиллагааны орлого',
                key: 3 - 3 - 1 - 7,
                children: [
                  {
                    name: 'Импортын гаалийн албан татвар',
                    key: 3 - 3 - 1 - 7 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Экспортын гаалийн албан татвар',
                    key: 3 - 3 - 1 - 7 - 2,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Бусад татвар, төлбөр, хураамж',
                key: 3 - 3 - 1 - 8,
                children: [
                  {
                    name: 'Газрын төлбөр',
                    key: 3 - 3 - 1 - 8 - 1,
                    children: [
                      {
                        name: 'Газрын төлбөр',
                        key: 3 - 3 - 1 - 8 - 1 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Дуудлага худалдаа',
                        key: 3 - 3 - 1 - 8 - 1 - 2,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Байгалийн нөөц ашигласны төлбөр',
                    key: 3 - 3 - 1 - 8 - 2,
                    children: [
                      {
                        name: 'Ойн нөөц ашигласны төлбөр',
                        key: 3 - 3 - 1 - 8 - 2 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Ан амьтны нөөц ашигласны төлбөр',
                        key: 3 - 3 - 1 - 8 - 2 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Ус, рашааны нөөц ашигласны төлбөр',
                        key: 3 - 3 - 1 - 8 - 2 - 3,
                        value: 1000,
                      },
                      {
                        name: 'Байгалийн ургамлын нөөц ашигласны төлбөр',
                        key: 3 - 3 - 1 - 8 - 2 - 4,
                        value: 1000,
                      },
                    ],
                  },
                  {
                    name: 'Бусад татвар',
                    key: 3 - 3 - 1 - 8 - 3,
                    children: [
                      {
                        name: 'Бусад татвар',
                        key: 3 - 3 - 1 - 8 - 3 - 1,
                        value: 1000,
                      },
                      {
                        name: 'Нийслэл хотын албан татвар',
                        key: 3 - 3 - 1 - 8 - 3 - 2,
                        value: 1000,
                      },
                      {
                        name: 'Нохойны албан татвар',
                        key: 3 - 3 - 1 - 8 - 3 - 3,
                        value: 1000,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'Татварын бус орлого',
            key: 3 - 3 - 2,
            children: [
              {
                name: 'Нийтлэг татварын бус орлого',
                key: '3-3-2-1',
                children: [
                  {
                    name: 'Хувьцааны ногдол ашиг',
                    key: '3-3-2-1-1',
                    value: 1000,
                  },
                  {
                    name: 'Хүүгийн орлого',
                    key: 3 - 3 - 2 - 1 - 2,
                    value: 1000,
                  },
                  {
                    name: 'Торгуулийн орлого',
                    key: 3 - 3 - 2 - 1 - 3,
                    value: 1000,
                  },
                  {
                    name: 'Төсөв байгууллагын өөрийн орлого /үндсэн/',
                    key: 3 - 3 - 2 - 1 - 4,
                    value: 1000,
                  },
                  {
                    name: ' Төсөв байгууллагын өөрийн орлого /туслах/',
                    key: 3 - 3 - 2 - 1 - 5,
                    value: 1000,
                  },
                  {
                    name: 'Түрээсийн орлого',
                    key: 3 - 3 - 2 - 1 - 6,
                    value: 1000,
                  },
                  {
                    name: 'Газрын тосны орлого',
                    key: 3 - 3 - 2 - 1 - 7,
                    value: 1000,
                  },
                  {
                    name: 'Навигацийн орлого',
                    key: 3 - 3 - 2 - 1 - 8,
                    value: 1000,
                  },
                  {
                    name: 'Бусад орлого',
                    key: 3 - 3 - 2 - 1 - 9,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Хөрөнгийн орлого',
                key: 3 - 3 - 2 - 2,
                children: [
                  {
                    name: 'Төрийн болон орон нутгийн өмчид бүртгэлтэй хөрөнгө борлуулсны орлого',
                    key: 3 - 3 - 2 - 2 - 1,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Тусламжийн орлого',
                key: 3 - 3 - 2 - 3,
                children: [
                  {
                    name: 'Хандив тусламж /дотоод/',
                    key: 3 - 3 - 2 - 3 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Хандив тусламж /гадаад/',
                    key: 3 - 3 - 2 - 3 - 1,
                    value: 1000,
                  },
                ],
              },
              {
                name: 'Улсын төсөв орон нутгийн төсөв хоорондын шилжүүлэг',
                key: 3 - 3 - 2 - 4,
                children: [
                  {
                    name: 'Тусгай зориулалтын шилжүүлгийн орлого',
                    key: 3 - 3 - 2 - 4 - 1,
                    value: 1000,
                  },
                  {
                    name: 'Орон нутгийн хөгжлийн нэгдсэн сангаас шилжүүлсэн орлого',
                    key: 3 - 3 - 2 - 4 - 2,
                    value: 1000,
                  },
                  {
                    name: 'Улсын төсвөөс орон нутгийн төсөвт олгох санхүүгийн дэмжлэг',
                    key: 3 - 3 - 2 - 4 - 3,
                    value: 1000,
                  },
                  {
                    name: 'Орон нутгийн төсвөөс улсын төсөвт төвлөрүүлэх шилжүүлэг',
                    key: 3 - 3 - 2 - 4 - 4,
                    value: 1000,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export const TreeChartsDataTEZ = [
  {
    name: 'Улсын ТЕЗ',
    key: 'a',
    value: '5,202,591.9',
    children: [
      {
        name: 'Монгол Улсын Ерөнхийлөгч',
        key: 1,
        value: 3748.5,
      },
      {
        name: 'УИХ',
        key: 2,
        value: 3748.5393,
      },
      {
        name: 'Үндсэн хуулийн цэц',
        key: 3,
        value: 802.0526,
      },
      {
        name: 'Улсын Дээд Шүүх',
        key: 4,
        value: 1915.7833,
      },
      {
        name: 'Шүүхийн ерөнхий зөвлөл',
        key: 5,
        value: 22054.4969,
      },
      {
        name: 'Улсын ерөнхий прокурор',
        key: 6,
        value: 15353.7693,
      },
      {
        name: 'ҮАБЗ',
        key: 7,
        value: 2399.0269,
      },
      {
        name: 'Ерөнхий сайд',
        key: 8,
        value: 297572.7033,
      },
      {
        name: 'Шадар сайд',
        key: 9,
        value: 81950.1387,
      },
      {
        name: 'ЗГХЭГ',
        key: 10,
        value: 28914.4547,
      },
      {
        name: 'Сангийн сайд',
        key: 11,
        value: 1155130.1536,
      },
      {
        name: 'Хууль зүйн сайд',
        key: 12,
        value: 283378.022,
      },
      {
        name: 'БОрчин, ногоон хөгжлийн сайд',
        key: 13,
        value: 59598.6767,
      },
      {
        name: 'Батлан хамгаалахын сайд',
        key: 14,
        value: 118311.6939,
      },
      {
        name: 'Боловсрол, ШУС-ын сайд',
        key: 15,
        value: 148873.576,
      },
      {
        name: 'Гадаад харилцааны сайд',
        key: 16,
        value: 36488.7527,
      },
      {
        name: 'Уул уурхай, ХҮ-ийн сайд',
        key: 17,
        value: 5355.9363,
      },
      {
        name: 'Уул уурхай, ХҮ-ийн сайд',
        key: 17,
        value: 5355.9363,
      },
      {
        name: 'ХХААХҮ-ийн сайд',
        key: 18,
        value: 289889.4161,
      },
      {
        name: 'Эрүүл мэндийн сайд',
        key: 19,
        value: 273563.6915,
      },
      {
        name: 'Төрийн албаны зөвлөл',
        key: 20,
        value: 2302.7879,
      },
      {
        name: 'Сонгуулийн ерөнхий хороо',
        key: 21,
        value: 20953.3964,
      },
      {
        name: 'Хүний эрхийн үндэсний хороо',
        key: 22,
        value: 492.4382,
      },
      {
        name: 'МУ-ын ерөнхий аудитор',
        key: 23,
        value: 8397.6153,
      },
      {
        name: 'Санхүүгийн зохицуулах хороо',
        key: 24,
        value: 2114.0417,
      },
      {
        name: 'Үндэсний Статистикийн газар',
        key: 25,
        value: 9044.9801,
      },
      {
        name: 'ЦАУЗБУК',
        key: 26,
        value: 49111.509,
      },
      {
        name: 'Зам тээврийн хөгжлийн сайд',
        key: 27,
        value: 85894.8138,
      },
      {
        name: 'Барилга хот байгуулалтын сайд',
        key: 28,
        value: 36962.9204,
      },
      {
        name: 'Эрчим хүчний сайд',
        key: 29,
        value: 28587.6932,
      },
      {
        name: 'Авилгалтай тэмцэх газар',
        key: 30,
        value: 9044.9801,
      },
      {
        name: 'ХНХЯам',
        key: 31,
        value: 630987.246,
      },
      {
        name: 'УНДЕГ',
        key: 63,
        value: 0,
      },
      // {
      //   name: 'НДС',
      //   key: 64,
      //   value: 1040556.0321,
      // },
      // {
      //   name: 'ЭМДС',
      //   key: 65,
      //   value: 107906.7988,
      // },
    ],
  },
  {
    name: 'Орон нутгийн ТЕЗ',
    key: 'b',
    value: '1,481,843.4',
    children: [
      {
        name: 'Архангай аймаг',
        key: 32,
        value: 33650.3079,
      },
      {
        name: 'Баян-Өлгий аймаг',
        key: 33,
        value: 34865.5439,
      },
      {
        name: 'Баянхонгор аймаг',
        key: 34,
        value: 41103.2691,
      },
      {
        name: 'Булган аймаг',
        key: 35,
        value: 27379.0948,
      },
      {
        name: 'Говь-Алтай аймаг',
        key: 36,
        value: 28197.1935,
      },
      {
        name: 'Дорноговь аймаг',
        key: 37,
        value: 37111.4173,
      },
      {
        name: 'Дорнод аймаг',
        key: 38,
        value: 34050.2933,
      },
      {
        name: 'Дундговь аймаг',
        key: 39,
        value: 26273.1864,
      },
      {
        name: 'Завхан аймаг',
        key: 40,
        value: 34313.8691,
      },
      {
        name: 'Өвөрхангай аймаг',
        key: 41,
        value: 40869.7047,
      },
      {
        name: 'Өмнөговь аймаг',
        key: 42,
        value: 85016.6426,
      },
      {
        name: 'Сүхбаатар аймаг',
        key: 43,
        value: 31363.729,
      },
      {
        name: 'Сэлэнгэ аймаг',
        key: 44,
        value: 41507.674,
      },
      {
        name: 'Төв аймаг',
        key: 45,
        value: 44606.4782,
      },
      {
        name: 'Увс аймаг',
        key: 46,
        value: 39551.6592,
      },
      {
        name: 'Ховд аймаг',
        key: 47,
        value: 33227.5142,
      },
      {
        name: 'Хөвсгөл аймаг',
        key: 48,
        value: 46110.0625,
      },
      {
        name: 'Хэнтий аймаг',
        key: 49,
        value: 34147.5407,
      },
      {
        name: 'Дархан-Уул аймаг',
        key: 50,
        value: 29880.9454,
      },
      {
        name: 'Нийслэл',
        key: 51,
        value: 329849.4046,
      },
      {
        name: 'Орхон аймаг',
        key: 52,
        value: 43498.6962,
      },
      {
        name: 'Говь-сүмбэр аймаг',
        key: 53,
        value: 8194.4717,
      },
      // {
      //   name: 'Хан-Уул дүүрэг',
      //   key: 54,
      //   value: 43475.6235,
      // },
      // {
      //   name: 'Багануур дүүрэг',
      //   key: 55,
      //   value: 10056.6808,
      // },
      // {
      //   name: 'Баянзүрх дүүрэг',
      //   key: 56,
      //   value: 64330.4184,
      // },
      // {
      //   name: 'Налайх дүүрэг',
      //   key: 57,
      //   value: 12244.3968,
      // },
      // {
      //   name: 'Баянгол дүүрэг',
      //   key: 58,
      //   value: 50595.5998,
      // },
      // {
      //   name: 'Сүхбаатар дүүрэг',
      //   key: 59,
      //   value: 50600.481,
      // },
      // {
      //   name: 'Чингэлтэй дүүрэг',
      //   key: 60,
      //   value: 42473.3921,
      // },
      // {
      //   name: 'Багахангай дүүрэг',
      //   key: 61,
      //   value: 2774.6234,
      // },
      // {
      //   name: 'Сонгинохайрхан дүүрэг',
      //   key: 62,
      //   value: 55278.9619,
      // },
      {
        name: 'УНДЕГ',
        key: 63,
        value: 0,
      },
      // {
      //   name: 'НДС',
      //   key: 64,
      //   value: 1040556.0321,
      // },
      // {
      //   name: 'ЭМДС',
      //   key: 65,
      //   value: 107906.7988,
      // },
    ],
  },
];
export const TreeChartsDataHutulbur = [
  {
    name: 'Ерөнхийлөгч, ХТ болон ГЗУдирдл',
    key: 1,
    value: 28028.17077395,
  },
  {
    name: 'Ерөнхий төлөвлөлт, санх төс ха',
    key: 2,
    value: 934082.88360579,
  },
  {
    name: 'Гадаад харилцаа',
    key: 3,
    value: 28095.18656533,
  },
  {
    name: 'Төрийн нийтлэг бусад үйлчилгээ',
    key: 4,
    value: 77984.61999029,
  },
  {
    name: 'Засаг захиргааны түвшин хоорон',
    key: 5,
    value: 149932.006653,
  },
  {
    name: 'Батлан хамгаалах',
    key: 6,
    value: 78044.9139963,
  },
  {
    name: 'Эрх зүй, НХЖ, аюулгүй байдал',
    key: 7,
    value: 268870.36703235,
  },
  {
    name: 'Хөдөө аж ахуй, газар тариалан,',
    key: 8,
    value: 258439.76470186,
  },
  {
    name: 'Эрчим хүч, уул уурхай',
    key: 9,
    value: 23902.75054717,
  },
  {
    name: 'Зам тээвэр',
    key: 10,
    value: 72796.8376677,
  },
  {
    name: 'Худалдаа',
    key: 11,
    value: 1404.51246677,
  },
  {
    name: 'Харилцаа холбоо',
    key: 12,
    value: 10724.93756971,
  },
  {
    name: 'Хүрээлэн буй орчин',
    key: 13,
    value: 37722.81262302,
  },
  {
    name: 'Барилга,хот байгуулалт, НААхуй',
    key: 14,
    value: 37089.29696306,
  },
  {
    name: 'Эрүүл мэнд',
    key: 15,
    value: 344092.63299499,
  },
  {
    name: 'Соёл урлаг, спорт, аялал жуулч',
    key: 16,
    value: 17272.71733481,
  },
  {
    name: 'Боловсрол, шинжлэх ухаан',
    key: 17,
    value: 668617.74518035,
  },
  {
    name: 'Нийгмийн хамгаалал',
    key: 18,
    value: 785630.55293606,
  },
  {
    name: 'Хөдөлмөр эрхлэлт',
    key: 19,
    value: 7423.33832682,
  },
  {
    name: 'Нийгм бүлэгт чиглэсэн хөт',
    key: 20,
    value: 54498.22280197,
  },
  {
    name: 'Ангилагдаагүй бусад',
    key: 21,
    value: 783.63342686,
  },
];
export const TreeChartsDataZoriulalt = [
  {
    name: 'Үйл ажиллагааны урсгал зардлын',
    key: 1,
    value: 1281221.93479556,
  },
  {
    name: 'Бусдаар гүйцэтгүүлсэн нийтлэг',
    key: 2,
    value: 31057.98921648,
  },
  {
    name: 'Бараа үйлчилгээний бусад нийтл',
    key: 3,
    value: 3118.234278,
  },
  {
    name: 'Татаасын зориулалт',
    key: 4,
    value: 274079.1154,
  },
  {
    name: 'ЗГ-ын дотоод,гадаад шилжүүлэг',
    key: 5,
    value: 780475.12961005,
  },
  {
    name: 'Нийгмийн даатгалын шилжүүлгийн',
    key: 6,
    value: 5683,
  },
  {
    name: 'Нийгмийн халамжийн шилжүүлгийн',
    key: 7,
    value: 475829.60343028,
  },
  {
    name: 'Бусад шилжүүлгийн зориулалт',
    key: 8,
    value: 138542.32263697,
  },
  {
    name: 'Хөрөнгийн зардлын зориулалт',
    key: 9,
    value: 312118.96688898,
  },
  {
    name: 'Ангилагдаагүй нөөцийн зориулал',
    key: 10,
    value: 11601.03180875,
  },
  {
    name: 'Хувь эзэмшил, санхүүгийн хөрөн',
    key: 11,
    value: 0,
  },
  {
    name: 'Нөөц хөрөнгийн зориулалт',
    key: 12,
    value: 12500.29705519,
  },
  {
    name: 'Зээл, өрийн үйлчилгээ',
    key: 13,
    value: 298095.59407514,
  },
  {
    name: 'Төрийн нийтлэг арга хэмжээ',
    key: 14,
    value: 19618.52956447,
  },
  {
    name: 'Эрүүл мэндийн арга хэмжээ',
    key: 15,
    value: 23070.22136759,
  },
  {
    name: 'Байгаль орчныг хамгаалах арга',
    key: 16,
    value: 19628.94474234,
  },
  {
    name: 'Боловсрол, шинжлэх ухаан',
    key: 17,
    value: 29680.95672826,
  },
  {
    name: 'Соёлын арга хэмжээ',
    key: 18,
    value: 259.092457,
  },
  {
    name: 'Биеийн тамир, спортын арга хэм',
    key: 19,
    value: 4943.38413914,
  },
  {
    name: 'Хот суурин, нийтийн үйлчилгээ',
    key: 20,
    value: 80.751,
  },
  {
    name: 'Батлан хамгаалах, зэвсэгт хүчи',
    key: 21,
    value: 3988.4931029,
  },
  {
    name: 'Уул уурхай, дэд бүтэц, тээвэр,',
    key: 22,
    value: 2047.8284,
  },
  {
    name: 'Хүнс, хөдөө аж ахуй, үйлдвэрлэ',
    key: 23,
    value: 238919.20122743,
  },
  {
    name: 'Хөдөлмөр эрхлэлт',
    key: 24,
    value: 10727.41211388,
  },
  {
    name: 'Болзошгүй өр төлбөрийг мөнгөжү',
    key: 25,
    value: 3309.55764519,
  },
  {
    name: 'Баяр, наадам, тэмдэглэлт ой',
    key: 26,
    value: 3988.4931029,
  },
  {
    name: 'Гадаад харилцаа',
    key: 27,
    value: 7.32149879,
  },
  {
    name: 'Эдийн засгийн бусад',
    key: 28,
    value: 4343.99282343,
  },
  {
    name: 'Ангилагдаагүй бусад зориулалт,',
    key: 29,
    value: 455.49815234,
  },
  {
    name: 'Нэг удаагийн арга хэмжээ',
    key: 30,
    value: 0,
  },
];
// export const TreeChartsDataZoriulalt = [
//   {
//     name: 'Орлогын албан татвар',
//     monUe: '960932.9',
//     batlagdsan: '960932.9',
//     tosov: '1023022',
//     guitsetgel: '773,816.7',
//     batlagdsanTosov: '78%',
//     tuhainSar: '78%',
//   },
//   {
//     name: 'Нэмэгдсэн өртөгийн албан татвар',
//     monUe: '860932.9',
//     batlagdsan: '860932.9',
//     tosov: '63022',
//     guitsetgel: '473,816.7',
//     batlagdsanTosov: '72%',
//     tuhainSar: '74%',
//   },
//   {
//     name: 'Нийгмийн даатгалын орлого',
//     monUe: '760932.9',
//     batlagdsan: '460932.9',
//     tosov: '923022',
//     guitsetgel: '567,816.7',
//     batlagdsanTosov: '34%',
//     tuhainSar: '78%',
//   },
//   {
//     name: 'Онцгой албан татвар',
//     monUe: '953932.9',
//     batlagdsan: '644932.9',
//     tosov: '435722',
//     guitsetgel: '167,816.7',
//     batlagdsanTosov: '94%',
//     tuhainSar: '48%',
//   },
//   {
//     name: 'Гадаад үйл ажиллагааны зорилго',
//     monUe: '840932.9',
//     batlagdsan: '568932.9',
//     tosov: '563022',
//     guitsetgel: '987,816.7',
//     batlagdsanTosov: '54%',
//     tuhainSar: '38%',
//   },
//   {
//     name: 'Хөрөнгө албан татвар',
//     monUe: '160932.9',
//     batlagdsan: '145932.9',
//     tosov: '923022',
//     guitsetgel: '567,816.7',
//     batlagdsanTosov: '34%',
//     tuhainSar: '78%',
//   },
//   {
//     name: 'Тусгай зориулалтын орлого',
//     monUe: '890932.9',
//     batlagdsan: '345932.9',
//     tosov: '653022',
//     guitsetgel: '367,816.7',
//     batlagdsanTosov: '14%',
//     tuhainSar: '48%',
//   },
// ];
export const TableDataColumn = [
  {
    title: 'Үзүүлэлт',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Дүн',
    dataIndex: 'value',
    key: 'value',
  },
];

// jiliin uzuulelt

export const chartDataDemos = [
  {
    type: 'jil',
    year: '2017',
    category: ' Нэгдсэн төсөв 2017',
    first: 1698744.8,
    second: 2052267.3,
    third: -1255682.5,
  },
  {
    type: 'jil',
    year: '2018',
    category: 'Нэгдсэн төсөв 2018',
    first: 8698744.8,
    second: 4852267.3,
    third: -3255682.5,
  },
  {
    type: 'jil',
    year: '2019',
    category: 'Нэгдсэн төсөв 2019',
    first: 7198744.8,
    second: 5852267.3,
    third: -1255682.5,
  },
  {
    type: 'jil',
    year: '2020',
    category: 'Нэгдсэн төсөв 2020',
    first: 506538.3,
    second: 506538.3,
    third: 0.0,
  },
  {
    type: 'ulsin',
    year: '2017',
    category: 'Улсын төсөв 2017',
    first: 3176844.2,
    second: 3371905.4,
    third: -2049946.4,
  },
  {
    type: 'niigmin',
    year: '2017',
    category: 'Нийгмийн даатгалын төсөв 2017',
    first: 2466923.4,
    second: 3185437.9,
    third: -1739085.1,
  },

  {
    type: 'ulsin',
    year: '2018',
    category: 'Улсын төсөв 2018',
    first: 3176844.2,
    second: 3371905.4,
    third: -2049946.4,
  },
  {
    type: 'niigmin',
    year: '2018',
    category: 'Нийгмийн даатгалын төсөв 2018',
    first: 2466923.4,
    second: 3185437.9,
    third: -1739085.1,
  },

  {
    type: 'ulsin',
    year: '2019',
    category: 'Улсын төсөв 2019',
    first: 3176844.2,
    second: 3371905.4,
    third: -2049946.4,
  },
  {
    type: 'niigmin',
    year: '2019',
    category: 'Нийгмийн даатгалын төсөв 2019',
    first: 2466923.4,
    second: 3185437.9,
    third: -1739085.1,
  },
  {
    type: 'ulsin',
    year: '2020',
    category: 'Улсын төсөв 2020',
    first: 211234.9,
    second: 214277.4,
    third: -3042.5,
  },
  {
    type: 'niigmin',
    year: '2020',
    category: 'Нийгмийн даатгалын төсөв 2020',
    first: 180943.2,
    second: 154963.1,
    third: 25980.2,
  },

  {
    type: 'eruulMend',
    year: '2017',
    category: 'Эрүүл мэндийн даатгалын төсөв 2017',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'eruulMend',
    year: '2018',
    category: 'Эрүүл мэндийн даатгалын төсөв 2018',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'eruulMend',
    year: '2019',
    category: 'Эрүүл мэндийн даатгалын төсөв 2019',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'eruulMend',
    year: '2020',
    category: 'Эрүүл мэндийн даатгалын төсөв 2020',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'OronNutag',
    year: '2017',
    category: 'Орон нутгийн төсөв 2017',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'OronNutag',
    year: '2018',
    category: 'Орон нутгийн төсөв 2018',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'OronNutag',
    year: '2019',
    category: 'Орон нутгийн төсөв 2019',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
  {
    type: 'OronNutag',
    year: '2020',
    category: 'Орон нутгийн төсөв 2020',
    first: 180943.2,
    second: 154963.1,
    third: -25980.2,
  },
];

export const chartBat = 'columnChartBat';
