/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */

import React, { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import mapDataAsia from '../../../localBudget/localTez/data/mapData';
import OrgContext from '../../../../context/OrgContext';
import { getOrgCountByRefCity } from '../../../../service/organizationServices';

require('highcharts/modules/map')(Highcharts);

export default function MglMap() {
  const { setMain, stateMap, setMapMain, form } = useContext(OrgContext);

  const [data, setData] = useState([
    ['mn-da', 0],
    ['mn-ub', 0],
    ['mn-hg', 0],
    ['mn-uv', 0],
    ['mn-dg', 0],
    ['mn-og', 0],
    ['mn-hn', 0],
    ['mn-bh', 0],
    ['mn-ar', 0],
    ['mn-dz', 0],
    ['mn-ga', 0],
    ['mn-hd', 0],
    ['mn-bo', 0],
    ['mn-bu', 0],
    ['mn-er', 0],
    ['mn-sl', 0],
    ['mn-oh', 0],
    ['mn-du', 0],
    ['mn-to', 0],
    ['mn-gs', 0],
    ['mn-dd', 0],
    ['mn-sb', 0],
  ]);

  const stateId = {
    'mn-ub': 1,
    'mn-dd': 2,
    'mn-sb': 3,
    'mn-hn': 4,
    'mn-to': 5,
    'mn-gs': 6,
    'mn-sl': 7,
    'mn-dg': 8,
    'mn-da': 9,
    'mn-og': 10,
    'mn-du': 11,
    'mn-er': 12,
    'mn-oh': 13,
    'mn-bu': 14,
    'mn-bh': 15,
    'mn-ar': 16,
    'mn-hg': 17,
    'mn-dz': 18,
    'mn-ga': 19,
    'mn-bo': 20,
    'mn-hd': 21,
    'mn-uv': 22,
  };

  const mapOptions = {
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    chart: {
      height: '600px',
    },

    colors: ['#F5F5F5'],
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoomTo: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    tooltip: {
      animation: true,
      backgroundColor: '#ffff',
      useHTML: true,
      shape: 'square',
      borderColor: '#ffff',
      style: {
        fontSize: '15px',
      },
    },
    plotOptions: {
      map: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: 'normal',
            fontFamily: 'arial',
            fontSize: '13px',
            color: '#395B82',
            fill: 'white',
            stroke: 'white',
            strokeWidth: '0px',
            strokelineJoin: 'unset',
            hover: {
              color: 'white',
            },
          },
        },
      },
      series: {
        point: {
          events: {
            click() {
              const selectedMap = stateMap?.stateAimag?.find(
                el => el.id === stateId[this['hc-key']]
              );
              if (selectedMap) {
                setMain({ refCityId: selectedMap.id, refDistrictId: null });
                setMapMain({
                  refCityId: selectedMap.id,
                  refCityCode: selectedMap.code,
                });
                form.resetFields();
              }
            },
          },
        },
      },
    },
    series: [
      {
        mapData: mapDataAsia,
        showInLegend: false,
        name: 'Монгол улс',
        borderColor: '#9C9C9C',
        nullColor: 'rgba(200, 200, 200, 0.3)',
        data,
        states: {
          hover: {
            color: '#81c5f8',
          },
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
      },
    ],
  };

  useEffect(() => {
    const params = {};

    getOrgCountByRefCity(params).then(result => {
      if (result) {
        const count = [];
        result?.forEach(element => {
          const city = Object.keys(stateId).find(
            key => stateId[key] === element.refCityId
          );

          if (city) {
            count.push([city, element.countOfOrg || 0]);
          }
        });

        if (count.length > 0) {
          setData(count);
        }
      }
    });
  }, []);

  return (
    <div style={{ width: '100%', height: '90%' }}>
      <HighchartsReact
        options={mapOptions}
        constructorType="mapChart"
        highcharts={Highcharts}
      />
    </div>
  );
}
