import React from 'react';
import { Col, Table } from 'antd';
import styles from './style.module.scss';

export default function ExampleTable({ columns, datasource }) {
  return (
    <Col span={24} className={styles.yearChartTable}>
      <Table
        size="small"
        dataSource={datasource}
        scroll={{ y: 373 }}
        bordered
        pagination={false}
      >
        {columns}
      </Table>
    </Col>
  );
}
