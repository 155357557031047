import { Table } from 'antd';
import React from 'react';
import { convertCurrencyFormat } from '../../../tools/Tools';
import styles from './style.module.scss';

export default function DataTables({
  column,
  budgetData,
  valueRadio,
  loading,
}) {
  return (
    <>
      <div className={styles.localIncometable}>
        <Table
          dataSource={budgetData}
          bordered
          size="small"
          pagination={false}
          scroll={{ y: 500 }}
          columns={column}
          loading={loading}
          summary={pageData => {
            let sdSum = 0;
            let tzdSum = 0;
            let onhSum = 0;

            pageData.forEach(row => {
              if (valueRadio === 'performance') {
                sdSum += Number(row.sdPerf || 0);
                tzdSum += Number(row.tzdPerf || 0);
                onhSum += Number(row.onhPerf || 0);
              } else {
                sdSum += Number(row.sdBudget || 0);
                tzdSum += Number(row.tzdBudget || 0);
                onhSum += Number(row.onhBudget || 0);
              }
            });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>Нийт:</Table.Summary.Cell>
                  <Table.Summary.Cell />
                  <Table.Summary.Cell align="right">
                    {convertCurrencyFormat(sdSum)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    {convertCurrencyFormat(tzdSum)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    {convertCurrencyFormat(onhSum)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    </>
  );
}
