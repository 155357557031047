import React from 'react';
import { Table } from 'antd';
import styles from './style.module.scss';
import { convertCurrencyFormat } from '../../../tools/Tools';

function DataTables({ column, budgetData, loading }) {
  return (
    <>
      <div className={styles.localIncometable}>
        <Table
          dataSource={budgetData}
          pagination={false}
          bordered
          size="small"
          loading={loading}
          columns={column}
          summary={pageData => {
            let budgetSum = 0;
            let performanceSum = 0;

            pageData.forEach(({ budget, performance }) => {
              budgetSum += Number(budget || 0);
              performanceSum += Number(performance || 0);
            });

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>Нийт:</Table.Summary.Cell>
                  <Table.Summary.Cell />
                  <Table.Summary.Cell align="right">
                    {convertCurrencyFormat(budgetSum)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    {convertCurrencyFormat(performanceSum)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    </>
  );
}
export default DataTables;
