import React, { useRef } from 'react';
import { Carousel } from 'antd';
import styles from './style.module.scss';
import banner from '../../img/investment/InvestmentBanner.svg';
import BannerTitle from '../../components/bannerTitle/BannerTitle';

export default function Banner() {
  const carousel = useRef();

  return (
    <div className={styles.bannerContainer}>
      <Carousel
        dots={false}
        ref={ref => {
          carousel.current = ref;
        }}
        autoplay={false}
      >
        <div>
          <img src={banner} alt="banner" style={{ width: '100%' }} />
          <BannerTitle title="Хөрөнгө оруулалт" />
        </div>
      </Carousel>
    </div>
  );
}

