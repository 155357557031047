export const FILTER_MODE = {
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  CONTAINS: 'contains',
  EXACT: 'exact',
  LT: 'lt',
  LTE: 'lte', // LESS THAN EQUALS
  GT: 'gt',
  GTE: 'gte', // GREATER_THAN_EQUALS
  EQUALS: 'equals',
  NOT: 'not',
  NOT_STARTS_WITH: 'notStartsWith',
  IN: 'in',
  DATE_BETWEEN: 'dateBetween',
};
