/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Divider, Typography } from 'antd';
import styles from './style.module.scss';

const { Text } = Typography;

export default function Help({ goToTabOrg }) {
  return (
    <div>
      <Text strong className={styles.isShowHeader}>
        Иргэний хяналт
      </Text>
      <br />
      <Text strong className={styles.isShowTitles}>
        Иргэн Та, Шилэн дансны тухай хуулийн хэрэгжилтийн явц, мэдээллийн
        зөрчил, дутагдал болон шаардлагыг Шилэн дансны тухай хуулийн 9 дүгээр
        зүйлд заасны дагуу дараах үе шаттай хүргэж, хуулийн хэрэгжилтэд хяналт
        тавих боломжтой.
      </Text>
      <br />
      <div className={styles.isShowDiv}>
        {goToTabOrg ? (
          <Text strong className={styles.isShowTitles}>
            1. Төрийн байгууллагад
            <a onClick={goToTabOrg}> гомдол гаргах</a>.
          </Text>
        ) : (
          <Text strong className={styles.isShowTitles}>
            1. Төрийн байгууллагад гомдол гаргах.
          </Text>
        )}
        <br />
        <Text strong className={styles.isShowTitles}>
          2. Хэрэв төрийн байгууллага таны гомдлыг хүлээн аваагүй эсвэл тодорхой
          арга хэмжээ авч хариу мэдэгдээгүй бол энэ талаарх гомдол, мэдээллийг
          Төрийн аудитын байгууллагад хүргүүлж байгууллагын үйл ажиллагаанд
          аудитын дүгнэлт гаргуулах хүсэлт гаргаж болно.{' '}
          <a
            href="https://open.audit.mn/citizen_sanal"
            target="_blank"
            rel="noreferrer"
          >
            Хүсэлт илгээх
          </a>
        </Text>
        <br />
        <Text strong className={styles.isShowTitles}>
          3. Иргэн, хуулийн этгээд, татвар төлөгчийн эрх ашиг зөрчигдсөн гэж
          үзвэл захиргааны хэргийн шүүхэд гомдол гаргах эрхтэй.
        </Text>
      </div>
      <Divider style={{ margin: '8px 0px' }} />
    </div>
  );
}

