import { getRequest, postService, putService } from './Service';

const serviceBaseUrl = '/gap-notification-service';

const serviceComments = `${serviceBaseUrl}/comments`;
const serviceCreateComment = `${serviceComments}/saveComment`;
const serviceAddLikeComment = `${serviceComments}/addLike`;
const serviceAddDislikeComment = `${serviceComments}/addDislike`;
const serviceReplyComment = `${serviceComments}/saveReply`;

const serviceNotification = `${serviceBaseUrl}/notifications`;
const serviceGetServerDate = `${serviceNotification}/getTime`;

const serviceRating = `${serviceBaseUrl}/ratings`;
const serviceAverageRatingByUrlLink = `${serviceRating}/getAverageRatingByUrlLink`;

const serviceGoogleAnalytic = `${serviceBaseUrl}/gaSessions`;
const serviceGASessionPages = `${serviceBaseUrl}/gaSessionPages`;
const serviceGATopAccess = `${serviceGASessionPages}/topAccessCountDiagram`;
const serviceGACount = `${serviceGoogleAnalytic}/gaPeriodCounter`;

export const getComments = async (params, cancelRef) =>
  await getRequest(serviceComments, params, cancelRef);

export const createComment = async values =>
  await postService(serviceCreateComment, values);

export const replyComment = async values =>
  await postService(serviceReplyComment, values);

export const addLikeComment = async id =>
  await putService(`${serviceAddLikeComment}/${id}`);

export const addDislikeComment = async id =>
  await putService(`${serviceAddDislikeComment}/${id}`);

export const getServerDate = async () => {
  let serverDate = new Date();
  try {
    serverDate = await getRequest(serviceGetServerDate);
  } catch {
    serverDate = new Date();
  }
  return new Date(serverDate);
};

export const createRating = async values =>
  await postService(serviceRating, values);

export const getAverageRatingByUrlLink = async params =>
  await getRequest(serviceAverageRatingByUrlLink, params);

export const getGATopAccess = async params =>
  await getRequest(serviceGATopAccess, params);

export const getGACount = async params =>
  await getRequest(serviceGACount, params);

