import {
  CheckOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PauseCircleOutlined,
  PlusCircleOutlined,
  StopFilled,
} from '@ant-design/icons';
import { REPORT_STATUS } from '../../../../constants/ReportConstants';

const COLOR_SUCCESS = '#09d48c';
const COLOR_WARN = 'orange';

const ReportStatus = (status, report, setSelectedForm) => {
  const onClick = () => {
    if (setSelectedForm) {
      setSelectedForm(report);
    }
  };

  const allStatus = [
    {
      id: REPORT_STATUS.SUCCESS,
      name: 'Мэдээлэл хугацаандаа оруулсан',
      icon: (
        <CheckOutlined style={{ color: COLOR_SUCCESS }} onClick={onClick} />
      ),
    },
    {
      id: REPORT_STATUS.EXPIRED,
      name: 'Хугацаа хоцорсон мэдээлэл',
      icon: <CheckOutlined style={{ color: COLOR_WARN }} onClick={onClick} />,
    },
    {
      id: REPORT_STATUS.NOT_PUBLISH,
      name: 'Ил тод болгоогүй /Мэдээлэл оруулж байгаа/',
      icon: <PauseCircleOutlined style={{ color: 'goldenrod' }} />,
    },
    {
      id: REPORT_STATUS.ACTIVE,
      name: 'Мэдээлэл оруулах хугацаа болсон',
      icon: <PlusCircleOutlined style={{ color: COLOR_SUCCESS }} />,
    },
    {
      id: REPORT_STATUS.NOT_ACTIVE,
      name: 'Мэдээлэл оруулах хугацаа болоогүй',
      icon: <MinusCircleOutlined style={{ color: '#aaa' }} />,
    },
    {
      id: REPORT_STATUS.NOT_REQUIRE,
      name: 'Мэдээлэл хугацаандаа оруулсан /оруулах мэдээлэл байхгүй/',
      icon: (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <CheckOutlined style={{ color: COLOR_SUCCESS }} onClick={onClick} />
          <StopFilled
            style={{ color: COLOR_SUCCESS, fontSize: 10, marginLeft: -4 }}
            onClick={onClick}
          />
        </div>
      ),
    },
    {
      id: REPORT_STATUS.NOT_REQUIRE_EXPIRED,
      name: 'Мэдээлэл хоцорч оруулсан /оруулах мэдээлэл байхгүй/',
      icon: (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <CheckOutlined style={{ color: COLOR_WARN }} onClick={onClick} />
          <StopFilled
            style={{ color: COLOR_WARN, fontSize: 10, marginLeft: -4 }}
            onClick={onClick}
          />
        </div>
      ),
    },
    {
      id: REPORT_STATUS.UPDATED_SUCCESS,
      name: 'Ил тод болгосон /араас нь хугацаандаа зассан/',
      icon: (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <CheckOutlined style={{ color: COLOR_SUCCESS }} onClick={onClick} />
          <EditFilled
            style={{ color: COLOR_SUCCESS, fontSize: 10, marginLeft: -4 }}
            onClick={onClick}
          />
        </div>
      ),
    },
    {
      id: REPORT_STATUS.UPDATED_EXPIRED,
      name: 'Ил тод болгосон /араас нь хоцорч зассан/',
      icon: (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <CheckOutlined style={{ color: COLOR_WARN }} onClick={onClick} />
          <EditFilled
            style={{ color: COLOR_WARN, fontSize: 10, marginLeft: -4 }}
            onClick={onClick}
          />
        </div>
      ),
    },
    {
      id: REPORT_STATUS.NOT_SUCCESS,
      name: 'Мэдээлэл оруулаагүй',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
    },
  ];

  if (status) {
    return allStatus.find(s => s.id === status);
  }
  return allStatus;
};

export default ReportStatus;

