import React, { useState } from 'react';
import moment from 'moment';
import { Button, DatePicker, message, Row, Space, Steps } from 'antd';
import { isEmpty } from 'lodash';
import { useAppStore } from '../../../../context/Tools';
import styles from './style.module.scss';
import FilterByLocation from '../../components/FilterByLocation';
import FilterByTEZ from '../../components/FilterByTEZ';
import FilterByOrg from '../../components/FilterByOrg';
import FilterByReportForm from '../../components/FilterByReportForm';
import { getOrganizationReport } from '../../../../service/reportServices';
import FilterByReportGroup from '../../components/FilterByReportGroup';
import FilterByOrgTypes from '../../components/FilterByOrgTypes';

const { Step } = Steps;

export const ReportControlStaticReport = ({ setStaticReportData }) => {
  const { cancelReportRef, serverDate, filters } = useAppStore();

  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(serverDate.getFullYear());
  const [current, setCurrent] = useState(0);

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const getData = async () => {
    if (!year) {
      message.info('Он сонгоогүй байна.');
      return;
    }

    const params = {
      year,
      reportFormIds: 0,
      refCities: 0,
      refDistricts: 0,
      refTypes: 0,
      refCentralBudgetGovernors: 0,
      orgIds: 0,
      refOrgTypes: 0,
      reportGroupIds: 0,
    };

    if (!isEmpty(filters.reportGroups)) {
      params.reportGroupIds = filters.reportGroups;
    } else {
      message.info('Байршуулах мэдээллийн төрөл сонгогдоогүй байна.');
      return;
    }
    if (!isEmpty(filters.reportForms)) {
      const reportFormIds = filters.reportForms
        .filter(p => p.type === 'report')
        .map(report => report.id);
      if (!isEmpty(reportFormIds)) {
        params.reportFormIds = reportFormIds;
      }
    }
    if (!isEmpty(filters.positions)) {
      const cities = filters.positions.filter(p => p.type === 'city');
      const cityCodes = cities.map(city => city.cityCode);
      const districtCodes = filters.positions
        .filter(p => p.type === 'district' && !cityCodes.includes(p.cityCode))
        .map(district => district.districtCode);
      if (!isEmpty(cityCodes)) {
        params.refCities = cityCodes.map(z => `'${z}'`); // back-s ingej ywuulna gesen
      }
      if (!isEmpty(districtCodes)) {
        params.refDistricts = districtCodes.map(z => `'${z}'`);
      }
    }
    if (!isEmpty(filters.tezs)) {
      const tezCodes = filters.tezs
        .filter(t => t.type === 'tez')
        .map(t => t.refTypeCode);
      const ttzCodes = filters.tezs
        .filter(t => t.type === 'ttz')
        .map(t => t.refCentralBudgetGovernorCode);
      if (!isEmpty(tezCodes)) {
        params.refTypes = tezCodes.map(z => `'${z}'`);
      }
      if (!isEmpty(ttzCodes)) {
        params.refCentralBudgetGovernors = ttzCodes.map(z => `'${z}'`);
      }
    }
    if (!isEmpty(filters.organizations)) {
      params.orgIds = filters.organizations.map(org => org.id);
    }
    if (!isEmpty(filters.orgTypes)) {
      params.refOrgTypes = filters.orgTypes;
    }
    setIsLoading(true);
    try {
      const result = await getOrganizationReport(params, cancelReportRef);
      const dataList = result || [];
      setStaticReportData(dataList, true);
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      title: 'Байршил',
      content: (
        <Row gutter={[20, 10]} className={styles.row}>
          <FilterByLocation />
          <FilterByTEZ />
          <FilterByOrg />
        </Row>
      ),
    },
    {
      title: 'Байгууллага',
      content: (
        <Row gutter={[20, 10]} className={styles.row}>
          <FilterByReportGroup />
          <FilterByOrgTypes />
          <FilterByReportForm />
        </Row>
      ),
    },
  ];

  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  return (
    <div className={styles.menu}>
      <Space size={16} style={{ marginLeft: 16, marginTop: 20 }}>
        <span>Тайлант үе</span>
        <DatePicker
          allowClear={false}
          onChange={onChangeYear}
          showToday
          defaultValue={moment(year, 'YYYY')}
          picker="year"
          disabledDate={curr => curr.isBefore(moment('2023-01-01'))}
        />
      </Space>
      <div className={styles.filterContent}>
        <Steps current={current} style={{ marginBottom: '20px' }}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
      </div>
      <Space className={styles.btn}>
        {current > 0 && <Button onClick={() => prev()}>Буцах</Button>}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Үргэлжлүүлэх
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" loading={isLoading} onClick={() => getData()}>
            Шүүх
          </Button>
        )}
      </Space>
    </div>
  );
};

