import React from 'react';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import government from '../../img/investment/government.svg';
import bank from '../../img/investment/bank.svg';
import gavel from '../../img/investment/gavel.svg';
import handshake from '../../img/investment/handshake.svg';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

export default function Cards(props) {
  const { setMenu, menu } = props;
  return (
    <Row justify="center" className={styles.cardContainer}>
      <Col {...CONTENT_SPAN}>
        <Row justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <Row
              justify="center"
              className={`${styles.card} ${styles.a} ${
                menu === 1 ? styles.active : styles.passive
              }`}
              onClick={() => {
                setMenu(1);
              }}
            >
              <div className={styles.logo}>
                <img src={government} alt="gov" />
              </div>
              <div className={styles.title}>Улсын төсвийн хөрөнгө оруулалт</div>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <Row
              justify="center"
              className={`${styles.card} ${styles.b} ${
                menu === 2 ? styles.active : styles.passive
              }`}
              onClick={() => {
                setMenu(2);
              }}
            >
              <div className={styles.logo}>
                <img src={bank} alt="gov" />
              </div>
              <div className={styles.title}>
                Орон нутгийн төсвийн хөрөнгө оруулалт
              </div>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <Row
              justify="center"
              className={`${styles.card} ${styles.c} ${
                menu === 3 ? styles.active : styles.passive
              }`}
              onClick={() => {
                setMenu(3);
              }}
            >
              <div className={styles.logo}>
                <img src={gavel} alt="gov" />
              </div>
              <div className={styles.title}>Концесс</div>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
            <Row
              justify="center"
              className={`${styles.card} ${styles.d} ${
                menu === 4 ? styles.active : styles.passive
              }`}
              onClick={() => {
                setMenu(4);
              }}
            >
              <div className={styles.logo}>
                <img src={handshake} alt="gov" />
              </div>
              <div className={styles.title}>
                Гадаадын зээл тусламжаар хэрэгжиж буй хөрөнгө оруулалт
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

