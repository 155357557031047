export const STEP = {
  APPROVED: 2,
  REJECTED: 3,
};

export const TZ_TYPE = {
  TEZ: 1,
  TTZ: 2,
  TShZ: 3,
};

