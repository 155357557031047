/* eslint-disable no-nested-ternary */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';

export default isLoading =>
  isLoading ? (
    <Spin indicator={<LoadingOutlined />} />
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      size="small"
      description="Өгөгдөл олдсонгүй"
    />
  );
