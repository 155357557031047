import styles from './style.module.scss';

export default function Banner3() {
  return (
    <svg
      width="512"
      height="363"
      viewBox="0 0 512 363"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.bannerSvg}
    >
      <g id="opendata3 1" clipPath="url(#clip0_1:2)">
        <g id="undraw_Real_time_sync_re_nky7">
          <path
            id="Path 3460"
            d="M265.513 64.167C267.598 55.667 265.613 45.749 258.881 40.157C258.748 49.6742 255.254 58.8378 249.016 66.027C246.6 68.791 243.625 71.614 243.508 75.284C243.543 77.5954 244.465 79.8048 246.083 81.456C247.692 83.0803 249.503 84.4918 251.471 85.656L251.752 86.156C257.918 79.938 263.429 72.669 265.513 64.167Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3461"
            d="M258.746 40.281C261.33 47.0506 261.714 54.4622 259.846 61.463C259.47 62.9771 258.858 64.4225 258.032 65.746C257.186 67.0093 256.017 68.0231 254.647 68.682C253.327 69.2234 252.077 69.9232 250.925 70.766C250.375 71.2536 249.935 71.8518 249.633 72.5215C249.33 73.1912 249.173 73.9172 249.171 74.652C249.218 76.4664 249.63 78.2527 250.381 79.905C251.266 81.7236 251.725 83.7203 251.723 85.743C251.703 85.991 252.088 86.008 252.108 85.76C252.4 82.204 249.985 79.2 249.602 75.751C249.467 74.9571 249.515 74.1428 249.744 73.3705C249.972 72.5981 250.373 71.8882 250.918 71.295C252.04 70.3717 253.302 69.6331 254.656 69.107C256.002 68.484 257.175 67.5408 258.073 66.36C258.964 65.082 259.63 63.6617 260.044 62.16C261.03 58.7958 261.481 55.2974 261.381 51.793C261.289 47.7927 260.513 43.8376 259.087 40.099C258.999 39.868 258.658 40.049 258.745 40.278L258.746 40.281Z"
            fill="white"
          />
          <path
            id="Path 3462"
            d="M260.578 59.245C261.795 59.3993 263.027 59.116 264.055 58.4455C265.082 57.7751 265.838 56.7612 266.187 55.585C266.257 55.347 265.887 55.227 265.82 55.466C265.497 56.5618 264.793 57.5061 263.835 58.1285C262.877 58.7509 261.728 59.0103 260.596 58.86C260.349 58.825 260.333 59.212 260.578 59.245Z"
            fill="white"
          />
          <path
            id="Path 3463"
            d="M255.33 68.442C253.776 66.4738 253.021 63.9922 253.215 61.492C253.234 61.244 252.849 61.227 252.83 61.475C252.632 64.0772 253.423 66.6583 255.045 68.703C255.2 68.898 255.484 68.637 255.33 68.443V68.442Z"
            fill="white"
          />
          <path
            id="Path 3464"
            d="M260.824 48.009C260.341 48.0474 259.855 47.9651 259.412 47.7696C258.968 47.5741 258.58 47.2715 258.282 46.889C258.13 46.689 257.846 46.954 257.997 47.149C258.328 47.5702 258.757 47.9038 259.247 48.1209C259.737 48.3379 260.273 48.4317 260.807 48.394C260.857 48.3941 260.906 48.3753 260.943 48.3412C260.98 48.3071 261.003 48.2602 261.007 48.21C261.009 48.1592 260.991 48.1097 260.957 48.0721C260.922 48.0345 260.875 48.0118 260.824 48.009Z"
            fill="white"
          />
          <path
            id="Path 3465"
            d="M229.757 45.661C229.849 45.787 229.94 45.912 230.031 46.041C231.253 47.7345 232.335 49.5243 233.267 51.393C233.341 51.536 233.414 51.683 233.485 51.828C235.682 56.3544 237.02 61.2493 237.43 66.264C237.592 68.2604 237.601 70.2662 237.458 72.264C237.258 75.022 236.827 77.996 237.532 80.582C237.602 80.8538 237.689 81.121 237.793 81.382L251.129 86.465C251.165 86.465 251.2 86.465 251.236 86.457L251.759 86.684C251.773 86.584 251.786 86.475 251.801 86.372C251.81 86.313 251.815 86.252 251.824 86.193C251.824 86.153 251.834 86.113 251.84 86.077C251.84 86.064 251.84 86.05 251.84 86.04C251.847 86.004 251.849 85.97 251.855 85.94C251.929 85.344 251.999 84.7467 252.064 84.148C252.064 84.148 252.064 84.148 252.064 84.141C252.607 79.5831 252.607 74.9769 252.064 70.419C252.046 70.283 252.029 70.144 252.005 70.006C251.724 67.973 251.278 65.9663 250.671 64.006C250.335 62.9336 249.945 61.8789 249.502 60.846C248.353 58.1792 246.792 55.7097 244.876 53.528C241.055 49.179 235.886 46.101 230.187 45.695C230.047 45.678 229.904 45.668 229.757 45.661Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3466"
            d="M229.722 45.841C235.86 49.6912 240.63 55.3778 243.352 62.093C243.964 63.5282 244.345 65.0509 244.483 66.605C244.568 68.1233 244.245 69.6367 243.548 70.988C242.82 72.2153 242.243 73.5266 241.831 74.893C241.686 75.6133 241.694 76.3561 241.856 77.0729C242.018 77.7896 242.329 78.4641 242.77 79.052C243.9 80.472 245.304 81.6503 246.899 82.517C248.701 83.4358 250.27 84.7536 251.486 86.37C251.619 86.58 251.937 86.362 251.804 86.152C249.896 83.137 246.159 82.193 243.777 79.669C243.191 79.1164 242.74 78.4371 242.457 77.6832C242.174 76.9292 242.067 76.1205 242.145 75.319C242.484 73.9055 243.047 72.5552 243.812 71.319C244.512 70.0109 244.88 68.5514 244.886 67.068C244.828 65.5119 244.506 63.977 243.933 62.529C242.695 59.2489 240.949 56.1837 238.759 53.446C236.276 50.3064 233.274 47.6149 229.883 45.488C229.673 45.356 229.51 45.706 229.718 45.837L229.722 45.841Z"
            fill="white"
          />
          <path
            id="Path 3467"
            d="M242.603 59.88C243.668 59.2703 244.481 58.3019 244.898 57.1478C245.314 55.9938 245.307 54.7292 244.877 53.58C244.789 53.348 244.424 53.473 244.512 53.706C244.914 54.7753 244.92 55.9531 244.53 57.0268C244.14 58.1005 243.379 58.9993 242.384 59.561C242.168 59.683 242.384 60.001 242.602 59.879L242.603 59.88Z"
            fill="white"
          />
          <path
            id="Path 3468"
            d="M243.949 70.384C241.523 69.7481 239.426 68.2213 238.076 66.108C237.942 65.898 237.624 66.117 237.758 66.326C239.167 68.5225 241.353 70.1069 243.879 70.763C244.12 70.825 244.189 70.446 243.95 70.384H243.949Z"
            fill="white"
          />
          <path
            id="Path 3469"
            d="M236.035 50.761C235.673 51.0821 235.236 51.3084 234.765 51.4196C234.294 51.5307 233.803 51.5233 233.335 51.398C233.095 51.333 233.026 51.712 233.264 51.777C233.782 51.9136 234.326 51.9212 234.848 51.7992C235.369 51.6772 235.853 51.4292 236.257 51.077C236.298 51.0469 236.325 51.0026 236.335 50.9528C236.344 50.9031 236.334 50.8517 236.307 50.809C236.278 50.7671 236.233 50.7383 236.183 50.729C236.133 50.7196 236.081 50.7304 236.039 50.759L236.035 50.761Z"
            fill="white"
          />
          <path
            id="Path 3470"
            d="M212.123 237.714C200.885 237.703 189.771 235.355 179.488 230.821C169.205 226.286 159.977 219.663 152.389 211.373C144.802 203.083 139.02 193.305 135.412 182.662C131.804 172.018 130.448 160.741 131.429 149.546C132.411 138.35 135.709 127.481 141.114 117.628C146.52 107.775 153.914 99.1527 162.829 92.3092C171.743 85.4657 181.983 80.55 192.898 77.8741C203.814 75.1982 215.166 74.8205 226.235 76.765C226.352 76.7869 226.455 76.8543 226.522 76.9524C226.589 77.0504 226.615 77.1712 226.593 77.288C226.571 77.4048 226.504 77.5082 226.406 77.5753C226.308 77.6425 226.187 77.6679 226.07 77.646C209.861 74.7818 193.164 76.974 178.244 83.9253C163.324 90.8765 150.904 102.25 142.67 116.502C134.436 130.754 130.787 147.195 132.217 163.592C133.648 179.99 140.089 195.55 150.666 208.161C161.244 220.772 175.446 229.823 191.344 234.086C207.242 238.349 224.067 237.617 239.534 231.989C255.002 226.362 268.364 216.111 277.806 202.629C287.248 189.147 292.313 173.086 292.314 156.626C292.334 136.64 284.871 117.371 271.395 102.612C271.326 102.523 271.293 102.411 271.302 102.298C271.312 102.186 271.364 102.081 271.448 102.005C271.532 101.929 271.641 101.888 271.754 101.889C271.867 101.89 271.975 101.934 272.057 102.012C282.642 113.629 289.623 128.072 292.151 143.584C294.678 159.096 292.644 175.009 286.294 189.385C279.944 203.762 269.553 215.984 256.385 224.563C243.217 233.143 227.84 237.711 212.123 237.712V237.714Z"
            fill="#E4E4E4"
          />
          <path
            id="Ellipse 512"
            d="M247.876 103.351C256.736 103.351 263.919 96.1683 263.919 87.308C263.919 78.4477 256.736 71.265 247.876 71.265C239.016 71.265 231.833 78.4477 231.833 87.308C231.833 96.1683 239.016 103.351 247.876 103.351Z"
            fill="#006CB3"
          />
          <path
            id="Path 3471"
            d="M245.684 95.658C245.407 95.6581 245.134 95.5936 244.886 95.4697C244.638 95.3457 244.422 95.1658 244.256 94.944L239.879 89.108C239.611 88.7285 239.501 88.2594 239.573 87.8002C239.645 87.3411 239.894 86.9283 240.266 86.6494C240.637 86.3706 241.103 86.2478 241.564 86.3071C242.025 86.3664 242.445 86.6031 242.734 86.967L245.597 90.785L252.951 79.754C253.217 79.3688 253.624 79.1037 254.084 79.0157C254.544 78.9278 255.02 79.024 255.409 79.2838C255.799 79.5435 256.071 79.9459 256.167 80.4042C256.262 80.8625 256.174 81.3401 255.921 81.734L247.167 94.864C247.009 95.1014 246.796 95.2973 246.546 95.4355C246.296 95.5736 246.017 95.6499 245.732 95.658H245.684Z"
            fill="white"
          />
          <path
            id="Path 3472"
            d="M210.037 144.044L185.298 75.219L185.925 74.993L181.375 62.334L180.748 62.56L173.155 41.429C172.871 40.6397 172.435 39.9141 171.871 39.2934C171.307 38.6728 170.626 38.1693 169.867 37.8118C169.109 37.4543 168.287 37.2497 167.449 37.2098C166.612 37.1698 165.774 37.2953 164.985 37.579L120.255 53.655C119.466 53.9386 118.74 54.3749 118.119 54.939C117.499 55.5031 116.995 56.1839 116.638 56.9426C116.28 57.7012 116.076 58.5229 116.036 59.3606C115.996 60.1984 116.121 61.0358 116.405 61.825L121.705 76.559L120.714 76.941L124.095 85.72L124.889 85.414L125.589 87.367L124.745 87.692L128.126 96.471L128.773 96.222L153.294 164.439C153.576 165.229 154.012 165.956 154.576 166.577C155.14 167.199 155.821 167.703 156.58 168.06C157.339 168.418 158.161 168.622 158.999 168.662C159.837 168.701 160.675 168.574 161.464 168.289L206.188 152.213C207.78 151.638 209.08 150.455 209.801 148.924C210.523 147.393 210.608 145.638 210.037 144.044Z"
            fill="#3F3D56"
          />
          <path
            id="Path 3473"
            d="M159.112 166.022C158.482 166.02 157.868 165.825 157.353 165.463C156.838 165.1 156.448 164.587 156.234 163.995L118.959 60.295C118.685 59.5312 118.725 58.6897 119.071 57.9555C119.417 57.2214 120.04 56.6547 120.804 56.38L130.32 52.959L130.306 53.292C130.282 53.8699 130.4 54.4449 130.65 54.9665C130.9 55.488 131.275 55.94 131.741 56.2826C132.207 56.6252 132.75 56.848 133.322 56.9312C133.895 57.0145 134.479 56.9557 135.023 56.76L156.142 49.169C156.652 48.9353 157.099 48.585 157.448 48.1464C157.798 47.7077 158.039 47.1931 158.152 46.644C158.264 46.104 158.247 45.5452 158.102 45.013C157.957 44.4809 157.688 43.9906 157.318 43.582L157.095 43.334L166.924 39.801C167.688 39.5275 168.529 39.5682 169.263 39.9141C169.997 40.2599 170.564 40.8828 170.839 41.646L206.403 140.583C207.128 142.61 207.02 144.843 206.101 146.79C205.183 148.738 203.529 150.241 201.503 150.971L160.144 165.838C159.811 165.958 159.461 166.02 159.107 166.02L159.112 166.022Z"
            fill="white"
          />
          <path
            id="Ellipse 513"
            d="M155.468 92.472C162.148 92.472 167.564 87.0564 167.564 80.376C167.564 73.6956 162.148 68.28 155.468 68.28C148.788 68.28 143.372 73.6956 143.372 80.376C143.372 87.0564 148.788 92.472 155.468 92.472Z"
            fill="#006CB3"
          />
          <path
            id="Path 3474"
            d="M185.441 97.965L143.553 113.026C143.422 113.075 143.282 113.098 143.142 113.092C143.002 113.087 142.865 113.054 142.738 112.995C142.611 112.936 142.496 112.853 142.402 112.749C142.307 112.646 142.234 112.525 142.187 112.393C142.139 112.261 142.119 112.121 142.126 111.981C142.133 111.842 142.168 111.705 142.228 111.578C142.289 111.452 142.374 111.339 142.478 111.246C142.583 111.152 142.705 111.081 142.837 111.035L184.725 95.978C184.856 95.9289 184.996 95.9064 185.136 95.9117C185.276 95.917 185.413 95.9501 185.54 96.009C185.667 96.0679 185.782 96.1514 185.876 96.2547C185.971 96.358 186.044 96.4791 186.091 96.6109C186.139 96.7427 186.159 96.8826 186.152 97.0225C186.145 97.1624 186.11 97.2995 186.05 97.4258C185.989 97.5522 185.904 97.6652 185.8 97.7585C185.695 97.8517 185.573 97.9233 185.441 97.969V97.965Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3475"
            d="M173.002 107.512L159.223 112.463C158.96 112.554 158.672 112.537 158.421 112.417C158.17 112.297 157.976 112.083 157.882 111.821C157.788 111.559 157.801 111.271 157.918 111.018C158.035 110.766 158.246 110.569 158.507 110.472L172.285 105.519C172.416 105.47 172.556 105.447 172.696 105.453C172.836 105.458 172.973 105.491 173.1 105.55C173.227 105.609 173.342 105.692 173.436 105.796C173.531 105.899 173.604 106.02 173.651 106.152C173.699 106.284 173.719 106.424 173.712 106.564C173.705 106.703 173.67 106.841 173.61 106.967C173.549 107.093 173.464 107.206 173.36 107.299C173.255 107.393 173.133 107.464 173.001 107.51L173.002 107.512Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3476"
            d="M176.279 116.629L162.501 121.582C162.37 121.631 162.23 121.654 162.09 121.648C161.95 121.643 161.813 121.61 161.686 121.551C161.559 121.492 161.444 121.409 161.35 121.305C161.255 121.202 161.182 121.081 161.135 120.949C161.087 120.817 161.067 120.677 161.074 120.537C161.081 120.398 161.116 120.261 161.176 120.134C161.237 120.008 161.322 119.895 161.426 119.802C161.531 119.708 161.653 119.637 161.785 119.591L175.563 114.638C175.694 114.589 175.834 114.566 175.974 114.572C176.114 114.577 176.251 114.61 176.378 114.669C176.505 114.728 176.62 114.811 176.714 114.915C176.809 115.018 176.882 115.139 176.929 115.271C176.977 115.403 176.997 115.543 176.99 115.683C176.983 115.822 176.948 115.96 176.888 116.086C176.827 116.212 176.742 116.325 176.638 116.418C176.533 116.512 176.411 116.583 176.279 116.629V116.629Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3477"
            d="M177.957 121.299L164.179 126.252C163.916 126.343 163.628 126.326 163.377 126.206C163.126 126.086 162.932 125.872 162.838 125.61C162.744 125.348 162.757 125.06 162.874 124.807C162.991 124.555 163.202 124.358 163.463 124.261L177.241 119.308C177.504 119.217 177.792 119.234 178.043 119.354C178.294 119.474 178.488 119.688 178.582 119.95C178.676 120.212 178.663 120.5 178.546 120.753C178.429 121.005 178.218 121.202 177.957 121.299V121.299Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3478"
            d="M182.344 109.229L153.108 119.737C152.977 119.786 152.837 119.809 152.697 119.803C152.557 119.798 152.42 119.765 152.293 119.706C152.166 119.647 152.051 119.564 151.957 119.46C151.862 119.357 151.789 119.236 151.742 119.104C151.694 118.972 151.674 118.832 151.681 118.692C151.688 118.553 151.723 118.415 151.783 118.289C151.844 118.163 151.929 118.05 152.033 117.957C152.138 117.863 152.26 117.792 152.392 117.746L181.625 107.238C181.756 107.189 181.896 107.166 182.036 107.172C182.176 107.177 182.313 107.21 182.44 107.269C182.567 107.328 182.682 107.411 182.776 107.515C182.871 107.618 182.944 107.739 182.991 107.871C183.039 108.003 183.059 108.143 183.052 108.283C183.045 108.422 183.01 108.559 182.95 108.686C182.889 108.812 182.804 108.925 182.7 109.018C182.595 109.112 182.473 109.183 182.341 109.229H182.344Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3479"
            d="M176.456 132.634L171.456 134.434C171.232 134.523 170.992 134.566 170.75 134.56C170.509 134.555 170.271 134.501 170.052 134.402C169.832 134.302 169.634 134.16 169.47 133.983C169.306 133.805 169.18 133.597 169.098 133.37C169.016 133.143 168.981 132.901 168.995 132.66C169.008 132.42 169.07 132.184 169.177 131.967C169.283 131.75 169.432 131.558 169.614 131.4C169.797 131.242 170.009 131.122 170.239 131.048L175.239 129.248C175.684 129.104 176.167 129.139 176.586 129.345C177.005 129.551 177.328 129.912 177.486 130.352C177.644 130.792 177.625 131.276 177.433 131.701C177.24 132.127 176.89 132.462 176.456 132.634Z"
            fill="#006CB3"
          />
          <path
            id="Ellipse 514"
            d="M137.033 53.298C137.417 53.298 137.729 52.9864 137.729 52.602C137.729 52.2176 137.417 51.906 137.033 51.906C136.649 51.906 136.337 52.2176 136.337 52.602C136.337 52.9864 136.649 53.298 137.033 53.298Z"
            fill="white"
          />
          <path
            id="Path 3480"
            d="M57.022 91.73C64.822 82.551 77.471 76.767 89.136 79.766C78.6306 87.587 71.2301 98.8719 68.244 111.624C67.11 116.546 66.362 122.139 62.365 125.224C59.7602 127.046 56.5552 127.797 53.412 127.324C50.3029 126.841 47.2685 125.961 44.383 124.706L43.603 124.793C45.564 112.912 49.225 100.909 57.022 91.73Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3481"
            d="M89.106 80.018C79.4761 82.5964 70.9035 88.1438 64.606 95.872C63.2209 97.5116 62.1028 99.3593 61.293 101.347C60.5666 103.309 60.3788 105.429 60.749 107.488C61.2099 109.397 61.4372 111.354 61.426 113.318C61.3256 114.324 61.0137 115.297 60.5108 116.174C60.008 117.051 59.3254 117.811 58.508 118.406C56.4474 119.816 54.1244 120.797 51.677 121.292C49.01 121.992 46.229 122.668 44.086 124.503C43.826 124.725 43.496 124.309 43.756 124.087C47.485 120.894 52.781 121.165 56.935 118.811C57.9289 118.321 58.7975 117.611 59.4745 116.734C60.1515 115.857 60.619 114.836 60.841 113.751C61.141 111.764 60.594 109.768 60.267 107.82C59.8762 105.817 59.982 103.748 60.575 101.795C61.2809 99.7725 62.3258 97.8849 63.665 96.213C66.6202 92.4007 70.1563 89.0768 74.144 86.363C78.6779 83.2406 83.7125 80.9167 89.03 79.492C89.359 79.404 89.43 79.93 89.105 80.018H89.106Z"
            fill="white"
          />
          <path
            id="Path 3482"
            d="M66.489 93.263C65.3369 92.0307 64.66 90.4291 64.5793 88.744C64.4985 87.0589 65.019 85.3999 66.048 84.063C66.257 83.793 66.686 84.106 66.477 84.376C65.5154 85.6195 65.0301 87.1657 65.1086 88.7356C65.1871 90.3055 65.8242 91.7956 66.905 92.937C67.139 93.181 66.722 93.51 66.489 93.263Z"
            fill="white"
          />
          <path
            id="Path 3483"
            d="M60.466 106.531C63.9131 106.677 67.2885 105.518 69.919 103.285C70.179 103.064 70.509 103.48 70.249 103.701C67.5072 106.019 63.992 107.218 60.405 107.059C60.063 107.044 60.125 106.516 60.465 106.532L60.466 106.531Z"
            fill="white"
          />
          <path
            id="Path 3484"
            d="M78.814 83.931C79.161 84.501 79.6443 84.9759 80.2203 85.313C80.7963 85.6501 81.447 85.8387 82.114 85.862C82.456 85.873 82.393 86.4 82.054 86.389C81.3176 86.3593 80.5996 86.1498 79.9628 85.7786C79.3261 85.4075 78.7898 84.8861 78.401 84.26C78.3593 84.204 78.3406 84.1343 78.3486 84.065C78.3566 83.9957 78.3907 83.932 78.444 83.887C78.4992 83.8447 78.5688 83.8256 78.6379 83.8338C78.7071 83.842 78.7702 83.8769 78.814 83.931Z"
            fill="white"
          />
          <path
            id="Path 3485"
            d="M106.48 116.671C106.266 116.671 106.053 116.671 105.836 116.671C102.963 116.674 100.095 116.911 97.26 117.379C97.04 117.412 96.818 117.448 96.6 117.487C89.7817 118.686 83.2453 121.14 77.323 124.725C74.9686 126.153 72.7261 127.757 70.615 129.525C67.7 131.968 64.733 134.85 61.282 136.149C60.923 136.289 60.5556 136.406 60.182 136.5L43.763 125.731C43.738 125.688 43.71 125.648 43.685 125.605L43.01 125.205C43.11 125.105 43.221 125.005 43.323 124.905C43.382 124.847 43.445 124.792 43.504 124.735C43.545 124.697 43.585 124.66 43.62 124.624L43.658 124.59C43.693 124.554 43.728 124.524 43.758 124.49C44.3627 123.927 44.9723 123.368 45.587 122.812C45.587 122.812 45.587 122.812 45.596 122.807C50.2388 118.526 55.373 114.812 60.891 111.741C61.057 111.652 61.225 111.558 61.398 111.474C63.8904 110.147 66.4871 109.027 69.162 108.123C70.6284 107.633 72.1186 107.217 73.627 106.878C77.5262 106.009 81.5379 105.758 85.515 106.135C93.443 106.888 101.042 110.169 106.09 116.196C106.223 116.361 106.353 116.513 106.48 116.671Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3486"
            d="M106.306 116.855C97.065 113.116 86.8808 112.384 77.2 114.763C75.1067 115.238 73.1014 116.04 71.258 117.14C69.4971 118.269 68.0707 119.849 67.127 121.716C66.346 123.517 65.349 125.217 64.158 126.778C63.4722 127.521 62.6372 128.11 61.7076 128.508C60.778 128.905 59.7748 129.101 58.764 129.084C56.2697 128.969 53.8239 128.354 51.572 127.275C49.024 126.225 46.394 125.093 43.578 125.268C43.237 125.289 43.224 124.759 43.565 124.737C48.465 124.437 52.53 127.837 57.265 128.459C58.3533 128.666 59.4746 128.622 60.5431 128.33C61.6117 128.037 62.5992 127.504 63.43 126.771C64.87 125.371 65.63 123.442 66.542 121.689C67.4361 119.854 68.7658 118.266 70.415 117.064C72.1961 115.873 74.1669 114.994 76.243 114.464C80.8987 113.199 85.7243 112.674 90.543 112.909C96.0429 113.146 101.462 114.322 106.565 116.386C106.881 116.514 106.622 116.978 106.309 116.851L106.306 116.855Z"
            fill="white"
          />
          <path
            id="Path 3487"
            d="M80.272 113.814C80.0934 112.136 80.5173 110.448 81.4678 109.054C82.4183 107.659 83.8336 106.648 85.461 106.2C85.791 106.111 85.945 106.619 85.615 106.708C84.0988 107.122 82.7805 108.064 81.8981 109.365C81.0157 110.666 80.6273 112.239 80.803 113.801C80.841 114.14 80.31 114.152 80.272 113.814Z"
            fill="white"
          />
          <path
            id="Path 3488"
            d="M67.476 120.782C70.1401 122.973 73.5321 124.08 76.976 123.882C77.317 123.862 77.33 124.393 76.989 124.413C73.4042 124.613 69.8758 123.454 67.108 121.167C66.844 120.949 67.208 120.567 67.474 120.782H67.476Z"
            fill="white"
          />
          <path
            id="Path 3489"
            d="M95.733 113.783C95.6667 114.447 95.7663 115.117 96.0229 115.732C96.2795 116.348 96.685 116.891 97.203 117.311C97.469 117.526 97.103 117.911 96.837 117.696C96.2667 117.229 95.8195 116.629 95.5345 115.949C95.2495 115.269 95.1353 114.53 95.202 113.796C95.2024 113.726 95.2294 113.659 95.2776 113.609C95.3257 113.558 95.3913 113.528 95.461 113.524C95.5313 113.523 95.5993 113.549 95.6502 113.598C95.7012 113.646 95.7309 113.713 95.733 113.783V113.783Z"
            fill="white"
          />
          <path
            id="Path 3490"
            d="M291.037 268.145C299.537 270.23 309.455 268.245 315.047 261.513C305.528 261.381 296.362 257.887 289.172 251.648C286.408 249.232 283.585 246.257 279.915 246.14C277.605 246.177 275.397 247.1 273.748 248.718C272.124 250.327 270.712 252.138 269.548 254.106L269.048 254.387C275.266 260.549 282.535 266.06 291.037 268.145Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3491"
            d="M314.923 261.377C308.153 263.961 300.742 264.345 293.741 262.477C292.227 262.101 290.782 261.49 289.458 260.664C288.195 259.818 287.181 258.649 286.522 257.279C285.981 255.959 285.281 254.709 284.438 253.557C283.95 253.007 283.352 252.567 282.683 252.265C282.013 251.962 281.287 251.805 280.552 251.803C278.738 251.85 276.951 252.262 275.299 253.013C273.48 253.898 271.484 254.357 269.461 254.355C269.213 254.335 269.196 254.72 269.443 254.74C272.999 255.032 276.003 252.617 279.452 252.234C280.246 252.099 281.06 252.147 281.833 252.376C282.605 252.604 283.315 253.005 283.908 253.55C284.831 254.672 285.57 255.934 286.096 257.288C286.719 258.634 287.662 259.807 288.843 260.705C290.121 261.596 291.541 262.262 293.043 262.676C296.407 263.663 299.906 264.114 303.41 264.014C307.41 263.922 311.365 263.146 315.104 261.72C315.335 261.632 315.154 261.291 314.924 261.378L314.923 261.377Z"
            fill="white"
          />
          <path
            id="Path 3492"
            d="M295.959 263.21C295.805 264.427 296.088 265.659 296.758 266.687C297.429 267.714 298.443 268.47 299.619 268.819C299.857 268.889 299.977 268.519 299.738 268.452C298.642 268.129 297.698 267.425 297.076 266.467C296.453 265.509 296.194 264.36 296.344 263.228C296.377 262.981 295.991 262.965 295.959 263.21Z"
            fill="white"
          />
          <path
            id="Path 3493"
            d="M286.762 257.961C288.731 256.407 291.212 255.652 293.713 255.846C293.961 255.866 293.978 255.48 293.73 255.461C291.128 255.264 288.547 256.054 286.502 257.676C286.307 257.83 286.568 258.114 286.762 257.961Z"
            fill="white"
          />
          <path
            id="Path 3494"
            d="M307.195 263.456C307.157 262.973 307.239 262.487 307.434 262.044C307.63 261.6 307.932 261.212 308.315 260.914C308.515 260.762 308.25 260.478 308.055 260.629C307.634 260.96 307.3 261.389 307.083 261.879C306.866 262.369 306.772 262.905 306.81 263.439C306.81 263.489 306.829 263.538 306.863 263.575C306.897 263.612 306.944 263.635 306.994 263.639C307.045 263.641 307.094 263.623 307.132 263.589C307.169 263.554 307.192 263.507 307.195 263.456V263.456Z"
            fill="white"
          />
          <path
            id="Path 3495"
            d="M309.543 232.388C309.418 232.48 309.292 232.571 309.163 232.662C307.47 233.884 305.68 234.966 303.811 235.898C303.668 235.972 303.521 236.046 303.376 236.116C298.85 238.313 293.955 239.651 288.94 240.061C286.944 240.222 284.938 240.232 282.94 240.089C280.182 239.889 277.207 239.458 274.622 240.162C274.35 240.232 274.083 240.319 273.822 240.423L268.745 253.76C268.745 253.796 268.745 253.831 268.753 253.867L268.526 254.39L268.838 254.432C268.897 254.441 268.958 254.447 269.017 254.455C269.057 254.455 269.097 254.465 269.133 254.471H269.17C269.206 254.478 269.24 254.48 269.27 254.486C269.865 254.56 270.463 254.63 271.062 254.695C271.062 254.695 271.062 254.695 271.069 254.695C275.627 255.238 280.233 255.238 284.791 254.695C284.927 254.677 285.066 254.66 285.204 254.636C287.237 254.356 289.244 253.909 291.204 253.302C292.276 252.966 293.331 252.576 294.364 252.133C297.031 250.984 299.5 249.423 301.682 247.507C306.031 243.686 309.109 238.517 309.515 232.818C309.527 232.678 309.536 232.535 309.543 232.388Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3496"
            d="M309.363 232.354C305.513 238.492 299.826 243.261 293.111 245.984C291.676 246.596 290.153 246.977 288.599 247.115C287.081 247.2 285.568 246.877 284.217 246.18C282.99 245.452 281.678 244.875 280.312 244.463C279.592 244.318 278.849 244.326 278.132 244.488C277.415 244.65 276.741 244.961 276.153 245.402C274.733 246.532 273.555 247.936 272.688 249.531C271.769 251.333 270.451 252.902 268.835 254.118C268.625 254.251 268.843 254.569 269.053 254.436C272.068 252.528 273.012 248.791 275.536 246.409C276.089 245.823 276.768 245.372 277.522 245.089C278.276 244.806 279.084 244.699 279.886 244.777C281.299 245.116 282.65 245.679 283.886 246.444C285.194 247.144 286.654 247.512 288.137 247.518C289.693 247.46 291.228 247.138 292.676 246.565C295.956 245.327 299.021 243.581 301.759 241.391C304.897 238.909 307.588 235.908 309.714 232.519C309.846 232.309 309.496 232.146 309.365 232.354H309.363Z"
            fill="white"
          />
          <path
            id="Path 3497"
            d="M295.324 245.234C295.934 246.299 296.902 247.112 298.056 247.529C299.21 247.945 300.475 247.938 301.624 247.508C301.856 247.42 301.731 247.055 301.498 247.143C300.429 247.545 299.251 247.551 298.177 247.161C297.104 246.771 296.205 246.01 295.643 245.015C295.521 244.799 295.203 245.015 295.325 245.233L295.324 245.234Z"
            fill="white"
          />
          <path
            id="Path 3498"
            d="M284.82 246.581C285.456 244.155 286.983 242.058 289.096 240.708C289.305 240.574 289.087 240.256 288.878 240.39C286.681 241.799 285.097 243.985 284.441 246.511C284.379 246.752 284.758 246.821 284.82 246.582V246.581Z"
            fill="white"
          />
          <path
            id="Path 3499"
            d="M304.443 238.667C304.122 238.305 303.896 237.868 303.785 237.397C303.674 236.926 303.682 236.435 303.807 235.967C303.872 235.727 303.493 235.658 303.428 235.896C303.291 236.414 303.284 236.958 303.406 237.48C303.528 238.001 303.776 238.485 304.128 238.889C304.158 238.93 304.202 238.957 304.252 238.967C304.302 238.976 304.353 238.966 304.396 238.939C304.417 238.924 304.435 238.906 304.449 238.884C304.463 238.862 304.472 238.838 304.476 238.813C304.481 238.788 304.48 238.762 304.474 238.737C304.469 238.712 304.458 238.688 304.443 238.667V238.667Z"
            fill="white"
          />
          <path
            id="Path 3500"
            d="M325.106 186.426L312.141 208.243L312.634 208.503L308.244 216.827L307.33 216.343L304.445 221.195L305.162 221.607L300.462 229.751L299.647 229.281L257.347 300.476C256.238 302.339 254.434 303.686 252.333 304.221C250.231 304.756 248.003 304.436 246.138 303.33L167.482 256.599C165.619 255.49 164.271 253.686 163.736 251.585C163.201 249.483 163.522 247.255 164.628 245.39L232.396 131.341C233.505 129.478 235.309 128.13 237.41 127.595C239.512 127.06 241.74 127.381 243.605 128.487L322.25 175.217C324.113 176.327 325.46 178.13 325.995 180.231C326.531 182.332 326.211 184.56 325.106 186.426V186.426Z"
            fill="#3F3D56"
          />
          <path
            id="Path 3501"
            d="M248.932 298.926L170.01 252.031C169.436 251.689 169.021 251.133 168.856 250.485C168.691 249.838 168.789 249.151 169.13 248.576L237.347 133.768C237.689 133.194 238.245 132.779 238.893 132.614C239.54 132.449 240.227 132.547 240.802 132.888L319.724 179.782C320.298 180.124 320.713 180.68 320.878 181.328C321.043 181.975 320.945 182.662 320.604 183.237L252.387 298.046C252.045 298.62 251.489 299.035 250.841 299.2C250.194 299.365 249.507 299.267 248.932 298.926V298.926Z"
            fill="white"
          />
          <path
            id="Ellipse 515"
            d="M281.351 154.522C281.735 154.522 282.047 154.21 282.047 153.826C282.047 153.442 281.735 153.13 281.351 153.13C280.967 153.13 280.655 153.442 280.655 153.826C280.655 154.21 280.967 154.522 281.351 154.522Z"
            fill="white"
          />
          <path
            id="Path 3502"
            d="M277.045 236.764L211.165 197.619C210.75 197.372 210.449 196.97 210.331 196.501C210.212 196.033 210.284 195.536 210.531 195.121C210.778 194.706 211.18 194.406 211.649 194.287C212.117 194.168 212.614 194.24 213.029 194.487L278.909 233.632C279.12 233.751 279.305 233.912 279.453 234.104C279.602 234.296 279.71 234.515 279.773 234.75C279.835 234.984 279.85 235.228 279.816 235.469C279.783 235.709 279.702 235.94 279.578 236.148C279.454 236.357 279.29 236.539 279.095 236.683C278.9 236.827 278.678 236.931 278.442 236.988C278.207 237.045 277.962 237.055 277.723 237.017C277.483 236.978 277.254 236.892 277.048 236.764H277.045Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3503"
            d="M250.74 230.698L229.067 217.822C228.663 217.57 228.373 217.17 228.26 216.707C228.148 216.244 228.221 215.755 228.464 215.345C228.708 214.935 229.102 214.637 229.563 214.515C230.023 214.393 230.513 214.455 230.928 214.69L252.598 227.566C252.809 227.685 252.994 227.846 253.142 228.038C253.291 228.23 253.399 228.449 253.462 228.684C253.524 228.918 253.539 229.162 253.505 229.403C253.472 229.643 253.391 229.874 253.267 230.083C253.143 230.291 252.979 230.473 252.784 230.617C252.589 230.761 252.367 230.865 252.131 230.922C251.896 230.979 251.651 230.989 251.412 230.951C251.172 230.912 250.943 230.826 250.737 230.698H250.74Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3504"
            d="M242.22 245.037L220.55 232.161C220.339 232.042 220.154 231.881 220.006 231.689C219.857 231.497 219.749 231.278 219.686 231.043C219.624 230.809 219.609 230.565 219.643 230.324C219.676 230.084 219.757 229.853 219.881 229.644C220.005 229.436 220.169 229.254 220.364 229.11C220.559 228.966 220.781 228.862 221.017 228.805C221.252 228.747 221.497 228.738 221.736 228.776C221.976 228.815 222.205 228.901 222.411 229.029L244.081 241.905C244.292 242.024 244.477 242.185 244.625 242.377C244.774 242.569 244.882 242.788 244.945 243.023C245.007 243.257 245.022 243.501 244.988 243.742C244.955 243.982 244.874 244.213 244.75 244.421C244.626 244.63 244.462 244.812 244.267 244.956C244.072 245.1 243.85 245.204 243.614 245.261C243.379 245.318 243.134 245.328 242.895 245.29C242.655 245.251 242.426 245.165 242.22 245.037Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3505"
            d="M237.856 252.381L216.186 239.505C215.975 239.386 215.79 239.225 215.642 239.033C215.493 238.841 215.385 238.622 215.322 238.387C215.26 238.153 215.245 237.909 215.279 237.668C215.312 237.428 215.393 237.197 215.517 236.989C215.641 236.78 215.805 236.598 216 236.454C216.195 236.31 216.417 236.206 216.653 236.149C216.888 236.092 217.133 236.082 217.372 236.12C217.612 236.159 217.841 236.245 218.047 236.373L239.717 249.249C239.928 249.368 240.113 249.529 240.261 249.721C240.41 249.913 240.518 250.132 240.581 250.367C240.643 250.601 240.658 250.845 240.624 251.086C240.591 251.326 240.51 251.557 240.386 251.765C240.262 251.974 240.098 252.156 239.903 252.3C239.708 252.444 239.486 252.548 239.25 252.605C239.015 252.662 238.77 252.672 238.531 252.634C238.291 252.595 238.062 252.509 237.856 252.381V252.381Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3506"
            d="M258.693 244.988L212.717 217.671C212.313 217.419 212.023 217.019 211.91 216.556C211.798 216.092 211.871 215.604 212.114 215.194C212.358 214.784 212.752 214.486 213.213 214.364C213.673 214.242 214.163 214.304 214.578 214.539L260.554 241.857C260.76 241.979 260.939 242.141 261.082 242.332C261.226 242.524 261.33 242.742 261.389 242.974C261.448 243.205 261.461 243.447 261.426 243.683C261.392 243.92 261.312 244.148 261.19 244.354C261.067 244.559 260.906 244.739 260.714 244.882C260.523 245.025 260.305 245.129 260.073 245.188C259.841 245.247 259.6 245.26 259.363 245.226C259.126 245.192 258.899 245.111 258.693 244.989V244.988Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3507"
            d="M249.928 279.901L242.06 275.226C241.389 274.79 240.913 274.111 240.731 273.331C240.549 272.552 240.676 271.732 241.085 271.044C241.493 270.356 242.153 269.852 242.924 269.639C243.696 269.426 244.52 269.519 245.224 269.9L253.092 274.575C253.763 275.011 254.239 275.69 254.421 276.47C254.603 277.249 254.476 278.069 254.067 278.757C253.659 279.446 252.999 279.949 252.228 280.162C251.456 280.375 250.632 280.282 249.928 279.901V279.901Z"
            fill="#006CB3"
          />
          <path
            id="Ellipse 516"
            d="M264.61 203.993C273.517 203.993 280.738 196.772 280.738 187.865C280.738 178.958 273.517 171.737 264.61 171.737C255.703 171.737 248.482 178.958 248.482 187.865C248.482 196.772 255.703 203.993 264.61 203.993Z"
            fill="#006CB3"
          />
          <path
            id="Path 3508"
            d="M0.192001 253.402H218.442V127.047C218.441 126.1 218.064 125.193 217.395 124.523C216.725 123.854 215.818 123.477 214.871 123.476H3.761C2.81424 123.477 1.90655 123.854 1.23709 124.523C0.567628 125.193 0.191061 126.1 0.190002 127.047L0.192001 253.402Z"
            fill="#CACACA"
          />
          <path
            id="Rectangle 2158"
            d="M211.699 128.415H6.93298V244.665H211.699V128.415Z"
            fill="white"
          />
          <path
            id="Path 3509"
            d="M0 249.981V265.573C0 266.878 0.518581 268.13 1.44164 269.053C2.36469 269.976 3.61662 270.495 4.92201 270.495H85.477V301.267H84.407C84.3231 301.267 84.24 301.284 84.1625 301.316C84.0849 301.348 84.0145 301.395 83.9552 301.454C83.8958 301.513 83.8488 301.584 83.8167 301.661C83.7845 301.739 83.768 301.822 83.768 301.906V304.046C83.768 304.13 83.7845 304.213 83.8167 304.291C83.8488 304.368 83.8958 304.439 83.9552 304.498C84.0145 304.557 84.0849 304.604 84.1625 304.636C84.24 304.668 84.3231 304.685 84.407 304.685H134.415C134.499 304.685 134.582 304.668 134.66 304.636C134.737 304.604 134.808 304.557 134.867 304.498C134.926 304.439 134.973 304.368 135.005 304.291C135.037 304.213 135.054 304.13 135.054 304.046V301.906C135.054 301.822 135.037 301.739 135.005 301.661C134.973 301.584 134.926 301.513 134.867 301.454C134.808 301.395 134.737 301.348 134.66 301.316C134.582 301.284 134.499 301.267 134.415 301.267H133.345V270.498H213.9C215.205 270.498 216.457 269.979 217.38 269.056C218.303 268.133 218.822 266.881 218.822 265.576V249.981H0Z"
            fill="#3F3D56"
          />
          <path
            id="Path 3510"
            d="M182.069 167.293H85.1C84.4887 167.293 83.9024 167.05 83.4701 166.618C83.0379 166.186 82.795 165.599 82.795 164.988C82.795 164.377 83.0379 163.79 83.4701 163.358C83.9024 162.926 84.4887 162.683 85.1 162.683H182.069C182.68 162.683 183.267 162.926 183.699 163.358C184.131 163.79 184.374 164.377 184.374 164.988C184.374 165.599 184.131 166.186 183.699 166.618C183.267 167.05 182.68 167.293 182.069 167.293Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3511"
            d="M149.533 177.699H117.633C117.022 177.699 116.435 177.456 116.003 177.024C115.571 176.592 115.328 176.005 115.328 175.394C115.328 174.783 115.571 174.196 116.003 173.764C116.435 173.332 117.022 173.089 117.633 173.089H149.533C150.144 173.089 150.731 173.332 151.163 173.764C151.595 174.196 151.838 174.783 151.838 175.394C151.838 176.005 151.595 176.592 151.163 177.024C150.731 177.456 150.144 177.699 149.533 177.699Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3512"
            d="M149.533 198.804H117.633C117.022 198.804 116.435 198.561 116.003 198.129C115.571 197.697 115.328 197.11 115.328 196.499C115.328 195.888 115.571 195.301 116.003 194.869C116.435 194.437 117.022 194.194 117.633 194.194H149.533C150.144 194.194 150.731 194.437 151.163 194.869C151.595 195.301 151.838 195.888 151.838 196.499C151.838 197.11 151.595 197.697 151.163 198.129C150.731 198.561 150.144 198.804 149.533 198.804V198.804Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3513"
            d="M149.533 209.614H117.633C117.022 209.614 116.435 209.371 116.003 208.939C115.571 208.507 115.328 207.92 115.328 207.309C115.328 206.698 115.571 206.111 116.003 205.679C116.435 205.247 117.022 205.004 117.633 205.004H149.533C150.144 205.004 150.731 205.247 151.163 205.679C151.595 206.111 151.838 206.698 151.838 207.309C151.838 207.92 151.595 208.507 151.163 208.939C150.731 209.371 150.144 209.614 149.533 209.614Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3514"
            d="M167.42 188.103H99.748C99.1367 188.103 98.5504 187.86 98.1181 187.428C97.6859 186.996 97.443 186.409 97.443 185.798C97.443 185.187 97.6859 184.6 98.1181 184.168C98.5504 183.736 99.1367 183.493 99.748 183.493H167.421C168.032 183.493 168.619 183.736 169.051 184.168C169.483 184.6 169.726 185.187 169.726 185.798C169.726 186.409 169.483 186.996 169.051 187.428C168.619 187.86 168.032 188.103 167.421 188.103H167.42Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3515"
            d="M180.455 231.747H168.871C167.831 231.747 166.835 231.334 166.1 230.599C165.364 229.864 164.952 228.867 164.952 227.827C164.952 226.788 165.364 225.791 166.1 225.056C166.835 224.321 167.831 223.908 168.871 223.908H180.452C181.492 223.908 182.488 224.321 183.223 225.056C183.959 225.791 184.371 226.788 184.371 227.827C184.371 228.867 183.959 229.864 183.223 230.599C182.488 231.334 181.492 231.747 180.452 231.747H180.455Z"
            fill="#006CB3"
          />
          <path
            id="Ellipse 517"
            d="M54.608 203.3C65.7421 203.3 74.768 194.274 74.768 183.14C74.768 172.006 65.7421 162.98 54.608 162.98C43.4739 162.98 34.448 172.006 34.448 183.14C34.448 194.274 43.4739 203.3 54.608 203.3Z"
            fill="#006CB3"
          />
          <path
            id="Path 3516"
            d="M290.515 305.519H0.533997C0.392504 305.519 0.256804 305.463 0.156754 305.363C0.0567029 305.263 0.000488281 305.127 0.000488281 304.985C0.000488281 304.844 0.0567029 304.708 0.156754 304.608C0.256804 304.508 0.392504 304.452 0.533997 304.452H290.515C290.656 304.452 290.792 304.508 290.892 304.608C290.992 304.708 291.048 304.844 291.048 304.985C291.048 305.127 290.992 305.263 290.892 305.363C290.792 305.463 290.656 305.519 290.515 305.519Z"
            fill="#CACACA"
          />
          <path
            id="Ellipse 518"
            d="M109.221 125.946C109.641 125.946 109.981 125.606 109.981 125.186C109.981 124.766 109.641 124.426 109.221 124.426C108.801 124.426 108.461 124.766 108.461 125.186C108.461 125.606 108.801 125.946 109.221 125.946Z"
            fill="white"
          />
        </g>
        <path
          id="Path 3315"
          d="M460.768 291.667C460.584 291.874 460.401 292.088 460.223 292.301C457.74 295.182 455.497 298.261 453.516 301.508C453.356 301.757 453.202 302.008 453.06 302.261C448.371 310.105 445.179 318.752 443.646 327.761C443.302 329.799 443.043 331.849 442.87 333.911C442.745 335.498 442.67 337.088 442.645 338.68C442.55 345.363 443.145 352.799 438.835 357.906C437.684 359.25 436.279 360.353 434.7 361.153C433.511 361.768 432.254 362.244 430.955 362.569H417.521C416.846 362.469 416.17 362.362 415.495 362.255C415.477 362.255 415.46 362.249 415.442 362.249L415.306 362.297L414.494 362.569L414.458 362.581V362.569C414.459 362.545 414.457 362.521 414.452 362.498C414.453 362.455 414.451 362.411 414.446 362.368V362.362C414.44 362.243 414.434 362.131 414.434 362.013L414.416 361.699C414.416 361.699 414.41 361.693 414.416 361.693C414.41 361.515 414.398 361.337 414.392 361.154C413.77 345.69 415.287 329.711 422.586 316.095C425.089 311.43 428.312 307.189 432.137 303.528C432.159 303.505 432.183 303.483 432.208 303.463C439.798 296.223 449.608 291.489 459.966 291.649C460.234 291.649 460.501 291.655 460.768 291.667Z"
          fill="#F0F0F0"
        />
        <path
          id="Path 3316"
          d="M460.798 292.002C449.069 298.021 439.534 307.583 433.548 319.33C432.215 321.835 431.285 324.535 430.792 327.33C430.4 330.069 430.749 332.863 431.802 335.421C432.736 337.826 433.97 340.178 434.302 342.769C434.453 344.097 434.321 345.443 433.915 346.717C433.51 347.991 432.839 349.164 431.948 350.161C429.792 352.703 426.848 354.318 423.922 355.797C420.669 357.44 417.263 359.097 415.004 362.065C414.73 362.425 414.188 361.979 414.461 361.62C418.391 356.453 425.313 355.326 430.027 351.12C432.227 349.16 433.849 346.52 433.666 343.489C433.506 340.835 432.236 338.408 431.266 335.981C430.202 333.5 429.762 330.797 429.984 328.107C430.333 325.296 431.157 322.564 432.422 320.029C435.179 314.277 438.824 308.993 443.222 304.373C448.213 299.072 454.074 294.664 460.551 291.339C460.951 291.134 461.193 291.793 460.795 291.998L460.798 292.002Z"
          fill="white"
        />
        <path
          id="Path 3317"
          d="M435.253 315.435C433.418 314.163 432.096 312.281 431.521 310.124C430.946 307.967 431.157 305.676 432.115 303.66C432.315 303.253 432.953 303.537 432.757 303.945C431.861 305.821 431.665 307.955 432.204 309.962C432.744 311.969 433.983 313.718 435.698 314.892C436.074 315.148 435.624 315.689 435.253 315.435Z"
          fill="white"
        />
        <path
          id="Path 3318"
          d="M431.168 334.266C435.665 333.493 439.706 331.053 442.484 327.433C442.759 327.074 443.302 327.52 443.027 327.878C440.129 331.64 435.919 334.171 431.237 334.965C430.791 335.04 430.724 334.341 431.168 334.265V334.266Z"
          fill="white"
        />
        <path
          id="Path 3319"
          d="M448.585 299.932C449.192 300.572 449.948 301.051 450.786 301.326C451.624 301.601 452.517 301.664 453.385 301.509C453.83 301.428 453.896 302.127 453.454 302.209C452.494 302.376 451.507 302.306 450.58 302.004C449.653 301.702 448.814 301.177 448.137 300.476C448.068 300.415 448.024 300.33 448.015 300.239C448.006 300.147 448.032 300.055 448.088 299.982C448.117 299.946 448.153 299.916 448.194 299.894C448.235 299.872 448.28 299.859 448.326 299.854C448.372 299.849 448.419 299.854 448.463 299.867C448.508 299.881 448.549 299.903 448.585 299.932V299.932Z"
          fill="white"
        />
        <path
          id="Path 3320"
          d="M493.479 334.54C493.206 334.599 492.934 334.659 492.655 334.724H492.649C488.936 335.531 485.294 336.637 481.759 338.03C481.486 338.137 481.214 338.243 480.941 338.356H480.935C472.508 341.787 464.787 346.745 458.16 352.979C458.136 353.003 458.113 353.021 458.089 353.044C458.075 353.061 458.059 353.077 458.042 353.091L458.012 353.121C455.383 355.612 452.945 358.298 450.719 361.155C450.719 361.161 450.713 361.167 450.707 361.173C450.352 361.635 449.99 362.097 449.635 362.573H414.491L414.455 362.585V362.573H414.307C414.307 362.567 414.313 362.561 414.319 362.555C414.325 362.549 414.325 362.549 414.325 362.543C414.367 362.484 414.408 362.425 414.443 362.371V362.365C414.452 362.347 414.464 362.331 414.479 362.317C414.515 362.258 414.556 362.21 414.586 362.157C414.823 361.825 415.054 361.487 415.291 361.157C415.676 360.606 416.067 360.057 416.464 359.51V359.504C416.47 359.498 416.47 359.492 416.476 359.486L416.488 359.474C421.295 352.646 426.896 346.413 433.172 340.906C433.362 340.74 433.551 340.574 433.753 340.42C436.605 338.007 439.651 335.833 442.86 333.92C444.613 332.883 446.42 331.931 448.281 331.064C453.08 328.852 458.196 327.409 463.443 326.786H463.455C463.467 326.786 463.479 326.78 463.497 326.78C463.515 326.78 463.527 326.774 463.539 326.774C473.973 325.565 484.667 327.692 492.849 334.056C493.07 334.214 493.271 334.374 493.479 334.54Z"
          fill="#F0F0F0"
        />
        <path
          id="Path 3321"
          d="M414.312 362.571H414.46V362.583L414.496 362.571H423.896C421.138 361.959 418.292 361.852 415.496 362.257C415.431 362.269 415.372 362.281 415.306 362.298C415.022 362.351 414.737 362.422 414.453 362.498L414.394 362.516C414.368 362.523 414.344 362.535 414.323 362.552C414.308 362.554 414.293 362.56 414.281 362.57L414.312 362.571Z"
          fill="white"
        />
        <path
          id="Path 3322"
          d="M493.307 334.83C493.088 334.794 492.874 334.753 492.655 334.723H492.649C488.078 333.977 483.438 333.743 478.815 334.023C478.584 334.035 478.347 334.053 478.11 334.064C471.694 334.512 465.387 335.949 459.41 338.324C459.404 338.324 459.404 338.324 459.404 338.33C459.339 338.354 459.274 338.377 459.215 338.401C457.823 338.964 456.448 339.58 455.103 340.243C452.529 341.441 450.16 343.037 448.082 344.971C446.418 346.613 445.158 348.619 444.402 350.831C444.325 351.056 444.248 351.275 444.177 351.5C444.124 351.678 444.07 351.862 444.017 352.039C443.317 354.522 442.885 357.139 441.594 359.41C441.235 360.043 440.799 360.628 440.294 361.152C439.771 361.696 439.182 362.172 438.54 362.568H437.039C437.856 362.215 438.613 361.737 439.284 361.152C439.806 360.702 440.262 360.182 440.641 359.606C442.11 357.39 442.56 354.688 443.248 352.17C443.361 351.761 443.479 351.364 443.609 350.97C444.293 348.797 445.439 346.798 446.968 345.11C448.939 343.075 451.241 341.39 453.776 340.127C455.376 339.286 457.019 338.523 458.706 337.84C458.925 337.745 459.144 337.662 459.364 337.579C470.014 333.434 481.579 332.215 492.859 334.048C493.078 334.078 493.291 334.119 493.511 334.155C493.953 334.232 493.752 334.907 493.307 334.83Z"
          fill="white"
        />
        <path
          id="Path 3323"
          d="M458.804 338.158C458.105 336.038 458.182 333.738 459.021 331.67C459.861 329.601 461.409 327.899 463.388 326.866C463.788 326.658 464.131 327.272 463.729 327.48C461.884 328.438 460.443 330.024 459.665 331.951C458.887 333.879 458.823 336.021 459.486 337.991C459.629 338.419 458.946 338.582 458.804 338.157V338.158Z"
          fill="white"
        />
        <path
          id="Path 3324"
          d="M444.202 350.734C448.258 352.824 452.953 353.309 457.351 352.091C457.786 351.97 457.951 352.652 457.516 352.773C452.937 354.032 448.052 353.517 443.836 351.333C443.438 351.125 443.802 350.527 444.202 350.734Z"
          fill="white"
        />
        <path
          id="Path 3325"
          d="M478.783 333.807C478.883 334.684 479.198 335.522 479.702 336.247C480.205 336.971 480.881 337.559 481.668 337.958C482.068 338.158 481.704 338.758 481.303 338.558C480.436 338.113 479.691 337.463 479.133 336.664C478.575 335.865 478.222 334.941 478.103 333.974C478.084 333.884 478.1 333.79 478.148 333.711C478.196 333.632 478.272 333.574 478.361 333.55C478.451 333.529 478.546 333.544 478.624 333.592C478.703 333.64 478.76 333.717 478.783 333.807Z"
          fill="white"
        />
        <path
          id="Path 3326"
          d="M458.522 98.524C458.682 78.9067 459.48 59.1 460.916 39.104C460.94 38.704 460.969 38.316 460.999 37.919C461.757 27.509 462.685 17.0617 463.784 6.57701V6.51801C463.783 4.78991 463.096 3.13291 461.874 1.91096C460.652 0.68902 458.995 0.00206677 457.267 0.00100708H282.484C280.762 0.00440037 279.11 0.68813 277.89 1.90331C276.669 3.11848 275.978 4.76667 275.967 6.489C274.45 20.9277 273.261 35.2943 272.4 49.589C272.364 50.057 272.341 50.519 272.317 50.989C271.89 58.0557 271.551 65.1063 271.298 72.141C271.28 72.556 271.263 72.97 271.256 73.385C269.639 116.534 271.213 159.743 275.966 202.66C275.978 204.381 276.67 206.028 277.891 207.242C279.111 208.456 280.762 209.139 282.483 209.142H457.266C458.994 209.141 460.652 208.454 461.874 207.232C463.096 206.01 463.783 204.353 463.784 202.625V202.56C462.925 194.935 462.17 187.26 461.521 179.536C461.426 178.403 461.334 177.271 461.243 176.141C460.793 170.578 460.4 164.993 460.064 159.386C459.993 158.253 459.926 157.121 459.864 155.991C459.23 144.77 458.815 133.461 458.62 122.065C458.596 120.933 458.579 119.808 458.561 118.67C458.49 113.107 458.468 107.522 458.496 101.915C458.498 100.787 458.509 99.66 458.522 98.524Z"
          fill="#F0F0F0"
        />
        <g id="paper" className={styles.paper}>
          <path
            id="Path 3327"
            d="M511.183 44.436C511.182 42.7079 510.495 41.0509 509.273 39.829C508.051 38.607 506.394 37.9201 504.666 37.919H329.883C328.161 37.9224 326.509 38.6061 325.289 39.8213C324.068 41.0365 323.377 42.6847 323.366 44.407C317.494 99.1241 316.514 154.256 320.439 209.147C320.919 215.73 321.48 222.279 322.122 228.794C322.465 232.284 322.832 235.764 323.222 239.234C323.269 239.684 323.317 240.134 323.37 240.585C323.382 242.306 324.074 243.953 325.295 245.167C326.515 246.381 328.166 247.064 329.887 247.067H504.665C506.393 247.066 508.05 246.379 509.272 245.157C510.494 243.935 511.181 242.278 511.182 240.55V240.479C504.114 177.812 504.114 111.879 511.182 44.491L511.183 44.436Z"
            fill="white"
          />
          <path
            id="Path 3328"
            d="M511.183 44.436C511.182 42.7079 510.495 41.0509 509.273 39.829C508.051 38.607 506.394 37.9201 504.666 37.919H329.883C328.161 37.9224 326.509 38.6061 325.289 39.8213C324.068 41.0365 323.377 42.6847 323.366 44.407C317.494 99.1241 316.514 154.256 320.439 209.147C320.919 215.73 321.48 222.279 322.122 228.794C322.465 232.284 322.832 235.764 323.222 239.234C323.269 239.684 323.317 240.134 323.37 240.585C323.382 242.306 324.074 243.953 325.295 245.167C326.515 246.381 328.166 247.064 329.887 247.067H504.665C506.393 247.066 508.05 246.379 509.272 245.157C510.494 243.935 511.181 242.278 511.182 240.55V240.479C504.114 177.812 504.114 111.879 511.182 44.491L511.183 44.436ZM509.993 240.579C509.983 241.987 509.417 243.334 508.419 244.327C507.42 245.32 506.069 245.878 504.661 245.879H329.883C328.47 245.877 327.115 245.314 326.115 244.315C325.116 243.315 324.553 241.96 324.551 240.547V240.479C324.509 240.1 324.468 239.715 324.427 239.335C324.018 235.709 323.637 232.073 323.284 228.427C322.662 222.034 322.111 215.606 321.631 209.142C317.704 154.281 318.682 99.1785 324.552 44.491V44.432C324.554 43.0185 325.117 41.6635 326.116 40.664C327.116 39.6646 328.471 39.1021 329.884 39.1H504.665C506.073 39.1005 507.425 39.658 508.423 40.651C509.422 41.6439 509.988 42.9916 509.997 44.4C502.929 111.843 502.929 177.852 509.993 240.579Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 142"
            d="M341.93 98.524C341.479 98.524 341.047 98.7031 340.728 99.0219C340.409 99.3407 340.23 99.7731 340.23 100.224C340.23 100.675 340.409 101.107 340.728 101.426C341.047 101.745 341.479 101.924 341.93 101.924H483.967C484.418 101.924 484.85 101.745 485.169 101.426C485.488 101.107 485.667 100.675 485.667 100.224C485.667 99.7731 485.488 99.3407 485.169 99.0219C484.85 98.7031 484.418 98.524 483.967 98.524H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143"
            d="M341.93 108.715C341.707 108.715 341.486 108.759 341.279 108.844C341.073 108.93 340.886 109.055 340.728 109.213C340.57 109.371 340.445 109.558 340.359 109.764C340.274 109.971 340.23 110.192 340.23 110.415C340.23 110.638 340.274 110.859 340.359 111.066C340.445 111.272 340.57 111.459 340.728 111.617C340.886 111.775 341.073 111.9 341.279 111.986C341.486 112.071 341.707 112.115 341.93 112.115H438.354C438.805 112.115 439.237 111.936 439.556 111.617C439.875 111.298 440.054 110.866 440.054 110.415C440.054 109.964 439.875 109.532 439.556 109.213C439.237 108.894 438.805 108.715 438.354 108.715H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 142_2"
            d="M341.93 118.675C341.479 118.675 341.047 118.854 340.728 119.173C340.409 119.492 340.23 119.924 340.23 120.375C340.23 120.826 340.409 121.258 340.728 121.577C341.047 121.896 341.479 122.075 341.93 122.075H483.967C484.418 122.075 484.85 121.896 485.169 121.577C485.488 121.258 485.667 120.826 485.667 120.375C485.667 119.924 485.488 119.492 485.169 119.173C484.85 118.854 484.418 118.675 483.967 118.675H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143_2"
            d="M341.93 128.865C341.479 128.865 341.047 129.044 340.728 129.363C340.409 129.682 340.23 130.114 340.23 130.565C340.23 131.016 340.409 131.448 340.728 131.767C341.047 132.086 341.479 132.265 341.93 132.265H438.354C438.805 132.265 439.237 132.086 439.556 131.767C439.875 131.448 440.054 131.016 440.054 130.565C440.054 130.114 439.875 129.682 439.556 129.363C439.237 129.044 438.805 128.865 438.354 128.865H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 142_3"
            d="M341.93 155.995C341.479 155.995 341.047 156.174 340.728 156.493C340.409 156.812 340.23 157.244 340.23 157.695C340.23 158.146 340.409 158.578 340.728 158.897C341.047 159.216 341.479 159.395 341.93 159.395H483.967C484.418 159.395 484.85 159.216 485.169 158.897C485.488 158.578 485.667 158.146 485.667 157.695C485.667 157.244 485.488 156.812 485.169 156.493C484.85 156.174 484.418 155.995 483.967 155.995H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143_3"
            d="M341.93 166.186C341.479 166.186 341.047 166.365 340.728 166.684C340.409 167.003 340.23 167.435 340.23 167.886C340.23 168.337 340.409 168.769 340.728 169.088C341.047 169.407 341.479 169.586 341.93 169.586H438.354C438.805 169.586 439.237 169.407 439.556 169.088C439.875 168.769 440.054 168.337 440.054 167.886C440.054 167.435 439.875 167.003 439.556 166.684C439.237 166.365 438.805 166.186 438.354 166.186H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 142_4"
            d="M341.93 176.146C341.479 176.146 341.047 176.325 340.728 176.644C340.409 176.963 340.23 177.395 340.23 177.846C340.23 178.297 340.409 178.729 340.728 179.048C341.047 179.367 341.479 179.546 341.93 179.546H483.967C484.418 179.546 484.85 179.367 485.169 179.048C485.488 178.729 485.667 178.297 485.667 177.846C485.667 177.395 485.488 176.963 485.169 176.644C484.85 176.325 484.418 176.146 483.967 176.146H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 143_4"
            d="M341.93 186.336C341.479 186.336 341.047 186.515 340.728 186.834C340.409 187.153 340.23 187.585 340.23 188.036C340.23 188.487 340.409 188.919 340.728 189.238C341.047 189.557 341.479 189.736 341.93 189.736H438.354C438.805 189.736 439.237 189.557 439.556 189.238C439.875 188.919 440.054 188.487 440.054 188.036C440.054 187.585 439.875 187.153 439.556 186.834C439.237 186.515 438.805 186.336 438.354 186.336H341.93Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 154"
            d="M370.034 77.437H342.905C342.554 77.4365 342.217 77.2967 341.969 77.0483C341.72 76.7999 341.581 76.4633 341.58 76.112V71.766C341.581 71.4147 341.72 71.078 341.969 70.8297C342.217 70.5813 342.554 70.4415 342.905 70.441H370.034C370.385 70.4415 370.722 70.5813 370.97 70.8297C371.219 71.078 371.358 71.4147 371.359 71.766V76.11C371.359 76.4616 371.219 76.7988 370.971 77.0476C370.723 77.2964 370.386 77.4365 370.034 77.437V77.437Z"
            fill="#196ED1"
          />
          <path
            id="Path 3329"
            d="M464.279 217.249L455.969 219.995L451.814 221.368C450.538 221.913 449.156 222.167 447.77 222.111C447.268 222.077 446.785 221.906 446.375 221.615C445.964 221.324 445.642 220.925 445.444 220.463C445.357 220 445.408 219.522 445.592 219.089C445.775 218.655 446.083 218.285 446.476 218.026C448.459 216.518 450.966 218.053 452.376 219.615C454.095 221.526 455.067 223.992 456.676 225.993C458.111 227.786 459.93 229.235 461.999 230.232C464.028 231.239 466.295 231.669 468.552 231.476C470.809 231.283 472.97 230.475 474.799 229.138C479.179 225.759 479.626 219.903 479.649 214.794C479.641 214.563 479.544 214.345 479.379 214.185C479.213 214.024 478.991 213.935 478.761 213.935C478.53 213.935 478.308 214.024 478.143 214.185C477.977 214.345 477.88 214.563 477.872 214.794C477.851 219.424 477.587 225.1 473.365 227.994C469.321 230.77 464.006 230.033 460.282 227.076C458.362 225.368 456.766 223.328 455.568 221.054C454.238 218.882 452.587 216.776 450.084 215.932C449.011 215.542 447.836 215.535 446.758 215.911C445.68 216.287 444.765 217.025 444.168 217.998C443.895 218.457 443.723 218.969 443.664 219.5C443.604 220.031 443.659 220.568 443.823 221.076C443.988 221.584 444.258 222.052 444.618 222.447C444.977 222.842 445.416 223.157 445.906 223.369C448.806 224.752 452.128 223.132 454.922 222.208L464.755 218.959C465.836 218.602 465.373 216.885 464.283 217.245L464.279 217.249Z"
            fill="#196ED1"
          />
        </g>
        <path
          id="rightHand"
          className={styles.rightHand}
          d="M429.878 240.808L417.933 209.939L412.386 198.668L423.054 192.775L431.646 207.912L437.405 239.54C438.31 240.159 439.009 241.034 439.413 242.054C439.817 243.073 439.908 244.19 439.672 245.26C439.437 246.331 438.887 247.307 438.093 248.063C437.299 248.819 436.297 249.321 435.215 249.503C434.134 249.686 433.023 249.541 432.025 249.087C431.027 248.634 430.187 247.892 429.613 246.958C429.04 246.023 428.758 244.939 428.805 243.843C428.853 242.748 429.226 241.692 429.878 240.81V240.808Z"
          fill="#9F616A"
        />
        <path
          id="Path 3330"
          d="M439.655 347.892L433.828 349.812L423.65 328.252L432.25 325.419L439.655 347.892Z"
          fill="#9F616A"
        />
        <path
          id="Path 3331"
          d="M443.811 352.788L443.411 351.588L442.368 348.418L441.657 346.262L439.713 346.902L439.313 346.872L433.975 346.511L433.264 346.464L432.209 346.393L431.119 346.316L428.074 350.742H428.068C426.93 351.113 425.876 351.706 424.969 352.487C424.062 353.268 423.319 354.221 422.783 355.292C422.718 355.422 422.658 355.553 422.599 355.683C422.452 356.002 422.328 356.33 422.226 356.666C421.765 358.118 421.679 359.663 421.977 361.157C422.053 361.529 422.15 361.896 422.267 362.257L422.285 362.316L422.356 362.535L422.783 362.393L426.527 361.161L439.644 356.842L442.244 355.983L443.044 355.716H443.05L444.602 355.206L443.811 352.788Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3332"
          d="M384.423 352.96H378.288L375.369 329.298H384.423V352.96Z"
          fill="#9F616A"
        />
        <path
          id="Path 3333"
          d="M374.606 249.23L371.887 253.93C371.887 253.93 368.898 254.746 369.875 264.487C370.564 271.365 370.788 302.163 372.524 322.171C373.247 330.498 374.231 336.956 375.624 339.043L376.479 339.809L385.406 340.662L385.631 340.583L388.909 317.431C389.497 313.275 389.409 309.052 388.648 304.924V304.924L388.055 298.407L396.274 265.407C401.536 278.931 406.109 292.713 409.974 306.701C411.257 311.55 416.133 314.634 417.982 318.795C418.601 320.189 419.21 321.54 419.803 322.836C423.713 331.371 425.673 337.585 427.156 338.102H428.341L435.451 335.732L436.851 332.216L425.973 300.184L417.827 248.464C402.305 240.121 387.744 239.193 374.606 249.23Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3334"
          d="M419.069 179.539C418.533 178.359 417.903 177.223 417.185 176.144C415.543 173.586 413.262 171.501 410.567 170.095L408.233 169.58L404.05 168.656L401.85 166.185L398.231 162.139H385.786L384.121 166.186L382.721 169.581L382.121 171.027L371.468 176.353C371.462 176.353 371.462 176.353 371.456 176.359C371.598 177.426 371.738 178.486 371.877 179.541C372.179 181.834 372.463 184.101 372.73 186.341C372.863 187.478 372.994 188.61 373.121 189.736C373.521 193.269 373.863 196.739 374.146 200.146C374.401 203.191 374.604 206.193 374.756 209.152C375.127 215.871 375.154 222.604 374.839 229.326C374.756 230.908 376.326 232.709 376.202 234.255C375.995 236.779 374.045 239.019 373.702 241.43C373.622 242.923 373.458 244.411 373.21 245.886C373.106 246.295 372.951 246.689 372.748 247.059C372.748 247.065 372.742 247.065 372.742 247.071C368.476 254.69 371.824 251.052 371.427 252.765L371.617 252.812C371.706 252.836 371.978 252.907 372.417 253.012C374.923 253.612 385.256 243.912 392.194 243.912C396.359 243.912 407.385 250.761 411.076 251.259C413.997 251.65 416.604 252.006 418.156 250.637C418.606 250.238 418.95 249.733 419.156 249.168L419.174 249.115L419.168 249.062L419.02 247.077L418.931 245.892L417.663 228.892C416.955 228.014 416.507 226.955 416.371 225.835C416.235 224.716 416.416 223.58 416.893 222.558L417.154 222.007L416.194 209.162L416.016 206.762L415.951 205.95V205.944L415.489 199.744L416.603 196.355V196.349L418.771 189.749L419.885 186.354L420.585 184.221L420.632 184.079C420.262 182.518 419.739 180.997 419.069 179.539V179.539Z"
          fill="#196ED1"
        />

        <path
          id="Path 3336"
          d="M430.119 198.28L427.062 196.301L423.549 189.731L421.736 186.336L420.593 184.203L420.107 183.291L414.615 179.541L410.903 177.004L407.881 179.54L406.441 180.749L407.851 186.336L408.704 189.731L410.565 197.096L416.022 206.748L417.379 209.148L418.155 210.528L418.161 210.54L418.268 210.724L432.055 210.149L432.487 210.131L432.511 209.147L432.523 208.791C432.529 208.613 432.529 208.436 432.529 208.258C432.528 204.788 431.702 201.368 430.119 198.28V198.28Z"
          fill="#196ED1"
        />
        <g id="leftHand" className={styles.leftHand}>
          <path
            id="lefthand"
            d="M325.768 228.532L350.203 206.204L358.695 196.946L368.056 204.746L357.066 218.246L329.681 235.085C329.433 236.153 328.871 237.122 328.067 237.869C327.264 238.615 326.256 239.104 325.173 239.273C324.089 239.442 322.98 239.284 321.987 238.818C320.995 238.352 320.164 237.6 319.602 236.659C319.04 235.717 318.772 234.629 318.832 233.535C318.893 232.44 319.28 231.388 319.942 230.515C320.605 229.641 321.514 228.986 322.552 228.632C323.59 228.279 324.71 228.244 325.768 228.532V228.532Z"
            fill="#9F616A"
          />
          <path
            id="Path 3338"
            d="M376.208 189.73L376.161 186.335L376.072 179.535L376.036 176.898L375.936 176.827C375.384 176.465 374.759 176.231 374.105 176.14C373.53 176.045 372.944 176.045 372.369 176.14C372.066 176.187 371.767 176.256 371.474 176.347C371.468 176.347 371.468 176.347 371.462 176.353C369.706 176.984 368.155 178.082 366.977 179.529L366.971 179.535C365.196 181.65 363.622 183.927 362.271 186.335C361.602 187.472 360.962 188.622 360.363 189.73C358.1 193.943 356.471 197.592 356.441 197.658L349.675 206.445L349.77 206.605L351.257 209.147L356.708 218.467L357.573 219.948L359.783 218.769C362.359 217.395 364.39 215.184 365.542 212.501L366.33 209.491L366.62 209.147L374.156 200.141L376.309 197.554L376.208 189.73Z"
            fill="#196ED1"
          />
        </g>
        <path
          id="Ellipse 501"
          d="M402.032 150.542C403.041 144.324 398.819 138.465 392.601 137.456C386.383 136.447 380.524 140.67 379.515 146.888C378.506 153.106 382.729 158.965 388.947 159.974C395.165 160.983 401.023 156.76 402.032 150.542Z"
          fill="#9F616A"
        />
        <path
          id="Path 3339"
          d="M379.749 141.241C379.242 139.467 378.228 137.941 378.735 137.941C379.242 137.941 379.495 136.674 379.495 136.674C379.495 136.674 380.002 136.421 380.255 136.674C380.508 136.927 380.762 134.9 380.762 134.9C380.762 134.9 381.015 134.14 381.269 134.647C381.523 135.154 381.522 134.9 381.776 134.14C382.03 133.38 383.297 132.873 383.297 132.873C383.297 132.873 388.113 133.126 388.873 132.113C389.633 131.1 398.251 131.86 400.025 133.127C401.799 134.394 408.789 136.66 404.642 143.77C407.012 147.917 403.474 155.315 402.56 156.192C401.114 157.58 400.113 158.08 393.273 159.441C391.935 159.707 388.645 148.509 388.645 148.509C388.645 148.509 380.257 143.013 379.749 141.241Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3340"
          d="M384.797 352.036L384.43 351.888L377.83 349.198L376.817 348.789L372.539 352.036H372.533C370.116 352.039 367.799 353.001 366.091 354.711C364.382 356.42 363.422 358.738 363.421 361.155V361.455H386.842V352.04L384.797 352.036Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3341"
          d="M453.635 361.859C453.635 361.952 453.617 362.044 453.582 362.13C453.547 362.216 453.495 362.294 453.43 362.361C453.364 362.427 453.286 362.479 453.201 362.515C453.115 362.551 453.023 362.57 452.93 362.57H332.478C332.29 362.57 332.11 362.495 331.977 362.363C331.845 362.23 331.77 362.05 331.77 361.862C331.77 361.674 331.845 361.494 331.977 361.361C332.11 361.229 332.29 361.154 332.478 361.154H452.93C453.116 361.156 453.294 361.231 453.425 361.363C453.556 361.495 453.629 361.673 453.63 361.859H453.635Z"
          fill="#CCCCCC"
        />
        <path
          id="Path 3342"
          d="M329.278 0C328.346 16.57 322.255 32.4336 311.858 45.3697C301.462 58.3057 287.28 67.6664 271.298 72.141C271.28 72.556 271.262 72.97 271.256 73.385C287.569 68.8975 302.06 59.3994 312.683 46.2319C323.307 33.0644 329.526 16.8927 330.463 0H329.278Z"
          fill="white"
        />
        <path
          id="Path 3343"
          d="M311.131 0C308.378 10.4614 303.524 20.253 296.865 28.7785C290.207 37.3041 281.883 44.3853 272.4 49.591C272.364 50.059 272.341 50.521 272.317 50.991C282.145 45.7098 290.775 38.4541 297.665 29.6792C304.555 20.9044 309.557 10.7998 312.357 0H311.131Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1:2">
          <rect width="511.183" height="362.582" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

