import React, { useState } from 'react';
import {
  Col,
  Row,
  Typography,
  Card,
  Space,
  Button,
  Select,
  Radio,
  message,
  Divider,
} from 'antd';

import { AlignCenterOutlined, HomeOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';

const { Option } = Select;
const { Text } = Typography;

export default function FilterCard({ loading, filterValue, setFilterValue }) {
  const { serverDate } = useAppStore();
  const year = serverDate.getFullYear();

  const [years, setYears] = useState(filterValue?.years || []);
  const [tosovIndicator, setTosovIndicator] = useState(
    filterValue?.profitExpenseLoss
  );

  const children = [];

  for (let i = 2015; i <= year; i++) {
    children.push(<Option key={i}>{i}</Option>);
  }

  const handleChange = yearList => {
    setYears(yearList);
  };

  const onChange = valueStr => {
    setTosovIndicator(valueStr.target.value);
  };

  const clicker = () => {
    if (years.length === 0) {
      return message.info('Он сонгоно уу');
    }

    const filteredData = {
      years,
      profitExpenseLoss: tosovIndicator,
    };

    setFilterValue(filteredData);
  };

  return (
    <>
      <Col span={24} className={styles.bigCardstyling}>
        <Radio.Group
          style={{ width: '100%' }}
          value={tosovIndicator}
          onChange={onChange}
        >
          <br />
          <Card
            title={
              <Space size={6}>
                <AlignCenterOutlined style={{ fontSize: 14 }} />{' '}
                <Text className={styles.localIndicatorText} strong>
                  {' '}
                  Шүүлтүүр
                </Text>
              </Space>
            }
            extra={<HomeOutlined />}
            style={{ height: '100%' }}
          >
            <Space direction="vertical">
              <Text>Он сонгох:</Text>
              <Divider style={{ margin: '4px 0px 11px 0px' }} />
            </Space>
            <Row className={styles.yearSelect}>
              <Select
                mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder="Он сонгох"
                defaultValue={['2020', '2021', '2022']}
                onChange={handleChange}
              >
                {children}
              </Select>
            </Row>
            <br />
            <br />
            <br />
            <Col
              xs={15}
              sm={23}
              md={23}
              lg={23}
              xl={21}
              style={{ padding: '0px 0px', marginRight: '0px' }}
            >
              <Text>Төсөв, гүйцэтгэл</Text>
              <Divider style={{ margin: '4px 0px 11px 0px' }} />
              <Space direction="vertical">
                <Radio value="1" name="profitExpenseLoss">
                  Нийт орлого ба тусламжийн дүн
                </Radio>
                <Radio value="2" name="profitExpenseLoss">
                  Нийт зарлага ба цэвэр зээлийн дүн
                </Radio>
                <Radio value="3" name="profitExpenseLoss">
                  Тэнцвэржүүлсэн нийт тэнцэл
                </Radio>
              </Space>
            </Col>

            <Row justify="center" className={styles.filterCardButtonRow}>
              <Button type="primary" loading={loading} onClick={clicker}>
                Шүүх
              </Button>
            </Row>
          </Card>
        </Radio.Group>
      </Col>
    </>
  );
}
