/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState,useEffect} from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {  message } from 'antd';
import { downloadBlob } from '../../../service/Service';

export const PdfViewer = (props) => {
  const {pdfUuid,setEmptySvg,setShowLoader} = props
  const [viewPdf, setViewPdf] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [IsShow, setIsShow] = useState(true);
  const setFileView = selectedFile => {
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = e => {
      setViewPdf(e.target.result);
    };
  };
  const downloadPdf = () =>{
    setShowLoader(true);
    setEmptySvg(false)
    setIsShow(true)
      downloadBlob(`/gap-file-service/files/download?uuid=${pdfUuid}`).then(blob => {
        setFileView(blob);
        setEmptySvg(false)
        setIsShow(false)
        setShowLoader(false);
      })
      .catch(() => {
        setViewPdf('error')
        message.info('Алдаатай файл')
        setEmptySvg(true)
        setShowLoader(false);
      })
  }

  useEffect(() => {
    downloadPdf()

  },[pdfUuid])

  if (!viewPdf) {
    return '.';
  }
  if (viewPdf === 'error') {
    return null;
  }



  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <section
        id="review-pdf"
        className="border border-white text-pink text-sm"
      >
        {IsShow? <></> :<Viewer fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} /> }
      </section>
    </Worker>
  );
};
