import { message } from 'antd';
import axios from 'axios';
import { MSG } from '../constants/Constant';
import { Warning } from '../components/Confirm';
import { FILTER_MODE } from '../constants/LazyTable';

export const convertLazyParamsToObj = (lazyParams, searchParam = null) => {
  const obj = { search: searchParam || '' };
  if (lazyParams.sortField) {
    obj.sort = `${lazyParams.sortField},${
      lazyParams.sortOrder > 0 ? 'asc' : 'desc'
    }`;
  }
  if (lazyParams.page) {
    obj.page = lazyParams.page;
  }
  if (lazyParams.rows) {
    obj.size = lazyParams.rows;
  }
  if (lazyParams.filters) {
    Object.entries(lazyParams.filters).forEach(([key, value]) => {
      if (obj.search !== '') {
        obj.search += ' AND ';
      }
      if (value.matchMode === FILTER_MODE.IN) {
        obj.search += '(';
        value.value.forEach(element => {
          obj.search += `${
            obj.search.endsWith('(') ? '' : ' OR '
          }${key}:${element}`;
        });
        obj.search += ')';
      } else if (value.matchMode === FILTER_MODE.CONTAINS) {
        obj.search += `${key}:*${value.value}*`;
      } else if (value.matchMode === FILTER_MODE.EQUALS) {
        obj.search += `${key}:${value.value}`;
      } else if (value.matchMode === FILTER_MODE.STARTS_WITH) {
        obj.search += `${key}:${value.value}*`;
      } else if (value.matchMode === FILTER_MODE.ENDS_WITH) {
        obj.search += `${key}:*${value.value}`;
      }
    });
  }
  return obj;
};

export const errorCatch = (error, serviceName) => {
  if (axios.isCancel(error)) {
    return;
  }
  if (!error) {
    message.error('Серверийн алдаа гарлаа! Түр хүлээгээд дахин оролдно уу.');
    return;
  }
  if (error.request) {
    if (
      error.request.statusText === 'Forbidden' &&
      error.request.status === 403
    ) {
      Warning({ title: MSG.NOT_AUTHORIZED, content: serviceName || '' });
      return;
    }
    if (error.request.responseText) {
      message.warning(
        'Серверийн алдаа гарлаа! Түр хүлээгээд дахин оролдно уу.'
      );
      return;
    }
    let msg = `${error.request.statusText}-${error.request.status}; `;
    if (error.response && error.response.config) {
      const urls = error.response.config.url?.split('?');
      msg += urls[0];
    }
    message.warning(msg);
  } else if (error.response) {
    if (typeof error.response.data === 'string') {
      message.warning(error.response.data);
    }
  } else {
    message.warning(error.message);
  }
};

export const sortArray = (list, sortField) => {
  if (!list) return [];
  return list.sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1));
};

export const getPathname = ({ pathname, search }) => {
  let path = pathname;
  let searchStr = search || '';
  if (path !== '/' && path.endsWith('/')) {
    path = path.slice(0, -1);
  }
  if (search.endsWith('/')) {
    searchStr = searchStr.slice(0, -1);
  }
  return path + searchStr;
};

export const getImgSrc = uuid =>
  `${process.env.REACT_APP_BASE_URL}gap-file-service/files/download?uuid=${uuid}`;

export const getDifferenceInDays = (date1, date2) => {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  return diffInMs / (1000 * 60 * 60 * 24);
};

export const convertCurrencyFormat = value =>
  Number(value || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const convertCurrencyFormatFraction = value =>
  Number(value || 0).toLocaleString(undefined, {});
