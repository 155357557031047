import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Divider, Image, Affix, Grid, Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import styles from './style.module.scss';
import OrgContext from '../../context/OrgContext';
import no from '../organizationPage/assets/no.png';

import GeneralInfotabs from './GeneralInfotabs/GeneralInfotabs';
import { getImgSrc } from '../../tools/Tools';
import { USER_TYPE } from '../../constants/Constant';
// import { ORGANIZATION_PAGE } from '../../settings/constantRoutes';
import MetaInfo from '../../components/MetaInfo';
import BranchOrganizations from './GeneralInfotabs/branchOrganizations/branchOrganizations';

const { useBreakpoint } = Grid;
const labelSpan = 10;
const valueSpan = 14;

export default function OrganizationOne() {
  // const navigate = useNavigate();
  const { orgId } = useParams();

  const [showInfo, setShowInfo] = useState(false);
  const [showBranches, setShowBranches] = useState(false);
  const { orgInfo, setOrgData } = useContext(OrgContext);
  const isMobile = window.innerWidth < 1000;
  useBreakpoint();

  useEffect(() => {
    setOrgData(orgId);
  }, []);

  return (
    <>
      {orgInfo?.name && <MetaInfo title={orgInfo.name} />}
      {/* <div className={styles.nameOrg}>
        <Text
          onClick={() => {
            navigate(ORGANIZATION_PAGE);
          }}
        >
          Байгууллагын ерөнхий хуудас /{' '}
          <b className={styles.blue}>{orgInfo.name}</b>
        </Text>
      </div> */}
      <Row gutter={[10, 10]} style={{ marginTop: '30px' }}>
        <Affix
          offsetTop={60}
          style={{ display: isMobile ? 'none' : 'block', width: '100%' }}
        >
          <Row gutter={14} align="middle" className={styles.orgMainInfo}>
            <Col span={4} flex="0 1 120px">
              <div className={styles.logoOrganization}>
                <Image
                  src={getImgSrc(orgInfo.logoUuid)}
                  fallback={no}
                  preview={false}
                />
              </div>
            </Col>
            <Col span={6}>
              {/* <div className={styles.label} style={{ marginTop: 0 }}>
                Байгууллагын нэр:
              </div> */}
              <div className={styles.orgName}>{orgInfo.name}</div>
            </Col>
            {/* <Col span={6}>
              <div className={styles.label}>
                Байгууллагын чиг үүрэг, үйл ажиллагааны чиглэл:
              </div>
              <Paragraph
                ellipsis={{
                  rows: 4,
                  tooltip: orgInfo.function,
                }}
              >
                {orgInfo.function}
              </Paragraph>
            </Col> */}
            <Col span={5} className={styles.contact}>
              <Row className={styles.labelContact}>И-мэйл:</Row>
              <Row>{orgInfo.email}</Row>
              <Row span={labelSpan} className={styles.labelContact}>
                Ажлын утас:
              </Row>
              <Row>{orgInfo.workPhone}</Row>
            </Col>
            <Col span={6} className={styles.contact}>
              <Row className={styles.labelContact}>Шуудангийн хаяг:</Row>
              <Row>{orgInfo.postAddress}</Row>
              <Row className={styles.labelContact}>Сошиал хаяг:</Row>
              <Row>{orgInfo.socialAddress}</Row>
            </Col>
            <Col span={3} style={{ height: '100%' }}>
              <Row>
                <Button
                  type="secondary"
                  onClick={() => setShowInfo(true)}
                  style={{ marginBottom: '10px' }}
                >
                  <b>Дэлгэрэнгүй мэдээлэл</b>
                </Button>
              </Row>
              <Row>
                <Button
                  type="secondary"
                  onClick={() => setShowBranches(true)}
                  style={{ paddingRight: '18px' }}
                >
                  <b>Салбар байгууллагууд</b>
                </Button>
              </Row>
            </Col>
          </Row>
        </Affix>
        <Col sm={24} style={{ display: isMobile ? 'block' : 'none' }}>
          <div id="orgMainInfo" className={styles.orgMainInfo}>
            <Row
              gutter={16}
              justify="center"
              align="middle"
              style={{ marginBottom: 16 }}
            >
              <Col flex="0 1 130px">
                <div className={styles.logoOrganization}>
                  <Image
                    src={getImgSrc(orgInfo.logoUuid)}
                    fallback={no}
                    preview={false}
                  />
                </div>
              </Col>
              <Col flex="1 1 150px">
                <div className={styles.label} style={{ marginTop: 0 }}>
                  Байгууллагын нэр:
                </div>
                <div className={styles.orgName}>{orgInfo.name}</div>
              </Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={24} className={styles.label}>
                Байгууллагын чиг үүрэг, үйл ажиллагааны чиглэл:
              </Col>
              <Col span={24}>{orgInfo.function}</Col>
            </Row>
            <Row className={styles.contact} style={{ display: 'none' }}>
              <Col span={24} className={styles.label}>
                ТЕЗ:
              </Col>
              <Col span={24}>{orgInfo.refTypeName}</Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={24} className={styles.label}>
                Төсвийн захирагч:
              </Col>
              <Col span={24}>
                {orgInfo.orgContacts &&
                  orgInfo.orgContacts.map((item, index) =>
                    item.type === USER_TYPE.ORG_CEO ? (
                      <Col key={index}>
                        {item?.lastname?.substring(0, 1)}
                        {'.'}
                        {item?.firstname}
                        {', '}
                        {item?.phone}
                        {', '}
                        {item?.workPhone}
                      </Col>
                    ) : (
                      ''
                    )
                  )}
              </Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={24} className={styles.label}>
                Нягтлан бодогч:
              </Col>
              <Col span={24}>
                {orgInfo.orgContacts &&
                  orgInfo.orgContacts.map((item, index) =>
                    item.type === USER_TYPE.ORG_ACCOUNTANT ? (
                      <Col key={index}>
                        {item?.lastname?.substring(0, 1)}
                        {'.'}
                        {item?.firstname}
                        {', '}
                        {item?.phone}
                        {', '}
                        {item?.workPhone}
                      </Col>
                    ) : (
                      ''
                    )
                  )}
              </Col>
            </Row>
            <Divider />
            <Row className={styles.contact}>
              <Col span={labelSpan} className={styles.labelContact}>
                И-мэйл:
              </Col>
              <Col span={valueSpan} className={styles.labelContact}>
                {orgInfo.email}
              </Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={labelSpan} className={styles.labelContact}>
                Ажлын утас:
              </Col>
              <Col span={valueSpan}>{orgInfo.workPhone}</Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={labelSpan} className={styles.labelContact}>
                Веб хуудас:
              </Col>
              <Col span={valueSpan}>{orgInfo.website}</Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={labelSpan} className={styles.labelContact}>
                Шуудангийн хаяг:
              </Col>
              <Col span={valueSpan}>{orgInfo.postAddress}</Col>
            </Row>
            <Row className={styles.contact}>
              <Col span={labelSpan} className={styles.labelContact}>
                Сошиал хаяг:
              </Col>
              <Col span={valueSpan}>{orgInfo.socialAddress}</Col>
            </Row>
            <Row justify="end">
              <Col xs={24} sm={9} md={7}>
                <Button
                  type="secondary"
                  onClick={() => setShowBranches(true)}
                  style={{
                    float: 'right',
                    paddingRight: '18px',
                    marginTop: '5px',
                  }}
                >
                  <b>Салбар байгууллагууд</b>
                </Button>
              </Col>
              <Col xs={24} sm={9} md={7}>
                <Button
                  type="secondary"
                  onClick={() => setShowInfo(true)}
                  style={{ float: 'right', marginTop: '5px' }}
                >
                  <b>Дэлгэрэнгүй мэдээлэл</b>
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal
        width={isMobile ? '90%' : '80%'}
        title="Дэлгэрэнгүй мэдээлэл"
        visible={showInfo}
        footer={false}
        onCancel={() => setShowInfo(false)}
      >
        <GeneralInfotabs />
      </Modal>
      <Modal
        width={isMobile ? '90%' : '80%'}
        title="Салбар байгууллагууд"
        visible={showBranches}
        footer={false}
        onCancel={() => setShowBranches(false)}
      >
        <BranchOrganizations />
      </Modal>
    </>
  );
}
