import { isEmpty } from 'lodash';

export const sortByPriority = (first, second) => {
  if (first.priority) {
    return first.priority - second.priority;
  }
  return 0;
};

export const getParamValue = (location, id) => {
  const search = new URLSearchParams(location.search);
  return search.get(id);
};

export const numberBolgogch = (treeMapData, parent) => {
  treeMapData?.forEach((item, index) => {
    item.number = parent?.number ? `${parent?.number}-${index + 1}` : index + 1;
    item.key = item.number;
    if (!isEmpty(item.children)) {
      numberBolgogch(item.children, item);
    } else {
      item.children = null;
    }
  });
};
