import React, { useState, useEffect } from 'react';
import { Space, Table } from 'antd';
import { isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import style from './style.module.scss';
import { getByOrgIdForDecision } from '../../../../service/reportServices';
import ReportTemplateGetHeader from './ReportTemplateGetHeader';
import ReportTemplateGetBody from './ReportTemplateGetBody';

let decisionForm = null;

export default function ReportAttachFile({ reportFormData }) {
  const [columns, setColumns] = useState([]);
  const [selectedList, setSelectedList] = useState();

  const initData = async () => {
    const reportFormDataId = reportFormData[0] ? reportFormData[0].id : 0;
    const params = { reportFormDataId };
    const dataList = await getByOrgIdForDecision(params);
    if (!isEmpty(dataList)) {
      // Шийдвэрийн тайлан нэг л байна гэж үзэж байгаа.
      decisionForm = dataList[0].reportForm[0] || {};
      decisionForm.reportFormHeaders = dataList[0].reportFormHeaders;
      setColumns(ReportTemplateGetHeader(decisionForm));

      const datas = [];
      try {
        if (reportFormData) {
          if (!isEmpty(dataList[0].years)) {
            dataList[0].years.forEach(yearRow => {
              const months = yearRow.months || [];
              months.forEach(monthRow => {
                datas.push(
                  ...ReportTemplateGetBody(
                    decisionForm,
                    monthRow.reportFormDataBodies
                  )
                );
              });
            });
            datas.forEach((row, index) => {
              row.rowNo = index + 1;
            });
          }
        }
      } finally {
        setSelectedList(datas);
      }
    }
  };

  useEffect(initData, []);

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <div className={style.tableWrapper}>
        <Table
          rowKey={record => record?.rowNo}
          bordered
          size="small"
          pagination={false}
          loading={{
            spinning: selectedList === undefined,
            indicator: <LoadingOutlined />,
          }}
          columns={columns}
          dataSource={selectedList}
          scroll={{ x: '100%' }}
        />
      </div>
    </Space>
  );
}

