import Axios from './Axios';

const serviceBaseUrl = 'gap-file-service';

const serviceFileDownload = `${serviceBaseUrl}/files/download`;

export const downloadFile = uuid => {
  const link = document.createElement('a');
  link.href = `${Axios.defaults.baseURL}${serviceFileDownload}?uuid=${uuid}`;
  link.target = '_blank';
  link.click();
};

