import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  Row,
  Space,
  Typography,
  Tabs,
  Dropdown,
  Button,
  Menu,
  Card,
} from 'antd';
import {
  BarChartOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  TableOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import styles from './style.module.scss';
import DataTables from './DataTable';
import StackedBarCharts from '../../components/MultipleColumnChart/stackedBarChart';
import { getReportYearBureaus } from '../../../service/reportServices';
import { useAppStore } from '../../../context/Tools';

const { Text } = Typography;
const { TabPane } = Tabs;
const menu = (
  <Menu>
    <Menu.Item key="1" icon={<FileImageOutlined />}>
      JPG
    </Menu.Item>
    <Menu.Item key="3" icon={<FilePdfOutlined />}>
      Excel
    </Menu.Item>
  </Menu>
);

export default function LocalBudgetInfo() {
  const { serverDate } = useAppStore();

  const [isShow, setIsShow] = useState(false);
  const [typeId, setTypeId] = useState(21);
  const [budgetData, setBudgetData] = useState([]);
  useEffect(() => {
    const param = {
      search: {
        status: true,
        refTransactionTypeId: typeId,
        year: serverDate.getFullYear(),
      },
    };
    getReportYearBureaus(param).then(result => {
      const data = result.content;
      setBudgetData(data);
    });
  }, [typeId]);

  return (
    <>
      <div className="sub-title">ОРОН НУТГИЙН ТӨСВИЙН МЭДЭЭЛЭЛ</div>
      <Row justify="space-between">
        <Space>
          <Text>Огноо сонгох:</Text>
          <DatePicker
            placeholder="Он сонгох"
            picker="year"
            bordered={false}
            disabledDate={current => current.isBefore(moment('2023-01-01'))}
          />
        </Space>
        <Space className={styles.downloadText}>
          <Space size={14}>
            <TableOutlined
              onClick={() => setIsShow(true)}
              className={styles.localIncomeToolsIcon}
            />
            <BarChartOutlined
              onClick={() => setIsShow(false)}
              className={styles.localIncomeToolsIcon}
            />
          </Space>
          <Space>
            <Dropdown overlay={menu}>
              <Button type="text">
                <DownloadOutlined className="icon" />
              </Button>
            </Dropdown>
          </Space>
        </Space>
      </Row>
      <div className={styles.TabsCol}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          onChange={key => {
            switch (key) {
              default:
                setTypeId(21);
                break;
              case '2':
                setTypeId(37);
                break;
            }
          }}
        >
          <TabPane tab="Орон нутгийн төсвийн орлого /аймгаар/" key="1">
            <Card style={{ overflow: 'auto' }}>
              {isShow ? (
                <DataTables budgetData={budgetData} />
              ) : (
                <StackedBarCharts
                  budgetData={budgetData}
                  typeId={typeId}
                  id="income"
                />
              )}
            </Card>
            <Text strong className={styles.localIncomeTableFooterText} />
          </TabPane>
          <TabPane tab="Орон нутгийн төсвийн зарлага /аймгаар/" key="2">
            <Card style={{ overflow: 'auto' }}>
              {isShow ? (
                <DataTables budgetData={budgetData} />
              ) : (
                <StackedBarCharts
                  budgetData={budgetData}
                  typeId={typeId}
                  id="cost"
                />
              )}
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

