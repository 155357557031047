import React, { useEffect, useState } from 'react';
import { Table, Skeleton, Empty, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import styles from './style.module.scss';
import { OPEN_DATA } from '../../settings/constantRoutes';

export default function OpenDataTable({ selectedFormType }) {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [datasource, setDatasource] = useState();

  const handleOpenData = reportId => {
    navigate(`${OPEN_DATA}/${reportId}`);
  };

  useEffect(() => {
    setSearchValue('');
    setDatasource(selectedFormType?.children);
  }, [selectedFormType]);

  useEffect(() => {
    const allData = selectedFormType?.children;
    if (searchValue) {
      const value = searchValue.toLowerCase();
      const filteredData = (allData || []).filter(
        data =>
          (data.name || '').toLowerCase().includes(value) ||
          (data.description || '').toLowerCase().includes(value)
      );
      setDatasource([...filteredData]);
    } else {
      setDatasource(allData);
    }
  }, [searchValue]);

  const headerData = [
    {
      title: (
        <Input
          prefix={<SearchOutlined />}
          className={styles.searchInput}
          value={searchValue}
          placeholder="Хайлт хийх"
          onChange={e => setSearchValue(e.target.value)}
        />
      ),
      children: [
        {
          title: <div className={styles.center}>№</div>,
          dataIndex: 'number',
          key: 'number',
          width: '5%',
          render: (text, record, index) => (
            <div className={styles.center}>{index + 1}</div>
          ),
        },
        {
          title: 'Маягтын нэр',
          dataIndex: 'name',
          key: 'name',
          width: '45%',
          render: text => <div className={styles.openDataLink}>{text}</div>,
        },
        {
          title: 'Тайлбар',
          dataIndex: 'description',
          key: 'description',
        },
      ],
    },
  ];

  if (!selectedFormType) {
    return null;
  }

  return (
    <div className={styles.tableContainer}>
      <Table
        rowKey="id"
        size="small"
        dataSource={datasource}
        columns={headerData}
        pagination={false}
        onRow={record => ({
          onClick: () => {
            handleOpenData(record.id);
          },
        })}
        locale={{
          emptyText: isNil(selectedFormType?.children) ? (
            <Skeleton active />
          ) : (
            <Empty description="Өгөгдөл олдсонгүй." />
          ),
        }}
      />
    </div>
  );
}

