import React from 'react';
import { Row } from 'antd';
import styles from './style.module.scss';
import FilterByOrg from './FilterByOrg';
import FilterByLocation from './FilterByLocation';
import FilterByTEZ from './FilterByTEZ';
import FilterByReportGroup from './FilterByReportGroup';

export default function FilterOrg() {
  return (
    <Row gutter={[20, 10]} className={styles.row}>
      <FilterByLocation />
      <FilterByTEZ />
      <FilterByReportGroup />
      <FilterByOrg />
    </Row>
  );
}

