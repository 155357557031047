import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, DatePicker, message, Space } from 'antd';
import { isEmpty } from 'lodash';
import { YEAR_MONTH_FORMAT } from '../../../../constants/Constant';
import { useAppStore } from '../../../../context/Tools';
import { getOrgByRefOrgType } from '../../../../service/reportServices';

const { RangePicker } = DatePicker;

export const OrgByRefOrgType = ({ setStaticReportData }) => {
  const { cancelReportRef, serverDate } = useAppStore();

  const [isLoading, setIsLoading] = useState(false);
  const [filterDate, setFilterDate] = useState([
    `${serverDate.getFullYear()}-01`,
    moment(serverDate).format(YEAR_MONTH_FORMAT),
  ]);

  const onChange = (date, dateString) => {
    setFilterDate(dateString);
  };

  const getData = async () => {
    if (!filterDate || filterDate.length !== 2) {
      message.info('Огноо сонгоогүй байна.');
      return;
    }
    setIsLoading(true);
    const params = {
      beginAt: filterDate[0],
      endAt: filterDate[1],
    };

    try {
      const result = await getOrgByRefOrgType(params, cancelReportRef);
      const dataList = result || [];
      const processedData = [];

      dataList.forEach(row => {
        if (!isEmpty(row.subList)) {
          const sublist = row.subList.map(org => ({
            ...org,
            refOrgTypeName: row.refOrgTypeName,
            orgQuantity: 1,
          }));
          processedData.push(...sublist);
        } else {
          processedData.push(row);
        }
      });
      setStaticReportData(processedData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(getData, []);

  return (
    <Space size={16}>
      <span>Тайлант үе</span>
      <RangePicker
        allowClear={false}
        picker="month"
        value={[moment(filterDate[0]), moment(filterDate[1])]}
        onChange={onChange}
        disabledDate={current => current.isBefore(moment('2023-01-01'))}
      />
      <Button style={{ width: 90 }} loading={isLoading} onClick={getData}>
        Шүүх
      </Button>
    </Space>
  );
};

