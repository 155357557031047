import React, { useState, useEffect } from 'react';
import { Input, Space, Row, Typography, Button, message, Table } from 'antd';
import {
  CarryOutFilled,
  RightOutlined,
  FilePdfFilled,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import styles from './style.module.scss';
import PdfViewerModal from '../component/pdfModal.js/Modal';
import { DATEFORMAT } from '../../../constants/Constant';
import { getTrainingResearchBudgets } from '../../../service/trainingService';

const { Search } = Input;
const { Text } = Typography;

let dataLists = [];

function Research() {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState(dataLists);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [Uuid, setUuid] = useState('');

  const searchOnChange = (e, fieldName, fieldList) => {
    if (e.target.value === '') {
      setDataSource(dataLists);
    }
    const currValue = e.target.value;
    if (fieldList) {
      const filteredData = dataLists.filter(entry =>
        entry[fieldName][fieldList].includes(currValue.toLocaleUpperCase())
      );
      setDataSource(filteredData);
    }
    if (!fieldList) {
      const filteredData = dataLists.filter(entry =>
        entry[fieldName].includes(currValue.toLocaleUpperCase())
      );
      setDataSource(filteredData);
    }
  };

  const createServer = () => {
    const params = { search: { status: true, isActive: true } };
    getTrainingResearchBudgets(params)
      .then(data => {
        const dataList = data.content || [];
        dataList.forEach((item, index) => {
          item.index = data.totalPages + index;
        });
        setDataSource(dataList);
        dataLists = dataList;
        setLoading(false);
        setTotalRecords(data.totalElements);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    createServer();
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const view = rowData => {
    if (!rowData.fileDTOS) return message.info('Файл олсонгүй');
    setUuid(!isEmpty(rowData.fileDTOS) && rowData.fileDTOS[0].uuid);
    setIsModalVisible(true);
  };

  const stateBodyButton = rowData => (
    <>
      {rowData ? (
        <Button
          type="link"
          icon={<FilePdfFilled />}
          className={styles.colPdfStyling}
        >
          {`${rowData?.map(z => z?.name)}.PDF`}
        </Button>
      ) : (
        <Text
          type="link"
          icon={<FilePdfFilled />}
          className={styles.colPdfStyling}
        >
          файл олсонгүй
        </Text>
      )}
    </>
  );

  const columns = [
    { title: '№', dataIndex: 'index', width: 30, minWidth: 30 },
    {
      title: 'Баримт бичгийн нэр',
      width: 120,
      minWidth: 120,
      render: text => <Text className={styles.tableName}>{text}</Text>,
      children: [
        {
          title: (
            <Search
              placeholder="Хайлт"
              onChange={e => searchOnChange(e, 'name')}
            />
          ),
          dataIndex: 'name',
          width: 120,
          minWidth: 120,
        },
      ],
    },
    {
      title: 'Боловсруулсан',
      width: 80,
      minWidth: 80,
      children: [
        {
          title: (
            <Search
              placeholder="Хайлт"
              onChange={e => searchOnChange(e, 'processedBy')}
            />
          ),
          dataIndex: 'processedBy',
          width: 80,
          minWidth: 80,
        },
      ],
    },
    {
      title: 'Боловсруулсан огноо',
      width: 120,
      minWidth: 120,
      render: text => <Text>{moment(text).format(DATEFORMAT)}</Text>,
      children: [
        {
          title: (
            <Search
              placeholder="Хайлт"
              onChange={e => searchOnChange(e, 'approvedAt')}
            />
          ),
          dataIndex: 'approvedAt',
          width: 120,
          minWidth: 120,
        },
      ],
    },
    {
      title: 'Үүссэн огноо',
      width: 90,
      minWidth: 90,
      render: text => <Text>{moment(text).format(DATEFORMAT)}</Text>,
      children: [
        {
          title: (
            <Search
              placeholder="Хайлт"
              onChange={e => searchOnChange(e, 'createdAt')}
            />
          ),
          dataIndex: 'createdAt',
          width: 90,
          minWidth: 90,
        },
      ],
    },
    {
      title: () => <div>Файл хавсралт</div>,
      dataIndex: 'fileDTOS',
      width: 30,
      minWidth: 30,
      render: text => stateBodyButton(text),
    },
    {
      title: 'Эх сурвалж',
      width: 80,
      minWidth: 80,
      children: [
        {
          title: (
            <Search
              placeholder="Хайлт"
              onChange={e => searchOnChange(e, 'source')}
            />
          ),
          dataIndex: 'source',
          width: 80,
          minWidth: 80,
        },
      ],
    },
    {
      title: 'Тайлбар',
      dataIndex: 'description1',
      ellipsis: true,
      children: [
        {
          title: (
            <Search
              style={{ minWidth: 300 }}
              placeholder="Хайлт"
              onChange={e => searchOnChange(e, 'description')}
            />
          ),
          dataIndex: 'description',
          width: 380,
          minWidth: 380,
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.theResearchDiv}>
        <Row className={styles.researchRow}>
          <Space align="start">
            <CarryOutFilled className={styles.breadIcon} />
            <Text strong className={styles.breadText}>
              Суралцахуй
            </Text>
            <RightOutlined className={styles.breadIcons} />
            <Text strong className={styles.breadText}>
              Судалгаа, тайлан
            </Text>
            <RightOutlined className={styles.breadIcons} />
            <Text strong className={styles.breadText}>
              Төсөв санхүүгийн бодлогын баримт бичиг
            </Text>
          </Space>
        </Row>

        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          pagination={false}
          scroll={{ x: 1000 }}
          bordered
          footer={() => <Text> Нийт: {totalRecords} </Text>}
          onRow={record => ({
            onClick: () => {
              view(record);
            },
          })}
        />
      </div>
      <PdfViewerModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        headerText="Төсөв санхүүгийн бодлогын баримт бичиг "
        pdfUuid={Uuid}
      />
    </>
  );
}
export default Research;
