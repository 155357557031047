import React from 'react';
import { Row, Col, Slider } from 'antd';
import styles from './style.module.scss';
import Comment from '../../components/Comment/Comment';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';

export default function ConcessResult() {
  const marks = {
    0: '2016',
    100: '2017',
  };
  const funding = {
    0: '2016',
    25: '2017',
    50: '2018',
    60: {
      style: {
        marginTop: '-40px',
      },
      label: '5,985,00 тербум төгрөг',
    },
    75: '2019',
    100: '2020',
  };

  function formatter(value) {
    return `${value}%`;
  }

  return (
    <>
      <Row justify="center">
        <Col {...CONTENT_SPAN} className={styles.resultContainer}>
          <Row className={styles.name}>
            Оюутолгой- Цагаансуврага чиглэлийн 220кВ -ын хоёр хэлхээт ЦДАШ, дэд
            станц
          </Row>
          <Row>
            <div className={styles.title}>Тайлант үе - </div>3р улирал
            <div className={styles.title}>Концессын гэрээний төлөв: </div>
            <div>Барилгын ажил дуусч, улсын комисс хүлээн авсан.</div>
          </Row>
          <Row>
            <Col span={6} className={styles.subContainer}>
              <Row className={styles.title}>Төслийн нэр:</Row>
              <Row>Уул уурхай, металлургийн цогцолбор байгуулах төсөл</Row>
              <Row className={styles.title}>Төрөл:</Row>
              <Row>Барих-шилжүүлэх</Row>
              <Row className={styles.title}>Шийдвэр гаргасан огноо:</Row>
              <Row>2016/06/16</Row>
              <Row className={styles.title}>Шийдвэрийн төрөл:</Row>
              <Row>Шийдвэрийн төрөл</Row>
              <Row className={styles.title}>Аймаг, Нийслэл, Сум, Дүүрэг</Row>
              <Row>Сэлэнгэ, Галт </Row>
              <Row>Сэлэнгэ аймгийн Галт сумын Идэрийн голын хөвдөг гүүр</Row>
            </Col>
            <Col span={18} className={styles.subContainer}>
              <Row>
                <Col span={8}>
                  <Row className={styles.title}>Гүйцэтгэгч байгууллага:</Row>
                  <Row>Монгол Алт</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>Хуулийн этгээдийн ангилал:</Row>
                  <Row>ХХК</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>Гэрээ байгуулсан огноо:</Row>
                  <Row>2016-02-30</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>Гэрээ/ Хуулийн дугаар:</Row>
                  <Row>I.II.I</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>
                    Гэрээний зүйл (тоо хэмжээ, хүчин чадал):
                  </Row>
                  <Row>Гэрээний зүйл (тоо хэмжээ, хүчин чадал)</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>
                    Гэрээний дүн Вальют/ төгрөгөөр :
                  </Row>
                  <Row>MNT 2.658.00 | US 2.658.00</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>
                    Худалдан авах ажилгааны журам:
                  </Row>
                  <Row>Шууд гэрээ байгуулах</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>Дугаар:</Row>
                  <Row>№148</Row>
                </Col>
                <Col span={8}>
                  <Row className={styles.title}>Эргэн төлөлт :</Row>
                  <Row>Эхлэх хугацаа: Дуусах хугацаа:</Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12} className={styles.progress}>
              <div className={styles.title}>Ажлын явц:</div>
              <Slider
                value={10}
                disabled="false"
                marks={marks}
                tipFormatter={formatter}
              />
            </Col>
            <Col span={12} className={styles.progress}>
              <div className={styles.title}>Санхүүжилт:</div>
              <Slider
                value={60}
                disabled="false"
                marks={funding}
                tooltipVisible={false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12} className={styles.progress}>
              <Row justify="center">
                <div className={styles.topTitle}>
                  Концессын зүйлийн тодорхойлолт
                </div>
              </Row>
              <div className={styles.more}>
                Дарханы Төмөрлөгийн Үйлдвэр ТӨХК-ийн үйл ажиллагаа, хүчин чадлыг
                жилийн 300 мянган тоннд хүргэх өргөтгөл, техник технологийн
                шинэчлэл хийж, үйлдвэрийн хүчин чадлыг нэмэгдүүлэх, төмрийн
                хүдэр боловсруулж, төмөрлөгийн цогцолбор байгуулж эцсийн
                бүтээгдэхүүн гаргах
              </div>
            </Col>
            <Col span={12} className={styles.progress}>
              <Row justify="center">
                <div className={styles.topTitle}>
                  Гүйцэтгэх ажил,үзүүлэх үйлчилгээ
                </div>
              </Row>
              <div className={styles.more}>
                Дархан-Сэлэнгийн бүсэд орших төрийн эзэмшлийн төмрийн хүдрийн
                орд газруудыг ашиглан, эдийн засгийн эргэлтэд оруулах, Дарханы
                Төмөрлөгийн Үйлдвэр ТӨХК-ийн үйл ажиллагааг өргөтгөн техник,
                технологийн шинэчлэл хийж, үйлдвэрийн хүчин чадлыг нэмэгдүүлэх
              </div>
            </Col>
          </Row>
          <Row className={styles.title2}>Тайлбар: </Row>
          <Row className={styles.explain} />
        </Col>
      </Row>
      <Comment />
    </>
  );
}

