import React, { useState, useEffect, useRef } from 'react';
import { Steps, Button, Space, message, notification } from 'antd';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import styles from './style.module.scss';
import FilterDate from '../../components/FilterDate';
import FilterReportSpecs from '../../components/FilterReportSpecs';
import FilterOrg from '../../components/FilterOrg';
import {
  getSpecificationsByReportForm,
  getReportFormHeaders,
  getOpenData,
} from '../../../../service/reportServices';
import { SORT_ORDER } from '../../../../constants/Constant';
import { useAppStore } from '../../../../context/Tools';
import { STATIC_REPORT } from '../../../../constants/ReportConstants';
import { getOpendataParams } from '../../utils';
import { FIELD } from '../../../../constants/OpenData';

const { Step } = Steps;

export default function DynamicReportFilter({ setData }) {
  const { reportFormId } = useParams();

  const {
    reportHeaderOptions,
    setReportHeaderOptions,
    filters,
    setFilters,
    selectedReport,
  } = useAppStore();

  const cancelRefGetSpecificationsByReportForm = useRef();
  const cancelRefGetReportFormHeaders = useRef();
  const cancelRefGetOpenData = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [specificationOptions, setSpecificationOptions] = useState();

  const getConvertedDatas = list => {
    const selectedHeaders = filters.headers.map(hdrId => {
      const item = { id: hdrId };
      const findHeader = (reportHeaderOptions || []).find(
        header => header.id === hdrId
      );
      if (findHeader) {
        item.col = findHeader.col;
        item.title = findHeader.data;
        item.format = findHeader.format;
      }
      return item;
    });

    const convertedList = list.map(data => {
      const convertedObj = {};

      convertedObj[FIELD.orgName] = data.name;
      convertedObj[FIELD.year] = data.year;
      convertedObj[FIELD.month] = data.month;

      if (!isEmpty(filters.orgInfo)) {
        filters.orgInfo.forEach(info => {
          if (info === 'refOrgType') {
            convertedObj[FIELD.refOrgTypeName] = data.refOrgTypeName;
          } else if (info === 'registerNo') {
            convertedObj[FIELD.registerNo] = data.registerNo;
          } else if (info === 'taxNo') {
            convertedObj[FIELD.taxNo] = data.taxNo;
          } else if (info === 'certificateNo') {
            convertedObj[FIELD.certificateNo] = data.certificateNo;
          } else if (info === 'name1') {
            convertedObj[FIELD.name1] = data.name1;
          } else if (info === 'activity') {
            convertedObj[FIELD.refIsicGroupName] = `${
              data.refIsicClassCode || ''
            } - ${data.refIsicGroupName || ''}`;
          } else if (info === 'refType') {
            convertedObj[FIELD.refTypeName] = data.refTypeName;
          } else if (info === 'refCentralBudgetGovernor') {
            convertedObj[FIELD.refCentralBudgetGovernorName] =
              data.refCentralBudgetGovernorName;
          } else if (info === 'refCity') {
            convertedObj[FIELD.refCityName] = data.refCityName;
          } else if (info === 'refDistrict') {
            convertedObj[FIELD.refDistrictName] = data.refDistrictName;
          } else if (info === 'refKhoroo') {
            convertedObj[FIELD.refKhorooName] = data.refKhorooName;
          }
        });
      }

      selectedHeaders.forEach(header => {
        convertedObj[header.title] = data[header.col] || null;
        // convertedObj[header.title] = getFormattedValue(
        //   header.format,
        //   data[header.col]
        // );
      });
      return convertedObj;
    });
    return convertedList;
  };

  const getReportData = async () => {
    setIsLoading(true);
    setData([]);
    try {
      const params = getOpendataParams(reportFormId, filters);

      const content = await getOpenData(params, null, cancelRefGetOpenData);

      if (isEmpty(content)) {
        notification.info({
          message: 'Шүүлтэнд тохирох мэдээлэл байхгүй байна.',
        });
      }
      setData(getConvertedDatas(content), true);
      setIsLoading(false);
    } catch {
      setData([]);
      setIsLoading(false);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    if (current === 0 && isEmpty(filters.years)) {
      message.info('Он сонгоно уу.');
      return;
    }
    if (current === 1 && isEmpty(filters.headers)) {
      message.info('Багана сонгогдоогүй байна.');
      return;
    }
    setCurrent(current + 1);
  };

  const last = () => {
    if (isEmpty(filters.reportGroups)) {
      message.info('Байршуулах мэдээллийн төрөл сонгоогүй байна.');
      return;
    }
    getReportData();
  };

  // const onClickStep = newStep => {
  //   if (current === 0) {
  //     if (isEmpty(filters.years)) {
  //       message.info('Он сонгоно уу.');
  //       return;
  //     }
  //     if (newStep === 2 && isEmpty(filters.headers)) {
  //       message.info('Багана сонгогдоогүй байна.');
  //       setCurrent(1);
  //       return;
  //     }
  //   }
  //   if (current === 1 && isEmpty(filters.headers)) {
  //     message.info('Багана сонгогдоогүй байна.');
  //     return;
  //   }
  //   setCurrent(newStep);
  // };

  const specificationConverter = (data, showFields = []) => {
    if (isEmpty(data)) {
      return [];
    }
    const specs = data.map(item => ({
      title: showFields.map(col => item[col]).join('-'),
      key: item.line,
      children: item.parent
        ? specificationConverter(item.parent, showFields)
        : [],
    }));
    return specs;
  };

  useEffect(() => {
    if (isEmpty(selectedReport)) {
      return;
    }
    setReportHeaderOptions(null);
    setSpecificationOptions(null);

    if (
      STATIC_REPORT.children
        .map(report => report.id)
        .includes(selectedReport.id)
    ) {
      return;
    }

    getSpecificationsByReportForm(
      selectedReport.id,
      cancelRefGetSpecificationsByReportForm
    )
      .then(result => {
        let specifications = [];
        const listSpec = result?.content || [];
        if (!isEmpty(listSpec)) {
          const spec = listSpec[0];
          const showSpecCols = [];
          ['A', 'B', 'C'].forEach(col => {
            if (spec[col] && col !== spec.isCode && showSpecCols.length < 2) {
              showSpecCols.push(col);
            }
          });
          specifications = specificationConverter(listSpec, showSpecCols);
        }
        setSpecificationOptions(specifications);
      })
      .catch(() => setSpecificationOptions([]));

    const params = {
      search: {
        status: true,
        reportFormId: selectedReport.id,
        isMergeCol: false,
        isOpenData: true,
      },
      sortField: 'col',
      sortOrder: SORT_ORDER.ASC,
    };

    getReportFormHeaders(params, cancelRefGetReportFormHeaders)
      .then(result => {
        const headers = result.content || [];
        headers.forEach(header => {
          header.label = header.description || header.data || '';
          header.value = header.id;
        });
        setReportHeaderOptions(headers);
        setFilters({ ...filters, headers: headers.map(h => h.value) });
      })
      .catch(() => setReportHeaderOptions([]));
  }, [selectedReport]);

  const steps = [
    {
      title: 'Огноо',
      content: <FilterDate filters={filters} setFilters={setFilters} />,
    },
    {
      title: 'Тайлангийн үзүүлэлт',
      content: (
        <FilterReportSpecs
          filters={filters}
          setFilters={setFilters}
          headerOptions={reportHeaderOptions}
          specificationOptions={specificationOptions}
        />
      ),
    },
    {
      title: 'Байгууллага',
      content: <FilterOrg />,
    },
  ];

  return (
    <div className={styles.menu}>
      <div className={styles.filterContent}>
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
      </div>
      <Space className={styles.btn}>
        {current > 0 && <Button onClick={() => prev()}>Буцах</Button>}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Үргэлжлүүлэх
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" loading={isLoading} onClick={last}>
            Тайлан харуулах
          </Button>
        )}
      </Space>
    </div>
  );
}

