/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row, Skeleton, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import home from '../../img/home.svg';
import Comment from '../components/Comment/Comment';
import styles from './style.module.scss';
import { getNews } from '../../service/portalService';
import { SORT_ORDER } from '../../constants/Constant';
import time from '../../img/IconIonicMdTime.svg';
import { getImgSrc } from '../../tools/Tools';
import { PORTAL_NEWS_DETAIL } from '../../settings/constantRoutes';

const { Text } = Typography;

export default function News() {
  const navigate = useNavigate();
  const { newsId } = useParams();

  const [loader, setLoader] = useState(false);
  const [InformationData, setInformationData] = useState();
  const [InformationDatas, setInformationDatas] = useState([]);

  const newsClick = item => {
    navigate(`${PORTAL_NEWS_DETAIL}/${item.id}`);
  };

  useEffect(() => {
    const params = {
      search: { isActive: true, status: true },
      sortField: 'priority',
      sortOrder: SORT_ORDER.DESC,
      size: 4,
    };
    getNews(params).then(data => {
      const linkData = data.content || [];
      setInformationDatas(linkData);
    });
  }, []);

  useEffect(() => {
    if (!Number(newsId)) {
      return;
    }
    const params = {
      search: { isActive: true, status: true, id: newsId },
      sortField: 'priority',
      sortOrder: SORT_ORDER.DESC,
      size: 4,
    };
    setLoader(true);
    getNews(params)
      .then(data => {
        const linkData = data.content || [];
        setInformationData(linkData[0]);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [newsId]);

  const urlify = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const url = text.match(urlRegex);
    return url ? (
      <a href={url} target="_blank" rel="noreferrer">
        {text}
      </a>
    ) : (
      <p>{text}</p>
    );
  };

  return (
    <Row justify="center">
      <Col span={18}>
        <div className={styles.path}>
          <img src={home} alt="home" className={styles.home} />
          <Text className={styles.breadcrumbs}>Мэдээ мэдээлэл</Text>
        </div>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={14}
            lg={17}
            xl={17}
            xxl={18}
            className={styles.mainContent}
          >
            {loader && <Skeleton active />}
            {!isEmpty(InformationData) && (
              <>
                <Text className={styles.headerDiv}>
                  {InformationData.subject || ''}
                </Text>
                <Row className={styles.newsContent}>
                  {InformationData.description && (
                    <Text className={styles.description}>
                      {InformationData.description}
                    </Text>
                  )}
                  <Col span={24} className={styles.imgCustome}>
                    {InformationData.subject && (
                      <img
                        src={getImgSrc(InformationData.imgUuid)}
                        alt="news"
                        style={{
                          maxHeight: '511px',
                          width: 'auto',
                          maxWidth: '100%',
                          marginBottom: '30px',
                        }}
                      />
                    )}
                  </Col>
                  {InformationData.content &&
                    InformationData.content.split('\n').map(el => urlify(el))}
                </Row>
              </>
            )}
          </Col>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={7}
            xl={7}
            xxl={6}
            className={styles.newsList}
          >
            {InformationDatas.map(item => (
              <div
                key={item.id}
                style={{
                  backgroundImage: `url(${getImgSrc(item?.imgUuid)})`,
                }}
                className={styles.smallNews}
                role="main"
                onClick={() => newsClick(item)}
              >
                <div className={styles.newsTitle}>
                  <div className={styles.newsDesc}>{item?.description}</div>
                  <div className={styles.more}>
                    <div>
                      <img src={time} alt="thumpsUp" /> 10 цагийн өмнө
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Col>
        </Row>
        <Comment />
      </Col>
    </Row>
  );
}

