import React, { useState, useEffect, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import LocalBudgetInfo from './localBudgetInfo/localBudgetInfo';
// import LocalTez from './localTez/localTez';
import Calendar from '../united-budget/UnitedBudgetCalendar';
import {
  getBudgetPlanPerformanceCode,
  getReportBudgetDatas,
} from '../../service/reportServices';
import TreeMapUnited from '../components/TreeMapUnited/TreeMapUnited';
import Comment from '../components/Comment/Comment';
import { useAppStore } from '../../context/Tools';
import { numberBolgogch } from '../../tools/utils';
import UrDungiinUzuulelt from '../components/urDungiinUzuulelt/UrDungiinUzuulelt';
import MetaInfo from '../../components/MetaInfo';
import Banner from '../united-budget/UnitedBudgetBanner';
import Cards from '../united-budget/UnitedBudgetCards';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import BannerTitle from '../../components/bannerTitle/BannerTitle';

export default function LocalBudget() {
  const [searchParams] = useSearchParams();

  const year = searchParams.get('year');
  const month = searchParams.get('month');
  const type = searchParams.get('type');

  const { serverDate } = useAppStore();

  const cancelRef = useRef();

  const [budgetData, setBudgetData] = useState([]);
  const [tezData, setTezData] = useState();
  const [typeOfExpenditure, setTypeOfExpenditure] = useState('eza');
  const [data, setData] = useState(false);

  const getTreeMapData = async () => {
    if (typeOfExpenditure === 'tez' && data === 'cost') {
      setTezData();
      const params = {
        typeBy: type || 1,
        year: year || serverDate.getFullYear(),
        month: month || 1,
      };
      try {
        const result = await getBudgetPlanPerformanceCode(params, cancelRef);
        if (!result) return;
        const treeData = [
          {
            name: 'Орон нутгийн ТЕЗ',
            value: result.onTezValue,
            children: (result.onTEZ || []).map(tez => ({
              code: tez.tezCode,
              name: tez.tezName,
              value: tez.tezValue,
              children: (tez.hutulbur || []).map(hutulbur => ({
                name: hutulbur.hutulburName,
                value: hutulbur.hutulburValue,
                children: hutulbur.zoriulalt,
              })),
            })),
          },
        ];
        numberBolgogch(treeData);
        setTezData(treeData);
      } catch {
        setTezData([]);
      }
    } else {
      const params = {
        typeOfExpenditure,
        refBudgetTypeId: 3,
        year: year || serverDate.getFullYear(),
        month: month || 1,
      };
      getReportBudgetDatas(params, cancelRef).then(result => {
        setBudgetData(result);
      });
    }
  };

  useEffect(getTreeMapData, [year, month, type, typeOfExpenditure]);

  return (
    <>
      <MetaInfo title="Орон нутгийн төсөв - Шилэн данс" />
      <Banner title={<BannerTitle title="Орон нутгийн төсөв" />} />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <Cards />
          <Calendar table={false} />
          <UrDungiinUzuulelt
            budgetType={3}
            title="ОРОН НУТГИЙН ТӨСВИЙН ҮР ДҮНГИЙН ҮЗҮҮЛЭЛТ /жилээр/"
          />
          <TreeMapUnited
            setData={setData}
            data={data}
            setTypeOfExpenditure={setTypeOfExpenditure}
            typeOfExpenditure={typeOfExpenditure}
            budgetData={budgetData}
            tezData={tezData}
            Title="ОРОН НУТГИЙН ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар/"
            getData={getTreeMapData}
          />
          <LocalBudgetInfo />
          {/* <LocalTez /> */}
          <Comment />
        </Col>
      </Row>
    </>
  );
}
