import React from 'react';
import { Typography, Row, Space } from 'antd';
import styles from './style.module.scss';

const { Text } = Typography;
function ContentText({ title }) {
  return (
    <>
      <Row>
        <Space direction="vertical">
          <span className={styles.headertext} strong>
            {title.name}
          </span>
          <Text>{title.officialWord}</Text>
        </Space>
      </Row>
    </>
  );
}
export default ContentText;
