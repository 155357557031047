// DateFormat
export const DATEFORMAT = 'YYYY-MM-DD';
export const YEARFORMAT = 'YYYY';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';

export const MSG = {
  NOT_AUTHORIZED: 'Танд энэ үйлдлийг хийх эрх олгогдоогүй байна!',
  CYRILLIC: 'Кириллээр бичнэ үү!',
  MUST_FILL_FIELD: 'Энэ хэсгийг заавал бөглөнө үү!',
};

export const USER_TYPE = {
  ORG_ADMIN: 1,
  ORG_CEO: 2,
  ORG_ACCOUNTANT: 3,
};

export const WHOM = {
  ORG: 1, // Байгууллага
  AUDIT: 2, // Аудит
  ADMIN: 3, // Нэгдсэн Админ
};

// Санал хүсэлт
export const FFEDBACKTYPE = {
  COMPLIANT: 1, // гомдол
  FEEDBACK: 2, // Санал хүсэлт
};

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};
