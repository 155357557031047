/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Input, Space, Row, Typography, Button, Table } from 'antd';
import {
	CarryOutFilled,
	RightOutlined,
	FilePdfFilled,
} from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styles from './style.module.scss';
import PdfViewerModal from '../component/pdfModal.js/Modal';
import { DATEFORMAT } from '../../../constants/Constant';
import { getTrainingManuals } from '../../../service/trainingService';

const { Search } = Input;
const { Text } = Typography;

let dataLists = [];
function HandBook() {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [dataSource, setDataSource] = useState(dataLists);

	const searchOnChange = (e, fieldName, fieldList) => {
		if (e.target.value === '') {
			setDataSource(dataLists);
		}
		const currValue = e.target.value;
		if (fieldList) {
			const filteredData = dataLists.filter(entry =>
				entry[fieldName][fieldList].includes(currValue.toLocaleUpperCase())
			);
			setDataSource(filteredData);
		}
		if (!fieldList) {
			const filteredData = dataLists.filter(entry =>
				entry[fieldName].includes(currValue.toLocaleUpperCase())
			);
			setDataSource(filteredData);
		}
	};

	const [Uuid, setUuid] = useState('');

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const view = rowData => {
		setUuid(!isEmpty(rowData.fileDTOS) && rowData.fileDTOS[0].uuid);
		showModal();
	};

	const columns = [
		{ title: '№', dataIndex: 'index', width: 20, minWidth: 20 },
		{
			title: () => 'Гарын авлага',
			width: 50,
			minWidth: 50,
			children: [
				{
					title: (
						<Search
							placeholder="Хайлт"
							onChange={e => searchOnChange(e, 'name')}
						/>
					),
					dataIndex: 'name',
					width: 50,
					minWidth: 50,
				},
			],
		},
		{
			title: () => 'Боловсруулсан',
			width: 50,
			minWidth: 50,
			children: [
				{
					title: (
						<Search
							placeholder="Хайлт"
							onChange={e => searchOnChange(e, 'processedBy')}
						/>
					),
					dataIndex: 'processedBy',
					width: 50,
					minWidth: 50,
				},
			],
		},
		{
			title: () => 'Огноо',
			width: 50,
			minWidth: 50,
			render: text => <Text>{moment(text).format(DATEFORMAT)}</Text>,
			ellipsis: true,
			children: [
				{
					title: (
						<Search
							placeholder="Хайлт"
							onChange={e => searchOnChange(e, 'createdAt')}
						/>
					),
					dataIndex: 'createdAt',
					width: 50,
					minWidth: 50,
				},
			],
		},
		{
			title: 'Файл хавсралт',
			dataIndex: 'fileDTOS',
			width: 50,
			minWidth: 50,
			render: text => (
				<Button
					type="link"
					icon={<FilePdfFilled />}
					className={styles.colPdfStyling}
				>
					{`${text.map(z => z.name)}.PDF`}
				</Button>
			),
			ellipsis: true,
		},
	];

	const createServer = () => {
		const search = { search: { status: true, isActive: true } };
		getTrainingManuals(search)
			.then(data => {
				const dataList = data.content || [];
				dataList.forEach((item, index) => {
					item.index = data.totalPages + index;
				});
				setDataSource(dataList);
				dataLists = dataList;
				setLoading(false);
				setTotalRecords(data.totalElements);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		createServer();
	}, []);

	return (
		<>
			<div className={styles.theResearchDiv}>
				<Row className={styles.researchRow}>
					<Space>
						<CarryOutFilled className={styles.breadIcon} />
						<Text strong className={styles.breadText}>
							Суралцахуй
						</Text>
						<RightOutlined className={styles.breadIcons} />
						<Text strong className={styles.breadText}>
							Гарын авлага
						</Text>
					</Space>
				</Row>
				<Row className={styles.budgetFinanceSearch} />
				<Table
					columns={columns}
					dataSource={dataSource}
					pagination={false}
					loading={loading}
					scroll={{ x: 1000 }}
					bordered
					footer={() => <Text> Нийт: {totalRecords} </Text>}
					onRow={record => ({
						onClick: () => {
							view(record);
						},
					})}
				/>
			</div>
			<PdfViewerModal
				handleOk={handleOk}
				handleCancel={handleCancel}
				isModalVisible={isModalVisible}
				headerText="Төсөв санхүүгийн бодлогын баримт бичиг "
				pdfUuid={Uuid}
			/>
		</>
	);
}
export default HandBook;
