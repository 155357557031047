import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ReactApexChart from 'react-apexcharts';
import styles from './style.module.scss';
import Location from './Location';
import Search from './Search';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';

export default function Country() {
  const state = {
    date: {
      series: [
        {
          name: 'Нийт',
          data: [300, 250, 130, 230, 100, 275, 90],
        },
      ],
      options: {
        chart: {
          height: 300,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        yaxis: {
          show: true,
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        stroke: {
          size: 2,
          curve: 'smooth',
        },
        xaxis: {
          categories: [2018, 2019, 2020, 2021, 2022, 2023, 2024],
        },
        colors: ['#004a9f'],
      },
    },
  };
  useEffect(() => {
    const chart = am4core.create('donutAmount', am4charts.PieChart);
    chart.data = [
      {
        name: 'Тоног төхөөрөмж',
        value: 25,
        color: am4core.color('#ffb60a'),
      },
      {
        name: 'Барилга байгууламж',
        value: 15,
        color: am4core.color('#258b70'),
      },
      {
        name: 'Их засвар',
        value: 35,
        color: am4core.color('#79f8bd'),
      },
      {
        name: 'ТЭЗҮ',
        value: 10,
        color: am4core.color('#004a9f'),
      },
      {
        name: 'ЗГ-н нөөц хөрөнгө',
        value: 15,
        color: am4core.color('#ff7575'),
      },
    ];
    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'name';
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    // Set up slices
    pieSeries.tooltip.background.fill = am4core.color('#ffffff');
    pieSeries.slices.template.tooltipText = '[#ffffff] {name}: {value} сая ₮';
    // Add slice click event
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'right';
    chart.legend.width = 250;
    chart.innerRadius = am4core.percent(45);

    const chart2 = am4core.create('donutCount', am4charts.PieChart);
    chart2.data = [
      {
        name: 'Төсвийн жилд үргэлжлүүлэн /шилжих/арга хэмжээ ',
        value: 120,
        color: am4core.color('#0066B3'),
      },
      {
        name: 'Төсвийн жилд шинээр хэрэгжүүлэх арга хэмжээ',
        value: 50,
        color: am4core.color('#41A2EB'),
      },
    ];
    // Add and configure Series
    const pieSeries2 = chart2.series.push(new am4charts.PieSeries());
    pieSeries2.dataFields.value = 'value';
    pieSeries2.dataFields.category = 'name';
    pieSeries2.slices.template.propertyFields.fill = 'color';
    pieSeries2.labels.template.disabled = true;
    pieSeries2.ticks.template.disabled = true;
    // Set up slices
    pieSeries2.tooltip.background.fill = am4core.color('#ffffff');
    pieSeries2.slices.template.tooltipText = '[#ffffff] {name}: {value} сая ₮';
    // Add slice click event
    chart2.legend = new am4charts.Legend();
    chart2.legend.position = 'right';
    chart2.legend.width = 250;
    chart2.innerRadius = am4core.percent(45);
  }, []);

  return (
    <>
      <Row justify="center" className={styles.chartContainer}>
        <Col {...CONTENT_SPAN}>
          <Row>
            <Col xs={24} lg={12} className={styles.first}>
              <div className={styles.title1}>
                2021 оны төсвийн хөрөнгө оруулалт
              </div>
              <div className={styles.title2}>Нийт 450.000.000 төсөвт өртөг</div>
              <div className={styles.title3}>
                2021 онд нийт батлагдсан төсөвт өртөгтэйгэээс тэдэн ширхэг нь
                санхүүжүүлж байна
              </div>
            </Col>
            <Col xs={24} lg={12} className={styles.second}>
              <div
                id="donutAmount"
                style={{ width: '100%', height: '300px' }}
              />
            </Col>
            <Col xs={24} lg={12} className={styles.third}>
              <ReactApexChart
                options={state.date.options}
                series={state.date.series}
                type="bar"
                height={300}
              />
            </Col>
            <Col xs={24} lg={12} className={styles.fourth}>
              <div id="donutCount" style={{ width: '100%', height: '300px' }} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Search />
      <Location />
    </>
  );
}

