import { Col, Row, Typography, Tag } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { DATEFORMAT, USER_TYPE } from '../../../constants/Constant';
import OrgContext from '../../../context/OrgContext';
import styles from './style.module.scss';

const { Text } = Typography;

export default function OrgGeneralInfo() {
  const { orgInfo } = useContext(OrgContext);

  const stateBodyTemplate = rowData => (
    <Text style={{ color: rowData?.hasBranch ? 'orange' : 'green' }}>
      {rowData?.hasBranch ? 'Тийм' : 'Үгүй'}
    </Text>
  );

  return (
    <>
      <Row>
        <Col span={12}>
          <Row
            justify="center"
            style={{ padding: '1rem', borderRight: '1px solid #e5e5e5' }}
          >
            <Col span={24} className={styles.question}>
              Байгууллагын төрөл:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.refOrgTypeName}
            </Col>
            <Col span={24} className={styles.question}>
              Байгууллагын регистрийн дугаар:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.registerNo}
            </Col>
            <Col span={24} className={styles.question}>
              Анх үүсгэн байгуулагдсан огноо:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.createdAt
                ? moment(orgInfo.createdAt).format(DATEFORMAT)
                : ''}
            </Col>
            <Col span={24} className={styles.question}>
              Татвар төлөгчийн дугаар:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.taxNo}
            </Col>
            <Col span={24} className={styles.question}>
              Үйл ажиллагааны чиглэл код:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.activityCode}
            </Col>
            <Col span={24} className={styles.question}>
              Үндсэн эрхлэх үйл ажиллагааны чиглэл:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.activityContent}
            </Col>
            <Col span={24} className={styles.question}>
              Салбар төлөөлөгчийн газартай эсэх:
            </Col>
            <Col span={24} className={styles.answer}>
              {stateBodyTemplate(orgInfo)}
            </Col>
            <Col span={24} className={styles.question}>
              Толгой байгууллага:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.parentName}
            </Col>
            <Col span={24} className={styles.question}>
              Байгууллагын дэлгэрэнгүй хаяг:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.address}
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="center" style={{ padding: '1rem' }}>
            <Col span={24} className={styles.question}>
              Байгууллагын чиг үүрэг, үйл ажиллагааны чиглэл:
            </Col>
            <Col span={24}>{orgInfo.function}</Col>
            <Col span={24} className={styles.question}>
              Төсвийн ерөнхийлөн захирагч:
            </Col>
            <Col span={24}>{orgInfo.refTypeName}</Col>
            <Col span={24} className={styles.question}>
              Салбар төсвийн төвлөрүүлэн захирагч:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.refCentralBudgetGovernorSubName || 'Мэдээлэл байхгүй'}
            </Col>
            <Col span={24} className={styles.question}>
              Төсвийн захирагч:
            </Col>
            <Col span={24}>
              {orgInfo.orgContacts &&
                orgInfo.orgContacts.map((item, index) =>
                  item.type === USER_TYPE.ORG_CEO ? (
                    <Col key={index}>
                      {item?.lastname?.substring(0, 1)}
                      {'.'}
                      {item?.firstname}
                      {', '}
                      {item?.phone}
                      {', '}
                      {item?.workPhone}
                    </Col>
                  ) : (
                    ''
                  )
                )}
            </Col>
            <Col span={24} className={styles.question}>
              Нягтлан бодогч:
            </Col>
            <Col span={24}>
              {orgInfo.orgContacts &&
                orgInfo.orgContacts.map((item, index) =>
                  item.type === USER_TYPE.ORG_ACCOUNTANT ? (
                    <Col key={index}>
                      {item?.lastname?.substring(0, 1)}
                      {'.'}
                      {item?.firstname}
                      {', '}
                      {item?.phone}
                      {', '}
                      {item?.workPhone}
                    </Col>
                  ) : (
                    ''
                  )
                )}
            </Col>
            <Col span={24} className={styles.question}>
              Байршуулах мэдээллийн төрөл:
            </Col>
            <Col span={24} className={styles.answer}>
              {orgInfo.reportGroupDTOS &&
                orgInfo.reportGroupDTOS
                  .sort((a, b) => a.priority - b.priority)
                  .map(r => (
                    <Tag key={r.id} color="blue">
                      {r.name}
                    </Tag>
                  ))}
            </Col>
            {(orgInfo.refOrgTypeCode === '1' ||
              orgInfo.refOrgTypeCode === '4') && (
              <>
                <Col span={24} className={styles.question}>
                  Орон нутагт үйл ажиллагаагаа хэрхэн явуулдаг:
                </Col>
                <Col span={24} className={styles.answer}>
                  {orgInfo.refLocalName || 'Мэдээлэл байхгүй'}
                </Col>
                <Col span={24} className={styles.question}>
                  Төрийн болон орон нутгийн өмчит үйлдвэрийн газар:
                </Col>
                <Col span={24} className={styles.answer}>
                  {orgInfo.refStateEnterPriseName || 'Мэдээлэл байхгүй'}
                </Col>
                <Col span={24} className={styles.question}>
                  Ангилал:
                </Col>
                <Col span={24} className={styles.answer}>
                  Мэдээлэл байхгүй
                </Col>
                <Col span={24} className={styles.question}>
                  Төрд ногдох хувь хөрөнгө:
                </Col>
                <Col span={24} className={styles.answer}>
                  {orgInfo.participationStatePercent || 'Мэдээлэл байхгүй'}
                </Col>
                <Col span={24} className={styles.question}>
                  Орон нутагт ногдох хувь хөрөнгө:
                </Col>
                <Col span={24} className={styles.answer}>
                  {orgInfo.participationLocalPercent || 'Мэдээлэл байхгүй'}
                </Col>
                <Col span={24} className={styles.question}>
                  Бусад хувь оролцоо:
                </Col>
                <Col span={24} className={styles.answer}>
                  {orgInfo.participationOtherPercent || 'Мэдээлэл байхгүй'}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
}
