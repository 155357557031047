import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Typography, Card } from 'antd';
import OrgContext from '../../../context/OrgContext';
import styles from './style.module.scss';
import { getAllOrgTypeWithOrgCount } from '../../../service/referenceServices';
import { convertCurrencyFormatFraction } from '../../../tools/Tools';

const { Text } = Typography;

export default function OrgType({ setSearchType }) {
  const [orgTypes, setOrgTypes] = useState([]);
  const { setMain } = useContext(OrgContext);

  useEffect(() => {
    getAllOrgTypeWithOrgCount().then(data => {
      setOrgTypes(data);
    });
  }, []);

  const searchByOrgType = id => {
    if (id) {
      setMain({
        refOrgTypeId: id,
      });
      setSearchType('byTEZ');
    } else setSearchType('byInactiveOrg');
  };

  return (
    <Row justify="center" gutter={[24, 24]}>
      {orgTypes.map((item, index) => (
        <Col
          key={index}
          xs={24}
          sm={12}
          md={8}
          lg={8}
          xl={6}
          className={styles.boxFeaturedNews}
        >
          <Card
            hoverable
            className={styles.paddingCustome}
            onClick={() => searchByOrgType(item.id)}
          >
            <Text strong className={styles.cardTopTitle}>
              {item.name}
            </Text>
            <Text strong className={styles.leftCardTitle}>
              {convertCurrencyFormatFraction(item.orgCounter)}
            </Text>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

