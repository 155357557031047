import { Col, Row } from 'antd';
import React, { useState } from 'react';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import { OrgStore } from '../../context/OrgContext';
import CompanyFilter from './companyFilter/CompanyFilter';
import ContentFilter from './contentFilter/ContentFilter';
import HeaderColumnChart from './headerColumnChart/HeaderColumnChart';
import OrgType from './orgType/OrgType';

export default function OrganizationsFilter() {
  const [searchType, setSearchType] = useState();

  return (
    <OrgStore>
      <MetaInfo title="Байгууллагын хайлт - Шилэн данс" />
      <CompanyFilter searchType={searchType} setSearchType={setSearchType} />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          {searchType ? (
            <ContentFilter searchType={searchType} />
          ) : (
            <>
              <HeaderColumnChart />
              <OrgType setSearchType={setSearchType} />
            </>
          )}
        </Col>
      </Row>
    </OrgStore>
  );
}
