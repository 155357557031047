import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row, Tree } from 'antd';
import { isEmpty, isNil } from 'lodash';
import styles from './style.module.scss';
import { Loading } from './Loading';
import { FILTER_SPAN } from '../utils';

const orgInfoOptions = [
  { label: 'Байгууллагын төрөл', value: 'refOrgType' },
  { label: 'Байгууллагын регистр', value: 'registerNo' },
  { label: 'Татвар төлөгчийн дугаар', value: 'taxNo' },
  { label: 'Хувийн хэргийн дугаар', value: 'certificateNo' },
  { label: 'Байгууллагын нэр шинэ', value: 'name1' },
  { label: 'Байгууллагын нэр хуучин тайлант үе', value: 'name' },
  { label: 'Үйл ажиллагааны чиглэлийн нэр код', value: 'activity' },
  { label: 'ТЕЗ', value: 'refType' },
  { label: 'ТТЗ', value: 'refCentralBudgetGovernor' },
  { label: 'Аймаг', value: 'refCity' },
  { label: 'Сум', value: 'refDistrict' },
  { label: 'Баг хороо', value: 'refKhoroo' },
  // { label: 'Данс', value: '8' },
];

export default function FilterReportSpecs({
  filters,
  setFilters,
  headerOptions,
  specificationOptions,
}) {
  const { headers, specifications, orgInfo } = filters;

  const [specificationState, setSpecificationState] = useState({
    indeterminate: false,
    checkAll: false,
  });
  const [headerState, setHeaderState] = useState({
    indeterminate: false,
    checkAll: headers.length === (headerOptions || []).length,
  });
  const [orgInfoState, setOrgInfoState] = useState({
    indeterminate: false,
    checkAll: true,
  });

  useEffect(() => {
    if (isEmpty(orgInfo)) {
      setFilters({
        ...filters,
        orgInfo: orgInfoOptions.map(item => item.value),
      });
    }
  }, []);

  return (
    <Row gutter={[20, 10]} className={styles.row}>
      <Col {...FILTER_SPAN} className={styles.subMenu}>
        <div className={styles.subHeader}>
          <div> Үзүүлэлт</div>
          <Checkbox
            checked={specificationState.checkAll}
            indeterminate={specificationState.indeterminate}
            onChange={e => {
              setFilters({
                ...filters,
                specifications: e.target.checked
                  ? (specificationOptions || []).map(y => y.key)
                  : [],
              });
              setSpecificationState({
                indeterminate: false,
                checkAll: e.target.checked,
              });
            }}
          >
            Бүгд
          </Checkbox>
        </div>
        {isNil(specificationOptions) ? (
          <Loading />
        ) : (
          <Tree
            className={styles.menuBox}
            treeData={specificationOptions}
            checkable
            selectable={false}
            autoExpandParent
            onCheck={e => {
              setFilters({ ...filters, specifications: e });
              setSpecificationState({
                indeterminate:
                  !!e.length && e.length < (specificationOptions || []).length,
                checkAll: e.length === (specificationOptions || []).length,
              });
            }}
            checkedKeys={specifications}
          />
        )}
      </Col>
      <Col {...FILTER_SPAN} className={styles.subMenu}>
        <div className={styles.subHeader}>
          <div> Багана</div>
          <Checkbox
            checked={headerState.checkAll}
            indeterminate={headerState.indeterminate}
            onChange={e => {
              setFilters({
                ...filters,
                headers: e.target.checked
                  ? headerOptions.map(y => y.value)
                  : [],
              });
              setHeaderState({
                indeterminate: false,
                checkAll: e.target.checked,
              });
            }}
          >
            Бүгд
          </Checkbox>
        </div>
        {isNil(headerOptions) ? (
          <Loading />
        ) : (
          <Checkbox.Group
            value={headers}
            className={styles.menuBox}
            options={headerOptions}
            onChange={e => {
              setFilters({ ...filters, headers: e });
              setHeaderState({
                indeterminate: !!e.length && e.length < headerOptions.length,
                checkAll: e.length === headerOptions.length,
              });
            }}
          />
        )}
      </Col>
      <Col {...FILTER_SPAN} className={styles.subMenu}>
        <div className={styles.subHeader}>
          <div> Байгууллагын мэдээлэл</div>
          <Checkbox
            checked={orgInfoState.checkAll}
            indeterminate={orgInfoState.indeterminate}
            onChange={e => {
              setFilters({
                ...filters,
                orgInfo: e.target.checked
                  ? orgInfoOptions.map(y => y.value)
                  : [],
              });
              setOrgInfoState({
                indeterminate: false,
                checkAll: e.target.checked,
              });
            }}
          >
            Бүгд
          </Checkbox>
        </div>
        <Checkbox.Group
          value={orgInfo}
          className={styles.menuBox}
          options={orgInfoOptions}
          onChange={e => {
            setFilters({ ...filters, orgInfo: e });
            setOrgInfoState({
              indeterminate: !!e.length && e.length < orgInfoOptions.length,
              checkAll: e.length === orgInfoOptions.length,
            });
          }}
        />
      </Col>
    </Row>
  );
}

