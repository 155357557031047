import React from 'react';
import { Affix, Space } from 'antd';
import faceBookImage from '../assets/facebook.svg';
import youtubeImage from '../assets/youtube.svg';
import styles from './style.module.scss';

const jumpToPage = url => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
};

export default function SocialLink() {
  return (
    <Affix className={styles.link}>
      <Space size={1} direction="vertical">
        <div
          role="button"
          tabIndex={0}
          onClick={() =>
            jumpToPage(
              'https://www.facebook.com/people/Шилэн-дансны-цахим-хуудас/100078296019591/'
            )
          }
          onKeyDown={() =>
            jumpToPage(
              'https://www.facebook.com/people/Шилэн-дансны-цахим-хуудас/100078296019591/'
            )
          }
        >
          <img src={faceBookImage} alt="chat" className={styles.chatBotImg} />
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() =>
            jumpToPage(
              'https://www.youtube.com/channel/UCXL_iCaScGRKjZ1iTW-QJUQ'
            )
          }
          onKeyDown={() =>
            jumpToPage(
              'https://www.youtube.com/channel/UCXL_iCaScGRKjZ1iTW-QJUQ'
            )
          }
        >
          <img src={youtubeImage} alt="chat" className={styles.chatBotImg} />
        </div>
      </Space>
    </Affix>
  );
}
