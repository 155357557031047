import React from 'react';
import { ConfigProvider, Layout } from 'antd';
import MN from 'antd/lib/locale/mn_MN';
import ReactGA from 'react-ga4';
import { ContextProvider } from './context/Tools';
import Routers from './route';
import 'moment/locale/mn';
import PageHeader from './layout/navBar/navHeader';
import MainMenu from './layout/menu/MainMenu';
import Side from './layout/sider/Side';
import PageFooter from './layout/footer/PageFooter';
// import ChatBot from './layout/chatBot/ChatBot';
import SocialLink from './layout/socialLink/SocialLink';
import { GOOGLE_ANALYTIC_CODE } from './constants/SystemConstants';

const { Content } = Layout;

function App() {
  ReactGA.initialize(GOOGLE_ANALYTIC_CODE);
  ReactGA.send('pageview');

  return (
    <ConfigProvider locale={MN}>
      <ContextProvider>
        <PageHeader />
        <MainMenu />
        <Content>
          <Routers />
        </Content>
        <Side />
        {/* <ChatBot /> */}
        <SocialLink />
        <PageFooter />
      </ContextProvider>
    </ConfigProvider>
  );
}
export default App;

