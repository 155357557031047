import { Col, Row, Tabs } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import styles from './style.module.scss';

const { TabPane } = Tabs;

export default function InfoTabs(props) {
  const { typeOfExpenditure, setTypeOfExpenditure, tabs } = props;

  if (isEmpty(tabs)) {
    return null;
  }

  return (
    <>
      <Row justify="center" className={styles.tabsDiv}>
        <Col span={24} className={styles.tabsCols}>
          <Tabs
            defaultActiveKey={typeOfExpenditure || tabs[0].key}
            type="card"
            onChange={key => setTypeOfExpenditure(key)}
          >
            {tabs.map(tab => (
              <TabPane key={tab.key} tab={tab.name}>
                {tab.component}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}
