/* eslint-disable no-shadow */
import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');
function DonutChart3D(props) {
  const {chartId, chartData} = props
  useLayoutEffect(() => {
    const chart = am4core.create(chartId, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    chart.data = chartData.filter(datas => datas.count > 0);
    chart.innerRadius = 100;
    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "count";
    series.dataFields.category = "name";
    series.labels.template.text = "{category}: {value.value}";
    series.slices.template.tooltipText = "{category}: {value.value}";
  }, []);
  return (
    <div id={chartId} style={{ width: '100%', height: '350px' }} />
  );
}
export default DonutChart3D;
