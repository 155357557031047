import React, { useState } from 'react';
import { DatePicker, Rate, Space, Radio, Button, Row } from 'antd';
import { AlignCenterOutlined, HomeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';

export default function DateAndTypeFilter({ home, type, getData }) {
  const { serverDate } = useAppStore();

  const [searchParams, setSearchParams] = useSearchParams();

  const [year, setYear] = useState(
    searchParams.get('year') || serverDate.getFullYear()
  );
  const [month, setMonth] = useState(searchParams.get('month') || 1);
  const [reportType, setReportType] = useState(searchParams.get('type') || '1');

  const filter = () => {
    searchParams.set('year', year);
    searchParams.set('month', month);
    searchParams.set('type', reportType);
    setSearchParams(searchParams);
    if (getData) {
      getData();
    }
  };

  const onChange = e => {
    setReportType(e.target.value);
  };

  const onChangeDate = (date, dateString) => {
    setYear(dateString);
  };

  const onChangeMonth = e => {
    setMonth(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <AlignCenterOutlined />
        <div className={styles.title}>Шүүлтүүр</div>
        <HomeOutlined
          className={styles.minusButton}
          onClick={() => {
            home && home();
          }}
        />
      </div>
      <div className={styles.content}>
        <div>
          <div className={styles.date}>Он сонгох:</div>
          <DatePicker
            allowClear={false}
            placeholder="Он сонгох"
            picker="year"
            bordered={false}
            className={styles.calendar}
            onChange={onChangeDate}
            value={moment(year, 'YYYY')}
            disabledDate={current => current.isBefore(moment('2023-01-01'))}
          />
        </div>
        <div>
          <div className={styles.date}>Сар сонгох:</div>
          <Rate
            className={styles.rate}
            defaultValue={1}
            character={({ index }) => index + 1}
            count={12}
            onChange={onChangeMonth}
            value={month}
          />
          <div className={styles.date}>Төрөл сонгох:</div>
          <Radio.Group onChange={onChange} value={reportType}>
            <Space direction="vertical">
              <Radio value="1">Жилийн төсөв</Radio>
              {type !== 'org' && <Radio value="2">Батлагдсан төсөв</Radio>}
              <Radio value="3">Төсвийн гүйцэтгэл</Radio>
            </Space>
          </Radio.Group>
        </div>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" onClick={filter}>
            Шүүх
          </Button>
        </Row>
      </div>
    </div>
  );
}

