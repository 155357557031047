import { Col, Image, Row } from 'antd';
import React from 'react';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import emptyImg from '../../img/emptyPage.svg';

export default function NotFound() {
  return (
    <Row justify="center" align="middle" style={{ minHeight: '48vh' }}>
      <Col {...CONTENT_SPAN}>
        <div className="sub-title">Хуудас олдсонгүй</div>
        <Image
          src={emptyImg}
          alt="empty"
          preview={false}
          width="100%"
          height={300}
        />
      </Col>
    </Row>
  );
}

