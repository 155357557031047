import React from 'react';
import { Drawer, Divider } from 'antd';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';

function MobileMenu({ menus, showMenu, onCloseMenu }) {
  return (
    <Drawer
      className={styles.mobileMenuContainer}
      placement="right"
      closable
      onClose={onCloseMenu}
      visible={showMenu}
      zIndex={2000}
      width={260}
    >
      {menus.map((menu, index) => (
        <div key={index}>
          <Link to={menu.path}>{menu.title}</Link>
          <Divider type="horizontal" />
        </div>
      ))}
    </Drawer>
  );
}
export default MobileMenu;

