import React, { useEffect, useState } from 'react';
import { Col, Row, Checkbox, notification } from 'antd';
import { isEmpty, isNil } from 'lodash';
import styles from './style.module.scss';
import { getDistinctByYear } from '../../../service/reportServices';
import { useAppStore } from '../../../context/Tools';
import { Loading } from './Loading';
import { FILTER_SPAN } from '../utils';

const CheckboxGroup = Checkbox.Group;

const seasonOptions = [
  { label: '1-р улирал', value: 1 },
  { label: '2-р улирал', value: 2 },
  { label: '3-р улирал', value: 3 },
  { label: '4-р улирал', value: 4 },
];

const monthOptions = [
  { label: '1 сар', value: 1 },
  { label: '2 сар', value: 2 },
  { label: '3 сар', value: 3 },
  { label: '4 сар', value: 4 },
  { label: '5 сар', value: 5 },
  { label: '6 сар', value: 6 },
  { label: '7 сар', value: 7 },
  { label: '8 сар', value: 8 },
  { label: '9 сар', value: 9 },
  { label: '10 сар', value: 10 },
  { label: '11 сар', value: 11 },
  { label: '12 сар', value: 12 },
];

export default function FilterDate({ filters, setFilters }) {
  const { serverDate, yearList, setYearList } = useAppStore();
  const { years, seasons, months } = filters;

  const [yearState, setYearState] = useState({
    indeterminate: false,
    checkAll: false,
  });
  const [seasonState, setSeasonState] = useState({
    indeterminate: false,
    checkAll: false,
  });

  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.warning({
      message: 'Анхааруулга',
      description: 'Та 3 хүртэлх сар сонгох боломжтой.',
      duration: 3,
    });
  };

  const getReportYears = async () => {
    if (!isEmpty(yearList)) {
      return;
    }
    try {
      let reportYears = await getDistinctByYear();
      if (isEmpty(reportYears)) {
        reportYears = [serverDate.getFullYear()];
      }
      const yearData = reportYears.map(year => ({
        label: `${year} он`,
        value: year,
      }));
      setYearList(yearData);
    } catch {
      setYearList([]);
    }
  };
  useEffect(getReportYears, []);

  return (
    <Row gutter={[20, 10]} className={styles.row}>
      {contextHolder}
      <Col {...FILTER_SPAN} className={styles.subMenu}>
        <div className={styles.subHeader}>
          <div> Он</div>
          <Checkbox
            checked={yearState.checkAll}
            disabled={isNil(yearList)}
            indeterminate={yearState.indeterminate}
            onChange={e => {
              if (isNil(yearList)) return;
              setFilters({
                ...filters,
                years: e.target.checked ? yearList.map(y => y.value) : [],
              });
              setYearState({
                indeterminate: false,
                checkAll: e.target.checked,
              });
            }}
          >
            Бүгд
          </Checkbox>
        </div>
        {isNil(yearList) ? (
          <Loading />
        ) : (
          <CheckboxGroup
            value={years}
            className={styles.menuBox}
            options={yearList}
            onChange={e => {
              setFilters({ ...filters, years: e });
              setYearState({
                indeterminate: !!e.length && e.length < yearList.length,
                checkAll: e.length === yearList.length,
              });
            }}
          />
        )}
      </Col>
      <Col {...FILTER_SPAN} className={styles.subMenu}>
        <div className={styles.subHeader}>
          <div> Улирал</div>
          <Checkbox
            checked={seasonState.checkAll}
            indeterminate={seasonState.indeterminate}
            onChange={e => {
              setFilters({
                ...filters,
                seasons: e.target.checked
                  ? seasonOptions.map(s => s.value)
                  : [],
              });
              setSeasonState({
                indeterminate: false,
                checkAll: e.target.checked,
              });
            }}
          >
            Бүгд
          </Checkbox>
        </div>
        <CheckboxGroup
          value={seasons}
          className={styles.menuBox}
          options={seasonOptions}
          onChange={e => {
            setFilters({ ...filters, seasons: e });
            setSeasonState({
              indeterminate: !!e.length && e.length < seasonOptions.length,
              checkAll: e.length === seasonOptions.length,
            });
          }}
        />
      </Col>
      <Col {...FILTER_SPAN} className={styles.subMenu}>
        <div className={styles.subHeader}>
          <div> Сар</div>
        </div>
        <Checkbox.Group
          value={months}
          className={styles.menuBox}
          options={monthOptions}
          onChange={e => {
            if (e.length < 4) {
              setFilters({ ...filters, months: e });
            } else {
              openNotification();
            }
          }}
        />
      </Col>
    </Row>
  );
}

