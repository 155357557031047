import React, { useState, useEffect } from 'react';
import { Input, Row, Space, Typography, Table } from 'antd';
import { CarryOutFilled, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import styles from './style.module.scss';
import { DATEFORMAT } from '../../../constants/Constant';
import { getTrainingLegals } from '../../../service/trainingService';

const { Text } = Typography;
const { Search } = Input;

let dataLists = [];
function TheLaw() {
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [dataSource, setDataSource] = useState(dataLists);

	// const view = rowData => {};

	const searchOnChange = (e, fieldName, fieldList) => {
		if (e.target.value === '') {
			setDataSource(dataLists);
		}
		const currValue = e.target.value;
		if (fieldList) {
			const filteredData = dataLists.filter(entry =>
				entry[fieldName][fieldList].includes(currValue.toLocaleUpperCase())
			);
			setDataSource(filteredData);
		}
		if (!fieldList) {
			const filteredData = dataLists.filter(entry =>
				entry[fieldName].includes(currValue.toLocaleUpperCase())
			);
			setDataSource(filteredData);
		}
	};

	const columns = [
		{ title: '№', dataIndex: 'index', width: 20, minWidth: 20 },
		{
			title: 'Эрх зүйн актын нэр',
			width: 50,
			minWidth: 50,
			children: [
				{
					title: (
						<Search
							placeholder="Хавсралт"
							onChange={e => searchOnChange(e, 'name')}
						/>
					),
					dataIndex: 'name',
					width: 50,
					minWidth: 50,
				},
			],
		},
		{
			title: 'Төрөл',
			width: 50,
			minWidth: 50,
			children: [
				{
					title: (
						<Search
							placeholder="Хууль"
							onChange={e => searchOnChange(e, 'trainingLegalType', 'name')}
						/>
					),
					dataIndex: 'trainingLegalType',
					width: 50,
					minWidth: 50,
					render: text => <p>{text.name}</p>,
				},
			],
		},
		{
			title: 'Дугаар',
			width: 50,
			minWidth: 50,
			children: [
				{
					title: (
						<Search
							placeholder="123"
							onChange={e => searchOnChange(e, 'code')}
						/>
					),
					dataIndex: 'code',
					width: 50,
					minWidth: 50,
				},
			],
		},
		{
			title: 'Батлагдсан огноо',
			width: 50,
			minWidth: 50,
			render: text => <p>{moment(text).format(DATEFORMAT)}</p>,
			children: [
				{
					title: (
						<Search
							onChange={e => searchOnChange(e, 'approvedAt')}
						/>
					),
					dataIndex: 'approvedAt',
					width: 50,
					minWidth: 50,
				},
			],
		},
		{
			title: 'Холбоос',
			dataIndex: 'link',
			width: 50,
			className: "textAlignLastCenter",
			minWidth: 50,
			render: (text, record) => (
				<a href={`https://${record.link}`} target="_blank" rel="noreferrer">
					{text}
				</a>
			),
			ellipsis: true,
		},
	];

	const createServer = () => {
		const params = { search: { status: true, isActive: true } };
		getTrainingLegals(params)
			.then(data => {
				const dataList = data.content || [];
				dataList.forEach((item, index) => {
					item.index = data.totalPages + index;
				});
				setDataSource(dataList);
				dataLists = dataList;
				setLoading(false);
				setTotalRecords(data.totalElements);
			})
			.catch(() => {
				setLoading(false);
			});
	};
	useEffect(() => {
		createServer();
	}, []);

	return (
		<>
			<div className={styles.theLawDiv}>
				<Row className={styles.researchRow}>
					<Space>
						<CarryOutFilled className={styles.breadIcon} />
						<Text className={styles.breadText}>
							Суралцахуй
						</Text>
						<RightOutlined className={styles.breadIcons} />
						<Text className={styles.breadText}>
							Хууль, эрх зүй
						</Text>
					</Space>
				</Row>
				<Table
					columns={columns}
					dataSource={dataSource}
					pagination={false}
					loading={loading}
					scroll={{ x: 1000 }}
					bordered
					footer={() => <Text> Нийт: {totalRecords} </Text>}
				/>
			</div>
		</>
	);
}
export default TheLaw;
