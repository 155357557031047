/* eslint-disable no-shadow */
import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');
export default function stackedBarChart(props) {
  const { budgetData, typeId, id } = props;
  // console.log('++++', budgetData);

  useLayoutEffect(() => {
    const maxOfBudget = Math.max(...budgetData?.map(item => item.budget), 0);
    const dummy = JSON.parse(JSON.stringify(budgetData));
    dummy.forEach(el => {
      if (el.budget === maxOfBudget) {
        el.budget = 0;
      }
    });
    const secondMaxOfBudget = Math.max(...dummy.map(item => item.budget), 0);
    const chart = am4core.create(id, am4charts.XYChart);
    chart.data = budgetData;

    // Create axis
    const provinceAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    provinceAxis.dataFields.category = 'aimagName';
    provinceAxis.renderer.labels.template.rotation = 90;
    provinceAxis.renderer.labels.template.verticalCenter = 'middle';
    provinceAxis.renderer.labels.template.horizontalCenter = 'left';
    provinceAxis.renderer.minGridDistance = 10;

    // Create Axis
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = '[bold font-size: 20] Дүн (сая төгрөгөөр)';
    const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = '[bold font-size: 20] Хувь';
    valueAxis2.renderer.opposite = true;
    valueAxis2.renderer.grid.template.disabled = true;

    // ---Create Breaks
    const axisBreak = valueAxis1.axisBreaks.create();
    axisBreak.startValue = secondMaxOfBudget + 10000;
    axisBreak.endValue = maxOfBudget - 10000;
    axisBreak.breakSize = 0.02;

    // ---make break expand on hover
    const hoverState = axisBreak.states.create('hover');
    hoverState.properties.breakSize = 1;
    hoverState.properties.opacity = 0.1;
    hoverState.transitionDuration = 1500;

    // Create series
    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = 'budget';
    series1.dataFields.categoryX = 'aimagName';
    series1.yAxis = valueAxis1;
    series1.name = 'Төлөвлөгөө';
    series1.tooltipText = '{name} \n [bold font-size: 20] {valueY}[/]';
    series1.fill = chart.colors.getIndex(0);
    series1.strokeWidth = 0;
    series1.clustered = false;
    series1.columns.template.width = am4core.percent(40);

    const series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = 'performance';
    series2.dataFields.categoryX = 'aimagName';
    series2.yAxis = valueAxis1;
    series2.name = 'Гүйцэтгэл';
    series2.tooltipText = '{name}\n[bold font-size: 20] {valueY}[/]';
    series2.fill = chart.colors.getIndex(0).lighten(0.5);
    series2.strokeWidth = 0;
    series2.clustered = false;
    series2.toBack();

    const series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = 'percent';
    series3.dataFields.categoryX = 'aimagName';
    series3.name = 'Гүйцэтгэлийн хувь';
    series3.strokeWidth = 2;
    series3.tensionX = 0.8;
    series3.tensionY = 1;
    series3.yAxis = valueAxis2;
    series3.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]';
    series3.toFront();

    const bullet3 = series3.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.radius = 3;
    bullet3.circle.strokeWidth = 2;
    bullet3.circle.fill = am4core.color('#fff');

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';

    // Add scrollbar
    // chart.scrollbarX = new am4charts.XYChartScrollbar();
    // chart.scrollbarX.series.push(series1);
    // chart.scrollbarX.parent = chart.bottomAxesContainer;
  }, [budgetData, typeId]);

  return (
    <div id={id} style={{ width: '100%', height: '700px', minWidth: 600 }} />
  );
}

