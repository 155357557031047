import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { useLocation } from 'react-router-dom';
import {
  filterActiveOrg,
  getOrganizationsByFields,
} from '../../service/organizationServices';
import { getRefFundClassifications } from '../../service/referenceServices';
import ContentCards from './components/contentCards/ContentCards';
import GovernmentSpecialsContent from './governmentSpecialsContent/governmentSpecialsContent';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import tusgaiSan from './assets/tusgaiSan.png';

export default function GovernmentSpecials() {
  const location = useLocation();

  const cancelRefFundClassifications = useRef();
  const cancelOrganizationsByFields = useRef();

  const [fund, setFund] = useState([]);
  const [loading, setLoading] = useState(false);

  const specialData = async () => {
    setLoading(true);
    let params = {
      search: { status: true },
    };
    try {
      const result = await getRefFundClassifications(
        params,
        cancelRefFundClassifications
      );

      if (result) {
        const refFunds = result.content || [];
        params = {
          search: { ...filterActiveOrg, isGovSpecialFund: false },
          requestedFields: 'id,refFundClassificationId',
          size: 100,
        };
        const orgResult = await getOrganizationsByFields(
          params,
          cancelOrganizationsByFields
        );
        const orgs = orgResult.content || [];
        refFunds.forEach(fundClassification => {
          const findOrg = orgs.find(
            org => org.refFundClassificationId === fundClassification.id
          );
          if (findOrg) {
            fundClassification.orgId = findOrg.id;
          }
        });
        setFund(refFunds);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    specialData();

    return () => {
      if (cancelRefFundClassifications?.current) {
        cancelRefFundClassifications.current.cancel();
      }
      if (cancelOrganizationsByFields?.current) {
        cancelOrganizationsByFields.current.cancel();
      }
    };
  }, [location?.pathname]);

  const saving = fund.filter(el => el.fundType === 1);
  const social = fund.filter(el => el.fundType === 2);
  const other = fund.filter(el => el.fundType === 3);

  return (
    <Row
      justify="center"
      style={{ backgroundImage: `url(${tusgaiSan})`, marginTop: 50 }}
    >
      <Col {...CONTENT_SPAN}>
        <MetaInfo title="Засгийн газрын тусгай сан - Шилэн данс" />
        <GovernmentSpecialsContent header="ХУРИМТЛАЛ, ТОГТВОРЖУУЛАЛТЫН САН" />
        {loading ? <Skeleton active /> : <ContentCards el={saving} />}
        <GovernmentSpecialsContent header="НИЙГМИЙН ХАМГААЛЛЫН САН" />
        {loading ? <Skeleton active /> : <ContentCards el={social} />}
        <GovernmentSpecialsContent header="БУСАД ЗАСГИЙН ГАЗРЫН ТУСГАЙ САН" />
        {loading ? <Skeleton active /> : <ContentCards el={other} />}
      </Col>
    </Row>
  );
}
