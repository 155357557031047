import { getRequest } from './Service';

const serviceBaseUrl = '/gap-reference-service';

export const serviceRefOrgTypes = `${serviceBaseUrl}/refOrgTypes`;
export const serviceRefCity = `${serviceBaseUrl}/refCities`;
export const serviceRefDistrict = `${serviceBaseUrl}/refDistricts`;
export const serviceRefBagKhoroo = `${serviceBaseUrl}/refKhoroos`;
export const serviceRefUlsTEZ = `${serviceBaseUrl}/refTypes`;
export const serviceRefTTZ = `${serviceBaseUrl}/refCentralBudgetGovernors`;
export const serviceRefISIC = `${serviceBaseUrl}/refIsics`;
export const serviceRefFundClassifications = `${serviceBaseUrl}/refFundClassifications`;
export const serviceRefSelectableChoices = `${serviceBaseUrl}/refSelectableChoices`;
export const serviceAllOrgTypeWithOrgCount = `${serviceRefOrgTypes}/findAllRefOrgTypeDTO`;

export const getRefOrgTypes = async params =>
  await getRequest(serviceRefOrgTypes, { ...params });

export const getRefCities = async params =>
  await getRequest(serviceRefCity, params);

export const getRefDistricts = async params =>
  await getRequest(serviceRefDistrict, params);

export const getRefBagKhoroo = async params =>
  await getRequest(serviceRefBagKhoroo, { ...params });

export const getRefUlsTez = async params => {
  const param = {
    ...params,
    search: { status: true, ...params?.search },
    size: 10000,
  };
  const result = await getRequest(serviceRefUlsTEZ, param);
  return result;
};

export const getRefCentralBudgetGovernor = async params =>
  await getRequest(serviceRefTTZ, params);

export const getRefIsic = async params =>
  await getRequest(serviceRefISIC, params);

export const getRefFundClassifications = async (params, cancelRef) =>
  await getRequest(serviceRefFundClassifications, params, cancelRef);

export const getRefSelectableChoices = async params =>
  await getRequest(serviceRefSelectableChoices, params);

export const getAllOrgTypeWithOrgCount = async params =>
  await getRequest(serviceAllOrgTypeWithOrgCount, params);
