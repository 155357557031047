import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import TreeCharts from '../components/TreeMaps/TreeMaps';
import InfoTabs from '../components/infoTabs/infoTabs';
import DateAndTypeFilter from '../components/DateAndTypeFilter/DateAndTypeFilter';
import { getBudgetPlanPerformance } from '../../service/reportServices';
import { useAppStore } from '../../context/Tools';
// import { TreeChartsDataCost } from './Data';

export default function OrganizationBudget() {
  const { orgId } = useParams();
  const [searchParams] = useSearchParams();

  const year = searchParams.get('year');
  const month = searchParams.get('month');
  const type = searchParams.get('type');

  const { serverDate } = useAppStore();

  const cancelRef = useRef();

  const [costData, setCostData] = useState();
  const [incomeData, setIncomeData] = useState();

  const [typeOfExpenditure, setTypeOfExpenditure] = useState('eza');

  const getData = async () => {
    const params = {
      year: year || serverDate.getFullYear(),
      month: month || 1,
      typeBy: type || '1',
      orgId,
    };
    try {
      const result = await getBudgetPlanPerformance(params, cancelRef);
      if (result) {
        setCostData(result.eza ? [result.eza] : []);
        if (result.hz && !isEmpty(result.hz.hzDTOList)) {
          const treeData = result.hz.hzDTOList.map((hutulbur, index) => ({
            key: `hutulbur${index}`,
            name: hutulbur.hutulburName,
            value: hutulbur.hutulburValue,
            children: hutulbur.zoriulalt,
          }));
          setIncomeData(treeData);
        } else {
          setIncomeData([]);
        }
      }
    } catch {
      setCostData([]);
      setIncomeData([]);
    }
  };

  useEffect(getData, [year, month, type]);

  return (
    <>
      <div className="sub-title">Төсвийн төлөвлөгөө гүйцэтгэл</div>
      <Row>
        <Col xs={24} sm={15} md={6}>
          <DateAndTypeFilter
            type="org"
            getData={getData}
            // home={() => {
            //   setTypeOfExpenditure('eza');
            //   setData(false);
            // }}
          />
        </Col>
        <Col xs={24} sm={17} md={18}>
          <InfoTabs
            typeOfExpenditure={typeOfExpenditure}
            setTypeOfExpenditure={setTypeOfExpenditure}
            tabs={[
              {
                key: 'eza',
                name: 'Эдийн засгийн ангиллаар',
                component: !costData ? (
                  <Skeleton active />
                ) : (
                  <TreeCharts
                    TreeChartsId="TreeChartsDataCost"
                    TreeChartsData={costData}
                  />
                ),
              },
              {
                key: 'hutulbur',
                name: 'Хөтөлбөрөөр',
                component: !incomeData ? (
                  <Skeleton active />
                ) : (
                  <TreeCharts
                    TreeChartsId="TreeChartsDataIncome"
                    TreeChartsData={incomeData}
                  />
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
