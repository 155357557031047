/* eslint-disable no-nested-ternary */
import { Col, Row, Dropdown, Menu, Space, Table, Skeleton, Card } from 'antd';
import {
  BarChartOutlined,
  PrinterOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  TableOutlined,
} from '@ant-design/icons';
import React, { useState, useEffect, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';
import styles from './style.module.scss';
// import TreeCharts from '../TreeMaps/TreeMaps';
import TreeMapSelect from '../TreeMapSelect/treeMapSelect';
import DateAndTypeFilter from '../DateAndTypeFilter/DateAndTypeFilter';
import InfoTabs from '../infoTabs/infoTabs';
import { convertCurrencyFormat } from '../../../tools/Tools';

const TreeCharts = React.lazy(() => import('../TreeMaps/TreeMaps'));

export default function TreeMapUnited({
  setTypeOfExpenditure,
  typeOfExpenditure,
  budgetData,
  tezData,
  Title,
  data,
  setData,
  getData,
}) {
  const [searchParams] = useSearchParams();

  const reportType = searchParams.get('type');

  const [isShow, setIsShow] = useState(false);

  const TableDataColumn = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
      align: 'left',
      width: 150,
    },
    {
      title: 'Үзүүлэлт',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Өмнөх оны мөн үе',
      dataIndex: 'previousYear',
      key: 'previousYear',
    },
    {
      title: 'Жилийн төсөв',
      dataIndex: 'yearPlanned',
      key: 'yearPlanned',
    },
    {
      title: 'Батлагдсан Төсөв',
      dataIndex: 'planned',
      key: 'planned',
    },
    {
      title: 'Төсвийн гүйцэтгэл',
      dataIndex: 'performance',
      key: 'performance',
    },
    {
      title: 'Хувь-1',
      dataIndex: 'percent_25_24',
      key: 'percent_25_24',
      children: [
        {
          title: 'ТГ/БТ',
          key: 'percent_25_24',
          dataIndex: 'percent_25_24',
        },
      ],
    },
    {
      title: 'Хувь-2',
      dataIndex: 'column_25_23',
      key: 'column_25_23',
      children: [
        {
          title: 'ТГ/ЖТ',
          dataIndex: 'column_25_23',
          key: 'column_25_23',
        },
      ],
    },
    {
      title: 'Зөрүү',
      dataIndex: 'difference_24_25',
      key: 'difference_24_25',
      children: [
        {
          title: 'ТГ-БТ',
          dataIndex: 'difference_24_25',
          key: 'difference_24_25',
        },
      ],
    },
  ];

  const TEZTableDataColumn = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
      align: 'left',
      width: 'max-content',
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
      align: 'left',
    },
    {
      title: 'Үзүүлэлт',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Төсөв',
      dataIndex: 'value',
      key: 'value',
      render: value => convertCurrencyFormat(value),
    },
  ];

  const numberBolgogch = (treeMapData, parent) => {
    treeMapData?.forEach((item, index) => {
      item.number = parent?.number
        ? `${parent?.number}-${index + 1}`
        : index + 1;
      item.key = item.number;
      if (!isEmpty(item.children)) {
        numberBolgogch(item.children, item);
      }
    });
  };

  useEffect(() => {
    if (typeOfExpenditure === 'tez') {
      return;
    }

    const valueBolgogch = treeMapData => {
      treeMapData?.forEach(item => {
        item.key = item.name;
        if (reportType === '3') {
          item.value = item.performance;
        } else if (reportType === '1') {
          item.value = item.yearPlanned;
        } else {
          item.value = item.planned;
        }
        if (item.children?.length !== 0) {
          valueBolgogch(item.children, item);
        } else {
          delete item.children;
        }
      });
    };
    valueBolgogch(budgetData);

    const numberData = budgetData?.length > 0 ? budgetData[0]?.children : null;
    const ezaData0 =
      budgetData?.length > 0 ? budgetData[0]?.children[0]?.children : null;
    const ezaData1 =
      budgetData?.length > 0 ? budgetData[1]?.children[0]?.children : null;
    const ezaData2 =
      budgetData?.length > 0 ? budgetData[2]?.children[0]?.children : null;
    if (typeOfExpenditure === 'eza') {
      numberBolgogch(ezaData0);
      numberBolgogch(ezaData1);
      numberBolgogch(ezaData2);
    } else if (numberData) {
      numberBolgogch(numberData[0]?.children);
    }
  }, [isShow, budgetData, typeOfExpenditure, data, tezData]);

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FileImageOutlined />}>
        JPG
      </Menu.Item>
      <Menu.Item key="2" icon={<PrinterOutlined />}>
        Хэвлэх
      </Menu.Item>
      <Menu.Item key="3" icon={<FilePdfOutlined />}>
        PDG
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="sub-title">{Title}</div>
      <Card className={styles.container}>
        <Row justify="space-between">
          <Col span={24} md={24} lg={5}>
            <DateAndTypeFilter
              home={() => {
                setTypeOfExpenditure('eza');
                setData(false);
              }}
              getData={getData}
            />
          </Col>
          <Col
            className={styles.threemapTabsCol}
            xs={24}
            sm={24}
            md={24}
            lg={19}
          >
            <>
              <Row justify="end">
                <div className={styles.logos}>
                  <Space size={15}>
                    <TableOutlined
                      className={styles.threemapToolsIcon}
                      onClick={() => setIsShow(true)}
                    />
                    <BarChartOutlined
                      onClick={() => setIsShow(false)}
                      className={styles.threemapToolsIcon}
                    />
                    <Dropdown overlay={menu}>
                      <DownloadOutlined className={styles.toolsIcon} />
                    </Dropdown>
                  </Space>
                </div>
              </Row>
              {data === 'income' &&
                (isShow ? (
                  <Table
                    dataSource={
                      budgetData.find(el => el?.code === 'A')?.children[0]
                        ?.children
                    }
                    columns={TableDataColumn}
                    size="small"
                    pagination={false}
                    scroll={{ x: 1000 }}
                  />
                ) : (
                  <Suspense fallback={<div>...</div>}>
                    <TreeCharts
                      TreeChartsId="TreeChartsDataIncome"
                      TreeChartsData={
                        budgetData.find(el => el?.code === 'A')?.children[0]
                          ?.children
                      }
                    />
                  </Suspense>
                ))}
              {data === 'balance' && (
                <Table
                  dataSource={
                    budgetData?.find(el => el?.code === 'C')?.children[0]
                      ?.children
                  }
                  columns={TableDataColumn}
                  size="small"
                  pagination={false}
                  scroll={{ x: 1000 }}
                />
              )}
              {data === 'cost' && (
                <InfoTabs
                  typeOfExpenditure={typeOfExpenditure}
                  setTypeOfExpenditure={setTypeOfExpenditure}
                  tabs={[
                    {
                      key: 'tez',
                      name: 'Төсвийн ерөнхийлөн захирагч',
                      component: isShow ? (
                        <Table
                          loading={isNil(tezData)}
                          dataSource={tezData}
                          columns={TEZTableDataColumn}
                          size="small"
                          pagination={false}
                        />
                      ) : isNil(tezData) ? (
                        <Skeleton active style={{ padding: 40 }} />
                      ) : (
                        <Suspense fallback={<div>...</div>}>
                          <TreeCharts
                            TreeChartsId="TreeChartsDataTEZ"
                            TreeChartsData={tezData}
                            isDynamic
                          />
                        </Suspense>
                      ),
                    },
                    {
                      key: 'eza',
                      name: 'Эдийн засгийн ангиллаар',
                      component: isShow ? (
                        <Table
                          dataSource={
                            budgetData?.find(el => el?.code === 'B')
                              ?.children[0]?.children
                          }
                          columns={TableDataColumn}
                          size="small"
                          pagination={false}
                          scroll={{ x: 1000 }}
                        />
                      ) : (
                        <Suspense fallback={<div>...</div>}>
                          <TreeCharts
                            TreeChartsId="TreeChartsDataCost"
                            TreeChartsData={
                              budgetData?.find(el => el?.code === 'B')
                                ?.children[0]?.children
                            }
                          />
                        </Suspense>
                      ),
                    },
                    // {
                    //   key: 'hutulbur',
                    //   name: 'Хөтөлбөрөөр',
                    //   component: isShow ? (
                    //     <Table
                    //       dataSource={budgetData[0]?.children[0]?.children}
                    //       columns={TableDataColumn}
                    //       size="small"
                    //       pagination={false}
                    //     />
                    //   ) : (
                    //     <Suspense fallback={<div>...</div>}>
                    //       <TreeCharts
                    //         TreeChartsId="TreeChartsDataHutulbur"
                    //         TreeChartsData={
                    //           budgetData[0]?.children[0]?.children
                    //         }
                    //       />
                    //     </Suspense>
                    //   ),
                    // },
                    // {
                    //   key: 'zoriulalt',
                    //   name: 'Зориулалтаар',
                    //   component: isShow ? (
                    //     <Table
                    //       dataSource={budgetData[0]?.children[0]?.children}
                    //       columns={TableDataColumn}
                    //       size="small"
                    //       pagination={false}
                    //     />
                    //   ) : (
                    //     <Suspense fallback={<div>...</div>}>
                    //       <TreeCharts
                    //         TreeChartsId="TreeChartsDataZoriulalt"
                    //         TreeChartsData={
                    //           budgetData[0]?.children[0]?.children
                    //         }
                    //       />
                    //     </Suspense>
                    //   ),
                    // },
                  ]}
                />
              )}
              {data === false && (
                <TreeMapSelect
                  setData={setData}
                  setTypeOfExpenditure={setTypeOfExpenditure}
                  budgetData={budgetData}
                />
              )}
            </>
          </Col>
        </Row>
      </Card>
    </>
  );
}

