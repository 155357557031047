import React, { useState, useContext, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  BarChartOutlined,
  TableOutlined,
  FilterFilled,
} from '@ant-design/icons';
import {
  Row,
  Typography,
  Col,
  DatePicker,
  Space,
  Select,
  Table,
  Skeleton,
} from 'antd';
import moment from 'moment';
import { ToolsContext } from '../../../context/Tools';

import styles from './style.module.scss';
import InformationCtrByChart from '../../statebudget/stateIndicators/InformationCtrByChart';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';
import { getRadarInfoByTEZ } from '../../../service/reportServices';

const { Text } = Typography;
const { Option } = Select;

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');

export default function RadarCharts({ RadarChartId }) {
  const { serverDate } = useContext(ToolsContext);

  const [infoByTEZ, setInfoByTEZ] = useState([]);
  const [chartType, setChartType] = useState('0');
  const [loading, setLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [year, setYear] = useState(serverDate.getFullYear());

  const infoData = infoByTEZ;

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const typeChanger = value => {
    setLoading(true);
    setChartType(value);
  };

  const orgTypeTableData = infoData.map((item, key) => ({
    index: key,
    orgName: item.name,
    orgCount: 1,
    reported: item.reported,
    onTime: item.onTime,
    expired: item.expired,
    unreported: item.unreported,
    total: item.total,
  }));

  const orgTypeColumns = [
    {
      title: 'Байгууллагын төрөл',
      dataIndex: 'orgName',
      key: 'orgName',
      width: 200,
    },
    {
      title: 'Байгууллагын тоо',
      dataIndex: 'orgCount',
      key: 'orgCount',
      align: 'right',
      width: 130,
    },
    {
      title: 'Тайлагнасан',
      dataIndex: 'reported',
      key: 'reported',
      align: 'right',
    },
    {
      title: 'Хугацаа хоцорсон',
      dataIndex: 'expired',
      key: 'expired',
      align: 'right',
    },
    {
      title: 'Мэдээллээгүй',
      dataIndex: 'unreported',
      key: 'unreported',
      align: 'right',
    },
    { title: 'Нийт', dataIndex: 'total', key: 'total', align: 'right' },
  ];

  const getRadarInfo = () => {
    if (year) {
      setLoading(true);
      getRadarInfoByTEZ({
        year,
        typeId: chartType,
      })
        .then(result => {
          setInfoByTEZ(result);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getRadarInfo();
  }, [year, chartType]);

  return (
    <Row justify="center">
      <Col span={24}>
        <div className="main-title">
          <span>Шилэн дансны мэдээллийн хяналт</span>
        </div>
      </Col>
      <Col {...CONTENT_SPAN}>
        <Row className={styles.filterRow}>
          <Space style={{ flexFlow: 'wrap' }}>
            <FilterFilled className={styles.filterIcon} />
            <span>Шүүлтүүр</span>
            <Select
              bordered={false}
              placeholder="Сонгох"
              style={{ width: '100%' }}
              defaultValue="0"
              onChange={typeChanger}
            >
              <Option value="0">Байгууллагын төрлөөр</Option>
              <Option value="1">Улсын Төсвийн ерөнхийлөн захирагч</Option>
              <Option value="2">
                Орон нутгийн Төсвийн ерөнхийлөн захирагч
              </Option>
            </Select>
            <Space>
              <Text strong>Огноо сонгох:</Text>
              <DatePicker
                allowClear={false}
                onChange={onChangeYear}
                showToday
                defaultValue={moment(year, 'YYYY')}
                picker="year"
                disabledDate={current => current.isBefore(moment('2023-01-01'))}
              />
            </Space>
            {/* <Button type="primary" onClick={() => getRadarInfo()}>
              Шүүх
            </Button> */}
          </Space>
        </Row>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Row justify="end">
              <Space size={16}>
                <TableOutlined
                  className="icon"
                  onClick={() => setIsShow(true)}
                />
                <BarChartOutlined
                  className="icon"
                  onClick={() => setIsShow(false)}
                />
              </Space>
            </Row>
            <Row className={styles.allSpace}>
              {isShow ? (
                <Table
                  rowKey={obj => obj.index}
                  columns={orgTypeColumns}
                  dataSource={orgTypeTableData}
                  scroll={{ y: 750, x: 900 }}
                  pagination={false}
                />
              ) : (
                <InformationCtrByChart
                  chartId={RadarChartId}
                  chartData={orgTypeTableData}
                  seriesOne="Нийт оруулах"
                  seriesTwo="Хугацаандаа оруулсан"
                  seriesThree="Хугацаа хоцорч оруулсан"
                  seriesFour="Оруулаагүй"
                />
              )}
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
}
