import {
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Select,
  Row,
  Space,
  Typography,
  Radio,
  Dropdown,
  Button,
  Menu,
} from 'antd';
import React from 'react';
import moment from 'moment';
import MongoliaMapHealth from './insuranceMap';
import styles from './style.module.scss';

const { Text } = Typography;
const { Option } = Select;

export default function InsuranceIndicators() {
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FileImageOutlined />}>
        JPG
      </Menu.Item>
      <Menu.Item key="3" icon={<FilePdfOutlined />}>
        Excel
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="sub-title">НИЙГМИЙН ДААТГАЛЫН САНГИЙН ҮЗҮҮЛЭЛТ /жил/</div>
      <Row className={styles.threemapFilterRow} justify="center">
        <Col xs={21} sm={21} md={10} lg={6} xl={4} pull={3}>
          <Space>
            <Text> Аймгууд:</Text>
            <Select defaultValue="1" bordered={false}>
              <Option value="1">
                <Radio>Хэнтий </Radio>
              </Option>
              <Option value="2">
                <Radio>Сайншанд</Radio>
              </Option>
              <Option value="3">
                <Radio>Булган</Radio>
              </Option>
              <Option value="4">
                <Radio>Дорнод</Radio>
              </Option>
            </Select>
          </Space>
        </Col>
        <Col pull={2}>
          <Space>
            <Text>Он сонгох:</Text>
            <DatePicker
              placeholder="Он сонгох"
              picker="year"
              bordered={false}
              disabledDate={current => current.isBefore(moment('2023-01-01'))}
            />
          </Space>
        </Col>
        <Col push={4} className={styles.downloadText}>
          <Dropdown overlay={menu}>
            <Button type="text">
              <DownloadOutlined className="icon" />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <MongoliaMapHealth />
    </>
  );
}
