import { Row, Col } from 'antd';
import React from 'react';
import CardFilter from './cardFilter/CardFilter';
import FilterContent from './filterContent/FilterContent';
import styles from './style.module.scss';

export default function ContentFilter({ searchType }) {
  return (
    <Row gutter={[16, 10]} className={styles.contentRow}>
      <Col xs={24} sm={24} md={9} lg={8}>
        <CardFilter searchType={searchType} />
      </Col>
      <Col xs={24} sm={24} md={15} lg={16}>
        <FilterContent searchType={searchType} />
      </Col>
    </Row>
  );
}
