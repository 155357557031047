import { Card, Typography, List, Image, Spin, Col, Row, Select } from 'antd';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Option } from 'antd/lib/mentions';
import axios from 'axios';
import styles from './style.module.scss';
import MglMap from '../../components/mongoliaMap/MglMap';
import { getOrganizations } from '../../../../service/organizationServices';
import OrgContext from '../../../../context/OrgContext';
import emptyImg from '../../../../img/emptyPage.svg';
import OrgCard from '../../../../components/orgCard/OrgCard';

const { Text } = Typography;

export default function FilterContent({ searchType }) {
  const { mainState, stateMap } = useContext(OrgContext);

  const cancelRef = useRef();

  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortFilter, setSortFilter] = useState('name,asc');
  const [byTezName, setByTezName] = useState('Хайлт');
  const [statePagination, setStatePagination] = useState({
    size: 18,
    total: 0,
    pageNumber: 0,
  });

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  useEffect(() => {
    if (
      mainState.refCityId ||
      mainState.refDistrictId ||
      mainState.refOrgTypeId ||
      mainState.refTypeId ||
      mainState.activityCode ||
      mainState.registerNo ||
      mainState.name ||
      mainState.budgetGovernor ||
      searchType === 'byInactiveOrg'
    ) {
      setLoading(true);
      setOrgs([{}, {}, {}, {}, {}, {}]);

      const params = {
        search: { ...mainState },
        sort: sortFilter,
        page: statePagination.pageNumber,
        size: statePagination.size,
      };
      if (searchType === 'byInactiveOrg') {
        params.search.isActive = false;
      }

      getOrganizations(params, cancelRef)
        .then(result => {
          if (result) {
            setOrgs(result.content || []);
            setStatePagination({
              ...statePagination,
              total: result.totalElements || 0,
            });
            setLoading(false);
          }
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            setLoading(false);
            setOrgs([]);
          }
        });
    }
  }, [
    mainState,
    statePagination.pageNumber,
    stateMap.boolean,
    searchType,
    sortFilter,
  ]);

  useEffect(() => {
    setOrgs([]);
    setStatePagination({ size: 18, total: 0, pageNumber: 0 });
    setSortFilter(
      searchType === 'byInactiveOrg' ? 'rejectedAt,asc' : 'name,asc'
    );
  }, [searchType]);
  useEffect(
    () => setStatePagination({ ...statePagination, pageNumber: 0 }),
    [mainState.name]
  );

  const onChangePageNumber = number => {
    setStatePagination({ ...statePagination, pageNumber: number - 1 });
  };

  useEffect(() => {
    switch (mainState.budgetGovernor) {
      case 1:
        setByTezName('Төсвийн ерөнхийлөн захирагч');
        break;
      case 2:
        setByTezName('Төсвийн төвлөрүүлэн захирагч');
        break;
      case 3:
        setByTezName('Төсвийн шууд захирагч');
        break;
      default:
        setByTezName('Хайлт');
    }
  }, [mainState.budgetGovernor]);

  return (
    <Card
      className={styles.filterContent}
      title={
        <Row>
          <Col span={12}>
            <Text className={styles.cardheaderText}>
              {searchType === 'byTEZ' && <> {byTezName} </>}
              {searchType === 'byMap' && <>Газар зүйн байршил </>}
              {searchType === 'byOrgType' && <>Байгууллагын төрөл</>}
              {searchType === 'byInactiveOrg' && (
                <>Татан буугдсан байгууллага</>
              )}
            </Text>
          </Col>
          <Col span={12}>
            <div style={{ float: 'right' }}>
              {(searchType !== 'byMap' ||
                stateMap.refCityId ||
                stateMap.refDistrictId) && (
                <Select
                  value={sortFilter}
                  onChange={val => setSortFilter(val)}
                  style={{ width: searchType === 'byInactiveOrg' ? 280 : 170 }}
                >
                  {searchType === 'byInactiveOrg' ? (
                    <>
                      <Option value="rejectedAt,asc">
                        Татан буугдсан огноо, өсөхөөр
                      </Option>
                      <Option value="rejectedAt,desc">
                        Татан буугдсан огноо, буурахаар
                      </Option>
                    </>
                  ) : (
                    <>
                      <Option value="approvedAt,asc">Огноо, өсөхөөр</Option>
                      <Option value="approvedAt,desc">Огноо, буурахаар</Option>
                    </>
                  )}
                  <Option value="name,asc">Нэр, өсөхөөр</Option>
                  <Option value="name,desc">Нэр, буурахаар</Option>
                </Select>
              )}
            </div>
          </Col>
        </Row>
      }
    >
      {searchType === 'byMap' &&
      !(stateMap.refCityId || stateMap.refDistrictId) ? (
        <div className={styles.spinStyle}>
          {loading ? <Spin indicator={antIcon} /> : <MglMap pinData={orgs} />}
        </div>
      ) : (
        <List
          grid={{ gutter: [10, 10], xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 3 }}
          className={styles.orgList}
          locale={{
            emptyText: (
              <Image src={emptyImg} alt="empty" width="50%" preview={false} />
            ),
          }}
          pagination={{
            total: statePagination.total,
            pageSize: statePagination.size,
            showTotal: total => `Нийт: ${total}`,
            onChange: onChangePageNumber,
          }}
          loading={{
            size: 'large',
            spinning: loading,
            indicator: <LoadingOutlined />,
          }}
          dataSource={orgs}
          renderItem={item => (
            <List.Item>
              <OrgCard org={item} />
            </List.Item>
          )}
        />
      )}
    </Card>
  );
}
