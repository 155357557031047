import React from 'react';
import { useAppStore } from '../../../context/Tools';
import DynamicReportFilter from './reports/DynamicReportFilter';
import { OrgByRefOrgType } from './reports/OrgByRefOrgType';
import { ReportByGroupRecurring } from './reports/ReportByGroupRecurring';
import { ReportByRefType } from './reports/ReportByRefType';
import { ReportControlStaticReport } from './reports/ReportControlStaticReport';
import { ReportInfoByForm } from './reports/ReportInfoByForm';
import { ReportInfoByRefTypeTTZ } from './reports/ReportInfoByRefTypeTTZ';

export default function Filter({ setData }) {
  const { selectedReport } = useAppStore();

  const report = () => {
    switch (selectedReport.id) {
      case 'reportByRefType':
        return <ReportByRefType setStaticReportData={setData} />;
      case 'getRepFormGroupRecs':
        return <ReportByGroupRecurring setStaticReportData={setData} />;
      case 'getRepFormsByRefTypeTTZ':
        return <ReportInfoByRefTypeTTZ setStaticReportData={setData} />;
      case 'getRepForms':
        return <ReportInfoByForm setStaticReportData={setData} />;
      case 'getOrgByRefOrgType':
        return <OrgByRefOrgType setStaticReportData={setData} />;
      case 'getReportControlStaticReport':
        return <ReportControlStaticReport setStaticReportData={setData} />;

      default:
        return <DynamicReportFilter setData={setData} />;
    }
  };

  return <div>{report()}</div>;
}

