import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ReactApexChart from 'react-apexcharts';
import Search from './ConcessSearch';
import styles from './style.module.scss';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';

export default function Country() {
  const state = {
    date: {
      series: [
        {
          name: 'Нийт',
          data: [55, 75, 8, 7, 2],
        },
      ],
      options: {
        chart: {
          height: 300,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        yaxis: {
          show: true,
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        stroke: {
          size: 2,
          curve: 'smooth',
        },
        xaxis: {
          categories: [
            'Концессын гэрээ байгуулсан ',
            'Концессын гэрээ байгуулах эрх олгосон',
            'Концессын гэрээ хэлэлцээрийн шатанд',
            'Концессын гэрээ цуцлагдсан',
            'Уралдаант шалгаруулалт цуцлагдсан',
          ],
          // position: 'top',
        },
        colors: ['#004a9f'],
      },
    },
  };
  useEffect(() => {
    const chart = am4core.create('donutAmount', am4charts.PieChart);
    chart.data = [
      {
        name: ' Барих- Ашиглах - Шилжүүлэх',
        value: 25,
        color: am4core.color('#ffb60a'),
      },
      {
        name: 'Барих- Шилжүүлэх',
        value: 15,
        color: am4core.color('#258b70'),
      },
      {
        name: 'Барих- Өмчлөх - Ашиглах',
        value: 35,
        color: am4core.color('#79f8bd'),
      },
      {
        name: 'Барих- Өмчлөх - Ашиглах - Шилжүүлэх',
        value: 10,
        color: am4core.color('#004a9f'),
      },
      {
        name: 'Барих- түрээслэх - Шилжүүлэх',
        value: 15,
        color: am4core.color('#ff7575'),
      },
      {
        name: 'Зураг төсөл боловсруулах - Барих - Санхүүжүүлэх - Ашиглах',
        value: 15,
        color: am4core.color('#AB1338'),
      },
      {
        name: 'Шинэчлэн сайжруулах - Ашиглах - Шилжүүлэх',
        value: 15,
        color: am4core.color('#BFBFBF'),
      },
    ];
    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'name';
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    // Set up slices
    pieSeries.tooltip.background.fill = am4core.color('#ffffff');
    pieSeries.slices.template.tooltipText = '[#ffffff] {name}: {value} сая ₮';
    // Add slice click event
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'right';
    chart.legend.width = 250;
    chart.innerRadius = am4core.percent(45);

    // Add and configure Series

    const container = new am4core.Container();
    container.parent = pieSeries;
    container.horizontalCenter = 'middle';
    container.verticalCenter = 'middle';
    container.width = am4core.percent(100) / Math.sqrt(1);
    container.fill = 'white';

    const label = new am4core.Label();
    label.parent = container;
    label.textAlign = 'middle';
    label.text = '[bold]Концессийн \n 7 төрөл';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';
    label.fontSize = 14;
  }, []);

  return (
    <>
      <Row justify="center" className={styles.concessContainer}>
        <Col {...CONTENT_SPAN}>
          <Row justify="space-between">
            <Col xs={24} lg={11} className={styles.first}>
              <div className={styles.title}>Концессийн гэрээ</div>
              <ReactApexChart
                options={state.date.options}
                series={state.date.series}
                type="bar"
                height={300}
              />
            </Col>
            <Col xs={24} lg={12} className={styles.second}>
              <div className={styles.title}>Концессийн төрөл</div>
              <div
                id="donutAmount"
                style={{ width: '100%', height: '300px' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="sub-title">Концессийн дэлгэрэнгүй хайлт</div>
      <Search />
    </>
  );
}

