import React from 'react';
import { Button, message, Table } from 'antd';
import saveAs from 'file-saver';
import { Excel } from 'antd-table-saveas-excel';
import { isEmpty } from 'lodash';
import { DownloadOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';

export default function Download({ dataList }) {
  const { selectedReport } = useAppStore();

  const clickDownload = async row => {
    if (isEmpty(dataList)) {
      message.info('Өгөгдөл байхгүй байна.');
      return;
    }
    if (row.format === 'JSON') {
      const blob = new Blob([JSON.stringify(dataList)], {
        type: 'application/json',
      });
      saveAs(blob, `opendata-${selectedReport?.name || ''}.json`);
    } else if (row.format === 'CSV') {
      const headers = Object.keys(dataList[0])
        .map(z => z && `"${z}"`)
        .join(',');
      const content = dataList.map(r =>
        Object.values(r)
          .map(z => z && `"${z}"`)
          .join(',')
      );
      const csvData = [headers].concat(content).join('\n');
      const blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(blob, `opendata-${selectedReport?.name || ''}.csv`);
    } else if (row.format === 'XLSX') {
      const excelCols = [];
      Object.keys(dataList[0]).forEach(key => {
        excelCols.push({
          title: key,
          dataIndex: key,
          key,
        });
      });
      const excel = new Excel();
      try {
        excel
          .addSheet('sheet1')
          .addColumns(excelCols)
          .addDataSource(dataList)
          .saveAs(`opendata-${selectedReport?.name}.xlsx`);
      } catch (error) {
        message.warn(error);
      }
    }
  };

  const dataSource = [
    {
      number: '1',
      format: 'JSON',
      download: 'Татах',
    },
    {
      number: '2',
      format: 'XLSX',
      download: 'Татах',
    },
    {
      number: '3',
      format: 'CSV',
      download: 'Татах',
    },
  ];

  const columns = [
    {
      title: 'number',
      dataIndex: 'number',
      key: 'number',
      width: 60,
    },
    {
      title: 'format',
      dataIndex: 'format',
      key: 'format',
    },
    {
      title: 'download',
      dataIndex: 'download',
      key: 'download',
      align: 'right',
      render: (text, row) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => clickDownload(row)}
        >
          {text}
        </Button>
      ),
    },
  ];
  return (
    <div className={styles.dataContainer}>
      <Table
        rowKey="number"
        pagination={false}
        showHeader={false}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}

