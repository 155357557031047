import React, { useEffect, useState } from 'react';
import { Row, Col, Menu, Dropdown, Space, Table, Button } from 'antd';
import {
  BarChartOutlined,
  DownloadOutlined,
  TableOutlined,
  FileImageOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import styles from './style.module.scss';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';

export default function Search() {
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    // Create chart instance
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    // Add percent sign to all numbers
    chart.numberFormatter.numberFormat = "#.#'сая'";

    // Add data
    chart.data = [
      {
        country: 'Нийслэл',
        confirmed: 900,
        funded: 800,
        project: '10',
      },
      {
        country: 'Архангай',
        confirmed: 850,
        funded: 750,
        project: '10',
      },
      {
        country: 'Баянхонгор',
        confirmed: 800,
        funded: 700,
        project: '10',
      },
      {
        country: 'Говьсүмбэр',
        confirmed: 750,
        funded: 650,
        project: '10',
      },
      {
        country: 'Төв',
        confirmed: 700,
        funded: 600,
        project: '10',
      },
      {
        country: 'Булган',
        confirmed: 650,
        funded: 550,
        project: '10',
      },
      {
        country: 'Даланзадгад',
        confirmed: 600,
        funded: 500,
        project: '10',
      },
      {
        country: 'Өвөрхангай',
        confirmed: 550,
        funded: 450,
        project: '10',
      },
      {
        country: 'Завхан',
        confirmed: 500,
        funded: 400,
        project: '10',
      },
      {
        country: 'Сэлэнгэ',
        confirmed: 450,
        funded: 350,
        project: '10',
      },
      {
        country: 'Ховд',
        confirmed: 400,
        funded: 300,
        project: '10',
      },
    ];

    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = 'country';
    series.dataFields.valueX = 'confirmed';
    series.tooltipText =
      'Нийт гэрээний дүн: [bold]{valueX}[/]\n Санхүүжилт: [bold]{funded}[/]\n Хэрэгжиж буй ажлын тоо: [bold]{project}';
    series.clustered = false;

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.categoryY = 'country';
    series1.dataFields.valueX = 'funded';
    series1.clustered = false;
    series1.columns.template.height = am4core.percent(50);

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
  }, [isShow]);
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FileImageOutlined />}>
        JPG
      </Menu.Item>
      <Menu.Item key="3" icon={<FilePdfOutlined />}>
        Excel
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="center" className={styles.searchContainer}>
      <Col {...CONTENT_SPAN}>
        <Row justify="center" className="sub-title">
          Орон нутгийн хөгжлийн сан
        </Row>
        <Row justify="end">
          <Space className={styles.downloadText}>
            <Space size={15}>
              <TableOutlined
                className="icon"
                onClick={() => setIsShow(false)}
              />
              <BarChartOutlined
                className="icon"
                onClick={() => setIsShow(true)}
              />
            </Space>
            <Dropdown overlay={menu}>
              <Button type="text">
                <DownloadOutlined className="icon" />
              </Button>
            </Dropdown>
          </Space>
        </Row>
        {isShow ? (
          <Table className={styles.testtable} pagination={false} />
        ) : (
          <div
            id="chartdiv"
            style={{
              width: '100%',
              height: '400px',
              hover: { color: 'red' },
            }}
          />
        )}
      </Col>
    </Row>
  );
}

