import { Table } from 'antd';
import React from 'react';
import styles from './style.module.scss';

export default function ContentTable(props) {
  const { columns, data } = props;

  return (
    <div className={styles.allTable}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        footer={() =>
          ` Нийт: ${data.reduce(
            (total, dt) => total + (parseInt(dt.count, 10) || 0),
            0
          )}`
        }
        size="small"
      />
    </div>
  );
}
