/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Typography, Space, Card, Table, Image, message } from 'antd';
import {
  DatabaseFilled,
  LineChartOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Excel } from 'antd-table-saveas-excel';
import styles from './style.module.scss';
import FilterCard from '../components/filterCard/FilterCard';
import ColumnCharts from '../components/ColumnCharts/ColumnCharts';
import OrgContext from '../../context/OrgContext';
import emptyImg from '../../img/emptyPage.svg';
import { useAppStore } from '../../context/Tools';
import { getOrgResultStatistic } from '../../service/organizationServices';
import { convertCurrencyFormat } from '../../tools/Tools';

const { Text } = Typography;
const columns = [
  {
    title: 'Он',
    dataIndex: 'year',
    key: 'category',
  },
  {
    title: 'Жилийн төсөв',
    dataIndex: 'yearBudget',
    render: e => (e ? `${convertCurrencyFormat(e)} ₮` : ''),
    key: 'first',
  },
  {
    title: 'Төсвийн гүйцэтгэл',
    dataIndex: 'budgetPerformance',
    render: e => (e ? `${convertCurrencyFormat(e)} ₮` : ''),
    key: 'second',
  },
  // {
  //   title: 'Ашиг алдагдал',
  //   dataIndex: 'third',
  //   key: 'third',
  // },
];

export default function OrganizationThree() {
  const { orgId } = useParams();

  const { serverDate } = useAppStore();
  const year = serverDate.getFullYear();

  const { stateOrgStaticBig, setOrgStaticBigData } = useContext(OrgContext);

  const [loading, setLoading] = useState(true);
  const [graphic, setGraphic] = useState(true);
  const [filterValue, setFilterValue] = useState({
    years: [year - 2, year - 1, year],
    profitExpenseLoss: '1',
  });

  const table = () => {
    setGraphic(false);
  };
  const graph = () => {
    setGraphic(true);
  };

  const getOrgStaticData = () => {
    setLoading(true);
    const params = {
      years: filterValue.years.join(','),
      profitExpenseLoss: filterValue.profitExpenseLoss,
      orgId,
    };
    getOrgResultStatistic(params)
      .then(result => {
        if (result) {
          const bigData = result || [];
          bigData.forEach(item => {
            item.yearstr = `${item.year} он`;
          });
          setOrgStaticBigData(bigData);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setOrgStaticBigData([]);
      });
  };

  useEffect(() => {
    getOrgStaticData();
  }, [filterValue]);

  const exportExcel = () => {
    const excelDatas = stateOrgStaticBig || [];
    let excelCols = [];

    excelCols.push({
      title: '№',
      dataIndex: 'rowNo',
      key: 'rowNo',
      width: '40p',
    });
    excelCols = [...excelCols, ...columns];
    const excel = new Excel();
    try {
      excel
        .addSheet('sheet1')
        .addColumns(excelCols)
        .addDataSource(excelDatas)
        .saveAs(`Үр дүнгийн үзүүлэлт.xlsx`);
    } catch (error) {
      message.warn(error);
    }
  };

  return (
    <>
      <div className="sub-title">Үр дүнгийн үзүүлэлт</div>
      <Card className={styles.resultCard}>
        <Row>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <FilterCard
              loading={loading}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ padding: 24 }}>
            <Row justify="end">
              <Space onClick={table}>
                <DatabaseFilled className={styles.resultIndicatorsToolsIcon} />
                <Text>Хүснэгт</Text>
              </Space>
              <Space style={{ marginLeft: 20 }} onClick={graph}>
                <LineChartOutlined
                  className={styles.resultIndicatorsToolsIcon}
                />
                <Text>График</Text>
              </Space>
              <Space style={{ marginLeft: 20 }} onClick={exportExcel}>
                <DownloadOutlined
                  className={styles.resultIndicatorsToolsIcon}
                />
                <Text>Татах</Text>
              </Space>
            </Row>
            {graphic ? (
              <Row>
                {stateOrgStaticBig.length === 0 ? (
                  <Image
                    src={emptyImg}
                    alt="empty"
                    preview={false}
                    width="100%"
                    height={300}
                  />
                ) : (
                  <ColumnCharts
                    chartId="orgResultStatistic"
                    budgetData={stateOrgStaticBig || []}
                  />
                )}
              </Row>
            ) : (
              <Table
                rowKey={obj => obj.index}
                dataSource={stateOrgStaticBig || []}
                columns={columns}
                className={styles.testtable}
                pagination={false}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
}

