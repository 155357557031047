import React from 'react';
import { Row, Col, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import gov from '../../img/government.svg';
import EMD from '../../img/EMDWhite.svg';
import ND from '../../img/NDWhite.svg';
// import ITHText from '../../img/ITHText.svg';
import ITHLogo from '../../img/ITHLogo.svg';
import Down from '../../img/Down.svg';
import DownBlue from '../../img/DownBlue.svg';
import {
  SOCIAL_INSURANCE,
  COUNTRY_BUDGET,
  STATE_BUDGET,
  HEALTH_BUDGET,
} from '../../settings/constantRoutes';

export default function Cards() {
  const navigate = useNavigate();

  const handleCountry = () => {
    navigate(COUNTRY_BUDGET);
  };
  const handleStateBudget = () => {
    navigate(STATE_BUDGET);
  };
  const handleSocialInsurance = () => {
    navigate(SOCIAL_INSURANCE);
  };
  const handleHealtInsurance = () => {
    navigate(HEALTH_BUDGET);
  };

  const content = (
    <div>
      <div className={styles.popoverHead}>
        <div className={styles.popoverTitle}>Төсөв:</div>
        <span>
          <b>3,176,844.2 төгрөг</b>
        </span>
      </div>
      <div className={styles.popoverHead}>
        <span className={styles.popoverTitle}>Гүйцэтгэл:</span>
        <span>
          <b>2,466,923.4 төгрөг</b>
        </span>
      </div>
      <div style={{ maxWidth: 550 }}>
        Улсын Их Хурлаас баталсан, Засгийн газар болон улсын төсөвт харьяалагдах
        төсвийн ерөнхийлөн захирагчийн бүрдүүлж, хуваарилан зарцуулах төсөв.
      </div>
    </div>
  );
  return (
    <Row justify="center" className={styles.cardContainer} gutter={[10, 10]}>
      <Popover
        content={content}
        placement="bottom"
        trigger="hover"
        className={styles.budgetPopover}
        onClick={handleStateBudget}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
          <Row justify="center" className={`${styles.card} ${styles.a}`}>
            <Col span={15} className={styles.cardItem}>
              <img src={gov} alt="gov" />
            </Col>
            <Col className={styles.cardName} span={7}>
              Улсын төсөв
            </Col>
            <Col span={2}>
              <img src={DownBlue} alt="Down" />
            </Col>
          </Row>
        </Col>
      </Popover>
      <Popover
        content={content}
        placement="bottom"
        trigger="hover"
        className={styles.budgetPopover}
        onClick={handleSocialInsurance}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
          <Row justify="center" className={`${styles.card} ${styles.b}`}>
            <Col span={8} className={styles.cardItem}>
              <img src={ND} alt="ND" />
            </Col>
            <Col className={styles.cardName} span={14}>
              Нийгмийн даатгалын сангийн төсөв
            </Col>
            <Col span={2}>
              <img src={Down} alt="Down" />
            </Col>
          </Row>
        </Col>
      </Popover>
      <Popover
        content={content}
        placement="bottom"
        trigger="hover"
        className={styles.budgetPopover}
        onClick={handleHealtInsurance}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
          <Row justify="center" className={`${styles.card} ${styles.c}`}>
            <Col span={8} className={styles.cardItem}>
              <img src={EMD} alt="EMD" />
            </Col>
            <Col className={styles.cardName} span={14}>
              Эрүүл мэндийн даатгалын сангийн төсөв
            </Col>
            <Col span={2}>
              <img src={Down} alt="Down" />
            </Col>
          </Row>
        </Col>
      </Popover>
      <Popover
        content={content}
        placement="bottom"
        trigger="hover"
        className={styles.budgetPopover}
        onClick={handleCountry}
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
          <Row justify="center" className={`${styles.card} ${styles.d}`}>
            <Col span={8} className={styles.cardItem}>
              <img src={ITHLogo} alt="ith" />
            </Col>
            {/* <Col span={10}>
              <img src={ITHText} alt="ith" />
            </Col> */}
            <Col className={styles.cardName} span={14}>
              Орон нутгийн төсөв
            </Col>
            <Col span={2}>
              <img src={Down} alt="Down" />
            </Col>
          </Row>
        </Col>
      </Popover>
    </Row>
  );
}
