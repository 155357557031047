import React, { useState, useEffect } from 'react';
import { Checkbox, Col } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { Loading } from './Loading';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';
import { getReportGroups } from '../../../service/reportServices';
import { SORT_ORDER } from '../../../constants/Constant';
import { FILTER_SPAN } from '../utils';

export default function FilterByReportGroup() {
  const { reportGroupList, setReportGroupList, filters, setFilters } =
    useAppStore();

  const { reportGroups } = filters;

  const [reportGroupState, setReportGroupState] = useState({
    indeterminate: false,
    checkAll: false,
  });

  const getReportGroupData = async () => {
    if (!isEmpty(reportGroupList)) {
      return;
    }
    try {
      const { content } = await getReportGroups({
        search: { status: true },
        size: 100,
        sortField: 'priority',
        sortOrder: SORT_ORDER.ASC,
      });
      const data = content.map(item => ({
        label: item.name,
        key: item.id,
        value: item.id,
      }));
      setReportGroupList(data);
    } catch {
      setReportGroupList([]);
    }
  };

  useEffect(() => {
    getReportGroupData();
  }, []);

  return (
    <Col {...FILTER_SPAN} className={styles.subMenu}>
      <div className={styles.subHeader}>
        <div> Байршуулах мэдээллийн төрөл</div>
        <Checkbox
          checked={reportGroupState.checkAll}
          disabled={isNil(reportGroupList)}
          indeterminate={reportGroupState.indeterminate}
          onChange={e => {
            if (isNil(reportGroupList)) return;
            setFilters({
              ...filters,
              reportGroups: e.target.checked
                ? (reportGroupList || []).map(s => s.value)
                : [],
            });
            setReportGroupState({
              indeterminate: false,
              checkAll: e.target.checked,
            });
          }}
        >
          Бүгд
        </Checkbox>
      </div>
      {isNil(reportGroupList) ? (
        <Loading />
      ) : (
        <Checkbox.Group
          value={reportGroups}
          className={styles.menuBox}
          options={reportGroupList}
          onChange={e => {
            setFilters({ ...filters, reportGroups: e });
            setReportGroupState({
              indeterminate: !!e.length && e.length < reportGroupList.length,
              checkAll: e.length === reportGroupList.length,
            });
          }}
        />
      )}
    </Col>
  );
}

