import { getRequest, postService } from './Service';

const serviceBaseUrl = '/gap-portal-service';
const serviceOrgBaseUrl = '/gap-organization-service';

export const serviceSpecialMenus = `${serviceBaseUrl}/portalSpecialMenus/findAll`;
export const serviceLink = `${serviceBaseUrl}/portalLinks/findAll`;
export const serviceNews = `${serviceBaseUrl}/portalNewses/findAll`;
export const serviceBanner = `${serviceBaseUrl}/portalBanners/findAll`;
export const serviceFooterPartner = `${serviceBaseUrl}/portalFooterPartners/findAll`;
export const serviceFooterLink = `${serviceBaseUrl}/portalFooterLinks/findAll`;
export const serviceFooterContact = `${serviceBaseUrl}/portalFooterContacts/findAll`;

export const serviceAllOrgs = `${serviceOrgBaseUrl}/orgs/getAllByRefOrgTypes`;
export const serviceFeauturedCorps = `${serviceOrgBaseUrl}/orgs`;
export const serviceOrganizationFilter = `${serviceFeauturedCorps}/filter`;

export const getBanners = async params =>
  await getRequest(serviceBanner, params);

export const getSpecialMenus = async params =>
  await getRequest(serviceSpecialMenus, params);

export const getLinks = async params => await getRequest(serviceLink, params);

export const getNews = async params => await getRequest(serviceNews, params);

export const getFooterPartners = async params =>
  await getRequest(serviceFooterPartner, params);

export const getFooterLinks = async params =>
  await getRequest(serviceFooterLink, params);

export const getFooterContacts = async params =>
  await getRequest(serviceFooterContact, params);

export const getOrganizationFilter = async (body, cancelRef, params) =>
  await postService(serviceOrganizationFilter, body, params, cancelRef);

export const getAllOrgs = async params =>
  await getRequest(serviceAllOrgs, params);

