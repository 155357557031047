import React, { useState, useEffect } from 'react';
import { Checkbox, Col, Tree } from 'antd';
import { isNil } from 'lodash';
import { Loading } from './Loading';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';
import { FILTER_SPAN } from '../utils';

export default function FilterByReportForm() {
  const { getReportList, filters, setFilters } = useAppStore();

  const [treeList, setTreeList] = useState();
  const [state, setState] = useState({
    indeterminate: false,
    checkAll: false,
  });

  const { reportForms } = filters;

  useEffect(async () => {
    const reportTypes = await getReportList();
    const forms = reportTypes
      .filter(item => item.id)
      .map(item => ({
        type: 'type',
        title: item.name,
        key: item.id,
        children: (item.children || []).map(child => ({
          type: 'report',
          title: child.name,
          key: `${item.id}-${child.id}`,
          id: child.id,
        })),
      }));
    setTreeList(forms);
  }, []);

  const total = (treeList || []).reduce(
    (sum, item) => sum + (item.children || []).length + 1,
    0
  );

  return (
    <Col {...FILTER_SPAN} className={styles.subMenu}>
      <div className={styles.subHeader}>
        <div>Мэдээллийн төрөл</div>
        <Checkbox
          checked={state.checkAll}
          disabled={!treeList}
          indeterminate={state.indeterminate}
          onChange={e => {
            if (isNil(treeList)) return;
            setFilters({
              ...filters,
              reportForms: e.target.checked ? treeList : [],
            });
            setState({
              indeterminate: false,
              checkAll: e.target.checked,
            });
          }}
        >
          Бүгд
        </Checkbox>
      </div>
      {isNil(treeList) ? (
        <Loading />
      ) : (
        <Tree
          className={styles.menuBox}
          treeData={treeList}
          checkable
          selectable={false}
          checkedKeys={(reportForms || []).map(p => p.key)}
          onCheck={(keys, event) => {
            const checkedList = event.checkedNodes || [];
            setFilters({ ...filters, reportForms: checkedList });
            setState({
              indeterminate: !!checkedList.length && checkedList.length < total,
              checkAll: checkedList.length === total,
            });
          }}
        />
      )}
    </Col>
  );
}

