import React, { useState, useEffect } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import 'react-pivottable/pivottable.css';
import '../../styles/pivottable.css';
import { isEmpty } from 'lodash';
import { useAppStore } from '../../context/Tools';
import { FIELD } from '../../constants/OpenData';

export default function PivotTable({ dataList }) {
  const { reportHeaderOptions, filters } = useAppStore();
  const [state, setState] = useState();
  const [pivotData, setPivotData] = useState([]);

  const setConvertedData = () => {
    const selectedHeaders = filters.headers.map(hdrId => {
      const item = { id: hdrId };
      const findHeader = (reportHeaderOptions || []).find(
        header => header.id === hdrId
      );
      if (findHeader) {
        item.col = findHeader.col;
        item.title = findHeader.data;
        item.format = findHeader.format;
      }
      return item;
    });

    const rows = [
      FIELD.orgName,
      FIELD.year,
      FIELD.month,
      ...selectedHeaders.map(hdr => hdr.title),
    ];
    setState({ ...state, rows });

    if (isEmpty(dataList)) {
      setPivotData([]);
      return;
    }

    setPivotData([...dataList]);
  };

  useEffect(setConvertedData, [dataList]);

  const pivotPresets = {
    cols: [],
    rendererName: 'Table',
    aggregatorName: 'Sum',
    vals: [],
    unusedOrientationCutoff: 2000,
    // derivedAttributes: {
    //   linkTest: el => (
    //     <a
    //       href="https://youtube.com"
    //       target="_blank"
    //       rel="noreferrer"
    //       style={{ textAlign: 'center' }}
    //     >
    //       werty
    //     </a>
    //   ),
    // },
  };

  return (
    <PivotTableUI
      data={pivotData}
      renderers={{ ...TableRenderers }} // ,PlotlyRenderers)}
      onChange={s => setState(s)}
      {...pivotPresets}
      {...state}
    />
  );
}

