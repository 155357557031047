/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { Input, Space, Button, Row, Card } from 'antd';
import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import SearchList from './searchList';
import ContentCard from './contentCard';
import RelatedText from './relatedText';

const { Search } = Input;
function SearchContent({ details }) {
  const [searchField, setSearchField] = useState('');
  const [searchShow, setSearchShow] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [inputText, setInputText] = useState('');

  const filteredTitles = details.filter(
    textDetails =>
      textDetails?.name.toLowerCase().includes(searchField?.toLowerCase())
  );
  const handleChange = e => {
    setSearchField(e.target.value);
    filteredTitles.map(data => {
      if (data.name === null) {
        setDisabled(true);
      }
    });
    if (e.target.value === '') {
      setSearchShow(false);
      setIsShow(true);
    } else {
      setSearchShow(true);
      setDisabled(false);
    }
  };
  const onClickShow = () => {
    setIsShow(!isShow);
  };
  const onSearchShow =() =>{
    setSearchShow(false)
    setInputText('Түлхүүр үг');
  }
  const relatedChange = e => {
    setSearchField(e.target.outerText);
    setInputText(e.target.outerText);
    if (e.target.outerText === '') {
      setSearchShow(false);
      setIsShow(true);
    } else {
      setSearchShow(true);
      setDisabled(false);
    }
  };

  function searchList() {
    if (searchShow) {
      return <SearchList filteredTitle={filteredTitles} />;
    }
  }

  function searchListCard() {
    if (searchShow) {
      return (
        <ContentCard onClose={onClickShow} filteredCardTitle={filteredTitles} />
      );
    }
  }

  return (
    <>
      <Space direction="vertical">
        <Search
          placeholder={'Түлхүүр үг' && `${inputText}`}
          enterButton
          allowClear={false}
          defaultValue={inputText}
          onChange={handleChange}
          loading={false}
        />
        {searchList()}
        {searchShow ? (
          <> </>
        ) : (
          <>
            {' '}
            {details.map((item, index) => (
              <RelatedText
                key={index}
                item={item}
                relatedChange={relatedChange}
              />
            ))}
          </>
        )}

        <Row>
          {searchShow ? (<>  <Button
            type="link"
            onClick={onSearchShow}
            icon={
              <ArrowLeftOutlined style={{ color: '#004a9f', fontSize: 14 }} />
            }
          >
            Буцах
          </Button></>):(<> </>)}
            {searchShow ? (<>
              {isShow ? (
            <Button
              type="link"
              disabled={disabled}
              onClick={onClickShow}
              icon={
                <CheckCircleFilled style={{ color: '#004a9f', fontSize: 14 }} />
              }
            >
              Албан бус хэллэг
            </Button>
          ) : (
            <Card>
              {searchListCard()}
              <CloseCircleFilled
                onClick={onClickShow}
                style={{ color: '#004a9f', fontSize: 16 }}
              />
            </Card>
          )}
            </>):(<>
             </>)}
        </Row>
      </Space>
    </>
  );
}
export default SearchContent;
