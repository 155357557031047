export const LOGIN_URL = 'https://info.shilendans.gov.mn';

export const ILTOD_URL = 'http://iltod.mof.gov.mn/budget/main';

export const CONTENT_SPAN = { xs: 22, sm: 21, md: 19, lg: 19, xl: 19, xxl: 18 }; // 19/24 (24 -> full_width)

export const GOOGLE_MAP_KEY = 'AIzaSyCmyDZ-v3aoy_FMtFO2a8CJWskiHpv4_ig';

export const GOOGLE_ANALYTIC_CODE = 'G-CX0M2Z11J2';

