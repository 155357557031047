import React, { useState } from 'react';
import { Form } from 'antd';
import ReactGA from 'react-ga4';
import { getOrganization } from '../service/organizationServices';

const OrgContext = React.createContext({});

export const OrgStore = props => {
  const [form] = Form.useForm();
  const [mainState, setMainState] = useState({
    refOrgTypeId: null,
    refCityId: null,
    refDistrictId: null,
    refTypeId: null,
    activityCode: null,
    registerNo: null,
    budgetGovernor: null,
    name: null,
    params: null,
  });
  const [stateMap, setMainMapState] = useState({
    refCityId: null,
    refDistrictId: null,
    boolean: null,
  });
  const [orgInfo, setOrgInfo] = useState({});
  const [stateOrgStaticBig, setOrgStaticBigData] = useState([]);
  const [stateParentOrg, setStateParentOrg] = useState([]);

  const setMain = value => {
    setMainState({ ...mainState, ...value });
  };

  const setMapMain = value => {
    setMainMapState({ ...stateMap, ...value });
  };

  const setOrgData = orgId => {
    getOrganization(orgId).then(result => {
      const organization = result || {};
      setOrgInfo(organization);

      ReactGA.event({
        action: 'view_organization',
        category: 'organization',
        label: organization.name,
        value: organization.id,
      });
    });
  };

  return (
    <OrgContext.Provider
      value={{
        form,
        mainState,
        setMain,
        orgInfo,
        setOrgData,
        stateParentOrg,
        setStateParentOrg,
        stateMap,
        setMapMain,
        setOrgStaticBigData,
        stateOrgStaticBig,
      }}
    >
      {props.children}
    </OrgContext.Provider>
  );
};

export default OrgContext;
