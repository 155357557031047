import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input, Select, Button, Table, DatePicker } from 'antd';
import { isEmpty } from 'lodash';
import locale from 'antd/es/date-picker/locale/mn_MN';
import moment from 'moment';

import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from './style.module.scss';

import leftBlue from '../../img/pps/leftBlue.svg';
// import rightBlue from '../../img/pps/rightBlue.svg';
import { useAppStore } from '../../context/Tools';
import {
  getServiceGetReference,
  getServiceGetReferenceEzaByDate,
  getSupplierByDetail,
  getSupplierByDetailOne,
  getSupplierByDetailTwo,
} from '../../service/reportServices';
import { convertCurrencyFormat } from '../../tools/Tools';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Search() {
  const { serverDate } = useAppStore();

  const location = useLocation();

  const cancelRef = useRef();

  const [orgTypeData, setOrgTypeData] = useState([]);
  const [ezaTypeData, setEzaTypeData] = useState([]);
  const [filterStatusName, setFilterStatusName] = useState('');
  const [searchParams, setSearchParams] = useState([]);
  const [supplierByDtl, setSupplierByDtl] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [searchDate, setSearchDate] = useState({
    startDate: `${serverDate.getFullYear()}/01/01`,
    endDate: moment(serverDate).format('YYYY/MM/DD'),
  });

  const [det, setDet] = useState(false);
  const [searchTempParam, setSearchTempParam] = useState([]);
  const [supp, setSupp] = useState('');
  // const [sub, setSub] = useState('');
  const [subInfo, setSubInfo] = useState('');

  useEffect(() => {
    searchDate &&
      getServiceGetReferenceEzaByDate(searchDate).then(result => {
        setEzaTypeData(result);
      });
  }, [searchDate]);

  useEffect(() => {
    getServiceGetReference().then(result => {
      setOrgTypeData(result.content);
    });
  }, []);

  useEffect(
    () => () => {
      if (cancelRef && cancelRef.current) {
        cancelRef.current.cancel();
      }
    },
    [location?.pathname]
  );

  useEffect(() => {
    setLoading(true);
    setSupplierByDtl([]);
    const params = {
      // first: true,
      page: pageNumber,
      size: pageSize,
      // rows: pageSize,
      startDate: searchDate.startDate,
      endDate: searchDate.endDate,
      supplierType: filterStatusName,
      ezaType: searchParams.eza || '',
      registerNo: searchParams.registerNo || '',
      orgName: searchParams.supplierName || '',
      /* sortField: 'name',
			sortOrder: SORT_ORDER.DESC, */
    };

    getSupplierByDetail(params, cancelRef)
      .then(result => {
        setSupplierByDtl(result);
        setLoading(false);
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          setLoading(false);
        }
      });
  }, [searchDate, pageNumber, pageSize, searchParams, filterStatusName]);

  const onChange = (date, dateString) => {
    setSearchDate({
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  const onChangeRegister = event => {
    if (event.target.value) {
      setSearchParams({
        ...searchTempParam,
        registerNo: `${event.target.value}`,
      });
      setSearchTempParam({
        ...searchTempParam,
        registerNo: `${event.target.value}`,
      });
    } else {
      delete searchTempParam.registerNo;
      setSearchTempParam({ ...searchTempParam });
      setSearchParams({ ...searchTempParam });
    }
  };

  const onChangeTypeData = value => {
    if (value) {
      setFilterStatusName(value);
    } else {
      setFilterStatusName('');
    }
  };

  const onChangeEzaData = value => {
    if (value) {
      setSearchParams({ ...searchTempParam, eza: `${value}` });
      setSearchTempParam({ ...searchTempParam, eza: `${value}` });
    } else {
      delete searchTempParam.eza;
      setSearchTempParam({ ...searchTempParam });
      setSearchParams({ ...searchTempParam });
    }
  };

  const onChangeName = event => {
    if (event.target.value) {
      setSearchParams({
        ...searchTempParam,
        supplierName: `${event.target.value}`,
      });
      setSearchTempParam({
        ...searchTempParam,
        supplierName: `${event.target.value}`,
      });
    } else {
      delete searchTempParam.supplierName;
      setSearchTempParam({ ...searchTempParam });
      setSearchParams({ ...searchTempParam });
    }
  };

  const onChangePageNumber = (page, psize) => {
    setPageNumber(page - 1);
    setPageSize(psize);
  };

  const suppContentData = !isEmpty(supplierByDtl) ? supplierByDtl.content : [];
  const supplierDtlData = !isEmpty(suppContentData)
    ? suppContentData.map((item, key) => ({
        index: pageNumber * pageSize + key + 1,
        id: item.id,
        register: item.register,
        address: item.address,
        refLegalStatusCode: item.refLegalStatusCode,
        refLegalStatusName: item.refLegalStatusName,
        amount: item.amount,
        amountFormated: convertCurrencyFormat(item.amount),
        count: item.count,
        name: item.name,
        supplier: item.supplier,
      }))
    : [];

  const handleSupplier = async parentData => {
    const params = {
      startDate: searchDate.startDate,
      endDate: searchDate.endDate,
      // orgName: parentData.name || '',
      registerNo: parentData?.register,
    };
    setLoading(true);

    let totalSuppAmount = 0;
    let totalTransactionCount = 0;
    const tempSupp = [];
    const detailOne = await getSupplierByDetailOne(params, cancelRef).finally(
      () => setLoading(false)
    );
    const supplier = detailOne.content || [];

    supplier.forEach((item, key) => {
      totalSuppAmount += item.transactionAmount
        ? parseFloat(item.transactionAmount)
        : 0;
      totalTransactionCount += item.transactionCount
        ? parseFloat(item.transactionCount)
        : 0;

      tempSupp.push({
        sIndex: key + 1,
        transactionAmount: convertCurrencyFormat(item.transactionAmount),
        transactionCount: item.transactionCount,
        address: parentData.address ? parentData.address : '',
        refLegalStatusName: parentData.refLegalStatusName
          ? parentData.refLegalStatusName
          : '',
        register: parentData.register ? parentData.register : '',
        name: item.name,
        id: item.id,
        detail: item.detail,
        companyName: parentData.name ? parentData.name : '',
      });
    });

    const setSubInfoTemp = [];
    setSubInfoTemp.push({
      totalAmount: totalSuppAmount,
      totalCount: totalTransactionCount,
      name: supplier[0].name ? supplier[0].name : '',
      address: parentData.address ? parentData.address : '',
      refLegalStatusName: parentData.refLegalStatusName
        ? parentData.refLegalStatusName
        : '',
      register: parentData.register ? parentData.register : '',
      companyName: parentData.name ? parentData.name : '',
    });
    // setSub('');
    setSupp(tempSupp);
    setSubInfo(setSubInfoTemp);
  };

  const handleDetail = async record => {
    const params = {
      startDate: searchDate.startDate,
      endDate: searchDate.endDate,
      // orgName: record.companyName || '',
      registerNo: record?.register,
      customerName: record.name || '',
    };
    setLoading(true);
    const detailTwo = await getSupplierByDetailTwo(params, cancelRef).finally(
      () => setLoading(false)
    );
    const detail = detailTwo.content || [];

    const tempDetail = [];
    let dIndex = 1;
    detail.forEach(item => {
      tempDetail.push({
        dIndex: dIndex++,
        amount: item.amount,
        amountFormatted: item.amount ? convertCurrencyFormat(item.amount) : 0,
        accountName: item.accountName,
        accountNumber: item.accountNumber,
        classification: item.classification,
        date: item.datetime ? item.datetime.substring(0, 10) : '',
        datetime: item.datetime,
        date1: item.date,
        eza: item.eza,
        id: item.id,
        registerNumber: item.registerNumber,
        supplier: item.supplier,
        transactionValue: item.transactionValue,
      });
    });

    setDet(tempDetail);
    // setSub(tempDetail);
  };

  const supplierColumns = [
    {
      index: 'id1',
      title: '№',
      dataIndex: 'sIndex',
      key: 'id',
      width: 70,
    },
    {
      index: 'name2',
      title: 'Захиалагч',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      index: 'transactionAmount3',
      title: 'Гүйлгээний дүн',
      dataIndex: 'transactionAmount',
      key: 'transactionAmount',
      width: 100,
    },
    {
      index: 'transactionCount3',
      title: 'Гүйлгээний тоо',
      dataIndex: 'transactionCount',
      key: 'transactionCount',
      width: 100,
    },
    {
      title: '',
      index: 'address1',
      dataIndex: 'address',
      key: 'address',
      width: 50,
      align: 'right',
      fixed: 'right',
      render: (el, record) => (
        <Button type="primary" onClick={() => handleDetail(record)}>
          Дэлгэрэнгүй
        </Button>
      ),
    },
  ];

  const detailColumns = [
    {
      title: '№',
      index: 'id2',
      dataIndex: 'dIndex',
      key: 'dIndex',
      width: 70,
    },
    {
      title: 'Огноо',
      index: 'date2',
      dataIndex: 'date',
      key: 'date',
      width: 50,
      sorter: {
        compare: (a, b) => moment(a.date).diff(b.date),
      },
    },
    {
      title: 'Эдийн засгийн ангилал',
      index: 'classification2',
      dataIndex: 'eza',
      key: 'eza',
      width: 100,
    },
    {
      title: 'Мөнгөн дүн ₮',
      index: 'amount2',
      dataIndex: 'amountFormatted',
      key: 'amountFormatted',
      width: 100,
      sorter: {
        compare: (a, b) => a.amount - b.amount,
      },
    },
    {
      title: 'Гүйлгээний утга',
      index: 'transactionValue2',
      dataIndex: 'transactionValue',
      key: 'transactionValue',
      width: 100,
    },
  ];

  const firstColumns = [
    {
      title: '№',
      index: 'id2',
      dataIndex: 'index',
      key: 'index',
      width: 70,
      render: el => <>{el}.</>,
    },
    {
      title: 'Бэлтгэн нийлүүлэгч',
      index: 'name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'Регистрийн дугаар',
      index: 'registerFirst',
      dataIndex: 'register',
      key: 'register',
      width: 120,
    },
    {
      title: 'Гүйлгээний дүн',
      index: 'amount1',
      dataIndex: 'amountFormated',
      key: 'amountFormated',
      align: 'right',
      width: 150,
    },
    {
      title: 'Гүйлгээний тоо',
      index: 'count1',
      dataIndex: 'count',
      key: 'count',
      width: 120,
      align: 'right',
    },
    {
      title: '',
      index: 'address1',
      dataIndex: 'address',
      key: 'address',
      width: 150,
      align: 'right',
      fixed: 'right',
      render: (el, record) => (
        <>
          <Button type="primary" onClick={() => handleSupplier(record)}>
            Дэлгэрэнгүй
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="center" className="datePickerCustome">
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="main-title">
            <span>Бэлтгэн нийлүүлэгчдийн дэлгэрэнгүй хайлт</span>
          </div>
          <Row gutter={[10, 10]} className={styles.filterRow}>
            <Col sm={12} md={8} lg={5}>
              <Select
                placeholder="Байгууллагын төрөл:"
                className={styles.filter}
                onChange={onChangeTypeData}
                allowClear="true"
              >
                {orgTypeData &&
                  orgTypeData.map((z, index) => (
                    <Option key={index} value={z.name}>
                      {z.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col sm={12} md={8} lg={4}>
              <Input
                placeholder="Регистр:"
                onChange={onChangeRegister}
                className={styles.filter}
              />
            </Col>
            <Col sm={12} md={8} lg={5}>
              <Input
                placeholder="Байгууллагын нэр:"
                onChange={onChangeName}
                className={styles.filter}
              />
            </Col>
            <Col sm={12} md={8} lg={5}>
              <Select
                placeholder="Эдийн засгийн ангилал:"
                className={styles.filter}
                onChange={onChangeEzaData}
                allowClear="true"
              >
                {ezaTypeData &&
                  ezaTypeData.map((z, index) => (
                    <Option key={index} value={z?.code}>
                      {z?.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col sm={12} md={8} lg={5} style={{ minWidth: 250 }}>
              <RangePicker
                allowClear
                onChange={onChange}
                showToday
                size="large"
                format="YYYY/MM/DD"
                defaultValue={[
                  moment(searchDate.startDate),
                  moment(searchDate.endDate),
                ]}
                locale={{
                  ...locale,
                  lang: {
                    ...locale.lang,
                    now: 'Current Time',
                    ok: 'Submit',
                  },
                }}
                disabledDate={current => current.isBefore(moment('2023-01-01'))}
              />
            </Col>
          </Row>
          <div className={styles.results}>
            {det && (
              <>
                <Button
                  onClick={() => {
                    setDet('');
                  }}
                >
                  <img
                    src={leftBlue}
                    role="presentation"
                    className={styles.arrow}
                    alt="back"
                  />
                  Буцах
                </Button>
                <div style={{ marginTop: '1rem' }}>
                  <Table
                    rowKey={obj => obj.id}
                    dataSource={det}
                    columns={detailColumns}
                    pagination={false}
                    summary={detailData => {
                      let totalAmount = 0;
                      detailData.forEach(itemDtl => {
                        totalAmount += itemDtl.amount
                          ? parseFloat(itemDtl.amount)
                          : 0;
                      });
                      return (
                        <>
                          <Table.Summary fixed>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={10} />
                              <Table.Summary.Cell index={11}>
                                <b>Нийт</b>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell />
                              <Table.Summary.Cell index={12}>
                                <b>{convertCurrencyFormat(totalAmount)}</b>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        </>
                      );
                    }}
                  />
                </div>
              </>
            )}
            {!det && supp && (
              <div>
                <Button
                  onClick={() => {
                    setSupp('');
                  }}
                >
                  <img
                    src={leftBlue}
                    role="presentation"
                    className={styles.arrow}
                    alt="back"
                  />
                  Буцах
                </Button>
                {/* <Button
                  style={{ marginLeft: '1rem' }}
                  onClick={() => {
                    setDet(sub);
                  }}
                >
                  Дараах{' '}
                  <img
                    src={rightBlue}
                    alt="forward"
                    style={{ marginLeft: '0.5rem' }}
                    role="presentation"
                  />
                </Button> */}
                <Row className={styles.profile} gutter={10}>
                  <Col span={8}>
                    <Row>
                      <Col span={8} className={styles.question}>
                        Нэр:
                      </Col>
                      <Col span={16} className={styles.answer}>
                        {subInfo[0]?.companyName ? subInfo[0].companyName : ''}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className={styles.question}>
                        Регистр:
                      </Col>
                      <Col span={16} className={styles.answer}>
                        {subInfo[0]?.register ? subInfo[0].register : ''}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={8} className={styles.question}>
                        Байгууллагын төрөл:
                      </Col>
                      <Col span={16} className={styles.answer}>
                        {subInfo[0]?.refLegalStatusName}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className={styles.question}>
                        Хаяг:
                      </Col>
                      <Col span={16} className={styles.answer}>
                        {subInfo[0]?.address ? subInfo[0].address : ''}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={12} className={styles.question}>
                        Гүйлгээний нийт дүн:
                      </Col>
                      <Col span={12} className={styles.answer}>
                        {convertCurrencyFormat(subInfo[0]?.totalAmount)}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className={styles.question}>
                        Гүйлгээний нийт тоо:
                      </Col>
                      <Col span={12} className={styles.answer}>
                        {subInfo[0]?.totalCount}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Table
                  rowKey={obj => obj.id}
                  dataSource={supp}
                  columns={supplierColumns}
                  loading={{
                    size: 'large',
                    spinning: loading,
                    indicator: <LoadingOutlined />,
                  }}
                  pagination={false}
                  scroll={{ y: 240 }}
                  className={styles.table}
                  summary={() => (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          <b>Нийт</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} />
                        <Table.Summary.Cell index={2}>
                          <b>
                            {convertCurrencyFormat(subInfo[0]?.totalAmount)}
                          </b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <b>{subInfo[0]?.totalCount}</b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </div>
            )}
            {!supp && !det && (
              <Table
                rowKey={obj => obj.index}
                dataSource={supplierDtlData}
                columns={firstColumns}
                loading={{
                  size: 'large',
                  spinning: loading,
                  indicator: <LoadingOutlined />,
                }}
                pagination={{
                  pageSize,
                  pageSizeOptions: ['10', '20', '25', '30', '50'],
                  locale: { items_per_page: '' },
                  total:
                    !isEmpty(supplierByDtl) && supplierByDtl.totalElements
                      ? supplierByDtl.totalElements
                      : 0,
                  onChange: onChangePageNumber,
                  position: ['none', 'bottomRight'],
                }}
                className={styles.table}
                scroll={{ x: 600 }}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

