import React, { useState } from 'react';
import { Image, Modal , Typography } from 'antd';
import { PdfViewer } from '../../../components/pdfViewer/pdfViewer';
import styles from './style.module.scss';
import emptySvg from "../../../../img/emptyPage.svg"

const {Text} = Typography;

function PdfViewerModal({
  handleOk,
  handleCancel,
  isModalVisible,
  headerText,
  pdfUuid,
})
{
const [showEmptySvg,setEmptySvg] = useState(false);
const [showLoader, setShowLoader] = useState(true);
  return (
    <>
      <div className={styles.modalHeader}>
        <Modal
          className={styles.modalHeader}
          title={headerText}
          visible={isModalVisible}
          onOk={handleOk}
          mask={false}
          onCancel={handleCancel}
          width={1000}
          style={{ top: 20 }}
          footer={null}
        >
          <div style={{ overflow: 'auto', height: '700px',textAlign: 'center'}}>
            {showLoader?  <Text style={{fontSize: 16}} >Уншиж байна...</Text> : <></>}
            {!showEmptySvg ? <></> : <Image src={emptySvg} preview={false}  />}
            <PdfViewer pdfUuid={pdfUuid}  setEmptySvg={setEmptySvg} setShowLoader={setShowLoader} />
          </div>
        </Modal>
      </div>
    </>
  );
}
export default PdfViewerModal;
