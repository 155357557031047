import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import { isNil } from 'lodash';
import styles from './style.module.scss';

const colors = [
  '#26a6fe',
  '#fdb441',
  '#2bbbc8',
  '#7479bf',
  '#c86183',
  '#054fa4',
  'dimgray',
];

export default function OpenDataMenu({
  reportFormTypes,
  selectedFormType,
  setSelectedFormType,
}) {
  return (
    <div className={styles.menuContainer}>
      {isNil(reportFormTypes) ? (
        <Skeleton active />
      ) : (
        <Row gutter={[20, 20]}>
          {reportFormTypes.map((item, index) => (
            <Col xs={24} md={12} lg={8} xl={6} xxl={4} key={index}>
              <div
                style={{ background: colors[0] }}
                className={styles.outerCard}
              >
                <div
                  role="presentation"
                  className={`${styles.card} ${
                    selectedFormType?.id === item.id ? styles.activeCard : ''
                  }`}
                  onClick={() => {
                    setSelectedFormType(item);
                  }}
                >
                  <b>{item.name}</b>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

