import { Row, Col, Input, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Option } from 'antd/lib/mentions';
import styles from './style.module.scss';
import OrgContext from '../../../../context/OrgContext';
import { MSG } from '../../../../constants/Constant';

export default function CompanyBigSearch({ setSearchType }) {
  const { setMain, mainState } = useContext(OrgContext);
  const [searchBy, setSearchBy] = useState('name');

  const filterOrgData = e => {
    setSearchType('byOrgType');
    const mainval = {
      name: null,
      registerNo: null,
      refOrgTypeId: null,
      refCityId: null,
      refDistrictId: null,
      refTypeId: null,
      activityCode: null,
    };

    if (searchBy === 'name') {
      mainval.name = `'*${e.target.value}*'`;
    } else {
      mainval.registerNo = `'*${e.target.value}*'`;
    }
    setMain(mainval);
  };

  const selectBefore = (
    <Select
      defaultValue="name"
      value={searchBy}
      style={{
        width: 150,
      }}
      onChange={e => setSearchBy(e)}
    >
      <Option value="name">Нэрээр</Option>
      <Option value="register">Регистрээр</Option>
    </Select>
  );

  useEffect(() => {
    if (searchBy === 'name') {
      setMain({
        ...mainState,
        name: mainState.registerNo,
        registerNo: null,
      });
    } else {
      setMain({
        ...mainState,
        registerNo: mainState.name,
        name: null,
      });
    }
  }, [searchBy]);
  return (
    <Col span={24}>
      <div className={styles.companyFilter}>
        <Col span={24}>
          <Row>
            <Input
              size="large"
              addonBefore={selectBefore}
              placeholder={MSG.CYRILLIC}
              onChange={filterOrgData}
              className={styles.filterInput}
            />
          </Row>
        </Col>
      </div>
    </Col>
  );
}
