import React from 'react';
import CardText from './cardText';

function ContentCard({ filteredCardTitle, onClose }) {
  const filtered = filteredCardTitle.map(text => (
    <CardText onClose={onClose} title={text} />
  ));
  return <>{filtered}</>;
}
export default ContentCard;
