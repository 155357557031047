/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');

function ColumnCharts({ chartId, budgetData }) {
  useEffect(() => {
    const chart = am4core.create(`${chartId}`, am4charts.XYChart);
    chart.data = budgetData;

    // Create axis
    const provinceAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    provinceAxis.dataFields.category = 'yearstr';
    provinceAxis.renderer.labels.template.rotation = 90;
    provinceAxis.renderer.labels.template.verticalCenter = 'middle';
    provinceAxis.renderer.labels.template.horizontalCenter = 'left';
    provinceAxis.renderer.minGridDistance = 10;

    // Create Axis
    const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.title.text = '[bold font-size: 20] Дүн (төгрөгөөр)';

    // Create series
    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = 'budgetPerformance';
    series1.dataFields.categoryX = 'yearstr';
    series1.yAxis = valueAxis1;
    series1.name = 'Төсвийн гүйцэтгэл';
    series1.tooltipText = '{name} \n [bold font-size: 20] {valueY}[/]';
    series1.fill = chart.colors.getIndex(0);
    series1.strokeWidth = 0;
    series1.clustered = false;
    series1.columns.template.width = am4core.percent(40);

    const series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = 'yearBudget';
    series2.dataFields.categoryX = 'yearstr';
    series2.yAxis = valueAxis1;
    series2.name = 'Жилийн төсөв';
    series2.tooltipText = '{name}\n[bold font-size: 20] {valueY}[/]';
    series2.fill = chart.colors.getIndex(0).lighten(0.5);
    series2.strokeWidth = 0;
    series2.clustered = false;
    series2.toBack();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
  }, [budgetData]);

  return (
    <div
      id={chartId}
      style={{
        width: '100%',
        height: '500px',
        display: 'flex',
        overflow: 'auto',
        overflowY: 'hidden',
      }}
    />
  );
}
export default ColumnCharts;
