import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import InsuranceIndicators from './InsuranceIndicators/InsuranceIndicators';
import Calendar from './HealthInsuranceCalendar';
import { getReportBudgetDatas } from '../../service/reportServices';
import TreeMapUnited from '../components/TreeMapUnited/TreeMapUnited';
import Comment from '../components/Comment/Comment';
import { useAppStore } from '../../context/Tools';
import UrDungiinUzuulelt from '../components/urDungiinUzuulelt/UrDungiinUzuulelt';
import MetaInfo from '../../components/MetaInfo';
import Banner from '../united-budget/UnitedBudgetBanner';
import Cards from '../united-budget/UnitedBudgetCards';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import BannerTitle from '../../components/bannerTitle/BannerTitle';

export default function HealthInsurance() {
  const [searchParams] = useSearchParams();

  const year = searchParams.get('year');
  const month = searchParams.get('month');

  const { serverDate } = useAppStore();

  const cancelRef = useRef();

  const [budgetData, setBudgetData] = useState([]);
  const [typeOfExpenditure, setTypeOfExpenditure] = useState('eza');
  const [data, setData] = useState(false);

  const getTreeMapData = () => {
    const params = {
      typeOfExpenditure,
      refBudgetTypeId: 4,
      year: year || serverDate.getFullYear(),
      month: month || 1,
    };
    getReportBudgetDatas(params, cancelRef).then(result => {
      setBudgetData(result);
    });
  };

  useEffect(getTreeMapData, [year, month, typeOfExpenditure]);

  return (
    <>
      <MetaInfo title="Эрүүл мэндийн даатгалын сан - Шилэн данс" />
      <Banner
        title={<BannerTitle title="Эрүүл мэндийн даатгалын сангийн төсөв" />}
      />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <Cards />
          <Calendar LazyText="Эрүүл мэндийн даатгалын байгууллага нь эрүүл мэндийн даатгалын сангийн орлого, зарлагын тайлан тэнцлийг доор дурдсан хугацаанд улирал, жилээр нэгтгэн гаргаж, эрүүл мэндийн даатгалын асуудал эрхэлсэн төрийн захиргааны төв байгууллага, Үндэсний зөвлөлд тайлагнаж байна" />
          <UrDungiinUzuulelt
            budgetType={4}
            title="ЭРҮҮЛ МЭНДИЙН ДААТГАЛЫН ҮР ДҮНГИЙН ҮЗҮҮЛЭЛТ /жилээр/"
          />
          <TreeMapUnited
            setData={setData}
            data={data}
            setTypeOfExpenditure={setTypeOfExpenditure}
            typeOfExpenditure={typeOfExpenditure}
            budgetData={budgetData}
            Title="ЭРҮҮЛ МЭНДИЙН ДААТГАЛЫН ТӨСӨВ, ГҮЙЦЭТГЭЛ /сараар/"
            getData={getTreeMapData}
          />
          <InsuranceIndicators />
          <Comment />
        </Col>
      </Row>
    </>
  );
}
