import {
  Row,
  Col,
  Typography,
  Space,
  DatePicker,
  Radio,
  Skeleton,
  Image,
  Menu,
  Card,
  Dropdown,
  Button,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  BarChartOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  TableOutlined,
} from '@ant-design/icons';
import styles from './style.module.scss';
import emptyImg from '../../../img/emptyPage.svg';

import ColumnAxisCharts from '../../components/columnAxisCharts/ColumnAxisCharts';
import DonutChart3D from '../../components/donutChart3d/DonutChart3d';
import {
  DashboardOfBudgetGovernor,
  DashboardOfIsics,
  DashboardOfRefTypeGetService,
} from '../../../service/organizationServices';
import { YEARFORMAT } from '../../../constants/Constant';
import ContentTable from '../components/contentTable/ContentTable';
import { useAppStore } from '../../../context/Tools';

const { Text } = Typography;

export default function HeaderColumnChart() {
  const { serverDate } = useAppStore();

  const [typeRadio, setTypeRadio] = useState(1);
  const [stateOrgTypes, setStateOrgTypes] = useState([]);
  const [stateTezData, setStateTezData] = useState([]);
  const [stateIsicData, setStateIsicData] = useState([]);
  const [stateYearRange, setStateYearRange] = useState(
    serverDate.getFullYear()
  );
  const [stateLoadings, setStateLoadings] = useState(false);
  const [stateChartId, setStateChartId] = useState();
  const [stateAllCounts, setStateAllCounts] = useState();
  const [stataSwitches, setStateSwitches] = useState(true);
  const [stateTez, setStateTez] = useState(1);

  const onChangeDate = (e, year) => {
    setStateYearRange(year);
  };

  const onChangeType = e => {
    setTypeRadio(e.target.value);
  };

  const onChangeTez = e => {
    setStateTez(e.target.value);
  };

  useEffect(() => {
    setStateLoadings(false);
    let searchStr = null;
    if (typeRadio === 1) {
      if (stateYearRange) {
        searchStr = {
          year: stateYearRange,
        };
      }
      DashboardOfRefTypeGetService(searchStr).then(result => {
        if (result) {
          const data = result.orgDashboardOfUnitDTOS || [];
          data.forEach((item, index) => {
            item.index = index + 1;
          });
          setStateOrgTypes(result.orgDashboardOfUnitDTOS || []);
          setStateAllCounts(result.count);
          setStateChartId('donutChartIds');
          setStateLoadings(true);
        }
      });
    }
    if (typeRadio === 2) {
      if (stateYearRange) {
        searchStr = {
          year: stateYearRange,
          type: stateTez,
        };
      }
      DashboardOfBudgetGovernor(searchStr).then(result => {
        if (result) {
          const data = result.orgDashboardOfTezUnitDTOS || [];
          data.forEach((item, index) => {
            item.index = index + 1;
          });
          setStateTezData(result.orgDashboardOfTezUnitDTOS || []);
          setStateAllCounts(result.count);
          setStateChartId('tezChartIds');
          setStateLoadings(true);
        }
      });
    }
    if (typeRadio === 3) {
      if (stateYearRange) {
        searchStr = {
          year: stateYearRange,
        };
      }
      DashboardOfIsics(searchStr).then(result => {
        if (result) {
          const data = result.orgDashboardBranchUnitDTOS || [];
          data.forEach((item, index) => {
            item.index = index + 1;
          });
          setStateIsicData(result.orgDashboardBranchUnitDTOS || []);
          setStateAllCounts(result.count);
          setStateChartId('isicChartIds');
          setStateLoadings(true);
        }
      });
    }
  }, [stateYearRange, stateTez, typeRadio]);

  const columns = [
    {
      title: '№',
      dataIndex: 'index',
      width: 1,
    },
    {
      title: 'Байгууллагын төрөл',
      dataIndex: 'name',
      key: 'name',
      width: 15,
      render: text => (
        <div
          style={{
            textAlign: 'start',
          }}
        >
          <Text>{text}</Text>
        </div>
      ),
    },
    {
      title: 'Тоо',
      dataIndex: 'count',
      key: 'count',
      width: 3,
    },
  ];
  const columnsTez = [
    {
      title: '№',
      dataIndex: 'index',
      width: 3,
    },
    {
      title: 'Төсвийн ерөнхийлөн захирагч',
      dataIndex: 'name',
      key: 'name',
      width: 20,
      render: text => (
        <div
          style={{
            textAlign: 'start',
          }}
        >
          <Text>{text}</Text>
        </div>
      ),
    },
    {
      title: 'Харьяа байгууллага',
      dataIndex: 'ttzquantity',
      key: 'ttzquantity',
      width: 5,
    },
    {
      title: 'Салбар байгууллага',
      dataIndex: 'tshzquantity',
      key: 'tshzquantity',
      width: 5,
    },
    {
      title: 'Нийт',
      dataIndex: 'sumQuantity',
      key: 'sumQuantity',
      width: 5,
    },
  ];

  const columnSalbar = [
    {
      title: '№',
      dataIndex: 'index',
      width: 1,
    },
    {
      title: 'Салбар байгууллага',
      dataIndex: 'name',
      key: 'name',
      width: 20,
      render: text => (
        <div
          style={{
            textAlign: 'start',
          }}
        >
          <Text>{text}</Text>
        </div>
      ),
    },
    {
      title: 'Нийт',
      dataIndex: 'count',
      key: 'count',
      width: 5,
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FileImageOutlined />}>
        JPG
      </Menu.Item>
      <Menu.Item key="3" icon={<FilePdfOutlined />}>
        Excel
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="sub-title">Нийт байгууллагын тоо</div>
      <Card className={styles.headerCardRow}>
        <Row justify="space-between" className={styles.headerColumnChartSecond}>
          <Space>
            <Radio.Group onChange={onChangeType} value={typeRadio}>
              <Radio value={1}>Байгууллагын төрлөөр</Radio>
              <Radio value={2}>Төсвийн ерөнхийлөн захирагч</Radio>
              <Radio value={3}>Салбараар</Radio>
            </Radio.Group>
          </Space>
          <Space size="large">
            <Space>
              <Text>Огноо сонгох:</Text>
              <DatePicker
                picker="year"
                allowClear={false}
                value={
                  stateYearRange ? moment(stateYearRange, YEARFORMAT) : null
                }
                onChange={onChangeDate}
                disabledDate={current => current.isBefore(moment('2023-01-01'))}
              />
            </Space>
            <Space size="small" className={styles.switchIcon}>
              <Space size={15}>
                <TableOutlined onClick={() => setStateSwitches(false)} />
                <BarChartOutlined onClick={() => setStateSwitches(true)} />
              </Space>
              <Dropdown overlay={menu}>
                <Button type="text">
                  <DownloadOutlined className="icon" />
                </Button>
              </Dropdown>
            </Space>
          </Space>
        </Row>
        <Row justify="center" className={styles.headerColumnChart}>
          {typeRadio === 2 && (
            <Col span={18}>
              <Radio.Group
                onChange={onChangeTez}
                defaultValue={1}
                buttonStyle="solid"
              >
                <Radio.Button value={1}>Улсын ТЕЗ</Radio.Button>
                <Radio.Button value={2}>Орон нутгийн ТЕЗ</Radio.Button>
              </Radio.Group>
            </Col>
          )}
          <br />
          {stateAllCounts === 0 ? (
            <Image
              src={emptyImg}
              alt="empty"
              preview={false}
              width="100%"
              height={300}
            />
          ) : (
            <>
              {stateLoadings ? (
                <>
                  {typeRadio === 1 && (
                    <>
                      {stataSwitches ? (
                        <DonutChart3D
                          chartId={stateChartId}
                          chartData={stateOrgTypes}
                        />
                      ) : (
                        <Col span={18}>
                          <ContentTable
                            columns={columns}
                            data={stateOrgTypes}
                          />
                        </Col>
                      )}
                    </>
                  )}
                  {typeRadio === 2 && (
                    <>
                      {stataSwitches ? (
                        <ColumnAxisCharts
                          chartId={stateChartId}
                          chartData={stateTezData.filter(
                            data => data.sumQuantity > 0
                          )}
                          valueY="sumQuantity"
                          valueX="tshzquantity"
                          tooltipBoolen
                        />
                      ) : (
                        <Col span={18}>
                          <ContentTable
                            columns={columnsTez}
                            data={stateTezData}
                            numberOfElements={stateAllCounts}
                          />
                        </Col>
                      )}
                    </>
                  )}
                  {typeRadio === 3 && (
                    <>
                      {stataSwitches ? (
                        <ColumnAxisCharts
                          chartId={stateChartId}
                          chartData={stateIsicData.filter(
                            datas => datas.count > 0
                          )}
                          valueY="count"
                          tooltipBoolen={false}
                        />
                      ) : (
                        <Col span={18}>
                          <ContentTable
                            columns={columnSalbar}
                            data={stateIsicData}
                            numberOfElements={stateAllCounts}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Skeleton active />
                </>
              )}
            </>
          )}
        </Row>
      </Card>
      <br />
    </>
  );
}
