import React, { useState, useEffect } from 'react';
import {
  DatePicker,
  Row,
  Space,
  Typography,
  Tabs,
  Dropdown,
  Button,
  Menu,
  Card,
  Radio,
} from 'antd';
import {
  BarChartOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  TableOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Excel } from 'antd-table-saveas-excel';
import styles from './style.module.scss';
import DataTables from './DataTable';
import StackedBarCharts from './stackedBarChart';
import DataTablesSecond from './DataTables';
import StackedBarChartSecond from './StackedBarChartSecond';
import { getReportYearBureaus } from '../../../service/reportServices';
import { useAppStore } from '../../../context/Tools';
import { YEARFORMAT } from '../../../constants/Constant';
import { convertCurrencyFormat } from '../../../tools/Tools';

const { Text } = Typography;
const { TabPane } = Tabs;

const columnsTataas = [
  {
    title: '№',
    width: 70,
    key: 'index',
    render: (value, item, index) => index + 1,
  },
  {
    title: 'Орон нутаг',
    dataIndex: 'aimagName',
    key: 'aimagName',
  },
  {
    title: 'Санхүүгийн дэмжлэг Төлөвлөгөө /сая төгрөг/',
    dataIndex: 'sdBudget',
    key: 'sdBudget',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
  {
    title: 'Тусгай зориулалтын шилжүүлэг Төлөвлөгөө /сая төгрөг/',
    dataIndex: 'tzdBudget',
    key: 'tzdBudget',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
  {
    title: 'Орон нутгийн хөгжлийн сан Төлөвлөгөө /сая төгрөг/',
    dataIndex: 'onhBudget',
    key: 'onhBudget',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
];

const columnsTataasPerformance = [
  {
    title: '№',
    width: 70,
    key: 'index',
    render: (value, item, index) => index + 1,
  },
  { title: 'Орон нутаг', dataIndex: 'aimagName', key: 'aimagName' },
  {
    title: 'Санхүүгийн дэмжлэг Гүйцэтгэл /сая төгрөг/',
    dataIndex: 'sdPerf',
    key: 'sdPerf',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
  {
    title: 'Тусгай зориулалтын шилжүүлэг Гүйцэтгэл /сая төгрөг/',
    dataIndex: 'tzdPerf',
    key: 'tzdPerf',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
  {
    title: 'Орон нутгийн хөгжлийн сан Гүйцэтгэл /сая төгрөг/',
    dataIndex: 'onhPerf',
    key: 'onhPerf',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
];

const columnsIncome = [
  {
    title: '№',
    width: 70,
    key: 'index',
    render: (value, item, index) => index + 1,
  },
  { title: 'Үзүүлэлт', dataIndex: 'aimagName', key: 'aimagName' },
  {
    title: 'Төсөв /сая төгрөг/',
    dataIndex: 'budget',
    key: 'budget',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
  {
    title: 'Гүйцэтгэл /сая төгрөг/',
    dataIndex: 'performance',
    key: 'performance',
    align: 'right',
    render: value => convertCurrencyFormat(value),
    excelRender: value => value,
  },
  {
    title: 'Гүйцэтгэлийн хувь',
    dataIndex: 'percent',
    key: 'percent',
    align: 'center',
  },
];

export default function StateIncome() {
  const { serverDate } = useAppStore();

  const [showByTable, setShowByTable] = useState(false);
  const [budgetData, setBudgetData] = useState([]);
  const [year, setYear] = useState(serverDate.getFullYear());
  const [typeId, setTypeId] = useState('1');
  const [valueRadio, setValueRadio] = useState('budget');
  const [cols, setCols] = useState(columnsTataas);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const param = {
      search: { status: true, year },
      size: 1000,
    };
    setLoading(true);
    getReportYearBureaus(param)
      .then(result => {
        const data = result.content;
        setBudgetData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [year]);

  useEffect(() => {
    if (valueRadio === 'budget') {
      setCols(columnsTataas);
    }
    if (valueRadio === 'performance') {
      setCols(columnsTataasPerformance);
    }
  }, [valueRadio]);

  const addProperties = data => {
    data.forEach(item => {
      const aname = item.aimagName;
      item.sdPerf = budgetData.find(
        el =>
          el.refTransactionTypeName === 'Санхүүгийн дэмжлэг' &&
          el.aimagName === aname
      ).performance;
      item.sdBudget = budgetData.find(
        el =>
          el.refTransactionTypeName === 'Санхүүгийн дэмжлэг' &&
          el.aimagName === aname
      ).budget;
      item.tzdPerf = budgetData.find(
        el =>
          el.refTransactionTypeName === 'Тусгай зориулалтын дэмжлэг' &&
          el.aimagName === aname
      ).performance;
      item.tzdBudget = budgetData.find(
        el =>
          el.refTransactionTypeName === 'Тусгай зориулалтын дэмжлэг' &&
          el.aimagName === aname
      ).budget;
      item.onhPerf = budgetData.find(
        el =>
          el.refTransactionTypeName ===
            'ОН-гийн хөгжлийн нэгдсэн сангийн орлогын шилжүүлэг' &&
          el.aimagName === aname
      ).performance;
      item.onhBudget = budgetData.find(
        el =>
          el.refTransactionTypeName ===
            'ОН-гийн хөгжлийн нэгдсэн сангийн орлогын шилжүүлэг' &&
          el.aimagName === aname
      ).budget;
    });
  };
  addProperties(budgetData);

  const filteredSubsidy = budgetData.filter(
    item => item.refTransactionTypeName === 'Зарлага'
  );
  filteredSubsidy.sort((a, b) => (a.sdBudget > b.sdBudget ? -1 : 1));

  const filteredIncome = budgetData.filter(
    item =>
      item.refTransactionTypeName === 'Улсын төсөвт төвлөрүүлэх' &&
      item.budget > 0
  );
  filteredIncome.sort((a, b) => (a.budget > b.budget ? -1 : 1));

  const onChangeDatePicker = (z, e) => {
    setYear(Number(e));
  };

  const exportExcel = () => {
    let excelCols = [];
    let excelData = [];
    let fileName = 'Төсөв';

    if (typeId === '1') {
      excelCols =
        valueRadio === 'budget' ? columnsTataas : columnsTataasPerformance;
      excelData = filteredSubsidy;
      fileName = 'Улсын төсвөөс олгож буй татаас шилжүүлэг';
    } else if (typeId === '2') {
      excelCols = columnsIncome;
      excelData = filteredIncome;
      fileName = 'Улсын төсөвт орлого төвлөрүүлэгч аймгууд';
    }
    const excel = new Excel();
    excel
      .addSheet('sheet1')
      .addColumns(excelCols)
      .addDataSource(excelData)
      .setTHeadStyle({ fontName: 'Arial' })
      .setTBodyStyle({ fontName: 'Arial' })
      .saveAs(`${fileName} - ${year}.xlsx`);
  };

  const menu = (
    <Menu>
      <Menu.Item key="excel" icon={<FilePdfOutlined />} onClick={exportExcel}>
        Excel
      </Menu.Item>
    </Menu>
  );

  return (
    <Card style={{ marginTop: 60 }}>
      <Row justify="space-between">
        <Space>
          <Text>Огноо сонгох:</Text>
          <DatePicker
            placeholder="Он сонгох"
            picker="year"
            defaultValue={year ? moment(year, YEARFORMAT) : null}
            onChange={onChangeDatePicker}
            bordered={false}
            disabledDate={current => current.isBefore(moment('2023-01-01'))}
          />
        </Space>
        <Space>
          <Text>Сонгох:</Text>
          <Radio.Group
            onChange={e => {
              setValueRadio(e.target.value);
            }}
            defaultValue="budget"
          >
            <Radio defaultChecked value="budget">
              Төлөвлөгөө
            </Radio>
            <Radio value="performance">Гүйцэтгэл</Radio>
          </Radio.Group>
        </Space>
        <Space size={16}>
          <Space size={19}>
            <TableOutlined
              onClick={() => setShowByTable(true)}
              className={styles.localIncomeToolsIcon}
            />
            <BarChartOutlined
              onClick={() => setShowByTable(false)}
              className={styles.localIncomeToolsIcon}
            />
          </Space>
          <Space className={styles.downloadText}>
            <Dropdown overlay={menu}>
              <Button type="text">
                <DownloadOutlined className="icon" />
              </Button>
            </Dropdown>
          </Space>
        </Space>
      </Row>
      <div className={styles.TabsCol}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          onChange={key => {
            setTypeId(key);
          }}
        >
          <TabPane tab="Улсын төсвөөс олгож буй татаас шилжүүлэг" key="1">
            <>
              {showByTable ? (
                <DataTables
                  budgetData={filteredSubsidy}
                  column={cols}
                  valueRadio={valueRadio}
                  loading={loading}
                />
              ) : (
                <StackedBarCharts
                  id="tataas"
                  budgetData={filteredSubsidy}
                  valueRadio={valueRadio}
                />
              )}
            </>
          </TabPane>
          <TabPane tab="Улсын төсөвт орлого төвлөрүүлэгч аймгууд" key="2">
            <>
              {showByTable ? (
                <DataTablesSecond
                  column={columnsIncome}
                  budgetData={filteredIncome}
                  loading={loading}
                />
              ) : (
                <StackedBarChartSecond
                  id="orlogo"
                  budgetData={filteredIncome}
                  valueRadio={valueRadio}
                />
              )}
            </>
          </TabPane>
        </Tabs>
        <br />
        <Text strong className={styles.localIncomeTableFooterText}>
          Тусгай зориулалтын сангийн шилжүүлэг 7,231.4 тэрбум төгрөг буюу ДНБ-ий
          23.8%, Санхүү дэмжлэг авдаг 9,651.5 тэрбум төгрөг буюу ДНБ-ий 31.8%,
          Орон нутгийн хөгжлийн сан /тэрбум төгрөг/ 2,420.1 тэрбум төгрөг.
        </Text>
      </div>
    </Card>
  );
}
