import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import Banner from './UnitedBudgetBanner';
import Cards from './UnitedBudgetCards';
import Calendar from './UnitedBudgetCalendar';
import ResultIndicators from './resultIndicators/resultIndicators';
import TreeMapUnited from '../components/TreeMapUnited/TreeMapUnited';
import Comment from '../components/Comment/Comment';
import {
  getBudgetPlanPerformanceCode,
  getReportBudgetDatas,
} from '../../service/reportServices';
import {
  chartId,
  chartData,
  seriesOne,
  seriesTwo,
  seriesThree,
} from './mock-data/Data';
import { useAppStore } from '../../context/Tools';
import { getParamValue, numberBolgogch } from '../../tools/utils';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import BannerTitle from '../../components/bannerTitle/BannerTitle';

export default function UnitedBudget() {
  const location = useLocation();

  const { serverDate } = useAppStore();

  const [budgetData, setBudgetData] = useState([]);
  const [tezData, setTezData] = useState();
  const [typeOfExpenditure, setTypeOfExpenditure] = useState('eza');
  const [data, setData] = useState(false);

  const getTreeMapData = async () => {
    if (typeOfExpenditure === 'tez' && data === 'cost') {
      setTezData();
      const params = {
        typeBy: getParamValue(location, 'type') || 1,
        year: getParamValue(location, 'year') || serverDate.getFullYear(),
        month: getParamValue(location, 'month') || 1,
      };
      try {
        const result = await getBudgetPlanPerformanceCode(params);
        if (!result) return;
        const treeData = [
          {
            name: 'Улсын ТЕЗ',
            value: result.ulsTezValue,
            children: (result.ulsTEZ || []).map(tez => ({
              code: tez.tezCode,
              name: tez.tezName,
              value: tez.tezValue,
              children: (tez.hutulbur || []).map(hutulbur => ({
                name: hutulbur.hutulburName,
                value: hutulbur.hutulburValue,
                children: hutulbur.zoriulalt,
              })),
            })),
          },
          {
            name: 'Орон нутгийн ТЕЗ',
            value: result.onTezValue,
            children: (result.onTEZ || []).map(tez => ({
              code: tez.tezCode,
              name: tez.tezName,
              value: tez.tezValue,
              children: (tez.hutulbur || []).map(hutulbur => ({
                name: hutulbur.hutulburName,
                value: hutulbur.hutulburValue,
                children: hutulbur.zoriulalt,
              })),
            })),
          },
        ];
        numberBolgogch(treeData);
        setTezData(treeData);
      } catch {
        setTezData([]);
      }
    } else {
      const params = {
        typeOfExpenditure,
        refBudgetTypeId: 1,
        year: getParamValue(location, 'year') || serverDate.getFullYear(),
        month: getParamValue(location, 'month') || 1,
      };
      getReportBudgetDatas(params).then(result => {
        setBudgetData(result);
      });
    }
    // setBudgetData(testDataTree);
  };

  useEffect(getTreeMapData, [location, typeOfExpenditure]);

  return (
    <>
      <MetaInfo title="Нэгдсэн төсөв - Шилэн данс" />
      <Banner
        title={
          <BannerTitle
            title="Монгол улсын нэгдсэн төсөв"
            contentText={`Монгол Улсын нэгдсэн төсөв нь улсын төсөв, орон нутгийн төсөв,
              нийгмийн даатгалын сангийн төсөв, эрүүл мэндийн даатгалын сангийн
              төсөв, ирээдүй өв сангуудаас бүрдэнэ. Улсын Их Хурлаас төсөв,
              нийгмийн даатгалын сангийн төсөв, эрүүл мэндийн даатгалын сангийн
              төсвийг баталдаг бол орон нутгийн төсвийг тухайн шатны иргэдийн
              Төлөөлөгчдийн Хурлаас баталдаг.`}
          />
        }
      />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <Cards />
          <Calendar table="true" />
          <ResultIndicators
            chartId={chartId}
            chartData={chartData}
            seriesOne={seriesOne}
            seriesTwo={seriesTwo}
            seriesThree={seriesThree}
          />
          <TreeMapUnited
            setData={setData}
            data={data}
            setTypeOfExpenditure={setTypeOfExpenditure}
            typeOfExpenditure={typeOfExpenditure}
            budgetData={budgetData}
            tezData={tezData}
            Title="Монгол улсын нэгдсэн төсөв, гүйцэтгэл /сар/"
          />
          <Comment />
        </Col>
      </Row>
    </>
  );
}
