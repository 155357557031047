import React, { useState, useEffect } from 'react';
import {
  List,
  Card,
  Row,
  Col,
  Typography,
  Space,
  Button,
  Image,
  Select,
} from 'antd';
import { CarryOutFilled, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styles from './style.module.scss';
import PdfViewerModal from '../component/pdfModal.js/Modal';
import { DATEFORMAT, SORT_ORDER } from '../../../constants/Constant';
import { Fallback } from '../../../img/FailImg';
import { getTrainingResearchManuals } from '../../../service/trainingService';
import { getImgSrc } from '../../../tools/Tools';

const { Text } = Typography;
const { Option } = Select;

const params = {
  search: { status: true, isActive: true },
  sortField: 'createdAt',
  sortOrder: SORT_ORDER.DESC,
};

let dataLists = [];

function BudgetFinance() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState(dataLists);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [Uuid, setUuid] = useState('');
  const [stateSearchInput, setSearchInput] = useState();
  const [seletData, setSeletData] = useState([]);

  const searchOnChange = e => {
    setSearchInput(e);
  };

  const createServer = () => {
    setLoading(true);

    getTrainingResearchManuals(params)
      .then(data => {
        let datas = [];
        const dataList = data.content || [];
        dataList.forEach((item, index) => {
          item.index = data.totalPages + index;
        });
        setSeletData(dataList);
        if (stateSearchInput) {
          datas = dataList.filter(entry => entry.name === stateSearchInput);
          setDataSource(datas);
        } else {
          setDataSource(dataList);
        }
        dataLists = dataList;
        setLoading(false);
        setTotalRecords(data.totalElements);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    createServer();
  }, [stateSearchInput]);

  const convertLanguage = language => {
    switch (language) {
      case 1:
        return <Text>MN</Text>;
      case 2:
        return <Text>EN</Text>;
      default:
        return language;
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const stateBodyTemplate = selectData => convertLanguage(selectData);

  const stateBodyFileDots = selectData => (
    <Text type="link" className={styles.colPdfStyling}>
      {`${!isEmpty(selectData) && selectData.map(z => z.name)}.PDF`}
    </Text>
  );

  const stateBodyImageTemplate = selecedData => (
    <Image src={getImgSrc(selecedData.coverUuid)} fallback={Fallback} />
  );

  const itemSpec = (label, value) => (
    <div style={{ display: 'flex', textAlignLast: 'left', marginBottom: 8 }}>
      <Text className={styles.budgetFinanceText} strong>
        {label}
      </Text>
      <Text>{value}</Text>
    </div>
  );

  const onChange = uuids => {
    setUuid(uuids);
    showModal();
  };

  return (
    <>
      <Row className={styles.researchRow}>
        <Space align="start">
          <CarryOutFilled className={styles.breadIcon} />
          <Text strong className={styles.breadText}>
            Суралцахуй
          </Text>
          <RightOutlined className={styles.breadIcons} />
          <Text strong className={styles.breadText}>
            Судалгаа, тайлан
          </Text>
          <RightOutlined className={styles.breadIcons} />
          <Text strong className={styles.breadText}>
            Ном, гарын авлага, судалгааны тайлан
          </Text>
        </Space>
      </Row>
      <Row className={styles.budgetFinanceSearch}>
        <Space>
          <Text strong className={styles.budgetFinanceText}>
            Хайлт хийх:
          </Text>
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            placeholder="Ном хайх"
            optionFilterProp="children"
            onChange={searchOnChange}
          >
            {seletData.map((item, index) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Space>
      </Row>
      <Row>
        <div className={styles.budgetFinanceHeader}>
          <Text strong className={styles.budgetFinanceHeadeText}>
            Төсөв санхүүгийн бодлогын баримт бичиг
          </Text>
        </div>
      </Row>
      <div className={styles.footerSettings}>
        <List
          loading={loading}
          grid={{ gutter: 16, column: 1 }}
          dataSource={dataSource}
          footer={`Нийт: ${totalRecords}`}
          pagination={{
            pageSize: 5,
          }}
          renderItem={item => (
            <List.Item>
              <Card>
                <Row justify="space-between" gutter={[8, 10]}>
                  <Col style={{ alignSelf: 'center' }}>
                    <Text>{item.title}</Text>
                  </Col>
                  <Col
                    className={styles.budgetFinanceImage}
                    xs={21}
                    sm={21}
                    md={21}
                    lg={21}
                    xl={5}
                  >
                    {stateBodyImageTemplate(item)}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={17}>
                    {itemSpec('Баримт бичгийн нэр:', item.name)}
                    {itemSpec('Боловсруулсан:', item.processedBy)}
                    {itemSpec(
                      'Боловсруулсан огноо:',
                      moment(item.approvedAt).format(DATEFORMAT)
                    )}
                    {itemSpec('Захиалагч:', item.customerBy)}
                    {itemSpec('Санхүүжүүлэгч:', item.financeBy)}
                    {itemSpec('Хэл:', stateBodyTemplate(item.language))}
                    {itemSpec(
                      'Файл хавсралт:',
                      stateBodyFileDots(item.fileDTOS)
                    )}
                  </Col>
                  <Col span={24} style={{ textAlignLast: 'right' }}>
                    <Space>
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          onChange(
                            !isEmpty(item.fileDTOS) && item.fileDTOS[0].uuid
                          );
                        }}
                        block
                      >
                        Үзэх
                      </Button>
                      <Button
                        type="primary"
                        block
                        onClick={e => {
                          e.stopPropagation();
                          onChange(item.fileDTOS[0].uuid);
                        }}
                      >
                        Татаж авах
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </div>
      <PdfViewerModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        headerText="Төсөв санхүүгийн бодлогын баримт бичиг"
        pdfUuid={Uuid}
      />
    </>
  );
}
export default BudgetFinance;
