export const EVERY_TIME = 'Тухай бүр';

export const COL_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const FORMAT = {
  TEXT: 1,
  NUMBER: 2,
  DATE: 3,
  FILE: 4,
  CURRENCY: 5,
  PERCENT: 6,
  LINK: 7,
  SELECT: 8,
  FILE_EXCEL: 9,
  IS_CODE: 100,
  IS_INTEGRATION_CODE: 101,
};

export const REPORT_STATUS = {
  SUCCESS: 1, // Мэдээлэл хугацаандаа оруулсан
  NOT_REQUIRE: 2, // Мэдээлэл хугацаандаа оруулсан /оруулах мэдээлэл байхгүй/
  EXPIRED: 3, // Хугацаа хоцорсон мэдээлэл
  NOT_PUBLISH: 4, // Ил тод болгоогүй /Мэдээлэл оруулж байгаа/
  NOT_SUCCESS: 5, // Мэдээлэл оруулаагүй
  ACTIVE: 6, // Мэдээлэл оруулах хугацаа болсон
  NOT_ACTIVE: 7, // Мэдээлэл оруулах хугацаа болоогүй
  UPDATED_SUCCESS: 8, // Ил тод болгосон /араас нь хугацаандаа зассан/
  NOT_REQUIRE_EXPIRED: 9, // Мэдээлэл хоцорч оруулсан /оруулах мэдээлэл байхгүй/
  EMPTY: 10, // хоосон
  UPDATED_EXPIRED: 11, // Ил тод болгосон /араас нь хоцорч зассан/
};

export const BUDGET_INDICATORS = [
  { id: 1, name: 'Нийт орлого ба тусламжийн дүн' },
  { id: 2, name: 'Нийт зарлага ба цэвэр зээлийн дүн' },
  { id: 3, name: 'Тэнцвэржүүлсэн нийт тэнцэл' },
];

export const STATIC_REPORT = {
  name: 'Статик тайлан',
  children: [
    {
      id: 'reportByRefType',
      name: 'Байгууллагын төрлийн тайлан',
      isStaticReport: true,
      ownFilter: true,
      fields: [
        { name: 'name', mon: 'Байгууллагын төрөл', isRows: true },
        {
          name: 'column',
          mon: 'Багана',
          isCols: true,
          aggrFields: [{ name: 'count', mon: 'Байгууллагын тоо' }],
        },
      ],
    },
    /// getStaticRecurring
    {
      id: 'getRepFormGroupRecs',
      name: 'Хугацааны давтамжийн тайлан',
      isStaticReport: true,
      ownFilter: true,
      fields: [
        { name: 'name', mon: 'Мэдээллийн төрөл', isRows: true },
        {
          name: 'column',
          mon: 'Багана',
          isCols: true,
          aggrFields: [
            { name: 'total', mon: 'Нийт мэдээллэх' },
            { name: 'reported', mon: 'Мэдээллэсэн' },
            { name: 'onTime', mon: 'Хугацаандаа мэдээллэсэн' },
            { name: 'expired', mon: 'Хугацаа хоцорсон' },
            { name: 'percent', mon: 'Хэрэгжилтийн хувь' },
          ],
        },
      ],
    },
    /// getStaticTEZ
    {
      id: 'getRepFormsByRefTypeTTZ',
      name: 'Төсвийн ерөнхийлөн захирагчийн тайлан',
      isStaticReport: true,
      ownFilter: true,
      fields: [
        { name: 'refTypeName', mon: 'ТЕЗ нэр', isRows: true },
        {
          name: 'column',
          mon: 'Багана',
          isCols: true,
          aggrFields: [
            { name: 'subOrgCounts', mon: 'Харьяа байгууллагын тоо' },
            {
              name: 'reportRecurringCount',
              mon: 'Мэдээлэл оруулах давтамжийн тоо /1 байгууллагад дунджаар/',
            },
            {
              name: 'reportedRecurringCount',
              mon: 'Мэдээлэл оруулсан давтамжийн тоо /1 байгууллагад  дунджаар/',
            },
            { name: 'onTime', mon: 'Хугацаандаа мэдээллэсэн' },
            { name: 'expired', mon: 'Хугацаа хоцорсон' },
            { name: 'performancePercent', mon: 'Хэрэгжилтийн хувь' },
          ],
        },
      ],
    },
    /// getStaticForm
    {
      id: 'getRepForms',
      name: 'Мэдээллийн төрлийн тайлан',
      isStaticReport: true,
      ownFilter: true,
      fields: [
        { name: 'name', mon: 'Мэдээллийн төрөл', isRows: true },
        // { name: 'orgName', mon: 'Байгууллагын нэр' },
        {
          name: 'column',
          mon: 'Багана',
          isCols: true,
          aggrFields: [
            { name: 'total', mon: 'Нийт мэдээллэх' },
            { name: 'reported', mon: 'Мэдээллэсэн' },
            { name: 'onTime', mon: 'Хугацаандаа мэдээллэсэн' },
            { name: 'expired', mon: 'Хугацаа хоцорсон' },
            { name: 'percent', mon: 'Хэрэгжилтийн хувь' },
          ],
        },
      ],
    },
    // {
    //   id: 'getOrgByRefOrgType',
    //   name: 'Байгууллагын үзүүлэлтийн тайлан',
    //   isStaticReport: true,
    //   ownFilter: true,
    //   fields: [
    //     { name: 'refOrgTypeName', mon: 'Байгууллагын төрөл', isRows: true },
    //     { name: 'orgName', mon: 'Байгууллагын нэр' },
    //     {
    //       name: 'column',
    //       mon: 'Багана',
    //       isCols: true,
    //       aggrFields: [
    //         { name: 'orgQuantity', mon: 'Нийт' },
    //         { name: 'userQuantity', mon: 'Хэрэглэгчийн тоо' },
    //         { name: 'orgBankAccountQuantity', mon: 'Дансны тоо' },
    //         { name: 'orgBranchQuantity', mon: 'Салбар байгууллагын тоо' },
    //         { name: 'orgSubQuantity', mon: 'Харьяа байгууллагын тоо' },
    //       ],
    //     },
    //   ],
    // },
    /// getOrganizationReport
    {
      id: 'getReportControlStaticReport',
      name: 'Мэдээллийн хяналтын тайлан',
      isStaticReport: true,
      ownFilter: false,
      fields: [
        {
          name: 'refTypeName',
          mon: 'Төсвийн ерөнхийлөн захирагч',
          isRows: true,
        },
        {
          name: 'refCentralBudgetGovernorName',
          mon: 'Төсвийн төвлөрүүлэн захирагч',
          isRows: true,
        },
        { name: 'refOrgTypeName', mon: 'Байгууллагын төрөл', isRows: true },
        { name: 'parentName', mon: 'Толгой байгууллага', isRows: true },
        { name: 'orgName', mon: 'Байгууллагын нэр', isRows: true },
        {
          name: 'refIsicClassCode',
          mon: 'Үйл ажиллагааны чиглэлийн код',
          isRows: true,
        },
        {
          name: 'refIsicGroupName',
          mon: 'Үйл ажиллагааны чиглэл',
          isRows: true,
        },
        { name: 'reportFormName', mon: 'Мэдээллийн төрөл', isRows: true },
        {
          name: 'column',
          mon: 'Багана',
          isCols: true,
          aggrFields: [
            { name: 'totalTo', mon: 'Мэдээлэл оруулах тоо' },
            { name: 'reported', mon: 'Мэдээлэл оруулсан тоо' },
            { name: 'onTime', mon: 'Хугацаандаа' },
            { name: 'expired', mon: 'Хоцорсон' },
            { name: 'performancePercent', mon: 'Гүйцэтгэл' },
          ],
        },
      ],
    },
  ],
};

