import React, { useRef } from 'react';
import { Carousel } from 'antd';
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai';
import styles from './style.module.scss';
import Title1 from './OpenDataTitle1';
import Title2 from './OpenDataTitle2';
import Title3 from './OpenDataTitle3';

export default function Banner() {
  const carousel = useRef();
  const nextPage = () => {
    carousel.current.next();
  };
  const previous = () => {
    carousel.current.prev();
  };

  return (
    <div className={styles.bannerContainer}>
      <AiOutlineLeftCircle
        className="carousel-left"
        onClick={previous}
        style={{ color: 'blue' }}
      />
      <Carousel
        dots
        ref={ref => {
          carousel.current = ref;
        }}
        autoplay
      >
        <Title1 />
        <Title2 />
        <Title3 />
      </Carousel>
      <AiOutlineRightCircle
        className="carousel-right"
        onClick={nextPage}
        style={{ color: 'blue' }}
      />
    </div>
  );
}

