import { AutoComplete, Button, Col, Input, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  getBanners,
  getOrganizationFilter,
} from '../../../service/portalService';
import { ORGANIZATION_PAGE } from '../../../settings/constantRoutes';
import { getImgSrc } from '../../../tools/Tools';

export default function SearchSection() {
  const [searchedOrgs, setSearchedOrgs] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [bannerData, setBannerData] = useState([]);
  const cancelRef = useRef();
  const navigate = useNavigate();

  const selectOrg = value => {
    const selectedO = searchedOrgs.find(orgdata => orgdata.id === value);
    setSearchValue(selectedO?.name);
    window.open(`${ORGANIZATION_PAGE}/${value}`);
  };

  const onSearch = async value => {
    const body = {
      isActive: 1,
      name: value,
    };
    const params = {
      page: 0,
      size: 8,
    };
    setIsLoading(true);

    getOrganizationFilter(body, cancelRef, params)
      .then(result => {
        if (result?.content) {
          const content = result?.content;
          const data = content.map(orgData => ({
            id: orgData.id,
            value: orgData.id,
            label: orgData.name,
            name: orgData.name,
          }));
          setSearchedOrgs(data);
        }
        setIsLoading(false);
      })
      .catch(err => err?.toString() !== 'Cancel' && setIsLoading(false));
  };

  const onChange = value => {
    setSearchValue(value);
    onSearch(value);
  };

  const getBann = param =>
    getBanners(param).then(data => {
      const bann = data.content || [];
      if (bann.length > 0) {
        setBannerData(bann);
      }
    });

  useEffect(() => {
    if (!bannerData.length) {
      const params = {
        search: {
          status: true,
          isActive: true,
          'portalMenu.id': 21,
        },
        sortField: 'priority',
        // sortOrder: SORT_ORDER.DESC,
      };
      getBann(params);
    }
  }, []);

  return (
    <div
      style={{
        height: '340px',
        width: '100%',
        backgroundImage: `url(${getImgSrc(
          bannerData.length > 0
            ? bannerData[0]?.imgUuid
            : `5e7dd5e3-4487-43f8-9c59-312fb54dae73`
        )})`,
        backgroundSize: 'cover',
      }}
    >
      <Row justify="center" style={{ paddingTop: '235px' }}>
        <Col span={19}>
          <Row gutter={[0, 10]} justify="space-around">
            <Col xs={24} md={14} lg={18}>
              <AutoComplete
                size="large"
                style={{
                  width: '100%',
                }}
                options={searchedOrgs}
                value={searchValue}
                notFoundContent={
                  isLoading ? <LoadingOutlined /> : 'Өгөгдөл олдсонгүй'
                }
                onChange={onChange}
                onSelect={selectOrg}
              >
                <Input
                  size="large"
                  placeholder="Байгууллага хайх"
                  style={{ borderRadius: '12px', height: '45px' }}
                />
              </AutoComplete>
            </Col>
            <Col xs={24} md={8} lg={4}>
              <Button
                size="large"
                type="secondary"
                style={{ marginTop: 3, borderRadius: '10px', width: '100%' }}
                onClick={() => navigate('/organization')}
              >
                Дэлгэрэнгүй хайлт
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
