import { message } from 'antd';
import { isEmpty, uniq } from 'lodash';
import { SORT_ORDER } from '../constants/Constant';
import { COL_ALPHABET } from '../constants/ReportConstants';
import { sortArray } from '../tools/Tools';
import { getRequest, postService } from './Service';

const serviceBaseUrl = '/gap-report-service';
const serviceSupplier = '/gap-integration-service';
const serviceReference = '/gap-reference-service';

export const serviceReportBudgetDatas = `${serviceBaseUrl}/reportBudget/findByTypeOfExpenditureAndRefBudgetTypeId`;
export const serviceReportYearBureaus = `${serviceBaseUrl}/reportYearBureaus`;
export const serviceReportBudgetFiles = `${serviceBaseUrl}/reportBudgetFiles`;
export const serviceReportFormTypes = `${serviceBaseUrl}/reportFormTypes`;
export const serviceReportForms = `${serviceBaseUrl}/reportForms`;
const serviceGetByOrgIdForDecision = `${serviceReportForms}/getByOrgIdForDecision`;
export const serviceReportGroups = `${serviceBaseUrl}/reportGroups`;
export const serviceReportFormSpecifications = `${serviceBaseUrl}/reportFormSpecifications/getByReportFormId`;
export const serviceGetRadarInfoByRefOrgTypeId = `${serviceBaseUrl}/reportStatics/getRadarInfoByRefOrgTypeId`;
export const serviceGetRadarInfoByTEZ = `${serviceBaseUrl}/coreStaticReports/getRadarInfo`;

const serviceReportStatic = `${serviceBaseUrl}/reportStatics`;
const serviceReportFormDatas = `${serviceBaseUrl}/reportFormDatas`;
const serviceReportFormPublishHistory = `${serviceReportFormDatas}/publishHistory`;
const serviceOpenData = `${serviceReportFormDatas}/openData1`;
const serviceOpenDataExport = `${serviceReportFormDatas}/exportOpenData`;
const serviceDistinctByYear = `${serviceReportFormDatas}/distinctByYear`;
const serviceReportFormHeaders = `${serviceBaseUrl}/reportFormHeaders`;

const serviceReportFormOrganizationRecurrings = `${serviceBaseUrl}/reportFormOrganizationRecurrings`;
const serviceReportByUser = `${serviceReportFormOrganizationRecurrings}/getByTokenForPortal`;
// const serviceReportByUser = `${serviceReportFormOrganizationRecurrings}/getByTokenNew`;

/* Бэлтгэн нийлүүлэгч service */
export const serviceGetSupplierByEconomyCat = `${serviceSupplier}/integrationECopyCredits/getEZAListByDateBetween`;
export const serviceGetSupplierByEconomyTop = `${serviceSupplier}/integrationECopyCredits/getTopTen`;
export const serviceGetSupplierByDetail = `${serviceSupplier}/integrationECopyCredits/getBeltgenNiiluulegch`;
export const serviceGetSupplierByDetailOne = `${serviceSupplier}/integrationECopyCredits/getSupplierOne`;
export const serviceGetSupplierByDetailTwo = `${serviceSupplier}/integrationECopyCredits/getSupplierTwo`;
export const serviceGetReference = `${serviceReference}/refOrgLegalStatuses`;
export const serviceGetReferenceEza = `${serviceSupplier}/integrationECopyCredits/getEzaList`;
export const serviceGetReferenceEzaByDate = `${serviceSupplier}/integrationECopyCredits/eza`;
const serviceIntegrationTypes = `${serviceSupplier}/integrationTypes`;

const serviceBudgetPlanPerformance = `${serviceBaseUrl}/reportStatics/getBudgetPlanPerformance`;
const serviceBudgetPerformanceIndicatorByYear = `${serviceBaseUrl}/reportBudgetPerformanceIndicatorByYear`;
const serviceOrgReportCounter = `${serviceReportStatic}/getCounterPortal`;
const serviceRepFormGroupRecs = `${serviceReportStatic}/getRepFormGroupRecs`;
const serviceRepForms = `${serviceReportStatic}/getRepForms`;
const serviceOrgByRefOrgType = `${serviceReportStatic}/getOrgByRefOrgType`;
const serviceBudgetPlanPerformanceCode = `${serviceReportStatic}/getBudgetPlanPerformanceCode`;

const serviceCoreStaticReports = `${serviceBaseUrl}/coreStaticReports`;
const serviceReportControlStaticReport = `${serviceCoreStaticReports}/getSixthReport`;
const serviceOrganizationReport = `${serviceCoreStaticReports}/getOrganizationReport`;
const serviceRepFormsByRefTypeTTZ = `${serviceCoreStaticReports}/getTEZreport`;
const serviceStaticRecurring = `${serviceCoreStaticReports}/getStaticRecurring`;
const serviceStaticTEZ = `${serviceCoreStaticReports}/getStaticTEZ`;
const serviceStaticForm = `${serviceCoreStaticReports}/getStaticForm`;

export const getReportBudgetDatas = async (params, cancelRef) =>
  await getRequest(serviceReportBudgetDatas, params, cancelRef);

export const getReportYearBureaus = async params =>
  await getRequest(serviceReportYearBureaus, params);

export const getReportBudgetFiles = async params =>
  await getRequest(serviceReportBudgetFiles, params);

export const getReportFormTypes = async params => {
  const parameters = params || {};
  parameters.search = { ...parameters.search, status: true, isActive: true };
  parameters.sortField = 'priority';
  parameters.sortOrder = SORT_ORDER.ASC;
  const result = await getRequest(serviceReportFormTypes, parameters);
  const formTypes = result.content || [];
  return formTypes;
};

export const getReportForms = async params => {
  const parameters = params || {};
  parameters.search = {
    ...parameters.search,
    status: true,
    isActive: true,
    isOpenData: true,
  };
  parameters.size = 500;
  parameters.sortField = 'name';
  parameters.sortOrder = SORT_ORDER.ASC;

  const result = await getRequest(serviceReportForms, parameters);
  const reportForms = result.content || [];
  return reportForms;
};

const reportConvertData = reportForm => {
  const headers = sortArray(reportForm.reportFormHeaders, 'col');
  const specifications = sortArray(
    reportForm.reportFormSpecifications || [],
    'line'
  );

  const specificationCols = uniq(specifications.map(field => field.col));

  if (!isEmpty(headers)) {
    headers.forEach(field => {
      field.colIndex = COL_ALPHABET.indexOf(field.col);
      field.rowIndex = field.line - 1;
      field.isSpecification = specificationCols.includes(field.col);
    });
  }
  if (!isEmpty(specifications)) {
    specifications.forEach(field => {
      field.colIndex = COL_ALPHABET.indexOf(field.col);
      field.rowIndex = field.line - 1;
    });
  }
  reportForm.reportFormHeaders = headers;
  reportForm.reportFormSpecifications = specifications;
  return reportForm;
};

export const getReportForm = async (id, cancelRef) => {
  const reportForm = await getRequest(
    `${serviceReportForms}/${id}`,
    null,
    cancelRef
  );
  if (!reportForm) {
    message.warn('Тайлан олдсонгүй!');
    return null;
  }
  return reportConvertData(reportForm);
};

export const getOpenData = async (data, params, cancelRef) =>
  await postService(serviceOpenData, data, params, cancelRef);

export const getOpenDataExport = async (data, params) =>
  await postService(serviceOpenDataExport, data, params);

export const getSpecificationsByReportForm = async (reportId, cancelRef) =>
  await getRequest(
    `${serviceReportFormSpecifications}/${reportId}`,
    null,
    cancelRef
  );

export const getReportGroups = async params =>
  await getRequest(serviceReportGroups, params);

export const getDistinctByYear = async params =>
  await getRequest(serviceDistinctByYear, params);

export const getReportFormHeaders = async (params, cancelRef) =>
  await getRequest(serviceReportFormHeaders, params, cancelRef);

export const getRadarInfoByRefOrgTypeId = async params =>
  await getRequest(serviceGetRadarInfoByRefOrgTypeId, params);

export const getRadarInfoByTEZ = async params =>
  await getRequest(serviceGetRadarInfoByTEZ, params);

export const getSupplierByEconomyCategory = async (params, cancelRef) =>
  await getRequest(serviceGetSupplierByEconomyCat, params, cancelRef);

export const getSupplierByEconomyTopTen = async params =>
  await getRequest(serviceGetSupplierByEconomyTop, params);

export const getSupplierByDetail = async (params, cancelRef) =>
  await getRequest(serviceGetSupplierByDetail, params, cancelRef);

export const getSupplierByDetailOne = async (params, cancelRef) =>
  await getRequest(serviceGetSupplierByDetailOne, params, cancelRef);

export const getSupplierByDetailTwo = async (params, cancelRef) =>
  await getRequest(serviceGetSupplierByDetailTwo, params, cancelRef);

export const getServiceGetReference = async params =>
  await getRequest(serviceGetReference, params);

export const getServiceGetReferenceEza = async (params, cancelRef) =>
  await getRequest(serviceGetReferenceEza, params, cancelRef);

export const getServiceGetReferenceEzaByDate = async (params, cancelRef) =>
  await getRequest(serviceGetReferenceEzaByDate, params, cancelRef);

export const getReportByUser = async (lazyParams, cancelRef, header) =>
  await getRequest(serviceReportByUser, lazyParams, cancelRef, {
    headers: header,
  });

export const getBudgetPlanPerformance = async (params, cancelRef) =>
  await getRequest(serviceBudgetPlanPerformance, params, cancelRef);

export const getBudgetPerformanceIndicatorByYear = async params =>
  await getRequest(serviceBudgetPerformanceIndicatorByYear, params);

export const getReportFormDatas = async (lazyParams, cancelRef) =>
  await getRequest(serviceReportFormDatas, lazyParams, cancelRef);

export const getReportFormPublishHistory = async (lazyParams, cancelRef) =>
  await getRequest(serviceReportFormPublishHistory, lazyParams, cancelRef);

export const getByOrgIdForDecision = async lazyParams =>
  await getRequest(serviceGetByOrgIdForDecision, lazyParams);

export const getIntegrationTypeById = async id =>
  await getRequest(`${serviceIntegrationTypes}/${id}`);

export const getReportCountByOrg = async (params, cancelRef) =>
  await getRequest(serviceOrgReportCounter, params, cancelRef);

export const getRepFormGroupRecurring = async (params, cancelRef) =>
  await getRequest(serviceRepFormGroupRecs, params, cancelRef);

export const getStaticRecurring = async (params, cancelRef) =>
  await getRequest(serviceStaticRecurring, params, cancelRef);

export const getRepForms = async (params, cancelRef) =>
  await getRequest(serviceRepForms, params, cancelRef);

export const getStaticForm = async (params, cancelRef) =>
  await getRequest(serviceStaticForm, params, cancelRef);

export const getRepFormsByRefTypeTTZ = async (params, cancelRef) =>
  await getRequest(serviceRepFormsByRefTypeTTZ, params, cancelRef);

export const getStaticTEZ = async (params, cancelRef) =>
  await getRequest(serviceStaticTEZ, params, cancelRef);

export const getOrgByRefOrgType = async (params, cancelRef) =>
  await getRequest(serviceOrgByRefOrgType, params, cancelRef);

export const getBudgetPlanPerformanceCode = async (params, cancelRef) =>
  await getRequest(serviceBudgetPlanPerformanceCode, params, cancelRef);

export const getReportControlStaticReport = async (params, cancelRef) =>
  await getRequest(serviceReportControlStaticReport, params, cancelRef);

export const getOrganizationReport = async (params, cancelRef) =>
  await getRequest(serviceOrganizationReport, params, cancelRef);

