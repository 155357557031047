import React, { useState, useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import { Tree } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/Loading';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';
import { OPEN_DATA } from '../../../settings/constantRoutes';

export default function ReportSelectorTree() {
  const navigate = useNavigate();

  const { getReportList } = useAppStore();

  const [formTreeData, setFormTreeData] = useState();

  const handleMedeelel = (keys, e) => {
    navigate(`${OPEN_DATA}/${e.node.id}`);
  };

  const getData = async () => {
    const reportTypes = await getReportList();

    const list = reportTypes.map(item => ({
      title: item.name,
      key: item.id,
      selectable: false,
      children: item.children.map(report => ({
        title: report.name,
        key: `${item.id}-${report.id}`,
        id: report.id,
      })),
    }));
    setFormTreeData(list.filter(type => !isEmpty(type.children)));
  };

  useEffect(getData, []);

  return (
    <div>
      {isNil(formTreeData) ? (
        <div className={styles.showTree} style={{ padding: 0 }}>
          <Loading />
        </div>
      ) : (
        <Tree
          className={styles.showTree}
          treeData={formTreeData}
          showIcon
          showLine
          defaultCheckedKeys
          onSelect={handleMedeelel}
        />
      )}
    </div>
  );
}

