import React, { useState, useEffect, useRef } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import NotFoundContent from './NotFoundContent';
import { FILTER_MODE } from '../../../constants/LazyTable';
import { getRequest } from '../../../service/Service';

let loadLazyTimeout = null;

export default function AutocompleteSelect(props) {
  const { Option } = Select;
  const {
    data,
    viewfield,
    valuefield,
    service,
    isrealsearch,
    filter,
    ismultifield,
  } = props;
  const optionValue = valuefield || 'id';
  const optionViewField = viewfield || 'name';

  const cancelRef = useRef();

  const [list, setList] = useState();
  const [isLoading, setIsLoading] = useState();

  const getList = searchParam => {
    if ((service && !list) || isrealsearch) {
      const param = {
        size: isrealsearch ? 10 : 1000,
        search: { ...searchParam, status: true },
      };
      setIsLoading(true);
      getRequest(service, param, cancelRef)
        .then(result => {
          setList(result.content || []);
          setIsLoading(false);
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            setIsLoading(false);
          }
        });
    }
  };

  const onChange = value => {
    if (!props.onChange) return;
    const selectedItem = (list || []).find(item => item[optionValue] === value);
    props.onChange(value, selectedItem);
  };

  const onFocus = () => {
    if (isEmpty(list)) {
      getList(filter);
    }
  };

  const onSearch = value => {
    if (!isrealsearch || !service) {
      return;
    }
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      const tmpFilter = { ...filter };
      setList();
      if (value) {
        tmpFilter[optionViewField] = { value, matchMode: FILTER_MODE.CONTAINS };
      }
      getList(tmpFilter);
    }, 300);
  };

  useEffect(() => {
    if (data) {
      setList([...data]);
    }
  }, [data]);

  return (
    <Select
      showSearch
      allowClear
      style={{ width: '100%' }}
      optionFilterProp="children"
      maxTagCount="responsive"
      size="middle"
      {...props}
      placeholder={props.placeholder || 'Сонгох'}
      filterOption={!isrealsearch}
      onSearch={onSearch}
      onChange={onChange}
      onFocus={onFocus}
      showArrow
      notFoundContent={NotFoundContent(isLoading)}
    >
      {list &&
        list.map((z, index) => (
          <Option key={index} value={z[optionValue]}>
            {ismultifield
              ? `${z[optionValue]} - ${z[optionViewField]}`
              : z[optionViewField]}
          </Option>
        ))}
    </Select>
  );
}
