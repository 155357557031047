/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Row, Carousel, Col, Skeleton, Image } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { ORGANIZATION_PAGE } from '../../settings/constantRoutes';
import styles from './style.module.scss';
import StateIncome from './stateIncome/stateIncome';
import {
  getBudgetPlanPerformanceCode,
  getReportBudgetDatas,
} from '../../service/reportServices';
import TreeMapUnited from '../components/TreeMapUnited/TreeMapUnited';
import Comment from '../components/Comment/Comment';
import { numberBolgogch } from '../../tools/utils';
import { useAppStore } from '../../context/Tools';
import UrDungiinUzuulelt from '../components/urDungiinUzuulelt/UrDungiinUzuulelt';
import MetaInfo from '../../components/MetaInfo';
import Banner from '../united-budget/UnitedBudgetBanner';
import Cards from '../united-budget/UnitedBudgetCards';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import { getRefUlsTez } from '../../service/referenceServices';
import { getOrganizationsByFields } from '../../service/organizationServices';
import { TZ_TYPE } from '../../constants/OrgConstant';
import { getImgSrc } from '../../tools/Tools';
import no from '../../img/suld.svg';
import BannerTitle from '../../components/bannerTitle/BannerTitle';

const { TabPane } = Tabs;

export default function StateBudget() {
  const [searchParams] = useSearchParams();

  const year = searchParams.get('year');
  const month = searchParams.get('month');
  const type = searchParams.get('type');

  const { serverDate } = useAppStore();

  const cancelRef = useRef();

  const [tezList, setTezList] = useState();
  const [budgetData, setBudgetData] = useState([]);
  const [tezData, setTezData] = useState();
  const [typeOfExpenditure, setTypeOfExpenditure] = useState('eza');
  const [data, setData] = useState(false);

  const getTezData = async () => {
    let params = { search: { type: 1 } };
    const result = await getRefUlsTez(params);
    const tezs = result.content || [];

    const tezIds = tezs.map(z => z.id);
    params = {
      search: { budgetGovernor: TZ_TYPE.TEZ, refTypeId: tezIds },
      requestedFields: 'id,name,logoUuid,refTypeId',
      size: 1000,
    };
    const resultOrg = await getOrganizationsByFields(params);
    const orgs = resultOrg.content || [];

    setTezList(orgs);
  };

  const getTreeMapData = async () => {
    if (typeOfExpenditure === 'tez' && data === 'cost') {
      setTezData();
      const params = {
        typeBy: type || 1,
        year: year || serverDate.getFullYear(),
        month: month || 1,
      };
      try {
        const result = await getBudgetPlanPerformanceCode(params, cancelRef);
        if (!result) return;
        const treeData = [
          {
            name: 'Улсын ТЕЗ',
            value: result.ulsTezValue,
            children: (result.ulsTEZ || []).map(tez => ({
              code: tez.tezCode,
              name: tez.tezName,
              value: tez.tezValue,
              children: (tez.hutulbur || []).map(hutulbur => ({
                name: hutulbur.hutulburName,
                value: hutulbur.hutulburValue,
                children: hutulbur.zoriulalt,
              })),
            })),
          },
        ];
        numberBolgogch(treeData);
        setTezData(treeData);
      } catch {
        setTezData([]);
      }
    } else {
      const params = {
        typeOfExpenditure,
        refBudgetTypeId: 2,
        year: year || serverDate.getFullYear(),
        month: month || 1,
      };

      getReportBudgetDatas(params, cancelRef).then(result => {
        setBudgetData(result);
      });
    }
  };

  useEffect(getTreeMapData, [year, month, type, typeOfExpenditure]);

  useEffect(getTezData, []);

  const handleOrg = orgId => {
    window.open(`${ORGANIZATION_PAGE}/${orgId}`);
  };

  return (
    <>
      <MetaInfo title="Монгол улсын төсөв - Шилэн данс" />
      <Banner title={<BannerTitle title="Монгол Улсын төсөв" />} />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <Cards />
          <Row className={styles.stateBudgetTabs} justify="center">
            <Tabs defaultActiveKey="1">
              <TabPane tab="ТӨСВИЙН ЕРӨНХИЙЛӨН ЗАХИРАГЧ" key="1">
                {isNil(tezList) ? (
                  <Skeleton active />
                ) : (
                  <Carousel
                    arrows={false}
                    dots={false}
                    autoplay
                    slidesToShow={5}
                    slidesToScroll={5}
                    responsive={[
                      {
                        breakpoint: 1400,
                        settings: { slidesToShow: 5, slidesToScroll: 5 },
                      },
                      {
                        breakpoint: 1200,
                        settings: { slidesToShow: 4, slidesToScroll: 4 },
                      },
                      {
                        breakpoint: 1000,
                        settings: { slidesToShow: 4, slidesToScroll: 4 },
                      },
                      {
                        breakpoint: 800,
                        settings: { slidesToShow: 3, slidesToScroll: 3 },
                      },
                      {
                        breakpoint: 680,
                        settings: { slidesToShow: 2, slidesToScroll: 2 },
                      },
                    ]}
                  >
                    {(tezList || []).map((tez, key) => (
                      <div
                        key={key}
                        className={styles.ulsTEZ}
                        onClick={() => {
                          handleOrg(tez.id);
                        }}
                      >
                        <Image
                          src={getImgSrc(tez.logoUuid)}
                          fallback={no}
                          preview={false}
                        />
                        <div>{tez.name}</div>
                      </div>
                    ))}
                  </Carousel>
                )}
              </TabPane>
            </Tabs>
          </Row>
          <UrDungiinUzuulelt
            budgetType={2}
            title="УЛСЫН ТӨСВИЙН ҮР ДҮНГИЙН ҮЗҮҮЛЭЛТ /жилээр/"
          />
          <TreeMapUnited
            setData={setData}
            data={data}
            setTypeOfExpenditure={setTypeOfExpenditure}
            typeOfExpenditure={typeOfExpenditure}
            budgetData={budgetData}
            tezData={tezData}
            Title="Улсын төсөв, гүйцэтгэл /сар/"
          />
          <StateIncome />
          <Comment />
        </Col>
      </Row>
    </>
  );
}
