import { Col, Row } from 'antd';
import React from 'react';
import styles from './style.module.scss';

export default function BannerTitle({ title, contentText }) {
  return (
    <Row
      justify="center"
      align="middle"
      className={styles.headerTitles}
      style={{ top: contentText && '10%' }}
    >
      <Col xs={22} sm={19}>
        <Row className={styles.title}>{title}</Row>
        <Row className={styles.contentText}>{contentText}</Row>
      </Col>
    </Row>
  );
}

