import React, { useState, useEffect } from 'react';
import { Checkbox, Col } from 'antd';
import { isNil } from 'lodash';
import { Loading } from './Loading';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';
import { FILTER_SPAN } from '../utils';
import { getRefOrgTypes } from '../../../service/referenceServices';

export default function FilterByOrgTypes() {
  const { refOrgTypes, setRefOrgTypes, filters, setFilters } = useAppStore();
  const { orgTypes } = filters;

  const [orgTypeState, setOrgTypeState] = useState({
    indeterminate: false,
    checkAll: false,
  });

  useEffect(() => {
    getRefOrgTypes({ search: { status: true } }).then(result => {
      if (result) {
        const data = (result.content || []).map(item => ({
          label: item.name,
          key: item.id,
          value: item.id,
        }));
        setRefOrgTypes(data);
      }
    });
  }, []);

  return (
    <Col {...FILTER_SPAN} className={styles.subMenu}>
      <div className={styles.subHeader}>
        <div>Байгууллагын төрөл</div>
        <Checkbox
          checked={orgTypeState.checkAll}
          disabled={isNil(refOrgTypes)}
          indeterminate={orgTypeState.indeterminate}
          onChange={e => {
            if (isNil(refOrgTypes)) return;
            setFilters({
              ...filters,
              reportGroups: e.target.checked
                ? (refOrgTypes || []).map(s => s.value)
                : [],
            });
            setOrgTypeState({
              indeterminate: false,
              checkAll: e.target.checked,
            });
          }}
        >
          Бүгд
        </Checkbox>
      </div>
      {isNil(refOrgTypes) ? (
        <Loading />
      ) : (
        <Checkbox.Group
          value={orgTypes}
          className={styles.menuBox}
          options={refOrgTypes}
          onChange={e => {
            setFilters({ ...filters, orgTypes: e });
            setOrgTypeState({
              indeterminate: !!e.length && e.length < refOrgTypes.length,
              checkAll: e.length === refOrgTypes.length,
            });
          }}
        />
      )}
    </Col>
  );
}

