import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');
am4core.options.autoDispose = true;

export default function StackedBarChart({ budgetData, id, valueRadio }) {
  const sum = data => {
    data.forEach(el => {
      el.sumBudget = el.sdBudget + el.tzdBudget + el.onhBudget;
    });
  };
  sum(budgetData);
  budgetData.sort((a, b) => (a.sdBudget > b.sdBudget ? 1 : -1));

  const maxOfBudget = Math.max(...budgetData?.map(item => item.sumBudget), 0);
  const dummy = JSON.parse(JSON.stringify(budgetData));
  dummy.forEach(el => {
    if (el.sumBudget === maxOfBudget) {
      el.sumBudget = 0;
    }
  });
  const secondMaxOfBudget = Math.max(...dummy.map(item => item.sumBudget), 0);

  useLayoutEffect(() => {
    const chart = am4core.create(id, am4charts.XYChart);

    // Add data
    chart.data = budgetData;
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';

    // exporting
    chart.exporting.filePrefix = 'chart';
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = 'right';
    chart.exporting.menu.verticalAlign = 'top';
    chart.exporting.menu.items = [
      {
        type: 'png',
        label: 'PNG',
        title: 'График татаж авах',
      },
    ];

    // Create axis
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'aimagName';
    categoryAxis.renderer.grid.template.opacity = 0;
    categoryAxis.renderer.minGridDistance = 10;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color('#f70202');
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = false;
    valueAxis.renderer.minGridDistance = 50;
    valueAxis.title.text = '[bold font-size: 20] Дүн (сая төгрөгөөр)';

    // ---Create Breaks
    const axisBreak = valueAxis.axisBreaks.create();
    axisBreak.startValue = secondMaxOfBudget + 0;
    axisBreak.endValue = maxOfBudget - 5000;
    axisBreak.breakSize = 0.02;

    // ---make break expand on hover
    const hoverState = axisBreak.states.create('hover');
    hoverState.properties.breakSize = 1;
    hoverState.properties.opacity = 0.1;
    hoverState.transitionDuration = 1500;

    // Create series
    function createSeries(field, name, color) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = 'aimagName';
      series.stacked = true;
      series.name = name;
      series.fill = am4core.color(color);
      series.strokeWidth = 0;
      series.columns.template.tooltipText = `{name}\n[bold font-size: 20]{valueX} сая төгрөг`;
      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      // labelBullet.label.text = '{valueX}';
      labelBullet.label.fill = am4core.color('#fff');
    }
    if (valueRadio === 'budget') {
      createSeries('sdBudget', 'Санхүүгийн дэмжлэг', '#9c75bf');
      createSeries('tzdBudget', 'Тусгай зориулалтын шилжүүлэг', '#05f0b9');
      createSeries('onhBudget', 'Орон нутгийн хөгжлийн сан', '#FF6F91');
    }
    if (valueRadio === 'performance') {
      createSeries('sdPerf', 'Санхүүгийн дэмжлэг', '#9c75bf');
      createSeries('tzdPerf', 'Тусгай зориулалтын шилжүүлэг', '#05f0b9');
      createSeries('onhPerf', 'Орон нутгийн хөгжлийн сан', '#FF6F91');
    }
  }, [budgetData, valueRadio]);

  return <div id={id} style={{ width: '100%', height: '950px' }} />;
}
