/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Skeleton, Tabs } from 'antd';
import { isEmpty, uniq } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import ReportTemplate from './components/ReportTemplate';
import style from './style.module.scss';
import {
  getReportForm,
  getReportFormPublishHistory,
} from '../../../service/reportServices';
import ReportAttachFile from './components/ReportAttachFile';
import { DATEFORMAT } from '../../../constants/Constant';
import { FORMAT } from '../../../constants/ReportConstants';
import { getRefSelectableChoices } from '../../../service/referenceServices';
import { sortArray } from '../../../tools/Tools';

const { TabPane } = Tabs;

const ReportOrganizationDataEdit = ({ orgRecurring, back }) => {
  const cancelRefReportForm = useRef();
  const cancelRefReportFormDatas = useRef();

  const [selectedReportForm, setSelectedReportForm] = useState({});
  const [loading, setLoading] = useState();
  const [reportFormData, setReportFormData] = useState([]);

  const getReportData = async () => {
    try {
      const params = {
        reportFormOrgRecurringId: orgRecurring.id,
        year: orgRecurring.selectedYear,
        month: orgRecurring.selectedMonth,
      };
      const content = await getReportFormPublishHistory(
        params,
        cancelRefReportFormDatas
      );
      if (!isEmpty(content)) {
        setReportFormData(content);
      } else {
        setReportFormData([]);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setLoading(false);
      }
    }
  };

  const fetchData = async () => {
    try {
      const reportForm = await getReportForm(
        orgRecurring.reportFormId,
        cancelRefReportForm
      );

      const selectableFormatFields = reportForm.reportFormHeaders.filter(
        field => field.format === FORMAT.SELECT
      );
      if (!isEmpty(selectableFormatFields)) {
        const selectableIds = uniq(
          selectableFormatFields.map(field => field.selectableChoicesId)
        );
        const params = { search: { id: selectableIds } };
        const { content } = await getRefSelectableChoices(params);
        if (!isEmpty(content)) {
          content.forEach(select => {
            const findHeader = reportForm.reportFormHeaders.find(
              field => field.selectableChoicesId === select.id
            );
            if (findHeader && !isEmpty(select.children)) {
              const selectList = select.children
                .filter(child => child.status)
                .map(item => ({
                  value: `${item.id}`,
                  label: item.name,
                  priority: item.priority,
                }));
              findHeader.selectableList = sortArray(selectList, 'priority');
            }
          });
        }
      }
      setSelectedReportForm(reportForm);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setLoading(false);
      }
    }
  };

  const getReportEndDate = () => {
    if (orgRecurring.isEvery) {
      return 'Тухай бүр';
    }
    if (orgRecurring.extentionDays && orgRecurring.deadDate) {
      const newDate = moment(orgRecurring.deadDate)
        .add(Number(orgRecurring.extentionDays), 'd')
        .format(DATEFORMAT);

      return (
        <div style={{ display: 'inline-grid' }}>
          <span style={{ textDecoration: 'line-through', color: 'gray' }}>
            {orgRecurring.deadDate}
          </span>
          <span style={{ color: 'green' }}>{newDate}</span>
        </div>
      );
    }
    return orgRecurring.deadDate;
  };

  const getCalcSeason = month => {
    let season = '-';
    if ([1, 2, 3].includes(month)) {
      season = '1';
    } else if ([4, 5, 6].includes(month)) {
      season = '2';
    } else if ([7, 8, 9].includes(month)) {
      season = '3';
    } else if ([10, 11, 12].includes(month)) {
      season = '4';
    }
    return season;
  };

  useEffect(async () => {
    setLoading(true);
    try {
      await fetchData();
      await getReportData();
    } finally {
      setLoading(false);
    }
  }, [orgRecurring]);

  return (
    <div>
      <div className={style.header}>
        <Button type="primary" onClick={back}>
          Буцах
        </Button>
      </div>
      <div className={style.infoCard}>
        <Col>
          <Row className={style.head}>{orgRecurring.name}</Row>
          <Row>
            <Col span={8} className={style.body}>
              Он: <b>{orgRecurring.selectedYear}</b>
            </Col>
            <Col span={8} className={style.body}>
              Улирал: <b>{getCalcSeason(orgRecurring.selectedMonth)}</b>
            </Col>
            <Col span={8} className={style.body}>
              Сар: <b>{orgRecurring.selectedMonth}-р сар</b>
            </Col>
            <Col span={8} className={style.body}>
              Мэдээллийн төрөл: <b>{orgRecurring.type}</b>
            </Col>
            <Col span={8} className={style.body}>
              Давтамж: <b>{orgRecurring.recurringName}</b>
            </Col>
            <Col span={8} className={style.body}>
              Мэдээлэл оруулах хугацаа: <b>{getReportEndDate()}</b>
            </Col>
          </Row>
        </Col>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        !isEmpty(selectedReportForm.reportFormHeaders) &&
        (selectedReportForm.isHaveFile ? (
          <Tabs>
            <TabPane tab="Тайлан" key="report">
              <ReportTemplate
                isDataBodiesLoading={loading}
                selectedReportForm={selectedReportForm}
                orgRecurring={orgRecurring}
                reportFormDatas={reportFormData}
              />
            </TabPane>
            <TabPane tab="Хавсаргасан файл" key="file" disabled={loading}>
              <ReportAttachFile reportFormData={reportFormData} />
            </TabPane>
          </Tabs>
        ) : (
          <ReportTemplate
            isDataBodiesLoading={loading}
            selectedReportForm={selectedReportForm}
            orgRecurring={orgRecurring}
            reportFormDatas={reportFormData}
          />
        ))
      )}
    </div>
  );
};

export default ReportOrganizationDataEdit;

