import { Col, Row, Card, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import features from '../../../img/Banner3.png';
// import opendata from '../../../img/featured/opendata.png';
// import imgApart from '../../../img/featured/apart.png';
// import imgBank from '../../../img/featured/bank.png';
// import imgMap from '../../../img/featured/map.png';
import imgBook from '../../../img/featured/book.png';
import styles from './style.module.scss';
import { SORT_ORDER } from '../../../constants/Constant';
import { getSpecialMenus } from '../../../service/portalService';
import { getImgSrc } from '../../../tools/Tools';

const { Text } = Typography;

const rowBuild = [
  { length: 4, rows: [2, 2] },
  { length: 5, rows: [2, 3] },
  { length: 6, rows: [3, 3] },
  { length: 7, rows: [2, 3, 2] },
  { length: 8, rows: [3, 2, 3] },
  { length: 9, rows: [3, 3, 3] },
];

export default function FeaturedMenu() {
  const [specialMenus, setSpecialMenus] = useState([]);

  useEffect(() => {
    const params = {
      search: { isActive: true, status: true },
      sortField: 'priority',
      sortOrder: SORT_ORDER.DESC,
      size: 100,
    };

    getSpecialMenus(params).then(data => {
      const specialData = data.content || [];
      const find = rowBuild.find(z => z.length === specialData.length);
      const calcedList = [];
      if (find) {
        find.rows.forEach(count => {
          calcedList.push(specialData.splice(0, count));
        });
      } else {
        const count = 3;
        while (specialData.length > 0) {
          calcedList.push(specialData.splice(0, count));
        }
      }
      setSpecialMenus(calcedList);
    });
  }, []);

  const newTabLinks = link => {
    window.open(link, '_blank');
  };

  return (
    <Row
      style={{ backgroundImage: `url(${features})` }}
      className={styles.featuredMenuDiv}
      justify="center"
    >
      <Col span={19}>
        <div className={styles.featuredMenuHeader}>
          <Text strong className={styles.featuredMenuHeaderText}>
            ОНЦЛОХ ЦЭС
          </Text>
        </div>
        <Row
          gutter={[8, 16]}
          className={styles.featuredMenuRow}
          justify="center"
        >
          {specialMenus.map(row =>
            (row || []).map((menu, index) => (
              <Col key={index} xs={24} md={12} lg={24 / row.length}>
                <Card
                  onClick={() => newTabLinks(menu.link)}
                  className={styles.featuredMenuCardshover}
                >
                  <Col span={13} style={{ alignSelf: 'center' }}>
                    <span>{menu.name}</span>
                  </Col>
                  <Col span={11}>
                    <img
                      src={menu.iconUuid ? getImgSrc(menu.iconUuid) : imgBook}
                      alt="img"
                    />
                  </Col>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Col>
    </Row>
  );
}
