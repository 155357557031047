import React, { useState, useRef } from 'react';
import { AutoComplete, Button, Col, Image, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { FILTER_MODE } from '../../../constants/LazyTable';
import { getOrganizationsByFields } from '../../../service/organizationServices';
import { getImgSrc } from '../../../tools/Tools';
import styles from './style.module.scss';
import DetailedSearch from './DetailedSearch';
import { useAppStore } from '../../../context/Tools';
import no from '../../organizationPage/assets/no.png';

const { Option } = AutoComplete;

function FilterByOrg() {
  const { filters, setFilters } = useAppStore();
  const { organizations } = filters;

  const cancelRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [showDetailSearch, setShowDetailSearch] = useState(false);
  const [searchedOrgs, setSearchedOrgs] = useState();
  const [searchValue, setSearchValue] = useState();

  const removeFromSearchedOrgs = (orgs, removeOrgs) => {
    if (!searchedOrgs) {
      return;
    }
    const calcedOrgs = orgs.filter(
      org => !removeOrgs.find(r => r.id === org.id)
    );
    setSearchedOrgs(calcedOrgs);
  };

  const onSearch = async value => {
    const params = { requestedFields: 'id,name,logoUuid' };
    if (value) {
      params.search = { name: { value, matchMode: FILTER_MODE.CONTAINS } };
    }
    setIsLoading(true);

    try {
      const { content } = await getOrganizationsByFields(params, cancelRef);
      const data = content.map(org => ({
        id: org.id,
        value: org.id,
        label: org.name,
        name: org.name,
        function: org.function,
        logoUuid: org.logoUuid,
      }));
      setSearchedOrgs(data);
      removeFromSearchedOrgs(data, organizations);
      setIsLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsLoading(false);
      }
    }
  };

  const onChange = value => {
    setSearchValue(value);
    onSearch(value);
  };

  const selectOrg = value => {
    setSearchValue();
    const selectedOrg = searchedOrgs.find(org => org.id === value);
    if (selectedOrg && !organizations.find(org => org.id === value)) {
      const orgs = [...organizations, selectedOrg];
      setFilters({ ...filters, organizations: orgs });
      removeFromSearchedOrgs(searchedOrgs, orgs);
    }
  };

  const removeOrg = remove => {
    setFilters({
      ...filters,
      organizations: [...organizations.filter(org => org.id !== remove.id)],
    });
    setSearchedOrgs([...searchedOrgs, remove]);
  };

  // const onDetailedSearch = () => {
  //   setShowDetailSearch(true);
  // };

  return (
    <Col span={24} className={styles.FilterByOrg}>
      <div className={styles.subHeader} style={{ display: 'block' }}>
        Байгууллага
      </div>
      <AutoComplete
        className={styles.search}
        options={searchedOrgs}
        placeholder="Хайх"
        value={searchValue}
        notFoundContent={
          isLoading ? (
            <LoadingOutlined className={styles.loader} />
          ) : (
            'Өгөгдөл олдсонгүй'
          )
        }
        onChange={onChange}
        onSelect={selectOrg}
      >
        {searchedOrgs &&
          searchedOrgs.map((data, index) => (
            <Option key={index} value={data.id}>
              {data.name}
            </Option>
          ))}
      </AutoComplete>
      {/* <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={onDetailedSearch}
      /> */}
      <Row gutter={[16, 16]}>
        {organizations &&
          organizations.map((org, index) => (
            <Col key={index} xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <div className={styles.org}>
                <Image
                  src={getImgSrc(org.logoUuid)}
                  fallback={no}
                  preview={false}
                />
                <div style={{ width: '100%' }}>
                  <div className={styles.orgName}> {org.name}</div>
                </div>
                <Button shape="circle" onClick={() => removeOrg(org)}>
                  X
                </Button>
              </div>
            </Col>
          ))}
      </Row>
      {showDetailSearch && (
        <DetailedSearch
          isVisible={showDetailSearch}
          close={() => setShowDetailSearch(false)}
        />
      )}
    </Col>
  );
}

export default React.memo(FilterByOrg);

