import React from 'react';
import { Col, Row } from 'antd';
import styles from './style.module.scss';
import preTree1 from '../../../img/preTree1.png';
import preTree2 from '../../../img/preTree2.png';
import preTree3 from '../../../img/preTree3.png';
import line from '../../../img/LineTreemap.svg';

export default function TreeMapSelect(props) {
  const { setData, setTypeOfExpenditure, budgetData } = props;

  const incomeData =
    budgetData?.length > 0
      ? budgetData?.find(el => el?.code === 'A')?.children[0]?.performance
      : '';
  const incomePercent =
    budgetData?.length > 0
      ? budgetData?.find(el => el?.code === 'A')?.children[0]?.percent_25_24
      : '';
  const costData =
    budgetData?.length > 0
      ? budgetData?.find(el => el?.code === 'B')?.children[0]?.performance
      : '';
  const costPercent =
    budgetData?.length > 0
      ? budgetData?.find(el => el?.code === 'B')?.children[0]?.percent_25_24
      : '';
  const balanceData =
    budgetData?.length > 0
      ? budgetData?.find(el => el?.code === 'C')?.children[0]?.performance
      : '';
  const balancePercent =
    budgetData?.length > 0
      ? budgetData?.find(el => el?.code === 'C')?.children[0]?.percent_25_24
      : '';

  const balanceHandler = () => {
    setData('balance');
    setTypeOfExpenditure('eza');
  };

  const costHandler = () => {
    setData('cost');
    setTypeOfExpenditure && setTypeOfExpenditure('eza');
  };

  const incomeHandler = () => {
    setData('income');
    setTypeOfExpenditure('eza');
  };

  return (
    <Row className={styles.bigContainer}>
      <Col xs={24} sm={12} md={8}>
        <div
          className={styles.container}
          style={{
            backgroundImage: `url(${preTree1})`,
            backgroundSize: 'cover',
          }}
          onClick={incomeHandler}
          role="presentation"
        >
          <div className={styles.text1}>Нийт орлого</div>
          <div className={styles.text2}>Гүйцэтгэл</div>
          <div className={styles.text3}>{`${incomeData} сая төгрөг`}</div>
          <div className={styles.line}>
            <img src={line} alt="line" />
          </div>
          <div className={styles.text4}>{`${incomePercent}%`}</div>
        </div>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <div
          className={styles.container}
          style={{
            backgroundImage: `url(${preTree2})`,
            backgroundSize: 'cover',
          }}
          onClick={costHandler}
          role="presentation"
        >
          <div className={styles.text1}>Нийт зарлага</div>
          <div className={styles.text2}>Гүйцэтгэл</div>
          <div className={styles.text3}>{`${costData} сая төгрөг`}</div>
          <div className={styles.line}>
            <img src={line} alt="line" />
          </div>
          <div className={styles.text4}>{`${costPercent}%`}</div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <div
          className={styles.container}
          style={{
            backgroundImage: `url(${preTree3})`,
            backgroundSize: 'cover',
          }}
          onClick={balanceHandler}
          role="presentation"
        >
          <div className={styles.text1}>Төсвийн тэнцэл</div>
          <div className={styles.text2}>Засгийн газрын өр</div>
          <div className={styles.text3}>{`${balanceData} сая төгрөг`}</div>
          <div className={styles.line}>
            <img src={line} alt="line" />
          </div>
          <div className={styles.text4}>{`${balancePercent}%`}</div>
        </div>
      </Col>
    </Row>
  );
}

