import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';
import Location from '../../../components/maps/maps';
import OrgContext from '../../../../context/OrgContext';
import BranchContent from './BranchContent';
import {
  filterActiveOrg,
  getOrganizationsByFields,
} from '../../../../service/organizationServices';
import { TZ_TYPE } from '../../../../constants/OrgConstant';

const { TabPane } = Tabs;
export default function BranchOrganizations() {
  const { orgInfo, setStateParentOrg } = useContext(OrgContext);

  useEffect(() => {
    const params = {
      requestedFields: 'id,name,logoUuid',
      search: { ...filterActiveOrg, parentId: orgInfo.id },
    };

    getOrganizationsByFields(params).then(result => {
      if (result) {
        setStateParentOrg(result.content || []);
      }
    });
  }, []);

  if (!orgInfo?.id) {
    return null;
  }

  return (
    <Tabs defaultActiveKey="TTZ">
      {orgInfo.budgetGovernor === TZ_TYPE.TEZ && (
        <TabPane tab="Салбар төвлөрүүлэн захирагч" key="TTZ">
          <BranchContent type="TTZ" title="Салбар төвлөрүүлэн захирагчид" />
        </TabPane>
      )}
      {(orgInfo.budgetGovernor === TZ_TYPE.TEZ ||
        orgInfo.budgetGovernor === TZ_TYPE.TTZ) && (
        <TabPane tab="Харьяа байгууллага" key="TShZ">
          <BranchContent type="TShZ" title="Шууд харьяаны байгууллагууд" />
        </TabPane>
      )}
      <TabPane tab="Бусад холбоотой байгууллага" key="childOrg">
        <BranchContent
          type="childOrg"
          title="Тухайн салбарын харьяа бусад байгууллагууд"
        />
      </TabPane>
      <TabPane tab="Газрын зураг" key="map">
        <Location orgInfo={orgInfo} />
      </TabPane>
    </Tabs>
  );
}
