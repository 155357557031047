/* eslint-disable no-useless-escape */
import { Col, Divider, List, Row, Space, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { CarryOutFilled, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import YoutubeEmbed from '../../components/youtubeEmded/YoutubeEmbed';
import styles from './style.module.scss';
import { getTrainingVideos } from '../../../service/trainingService';
import { SORT_ORDER } from '../../../constants/Constant';

const { Text } = Typography;
function VideoLearning() {
  const [list, setList] = useState([]);
  const [emdedCode, setEmded] = useState(
    'https://www.youtube.com/embed/PanChFWdWr0'
  );

  const videoServerCreate = () => {
    const params = {
      search: { status: true, isActive: true },
      sortField: 'priority',
      sortOrder: SORT_ORDER.DESC,
      size: 500,
    };
    getTrainingVideos(params).then(data => {
      const dataList = data.content || [];
      dataList.forEach((item, index) => {
        item.index = data.totalPages + index;
      });
      setList(dataList);
    });
  };

  useEffect(() => {
    videoServerCreate();
  }, []);

  const getId = url => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    }
    return 'error';
  };
  const myId = getId(emdedCode);

  const handleSelect = selectData => {
    setEmded(selectData.target.firstChild.src);
  };

  return (
    <>
      <Row>
        <Space>
          <CarryOutFilled className={styles.breadIcon} />
          <Text strong className={styles.breadText}>
            Суралцахуй
          </Text>
          <RightOutlined className={styles.breadIcons} />
          <Text strong className={styles.breadText}>
            Видео сургалт
          </Text>
        </Space>
      </Row>
      <Row className={styles.allRow}>
        <Col xs={24} sm={23} md={22} lg={23} xl={24}>
          <Row gutter={[10, 30]}>
            <Col xs={24} sm={24} lg={24} xl={14} className={styles.headerCol}>
              <div className={styles.secondCol}>
                <YoutubeEmbed embedId={myId} />
              </div>
            </Col>
            <Col xs={24} sm={24} lg={24} xl={10}>
              <div className={styles.bigDiv}>
                <Col span={24} className={styles.secondCol}>
                  <Row className={styles.sectionRow}>
                    {list.map(item => (
                      <Row className={styles.sectionRow}>
                        <Col className={styles.commentPic} xs={24} sm={10}>
                          <List.Item onClick={handleSelect}>
                            <iframe
                              className={styles.sectionImage}
                              id="ytplayer"
                              src={`https://www.youtube.com/embed/${getId(
                                item.link
                              )}`}
                              frameBorder="0"
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture autoplay; modestbranding;"
                              title="Embedded youtube"
                              showinfo="0"
                            />
                          </List.Item>
                        </Col>
                        <Col xs={24} sm={13} className={styles.secondCol}>
                          <Col className={styles.secondText}>
                            <Text strong>{item.name}</Text>
                          </Col>
                          <Text style={{ fontSize: 13, marginBottom: 6 }}>
                            {moment(item.createdAt).format('YYYY-MM-DD')}
                          </Text>

                          <Col className={styles.commentCol}>
                            <Text style={{ fontSize: 13 }}>
                              {item.description}
                            </Text>
                          </Col>
                          <Divider
                            color="black"
                            style={{ margin: '0px 0px ', color: 'black' }}
                          />
                        </Col>
                      </Row>
                    ))}
                  </Row>
                </Col>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default VideoLearning;
