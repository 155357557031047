import React, { useRef, useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Input,
  Radio,
  Button,
  Form,
  AutoComplete,
} from 'antd';
import { InfoCircleFilled, LoadingOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import { postFeedback } from '../../service/feedbackService';
import { FFEDBACKTYPE, MSG, WHOM } from '../../constants/Constant';
import validateMessages from '../../tools/validateMessage';
import Help from './Help';
import ContactInfo from './ContactInfo';
import FormItemCyrillic from '../../components/FormItemCyrillic';
import { Success } from '../../components/Confirm';
import { getOrganizationFilter } from '../../service/portalService';

const { Text } = Typography;
const { TextArea } = Input;

export default function Company() {
  const [form] = Form.useForm();
  const [isShow, setIsShow] = useState(false);
  const [isAnswers, setIsAnswers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedOrgs, setSearchedOrgs] = useState();
  const [searchedOrgsRaw, setSearchedOrgsRaw] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const cancelRef = useRef();

  const onClickShow = () => {
    setIsShow(!isShow);
  };
  const onIsAnswers = e => {
    if (e.target.value === true) {
      setIsAnswers(true);
    }
    if (e.target.value === false) {
      setIsAnswers(false);
    }
  };

  const submitForm = ({
    orgName,
    address,
    email,
    phone,
    phone1,
    subject,
    description,
    isAnswer,
  }) => {
    setIsLoading(true);
    const saveData = {
      ...selectedOrg,
      type: FFEDBACKTYPE.COMPLIANT,
      whom: WHOM.ORG,
      address,
      phone,
      phone1,
      email,
      orgName,
      subject,
      description,
      isAnswer: isAnswer || false,
    };

    postFeedback(saveData)
      .then(() => {
        Success(null, 'Амжилттай илгээлээ.');
        form.resetFields();
        setIsAnswers(false);
      })
      .finally(() => setIsLoading(false));
  };

  const selectOrg = value => {
    const selectedO = searchedOrgsRaw.find(orgdata => orgdata.name === value);
    setSelectedOrg({
      budgetGovernor: selectedO.budgetGovernor,
      parentId: selectedO.parentId,
      refCentralBudgetGovernorId: selectedO.refCentralBudgetGovernorId,
      refTypeId: selectedO.refTypeId,
      orgId: selectedO.id,
    });
  };

  const onSearch = async value => {
    const body = {
      isActive: 1,
      name: value,
    };
    const params = {
      page: 0,
      size: 8,
    };
    setIsLoadingSearch(true);

    getOrganizationFilter(body, cancelRef, params)
      .then(result => {
        if (result?.content) {
          const content = result?.content;
          const data = content.map(orgData => ({
            value: orgData.name,
          }));
          setSearchedOrgsRaw(content);
          setSearchedOrgs(data);
        }
        setIsLoadingSearch(false);
      })
      .catch(err => err?.toString() !== 'Cancel' && setIsLoadingSearch(false));
  };

  return (
    <>
      <Row>
        <Col span={21}>
          <Text strong className={styles.sideCompanyHeader}>
            Иргэд гомдлын талаар бидэнд мэдэгдэнэ үү.
          </Text>
        </Col>
        <Col span={3} className={styles.companyCol}>
          <InfoCircleFilled
            className={styles.companyicons}
            onClick={onClickShow}
          />
        </Col>
      </Row>
      {isShow && (
        <Row>
          <Col>
            <br />
            <Help />
          </Col>
        </Row>
      )}
      <br />
      <Form
        form={form}
        onFinish={submitForm}
        layout="vertical"
        validateMessages={validateMessages}
      >
        <Form.Item
          name="orgName"
          label="Байгууллага сонгох:"
          rules={[
            {
              required: true,
              message: MSG.MUST_FILL_FIELD,
            },
          ]}
        >
          <AutoComplete
            style={{
              width: '100%',
            }}
            options={searchedOrgs}
            notFoundContent={
              isLoadingSearch ? <LoadingOutlined /> : 'Өгөгдөл олдсонгүй'
            }
            onChange={onSearch}
            onSelect={selectOrg}
          >
            <Input placeholder="Байгууллага хайх" />
          </AutoComplete>
        </Form.Item>
        <FormItemCyrillic name="subject" label="Утга:">
          <Input />
        </FormItemCyrillic>
        <FormItemCyrillic name="description" label="Санал:">
          <TextArea rows={3} />
        </FormItemCyrillic>
        <Text strong> Та хариу авах уу? </Text>
        <Form.Item
          name="isAnswer"
          rules={[
            {
              required: true,
              message: 'Та хариу авах уу?',
            },
          ]}
        >
          <Radio.Group
            onChange={onIsAnswers}
            className={styles.replyRadioButton}
          >
            <Radio value> Тийм </Radio>
            <Radio value={false}> Үгүй </Radio>
          </Radio.Group>
        </Form.Item>

        {isAnswers && <ContactInfo />}
        <br />
        <Row gutter={12} justify="center">
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Илгээх
          </Button>
        </Row>
      </Form>
    </>
  );
}
