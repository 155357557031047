import { postService } from './Service';


const serviceFeedbackBaseUrl = '/gap-notification-service';
const serviceFeedbackInfoUrl = `${serviceFeedbackBaseUrl}/notificationComplaints`;



export const postFeedback= async (values) =>
  await postService(`${serviceFeedbackInfoUrl}`, values);
