/* eslint-disable no-shadow */
import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');
function ColumnAxisCharts(props) {
  const { chartId, chartData, valueY, tooltipBoolen } = props;
  useLayoutEffect(() => {
    const chart = am4core.create(chartId, am4charts.XYChart);
    // Add data
    chart.data = chartData;

    // Create axes

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'name';
    categoryAxis.numberFormatter.numberFormat = '#';
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 500;
    label.maxHeight = 1020;
    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = true;

    // Create series
    function createSeries(field, names) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = 'name';
      series.name = names;
      series.columns.template.tooltipText = tooltipBoolen
        ? `[bold]{name}: {sumQuantity}[/]
      Харьяа байгууллага: {ttzquantity}
      Салбар байгууллага: {tshzquantity}
      [bold]Нийт: {sumQuantity}[/]
      `
        : '{categoryY}: [bold]{valueX}[/]';

      series.columns.template.height = am4core.percent(50);
      series.columns.template.width = am4core.percent(10);
      series.sequencedInterpolation = true;
      const cellSize = 40;
      chart.events.on('datavalidated', ev => {
        // Get objects of interest
        const chart = ev.target;
        const categoryAxis = chart.yAxes.getIndex(0);

        // Calculate how we need to adjust chart height
        const adjustHeight =
          chart.data.length * cellSize - categoryAxis.pixelHeight;

        // get current chart height
        const targetHeight = chart.pixelHeight + adjustHeight;

        // Set it on chart's container
        chart.svgContainer.htmlElement.style.height = `${targetHeight}px`;
      });

      const valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = `{valueX}`;

      valueLabel.label.horizontalCenter = 'left';
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;

      // const categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      // categoryLabel.label.text = names;
      // categoryLabel.label.horizontalCenter = "right";
      // categoryLabel.label.dx = -10;
      // categoryLabel.label.fill = am4core.color("#fff");
      // categoryLabel.label.hideOversized = false;
      // categoryLabel.label.truncate = false;
    }

    createSeries(`${valueY}`, tooltipBoolen ? 'Харьяа байгууллага' : '');
    // tooltipBoolen? createSeries(`${valueX}`, "Салбар байгууллага") : {}
  }, []);
  return <div id={chartId} style={{ width: '80%' }} />;
}
export default ColumnAxisCharts;
