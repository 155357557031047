import React from 'react';
import { Typography, Row, Space } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

const { Text } = Typography;
function CardText({ title, onClose }) {
  return (
    <>
      <Row>
        <Space direction="vertical">
          {/* <span className={styles.headertext} strong>
            {title.unofficialWord}
          </span> */}
          <Text style={{ fontSize: 14 }}>{title.unofficialWord}</Text>
          <CheckCircleFilled onClick={onClose} />
        </Space>
      </Row>
    </>
  );
}
export default CardText;
