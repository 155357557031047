import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import { sortAs } from 'react-pivottable/Utilities';
import 'react-pivottable/pivottable.css';
import '../../../styles/pivottable.css';
import * as XLSX from 'xlsx';
import { useAppStore } from '../../../context/Tools';
import imgExcel from '../../../img/excel.svg';

export default function OpendataStaticReport({ dataList }) {
  const { selectedReport } = useAppStore();
  const [state, setState] = useState({ data: [] });

  const fields = selectedReport?.fields || [];

  const sorters = () => {
    const mustAggrField = fields.find(z => z.aggrFields);
    if (mustAggrField) {
      return {
        [mustAggrField.mon]: sortAs(mustAggrField.aggrFields.map(z => z.mon)),
      };
    }
    return null;
  };

  const initPivot = () => {
    setState({
      ...state,
      cols: fields.filter(z => z.isCols).map(z => z.mon),
      data: dataList || [],
    });
  };

  useEffect(initPivot, [dataList]);

  useEffect(() => {
    setState({
      ...state,
      rows: fields.filter(z => z.isRows).map(z => z.mon),
      cols: [],
      vals: ['value'],
      hiddenFromDragDrop: ['value'],
      data: [],
      sorters: sorters(),
    });
  }, [selectedReport]);

  const pivotPresets = {
    rendererName: 'Table',
    aggregatorName: 'Integer Sum',
    rows: fields.filter(z => z.isRows).map(z => z.mon),
    cols: fields.filter(z => z.isCols).map(z => z.mon),
    vals: ['value'],
    hiddenFromDragDrop: ['value'],
    sorters: sorters(),
    unusedOrientationCutoff: 2000,
  };

  const exportdata = () => {
    const pvtTable = document.getElementsByClassName('pvtTable');
    const wb = XLSX.utils.table_to_book(pvtTable[0]);
    XLSX.writeFile(wb, `${selectedReport?.name}.xlsx`);
  };

  return (
    <>
      <div style={{ float: 'right' }}>
        <Tooltip title="Excel-рүү гаргах">
          <Button
            type="link"
            size="large"
            style={{ height: 50 }}
            icon={<img src={imgExcel} height="100%" />}
            onClick={() => {
              exportdata();
            }}
          />
        </Tooltip>
      </div>
      <PivotTableUI
        renderers={{ ...TableRenderers }} // ,PlotlyRenderers)}
        onChange={s => setState(s)}
        {...pivotPresets}
        {...state}
      />
    </>
  );
}

