import { FilterOutlined, HomeFilled, SearchOutlined } from '@ant-design/icons';
import { Card, Space, Typography, Form, Radio, Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FILTER_MODE } from '../../../../constants/LazyTable';
import OrgContext from '../../../../context/OrgContext';
import {
  getRefBagKhoroo,
  getRefCities,
  getRefDistricts,
  getRefOrgTypes,
  getRefUlsTez,
  serviceRefISIC,
} from '../../../../service/referenceServices';
import AutocompleteSelect from '../../../components/autocomplete/Autocomplete';
import styles from './style.module.scss';

const { Text } = Typography;

export default function CardFilter({ searchType }) {
  const [radioTez, setRadioTez] = useState(1);
  const [stateAimag, setStateAimag] = useState([]);
  const [stateSum, setStateSum] = useState([]);
  const [stateBagKhoroo, setBagKhoroo] = useState([]);
  const [stateUlsTez, setStateUlsTez] = useState([]);
  const [stateOrgTypes, setStateOrgTypes] = useState([]);
  const [stateBag, setStateBag] = useState(null);

  const { form, setMain, stateMap, setMapMain, mainState } =
    useContext(OrgContext);

  const onChangeTez = e => {
    form.resetFields();
    setRadioTez(e.target.value);
  };

  const getDistrict = cityCode => {
    const param = {
      search: {
        status: true,
        refCityCode: {
          value: cityCode,
          matchMode: FILTER_MODE.CONTAINS,
        },
      },
    };
    getRefDistricts(param).then(result => {
      setStateSum(result.content || []);
    });
  };

  const onChangeAimag = (id, selectedItem) => {
    setMain({
      refCityId: id,
      refDistrictId: null,
    });
    if (searchType === 'byMap') {
      setMapMain({
        refCityId: id,
        refDistrictId: null,
      });
      setStateBag(null);
      form.resetFields();
    }
    if (selectedItem) {
      getDistrict(selectedItem.code);
    }
  };

  const onChangeBudgetGovernor = id => {
    setMain({
      budgetGovernor: id,
    });
  };

  useEffect(() => {
    if (stateMap.refCityCode) {
      getDistrict(stateMap.refCityCode);
    }
  }, [stateMap.refCityCode]);

  const onChangeSum = (id, selectedItem) => {
    setMain({ refDistrictId: id });
    setStateBag(null);
    if (selectedItem) {
      const param = {
        search: {
          status: true,
          refDistrictCode: {
            value: selectedItem.code,
            matchMode: FILTER_MODE.CONTAINS,
          },
        },
      };
      getRefBagKhoroo(param).then(result => {
        setBagKhoroo(result.content || []);
      });
    }
  };

  useEffect(() => {
    if (searchType === 'byTEZ') {
      form.resetFields();
      getRefCities({ search: { status: true } }).then(result => {
        if (result) {
          setStateAimag(result.content || []);
        }
      });
      const tezParams = {
        search: {
          status: true,
          type: radioTez,
        },
      };
      setStateUlsTez([]);
      getRefUlsTez(tezParams).then(result => {
        if (result) {
          setStateUlsTez(result.content || []);
        }
      });
      getRefOrgTypes({ search: { status: true } }).then(result => {
        if (result) {
          setStateOrgTypes(result.content || []);
        }
      });
      if (mainState.refOrgTypeId) {
        form.setFieldsValue({ refOrgTypeId: mainState.refOrgTypeId });
      }
      if (
        !(
          mainState.refCityId ||
          mainState.refDistrictId ||
          mainState.refOrgTypeId ||
          mainState.refTypeId ||
          mainState.activityCode ||
          mainState.registerNo ||
          mainState.name ||
          mainState.budgetGovernor
        )
      ) {
        setMain({ budgetGovernor: 1 });
        form.setFieldsValue({ budgetGovernor: 1 });
      }
    }
    if (searchType === 'byMap') {
      form.resetFields();
      getRefCities({ search: { status: true } }).then(result => {
        if (result) {
          setStateAimag(result.content || []);
          setMapMain({ stateAimag: result.content || [] });
        }
      });
    }
    if (searchType === 'byOrgType' || searchType === 'byInactiveOrg') {
      form.resetFields();
      getRefCities({ search: { status: true } }).then(result => {
        if (result) {
          setStateAimag(result.content || []);
        }
      });
      getRefOrgTypes({ search: { status: true } }).then(result => {
        if (result) {
          setStateOrgTypes(result.content || []);
        }
      });
    }
  }, [radioTez, searchType]);

  const submitForm = ({
    refOrgTypeId,
    refCityId,
    refDistrictId,
    refTypeId,
    activityCode,
    registerNo,
    budgetGovernor,
  }) => {
    setMain({
      refOrgTypeId,
      refCityId,
      refDistrictId,
      refTypeId,
      activityCode,
      registerNo,
      budgetGovernor,
    });
    setMapMain({
      refCityId,
      refDistrictId,
      boolean: true,
    });
  };

  const clearFrom = () => {
    setBagKhoroo([]);
    setStateBag(null);
    form.resetFields();
    form.setFieldsValue({ budgetGovernor: 1 });
    setMapMain({
      refCityId: null,
      refDistrictId: null,
    });
    setMain({
      refCityId: null,
      refDistrictId: null,
      budgetGovernor: 1,
      refTypeId: null,
      refOrgTypeId: null,
      activityCode: null,
    });
  };

  return (
    <Card
      title={
        <Space>
          <SearchOutlined />
          <Text className={styles.cardheaderText}>Дэлгэрэнгүй хайлт</Text>
        </Space>
      }
      extra={<HomeFilled />}
      className={styles.contentFilterRow}
    >
      <Form form={form} layout="vertical" onFinish={submitForm}>
        {(searchType === 'byOrgType' || searchType === 'byTEZ') && (
          <>
            <Form.Item label="">
              <Radio.Group
                name="radiogroup"
                onChange={onChangeTez}
                defaultValue={1}
              >
                <Radio value={1}>Улсын ТЕЗ</Radio>
                <Radio value={2}>Орон нутгийн ТЕЗ</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Төсвийн захирагчийн ангилал:"
              name="budgetGovernor"
            >
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                onChange={onChangeBudgetGovernor}
                data={[
                  { id: 1, name: 'Төсвийн ерөнхийлөн захирагч' },
                  { id: 2, name: 'Төсвийн төвлөрүүлэн захирагч' },
                  { id: 3, name: 'Төсвийн шууд захирагч' },
                ]}
              />
            </Form.Item>
            <Form.Item label="Харьяа байгууллагууд: " name="refTypeId">
              <AutocompleteSelect
                data={stateUlsTez}
                viewfield="name"
                valuefield="id"
              />
            </Form.Item>
            <Form.Item label="Байгууллагын төрөл: " name="refOrgTypeId">
              <AutocompleteSelect data={stateOrgTypes} />
            </Form.Item>
            <Form.Item
              label="Үйл ажиллагааны чиглэлийн код: "
              name="activityCode"
            >
              <AutocompleteSelect
                service={serviceRefISIC}
                valuefield="classCode"
                viewfield="className"
                ismultifield="1"
              />
            </Form.Item>
            <Form.Item label="Аймаг. нийслэл" name="refCityId">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                onChange={onChangeAimag}
                data={stateAimag}
              />
            </Form.Item>
            <Form.Item label="Сум, Дүүрэг:" name="refDistrictId">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                data={stateSum}
              />
            </Form.Item>
          </>
        )}
        {searchType === 'byMap' && (
          <>
            <Form.Item label="Аймаг, Нийслэл:" name="refCityId">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                defaultValue={stateMap.refCityId}
                onChange={onChangeAimag}
                data={stateAimag}
              />
            </Form.Item>
            <Form.Item label="Сум, Дүүрэг:" name="refDistrictId">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                data={stateSum}
                onChange={onChangeSum}
              />
            </Form.Item>
            <Form.Item label="Баг хороо: ">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                data={stateBagKhoroo}
                value={stateBag}
                onChange={e => setStateBag(e)}
              />
            </Form.Item>
          </>
        )}

        {searchType === 'byInactiveOrg' && (
          <>
            <Form.Item label="Байгууллагын төрөл: " name="refOrgTypeId">
              <AutocompleteSelect data={stateOrgTypes} allowClear />
            </Form.Item>
            <Form.Item
              label="Үйл ажиллагааны чиглэлийн код: "
              name="activityCode"
            >
              <AutocompleteSelect
                service={serviceRefISIC}
                valuefield="classCode"
                viewfield="className"
                ismultifield="1"
              />
            </Form.Item>
            <Form.Item label="Аймаг. нийслэл" name="refCityId">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                onChange={onChangeAimag}
                data={stateAimag}
              />
            </Form.Item>
            <Form.Item label="Сум, Дүүрэг:" name="refDistrictId">
              <AutocompleteSelect
                valuefield="id"
                viewfield="name"
                data={stateSum}
              />
            </Form.Item>
          </>
        )}
        <Form.Item style={{ textAlign: 'right' }}>
          <Space
            style={{
              flexWrap: 'wrap',
              textAlign: 'right',
              justifyContent: 'right',
            }}
          >
            <Button icon={<FilterOutlined />} htmlType="submit" type="primary">
              Шүүх
            </Button>
            <Button onClick={clearFrom}>Цэвэрлэх</Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
}
