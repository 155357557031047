import React, { useState, useEffect } from 'react';
import { Checkbox, Col, Tree } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { useAppStore } from '../../../context/Tools';
import { Loading } from './Loading';
import styles from './style.module.scss';
import {
  getRefCities,
  getRefDistricts,
} from '../../../service/referenceServices';
import { FILTER_SPAN } from '../utils';

export default function FilterByLocation() {
  const { cityList, setCityList, filters, setFilters } = useAppStore();

  const { positions } = filters;

  const [cityState, setCityState] = useState({
    indeterminate: false,
    checkAll: false,
  });

  const getDistrict = async city => {
    const params = { search: { status: true, refCityCode: city.cityCode } };
    const { content } = await getRefDistricts(params);
    const districts = content.map(district => ({
      type: 'district',
      title: district.name,
      key: `${city.cityCode}-${district.code}`,
      cityId: city.cityId,
      cityCode: city.cityCode,
      districtId: district.id,
      districtCode: district.code,
      isLeaf: true,
    }));
    const currCity = cityList.find(item => item.cityCode === city.cityCode);
    if (currCity) {
      currCity.children = districts;
      setCityList([...cityList]);
    }
  };

  // const getKhoroo = async (cityCode, districtCode) => {
  //   const params = {
  //     search: {
  //       status: true,
  //       refCityCode: cityCode,
  //       refDistrictCode: districtCode,
  //     },
  //     size: 100,
  //   };

  //   const { content } = await getRefBagKhoroo(params);
  //   const khoroos = content.map(khoroo => ({
  //     title: khoroo.name,
  //     key: `${cityCode}-${districtCode}-${khoroo.code}`,
  //     cityCode,
  //     districtCode,
  //     khorooCode: khoroo.code,
  //     isLeaf: true,
  //   }));
  //   const currCity = cityList.find(city => city.cityCode === cityCode);
  //   if (currCity) {
  //     const currDistrict = currCity.children.find(
  //       district => district.districtCode === districtCode
  //     );
  //     if (currDistrict) {
  //       currDistrict.children = khoroos;
  //       setCityList([...cityList]);
  //     }
  //   }
  // };

  const onLoadData = async city => {
    if (!isEmpty(city.children)) {
      return;
    }
    await getDistrict(city);
    // if (districtCode) {
    //   await getKhoroo(cityCode, districtCode);
    // } else {
    //   await getDistrict(cityCode);
    // }
  };

  const getCityData = async () => {
    if (!isEmpty(cityList)) {
      return;
    }
    try {
      const { content } = await getRefCities({
        search: { status: true },
        size: 100,
      });
      const cityData = content.map(item => ({
        type: 'city',
        title: item.name,
        key: item.code,
        cityId: item.id,
        cityCode: item.code,
      }));
      setCityList(cityData);
    } catch {
      setCityList([]);
    }
  };

  useEffect(() => {
    getCityData();
  }, []);

  return (
    <Col {...FILTER_SPAN} className={styles.subMenu}>
      <div className={styles.subHeader}>
        <div> Байршил</div>
        <Checkbox
          checked={cityState.checkAll}
          disabled={!cityList}
          indeterminate={cityState.indeterminate}
          onChange={e => {
            if (isNil(cityList)) return;
            setFilters({
              ...filters,
              positions: e.target.checked ? cityList : [],
            });
            setCityState({
              indeterminate: false,
              checkAll: e.target.checked,
            });
          }}
        >
          Бүгд
        </Checkbox>
      </div>
      {isNil(cityList) ? (
        <Loading />
      ) : (
        <Tree
          className={styles.menuBox}
          treeData={cityList}
          checkable
          selectable={false}
          checkedKeys={(positions || []).map(p => p.key)}
          loadData={onLoadData}
          onCheck={(keys, event) => {
            const checkedCityDistrictBoth = event.checkedNodes || [];
            const checkedCities = checkedCityDistrictBoth.filter(
              t => t.type === 'city'
            );
            setFilters({ ...filters, positions: checkedCityDistrictBoth });
            setCityState({
              indeterminate:
                !!checkedCities.length &&
                checkedCities.length < cityList.length,
              checkAll: checkedCities.length === cityList.length,
            });
          }}
        />
      )}
    </Col>
  );
}

