import React, { useState } from 'react';
import AccountInformation from './accountInformation/accountInformation';
// import BranchOrganizations from './branchOrganizations/branchOrganizations';
import InfoTabs from '../../components/infoTabs/infoTabs';
import OrgGeneralInfo from '../../components/generalInfoText/OrgGeneralInfo';

export default function GeneralInfotabs() {
  const [typeOfExpenditure, setTypeOfExpenditure] = useState([]);

  return (
    <InfoTabs
      setTypeOfExpenditure={setTypeOfExpenditure}
      typeOfExpenditure={typeOfExpenditure}
      tabs={[
        {
          key: 'OrganzationInfo',
          name: 'Байгууллагын ерөнхий мэдээлэл',
          component: <OrgGeneralInfo />,
        },
        {
          key: 'AccountInformation',
          name: 'Дансны мэдээлэл',
          component: <AccountInformation />,
        },
        // {
        //   key: 'BranchOrganizations',
        //   name: 'Салбар байгууллагууд',
        //   component: <BranchOrganizations />,
        // },
      ]}
    />
  );
}
