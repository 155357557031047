import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, DatePicker, message, Space } from 'antd';
import { isEmpty } from 'lodash';
import { useAppStore } from '../../../../context/Tools';
import { getStaticForm } from '../../../../service/reportServices';

export const ReportInfoByForm = ({ setStaticReportData }) => {
  const { cancelReportRef, serverDate } = useAppStore();

  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(serverDate.getFullYear());

  const onChangeYear = (date, dateString) => {
    setYear(dateString);
  };

  const getData = async () => {
    if (!year) {
      message.info('Он сонгоогүй байна.');
      return;
    }
    setIsLoading(true);
    const params = { year, size: 1000 };

    try {
      const result = await getStaticForm(params, cancelReportRef);
      const dataList = result || [];

      const processedData = [];

      dataList.forEach(report => {
        if (!isEmpty(report.staticReportByOrgDTOList)) {
          const sublist = report.staticReportByOrgDTOList.map(org => ({
            reportFormName: report.reportFormName,
            ...org,
          }));
          processedData.push(...sublist);
        } else {
          processedData.push(report);
        }
      });
      setStaticReportData(processedData);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(getData, []);

  return (
    <Space size={16}>
      <span>Тайлант үе</span>
      <DatePicker
        allowClear={false}
        onChange={onChangeYear}
        showToday
        defaultValue={moment(year, 'YYYY')}
        picker="year"
        disabledDate={current => current.isBefore(moment('2023-01-01'))}
      />
      <Button style={{ width: 90 }} loading={isLoading} onClick={getData}>
        Шүүх
      </Button>
    </Space>
  );
};

