import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');
am4core.options.autoDispose = true;

function StackedBarChartSecond({ budgetData, id, valueRadio }) {
  budgetData.sort((a, b) => (a.budget > b.budget ? 1 : -1));

  const maxOfBudget = Math.max(...budgetData?.map(item => item.budget), 0);
  const dummy = JSON.parse(JSON.stringify(budgetData));
  dummy.forEach(el => {
    if (el.budget === maxOfBudget) {
      el.budget = 0;
    }
  });
  const secondMaxOfBudget = Math.max(...dummy.map(item => item.budget), 0);

  useLayoutEffect(() => {
    const chart = am4core.create(id, am4charts.XYChart);

    // Add data
    chart.data = budgetData;
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';

    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'aimagName';
    categoryAxis.renderer.grid.template.opacity = 0;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color('#495C43');
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = 100;
    valueAxis.title.text = '[bold font-size: 20] Дүн (сая төгрөгөөр)';

    // ---Create Breaks
    const axisBreak = valueAxis.axisBreaks.create();
    axisBreak.startValue = secondMaxOfBudget + 5000;
    axisBreak.endValue = maxOfBudget - 5000;
    axisBreak.breakSize = 0.02;

    // ---make break expand on hover
    const hoverState = axisBreak.states.create('hover');
    hoverState.properties.breakSize = 1;
    hoverState.properties.opacity = 0.1;
    hoverState.transitionDuration = 1500;

    // Create series
    function createSeries(field, name, color) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = 'aimagName';
      series.name = name;
      series.fill = am4core.color(color);
      series.strokeWidth = 0;
      series.columns.template.tooltipText = `{name}\n[bold font-size: 20]{valueX} сая төгрөг`;
      series.columns.template.height = am4core.percent(40);
    }

    if (valueRadio === 'budget') {
      createSeries(
        'budget',
        'Улсын төсөвт төвлөрүүлэх /Төлөвлөгөө/',
        '#9c75bf'
      );
    }
    if (valueRadio === 'performance') {
      createSeries(
        'performance',
        'Улсын төсөвт төвлөрүүлэх /Гүйцэтгэл/',
        '#9c75bf'
      );
    }
  }, [budgetData, secondMaxOfBudget, budgetData, id, valueRadio]);

  return <div id={id} style={{ width: '100%', height: '500px' }} />;
}

export default StackedBarChartSecond;
