import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Dropdown, Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import styles from './style.module.scss';
import DataGraphic from './OpenDataResultMenu/DataGraphic';
import DataMetaData from './OpenDataResultMenu/DataMetaData';
import DataDownload from './OpenDataResultMenu/DataDownload';
import Comment from '../components/Comment/Comment';
import Filter from './filter/Filter';
import PivotTable from './OpenDataPivotTable';
import inInfo from '../../img/openData/inInfo.svg';
import inChart from '../../img/openData/inChart.svg';
import acChart from '../../img/openData/acChart.svg';
import inDownload from '../../img/openData/inDownload.svg';
import inPrint from '../../img/openData/inPrint.svg';
import inData from '../../img/openData/inData.svg';
import inTable from '../../img/openData/inTable.svg';
import acDownload from '../../img/openData/acDownload.svg';
import acData from '../../img/openData/acData.svg';
import acTable from '../../img/openData/acTable.svg';
import down from '../../img/Down.svg';
import up from '../../img/openData/up.svg';
import { useAppStore } from '../../context/Tools';
import { staticReportConvertedList, staticReportDownloadList } from './utils';
import OpendataStaticReport from './OpendataStaticReport/OpendataStaticReport';
import ReportSelectorTree from './OpenDataResultMenu/ReportSelectorTree';
import { getReportForms } from '../../service/reportServices';
import { STATIC_REPORT } from '../../constants/ReportConstants';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

export default function OpenDataResult() {
  const { cancelReportRef, reportList, selectedReport, setSelectedReport } =
    useAppStore();

  const { reportFormId } = useParams();

  const [showType, setShowType] = useState('pivot');
  const [filterVisible, setFilterVisible] = useState(false);
  const [dataList, setDataList] = useState();
  const [downloadList, setDownloadList] = useState();

  const setReport = async () => {
    if (!reportFormId) {
      return;
    }
    let findReport = {};
    if (isEmpty(reportList)) {
      findReport = STATIC_REPORT.children.find(z => z.id === reportFormId);
      if (isEmpty(findReport)) {
        const params = { search: { id: reportFormId } };
        const report = await getReportForms(params);
        if (!isEmpty(report)) {
          // eslint-disable-next-line prefer-destructuring
          findReport = report[0];
        }
      }
    } else {
      let reportForms = [];
      reportList.forEach(report => {
        reportForms = [...reportForms, ...(report.children || [])];
      });
      findReport = reportForms.find(r => `${r.id}` === `${reportFormId}`);
    }
    if (cancelReportRef && cancelReportRef.current) {
      cancelReportRef.current.cancel();
    }
    setSelectedReport(findReport || {});
    setFilterVisible(!findReport?.ownFilter);
    setDataList([]);
  };

  const print = () => {
    window.print();
  };

  const handleFilter = () => {
    if (selectedReport?.ownFilter) {
      return;
    }
    setFilterVisible(!filterVisible);
  };

  const setData = (list, isFinish) => {
    if (selectedReport.isStaticReport) {
      setDataList(staticReportConvertedList(list, selectedReport.fields));
      setDownloadList(staticReportDownloadList(list, selectedReport.fields));
    } else {
      setDataList(list);
      setDownloadList(list);
    }
    if (isFinish) {
      setFilterVisible(false);
    }
  };

  useEffect(setReport, [reportFormId]);

  if (isEmpty(selectedReport)) {
    return (
      <Row
        justify="center"
        className={styles.resultContainer}
        style={filterVisible && { marginBottom: 150 }}
      >
        <Col span={20}>
          <Skeleton active />
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <Row justify="center" style={filterVisible && { marginBottom: 150 }}>
        <Col {...CONTENT_SPAN}>
          <div className="sub-title">НЭЭЛТТЭЙ ӨГӨГДЛИЙН САН</div>
          <div className={styles.content}>
            <div className={styles.menuSide}>
              <Dropdown overlay={<ReportSelectorTree />} trigger={['click']}>
                <div className={styles.menu} role="presentation">
                  <img src={inInfo} alt="info" />
                  Мэдээлэл
                </div>
              </Dropdown>
              <div
                className={`${styles.menu} ${
                  showType === 'pivot' ? styles.activeMenu : ''
                }`}
                onClick={() => setShowType('pivot')}
                role="presentation"
              >
                <img
                  src={showType === 'pivot' ? acTable : inTable}
                  alt="table"
                />
                Хүснэгт
              </div>
              <div
                className={`${styles.menu} ${
                  showType === 'chart' ? styles.activeMenu : ''
                }`}
                onClick={() => setShowType('chart')}
                role="presentation"
              >
                <img
                  src={showType === 'chart' ? acChart : inChart}
                  alt="chart"
                />
                График
              </div>
              <div
                className={`${styles.menu} ${
                  showType === 'download' ? styles.activeMenu : ''
                }`}
                onClick={() => setShowType('download')}
                role="presentation"
              >
                <img
                  src={showType === 'download' ? acDownload : inDownload}
                  alt="download"
                />
                Татах
              </div>
              <div className={styles.menu} onClick={print} role="presentation">
                <img src={inPrint} alt="print" />
                Хэвлэх
              </div>
              <div
                className={`${styles.menu} ${
                  showType === 'metadata' ? styles.activeMenu : ''
                }`}
                onClick={() => setShowType('metadata')}
                role="presentation"
              >
                <img
                  src={showType === 'metadata' ? acData : inData}
                  alt="data"
                />
                Мета дата
              </div>
            </div>
            <div className={styles.result}>
              <Dropdown
                overlayStyle={{ width: 'min-content', zIndex: 1 }}
                overlay={
                  selectedReport?.ownFilter ? (
                    <></>
                  ) : (
                    <Filter setData={setData} />
                  )
                }
                trigger={['click']}
                visible={filterVisible}
                onClick={handleFilter}
              >
                <div className={styles.filter}>
                  <div className={styles.reportName}>
                    {selectedReport?.name}
                  </div>
                  {selectedReport?.ownFilter ? (
                    <Filter setData={setData} />
                  ) : (
                    <div>
                      Шүүлт
                      <img
                        src={filterVisible ? up : down}
                        className={styles.filterIcon}
                      />
                    </div>
                  )}
                </div>
              </Dropdown>
              {showType === 'pivot' &&
                (selectedReport && selectedReport.isStaticReport ? (
                  <OpendataStaticReport dataList={dataList || []} />
                ) : (
                  <PivotTable dataList={dataList || []} />
                ))}
              {!filterVisible && showType === 'chart' && <DataGraphic />}
              {!filterVisible && showType === 'download' && (
                <DataDownload dataList={downloadList || []} />
              )}
              {!filterVisible && showType === 'metadata' && <DataMetaData />}
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <Comment />
        </Col>
      </Row>
    </div>
  );
}

