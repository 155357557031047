export const concessData = [
  {
    name: 'Хорооны цогцолборын барилга худалдан авах /Улаанбаатар, Баянгол дүүрэг, 24 р хороо/',
    address: 'Улаанбаатар, Баянгол дүүрэг',
    tez: 'Засгийн газрын хэрэг эрхлэх газар',
    id: 1,
  },
  {
    name: 'Оюутолгой- Цагаансуврага чиглэлийн 220кВ -ын хоёр хэлхээт ЦДАШ, дэд станц',
    address: 'Улаанбаатар, Баянгол дүүрэг',
    tez: 'Засгийн газрын хэрэг эрхлэх газар',
    id: 2,
  },
  {
    name: 'Хорооны цогцолборын барилга худалдан авах /Улаанбаатар, Баянгол дүүрэг, 24 р хороо/',
    address: 'Улаанбаатар, Баянгол дүүрэг',
    tez: 'Засгийн газрын хэрэг эрхлэх газар',
    id: 3,
  },
  {
    name: 'Оюутолгой- Цагаансуврага чиглэлийн 220кВ -ын хоёр хэлхээт ЦДАШ, дэд станц',
    address: 'Улаанбаатар, Баянгол дүүрэг',
    tez: 'Засгийн газрын хэрэг эрхлэх газар',
    id: 4,
  },
  {
    name: 'Хорооны цогцолборын барилга худалдан авах /Улаанбаатар, Баянгол дүүрэг, 24 р хороо/',
    address: 'Улаанбаатар, Баянгол дүүрэг',
    tez: 'Засгийн газрын хэрэг эрхлэх газар',
    id: 5,
  },
  {
    name: 'Оюутолгой- Цагаансуврага чиглэлийн 220кВ -ын хоёр хэлхээт ЦДАШ, дэд станц',
    address: 'Улаанбаатар, Баянгол дүүрэг',
    tez: 'Засгийн газрын хэрэг эрхлэх газар',
    id: 6,
  },
];
