/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Col, Row, Button, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import logo from '../../img/suld.svg';
import Phone from '../../img/logos/phone';
import Mail from '../../img/logos/mail';
import styles from './style.module.scss';
import { LOGIN_URL } from '../../constants/SystemConstants';

const { Option } = Select;

function Header() {
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  };

  const enterHandler = () => {
    window.open(LOGIN_URL);
  };

  return (
    <Row justify="center">
      <Col span={19}>
        <div className="header">
          <div className="logo-nav">
            <div className="logo-container">
              <img
                src={logo}
                alt="logo"
                className="logo"
                onClick={handleHome}
              />
              <Col push={1} onClick={handleHome}>
                <span className={styles.headerSpan}>ШИЛЭН ДАНС</span>
              </Col>
            </div>
            <ul className="nav-options">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: 10, paddingTop: 4 }}>
                    <Phone />
                  </div>
                  +(976)-7100 1111
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: 10, paddingTop: 4 }}>
                    <Mail />
                  </div>
                  shilendans@mof.gov.mn
                </div>
              </div>
              <li className="option">
                <Row>
                  <Col style={{ display: 'none' }}>
                    <Select
                      labelInValue
                      defaultValue={{ value: 'Mongolian' }}
                      style={{ marginRight: '20px' }}
                    >
                      <Option value="Mongolian">MN</Option>
                      <Option value="English">EN</Option>
                    </Select>
                  </Col>
                  <Col>
                    <Button type="primary" onClick={enterHandler}>
                      <b>Нэвтрэх</b>
                    </Button>
                  </Col>
                </Row>
              </li>
            </ul>
            <div className="mobile-menu">
              <Col>
                <Button type="primary" onClick={enterHandler}>
                  <b>Нэвтрэх</b>
                </Button>
              </Col>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Header;

