import { Col, Row } from 'antd';
import React from 'react';
import Banner1 from './animation/banner1';
import styles from './style.module.scss';

export default function HeaderTitle() {
  return (
    <Row justify="center" className={styles.headerTitles}>
      <Col xs={19} sm={19} md={17} lg={18}>
        <Row align="bottom" justify="space-between" gutter={[20, 40]}>
          <Col xs={24} sm={24} md={24} lg={14} xl={13}>
            <Row className="banner-title">
              Нээлттэй өгөгдлийн сан гэж юу вэ?
            </Row>
            <Row>
              http://opendata.gov.mn - Монгол Улсын Засгийн газрын “Нээлттэй
              өгөгдлийн нэгдсэн портал” сайт нь төрийн байгууллагын мэдээллийг
              түгээх, дахин ашиглах, үйл ажиллагаандаа хэрэглэх боломжтойгоор
              нийтэлж, төр өгөгдлийг эзэмшдэг биш иргэддээ ашиглуулдаг болгохыг
              зорьж байна. Засгийн газрын мэдээлэл нээлттэй, хүртээмжтэй байх нь
              шийдвэр гаргах түвшин дэх иргэдийн оролцоог нэмэгдүүлэх, эдийн
              засгийн боломжийг бий болгох, төр, хувийн хэвшлийн хамтын
              ажиллагааг дэмжих үндсэн нөхцөл болно.
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} className={styles.svgCol}>
            <Banner1 />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

