import React, { useContext, useState, useEffect, useRef } from 'react';
import { isEmpty, uniq } from 'lodash';
import { STATIC_REPORT } from '../constants/ReportConstants';
import { getServerDate } from '../service/notificationService';
import { getReportForms } from '../service/reportServices';
import { sortByPriority } from '../tools/utils';

export const ToolsContext = React.createContext({});

export const useAppStore = () => useContext(ToolsContext);

export const ContextProvider = ({ children }) => {
  const cancelReportRef = useRef();

  const [serverDate, setServerDate] = useState(new Date());
  const [commentCount, setCommentCount] = useState(0);
  const [commentList, setCommentList] = useState([]);
  const [yearList, setYearList] = useState();
  const [cityList, setCityList] = useState();
  const [tezList, setTezList] = useState();
  const [reportGroupList, setReportGroupList] = useState();
  const [refOrgTypes, setRefOrgTypes] = useState();
  const [reportList, setReportList] = useState();
  const [selectedReport, setSelectedReport] = useState({});
  const [reportHeaderOptions, setReportHeaderOptions] = useState();
  const [filters, setFilters] = useState({
    years: [],
    seasons: [],
    months: [],
    specifications: [],
    headers: [],
    orgInfo: [],
    positions: [],
    tezs: [],
    organizations: [],
    reportForms: [],
    orgTypes: [],
  });

  const getReportList = async () => {
    let reports = reportList || [];
    if (isEmpty(reportList)) {
      try {
        const dataList = await getReportForms();

        const types = uniq(
          dataList.map(report => report.reportFormType?.id)
        ).map(typeId => {
          const find = dataList.find(
            report => report.reportFormType?.id === typeId
          );
          return find.reportFormType;
        });

        const sortedReportTypes = types.sort(sortByPriority);
        sortedReportTypes.forEach(reportType => {
          reportType.children = dataList.filter(
            row => row.reportFormType?.id === reportType.id
          );
        });
        reports = [...sortedReportTypes, STATIC_REPORT];
        setReportList(reports);
      } catch {
        setReportList([]);
      }
    }
    return reports;
  };

  useEffect(() => {
    getServerDate().then(date => {
      setServerDate(date);
    });
  }, []);

  return (
    <ToolsContext.Provider
      value={{
        cancelReportRef,
        serverDate,
        commentCount,
        setCommentCount,
        commentList,
        setCommentList,
        yearList,
        setYearList,
        cityList,
        setCityList,
        tezList,
        setTezList,
        reportGroupList,
        setReportGroupList,
        refOrgTypes,
        setRefOrgTypes,
        reportList,
        getReportList,
        selectedReport,
        setSelectedReport,
        reportHeaderOptions,
        setReportHeaderOptions,
        filters,
        setFilters,
      }}
    >
      {children}
    </ToolsContext.Provider>
  );
};

export default ToolsContext;
