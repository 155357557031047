import { Col, Row } from 'antd';
import React from 'react';
import Banner3 from './animation/banner3';
import styles from './style.module.scss';

export default function HeaderTitle() {
  return (
    <Row justify="center" className={styles.headerTitles}>
      <Col xs={19} sm={19} md={17} lg={18}>
        <Row justify="space-between" gutter={[20, 40]}>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row className="banner-title" style={{ paddingTop: 40 }}>
              ЯАГААД ХЭРЭГТЭЙ ВЭ?
            </Row>
            <Row>
              Чөлөөтэй ашиглана. Үр ашиг олно. Дахин ашиглана, боловсруулна
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className={styles.svgCol}>
            <Banner3 />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

