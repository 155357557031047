import { Col, Row } from 'antd';
import React from 'react';
import Banner2 from './animation/banner2';
import styles from './style.module.scss';

export default function HeaderTitle() {
  return (
    <Row justify="center" className={styles.headerTitles}>
      <Col xs={19} sm={19} md={17} lg={18}>
        <Row justify="space-between" gutter={[20, 40]}>
          <Col xs={24} sm={24} md={24} lg={11}>
            <Row className="banner-title">Нээлттэй өгөгдөл гэж юу вэ?</Row>
            <Row>
              Машинд уншигддаг, ямар нэгэн бүртгэл, бичиг баримт
              шаардагдахгүйгээр авч, чөлөөтэй ашиглаж, хэрэглэж, дахин түгээж
              болдог, нээлттэй лицензтэй цахим өгөгдөл
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className={styles.svgCol}>
            <Banner2 />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

