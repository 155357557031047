import React, { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Checkbox,
  Rate,
  Menu,
  Dropdown,
  Space,
  Table,
  Button,
  List,
} from 'antd';
import {
  BarChartOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  TableOutlined,
} from '@ant-design/icons';
import ReactApexChart from 'react-apexcharts';
import search from '../../../img/investment/search.svg';
import home from '../../../img/investment/home.svg';
import brush from '../../../img/investment/brush.svg';
import filter from '../../../img/investment/filter.svg';
import styles from './style.module.scss';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';

const state = {
  date: {
    series: [
      {
        name: 'Нийт',
        data: [300, 250, 130, 230, 100, 275, 90],
      },
    ],
    options: {
      chart: {
        height: 300,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      yaxis: {
        show: true,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      stroke: {
        size: 2,
        curve: 'smooth',
      },
      xaxis: {
        categories: [2018, 2019, 2020, 2021, 2022, 2023, 2024],
      },
      colors: ['#004a9f'],
    },
  },
};
export default function Search() {
  const { Option } = Select;
  const [isShow, setIsShow] = useState(true);
  const CheckboxGroup = Checkbox.Group;
  const [year, setYear] = useState([]);
  const [mode, setMode] = useState([]);
  const [angilal, setAngilal] = useState([]);
  const [season, setSeason] = useState(1);
  const modeOptions = ['Шинэ', 'Шилжих', 'Төлөвлөгөө', 'Гүйцэтгэл'];
  const orgOptions = (
    <Select className={styles.options} placeholder="сонгох">
      <Option value="Байгууллага 1">Байгууллага 1</Option>
      <Option value="Байгууллага 2">Байгууллага 2</Option>
    </Select>
  );
  const isicOptions = (
    <Select className={styles.isicOptions}>
      <Option value="a">A</Option>
      <Option value="b">B</Option>
      <Option value="c">C</Option>
      <Option value="d">D</Option>
      <Option value="e">E</Option>
      <Option value="f">F</Option>
    </Select>
  );
  const angilalChange = value => {
    setAngilal(value);
  };
  const angilalOptions = (
    <Select
      className={styles.angilalOptions}
      placeholder="сонгох"
      onChange={angilalChange}
    >
      <Option value="ТЕЗ">ТЕЗ</Option>
      <Option value="Хөрөнгө оруулалтын төрлөөр">
        Хөрөнгө оруулалтын төрлөөр
      </Option>
      <Option value="Салбарын ангилалаар">Салбарын ангилалаар</Option>
    </Select>
  );

  const yearOptions = (
    <>
      <Option key="2017">2017 он</Option>
      <Option key="2018">2018 он</Option>
      <Option key="2019">2019 он</Option>
      <Option key="2020">2020 он</Option>
      <Option key="2021">2021 он</Option>
      <Option key="2022">2022 он</Option>
    </>
  );
  const changeYear = e => {
    setYear(e);
  };
  const changeMode = e => {
    setMode(e);
  };
  const changeSeason = e => {
    setSeason(e);
  };
  const clearFilter = () => {
    setYear([]);
    setMode([]);
    setSeason(1);
  };
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FileImageOutlined />}>
        JPG
      </Menu.Item>
      <Menu.Item key="3" icon={<FilePdfOutlined />}>
        Excel
      </Menu.Item>
    </Menu>
  );
  const data = [
    'Байгууллага 1',
    'Байгууллага 2',
    'Байгууллага 3',
    'Байгууллага 4',
    'Байгууллага 5',
    'Байгууллага 6',
  ];
  return (
    <Row justify="center" className={styles.searchContainer}>
      <Col {...CONTENT_SPAN}>
        <div className="sub-title">Хөрөнгө оруулалтын дэлгэрэнгүй хайлт</div>
        <Row justify="end">
          <Space className={styles.downloadText}>
            <Space size={15}>
              <TableOutlined
                className="icon"
                onClick={() => setIsShow(false)}
              />
              <BarChartOutlined
                className="icon"
                onClick={() => setIsShow(true)}
              />
            </Space>
            <Dropdown overlay={menu}>
              <Button type="text">
                <DownloadOutlined className="icon" />
              </Button>
            </Dropdown>
          </Space>
        </Row>
        <Row>
          <Col span={6}>
            <Row className={styles.angilal}>
              <span>Ангилал:</span>
              <span>{angilalOptions}</span>
            </Row>
            <div className={styles.filterContainer}>
              <div className={styles.subHeader}>
                <img src={search} alt="search" />
                <div className={styles.title}>Дэлгэрэнгүй хайлт</div>
                <img src={home} alt="home" />
              </div>
              <div className={styles.filter}>
                {angilal === 'ТЕЗ' && (
                  <div>
                    <div>Улсын ТЕЗ:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                    <div>ОН-ын ТЕЗ:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                    <div>Харьяа байгууллага:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                    <div>Байгууллагын төрөл:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                  </div>
                )}
                {angilal === 'Хөрөнгө оруулалтын төрлөөр' && (
                  <div>
                    <div>ТЕЗ:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                    <div>Төрөл:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                    <div>Ангилал:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                    <div>Байгууллага:</div>
                    <div className={styles.subFilter}>{orgOptions}</div>
                  </div>
                )}

                {angilal === 'Салбарын ангилалаар' && (
                  <>
                    <div>ISIC: </div>
                    <div className={styles.isic}>
                      {isicOptions}
                      <List
                        size="small"
                        bordered
                        dataSource={data}
                        renderItem={item => <List.Item>{item}</List.Item>}
                        className={styles.isicList}
                      />
                    </div>
                  </>
                )}
                <div>Он сонгох:</div>
                <Select
                  mode="multiple"
                  value={year}
                  size="default"
                  placeholder="Он сонгох"
                  onChange={changeYear}
                  style={{ width: '100%' }}
                >
                  {yearOptions}
                </Select>
                <span>Улирал: </span>
                <Rate
                  className={styles.rate}
                  value={season}
                  character={({ index }) => index + 1}
                  count={4}
                  onChange={changeSeason}
                />
                <CheckboxGroup
                  value={mode}
                  options={modeOptions}
                  onChange={changeMode}
                />
                <Row justify="space-between" className={styles.row}>
                  <Button onClick={clearFilter} className={styles.btnClear}>
                    <img src={brush} alt="clear" />
                    Цэвэрлэх
                  </Button>
                  <Button type="primary" className={styles.btnFilter}>
                    <img src={filter} alt="filter" />
                    Шүүх
                  </Button>
                </Row>
              </div>
            </div>
          </Col>

          <Col span={18} className={styles.chart}>
            {isShow ? (
              <ReactApexChart
                options={state.date.options}
                series={state.date.series}
                type="bar"
                height="100%"
                width="100%"
              />
            ) : (
              <Table className={styles.testtable} pagination={false} />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

