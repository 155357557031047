import React, { useState } from 'react';
import { Layout, Menu, Typography, Space, Tabs } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { FaHammer, FaBookOpen, FaSearch, FaVideo } from 'react-icons/fa';
import styles from './style.module.scss';
import TheLaw from '../thelaw/TheLaw';
import Research from '../research/research';
import BudgetFinance from '../budgetFinance/budgetFinance';
import HandBook from '../handBook/handBook';
import VideoLearning from '../videoLearning/videoLearning';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

function Contents() {
  const [clicktab, setClicktab] = useState();
  const [collapsed, setCollapsed] = useState(true);

  const click = e => {
    setClicktab(e.key);
    setCollapsed(true);
  };

  return (
    <Content className={styles.siderContent}>
      <Layout className={styles.siteLayoutBackgroundMain}>
        <Sider
          breakpoint="lg"
          collapsedWidth="50"
          className={styles.siteLayoutBackground}
          width={210}
          trigger={null}
          collapsed={collapsed}
          onCollapse={isCollapse => setCollapsed(isCollapse)}
        >
          <div className={styles.siderHeader}>
            <Space onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              {!collapsed && (
                <Text className={styles.siderHeaderText}>Суралцахуй</Text>
              )}
            </Space>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '50%' }}
            className={styles.siteLayoutBackground}
            onClick={click}
          >
            <Menu.Item icon={<FaHammer />} key="10">
              Хууль, эрх зүй
            </Menu.Item>
            <Menu.Item key="11" icon={<FaBookOpen />}>
              Гарын авлага
            </Menu.Item>
            <SubMenu icon={<FaSearch />} title="Судалгаа, тайлан">
              <Menu.Item key="12">
                <div className={styles.text}>
                  Төсөв санхүүгийн бодлогын баримт бичиг
                </div>
              </Menu.Item>
              <Menu.Item key="13">
                <div className={styles.text}>
                  Ном, гарын авлага, судалгааны тайлан
                </div>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="14" icon={<FaVideo />}>
              Видео сургалт
            </Menu.Item>
          </Menu>
        </Sider>
        <Content className={styles.siderContentSecond}>
          <Tabs hideAdd={false} activeKey={clicktab} defaultActiveKey="10">
            <TabPane tab="Tab 1" key="10">
              <TheLaw />
            </TabPane>
            <TabPane tab="Tab 2" key="11">
              <HandBook />
            </TabPane>
            <TabPane tab="Tab 3" key="12">
              <Research />
            </TabPane>
            <TabPane tab="Tab 4" key="13">
              <BudgetFinance />
            </TabPane>
            <TabPane tab="Tab 5" key="14">
              <VideoLearning />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Content>
  );
}
export default Contents;
