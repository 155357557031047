import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import OpenDataBanner from './OpenDataBanner';
import OpenDataMenu from './OpenDataMenu';
import OpenDataTable from './OpenDataTable';
import Comment from '../components/Comment/Comment';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';
import { useAppStore } from '../../context/Tools';

export default function OpenData() {
  const { reportList, getReportList } = useAppStore();

  const [selectedFormType, setSelectedFormType] = useState();

  useEffect(async () => {
    const reportTypes = await getReportList();
    if (!isEmpty(reportTypes)) {
      setSelectedFormType(reportTypes[0]);
    }
  }, []);

  return (
    <>
      <MetaInfo title="Нээлттэй өгөгдөл - Шилэн данс" />
      <OpenDataBanner />
      <Row justify="center">
        <Col {...CONTENT_SPAN}>
          <OpenDataMenu
            reportFormTypes={reportList}
            selectedFormType={selectedFormType}
            setSelectedFormType={setSelectedFormType}
          />
          <OpenDataTable selectedFormType={selectedFormType} />
          <Comment />
        </Col>
      </Row>
    </>
  );
}

