import { isEmpty, uniq } from 'lodash';
import moment from 'moment';
import { DATEFORMAT } from '../../../../constants/Constant';
import { FORMAT } from '../../../../constants/ReportConstants';
import { sortArray } from '../../../../tools/Tools';

const ReportTemplateGetBody = (selectedReportForm, reportDataBodies) => {
  const headers = selectedReportForm.reportFormHeaders || [];
  const specifications = selectedReportForm.reportFormSpecifications || [];

  const rows = [];
  let lineIndexes = [];

  if (!isEmpty(specifications)) {
    lineIndexes = uniq(specifications.map(field => field.line));
  } else {
    const dataBodies = sortArray(reportDataBodies || [], 'line');
    lineIndexes = uniq(
      dataBodies.filter(field => field.line).map(field => field.line)
    );
  }
  lineIndexes.forEach((line, index) => {
    const row = { line, rowNo: index + 1 };
    const currSpec = specifications.filter(field => field.line === line);
    currSpec.forEach(field => {
      row[field.col] = field.data;
    });

    if (!isEmpty(reportDataBodies)) {
      const currDataBodies = reportDataBodies.filter(
        body => body.line === line
      );
      if (!isEmpty(currDataBodies)) {
        currDataBodies.forEach(body => {
          const findHeader = headers.find(h => h.col === body.col) || {};
          if (
            findHeader?.format === FORMAT.FILE ||
            findHeader?.format === FORMAT.FILE_EXCEL
          ) {
            if (body.data) row[body.col] = JSON.parse(body.data);
          } else if (
            findHeader?.format === FORMAT.CURRENCY ||
            findHeader?.format === FORMAT.NUMBER
          ) {
            row[body.col] =
              Number(body.data?.replaceAll(',', '')) || body?.data;
          } else {
            row[body.col] = body.data;
          }
        });
        if (selectedReportForm.isDynamic) {
          const moments = currDataBodies.map(d => moment(d.createdAt));
          row.createdAt = moment.min(moments).format(DATEFORMAT);
        }
      }
    }

    rows.push(row);
  });
  return rows;
};

export default ReportTemplateGetBody;

