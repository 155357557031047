import React from 'react';
import { Table } from 'antd';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';

export default function MetaData() {
  const { selectedReport } = useAppStore();

  const dataSource = [
    {
      label: 'Тодорхойлолт',
      value: selectedReport?.description,
    },
    {
      label: 'Аргачлал, арга зүйн нэр',
      value: selectedReport.methodName,
    },
    {
      label: 'Тооцох аргачлал',
      value: selectedReport.method,
    },
    {
      label: 'Хэмжих нэгж',
      value: selectedReport.measureUnit,
    },
    {
      label: 'Эх сурвалж',
      value: selectedReport.source,
    },
  ];

  const headerData = [
    {
      title: 'number',
      dataIndex: 'number',
      key: 'number',
      width: 50,
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
      render: text => <div className={styles.metaDataTitle}>{text}</div>,
    },
    {
      title: 'value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <div className={styles.dataContainer}>
      <Table
        pagination={false}
        showHeader={false}
        size="small"
        dataSource={dataSource}
        columns={headerData}
      />
    </div>
  );
}

