export const FILTER_SPAN = { xs: 24, sm: 12, lg: 8 };

export const getOpendataBody = filters => {
  const filterBody = {
    columns: filters.headers,
    lines: filters.specifications,
    reportFormOrganizationDatas: filters.orgInfo,
    reportGroupIds: filters.reportGroups || [],
  };
  return filterBody;
};

export const getOpendataParams = (reportFormId, filters) => {
  // const search = {
  //   'reportForm.id': reportFormId,
  //   year: filters.years,
  // };

  // let calcMonths = filters.months;
  // if (!isEmpty(filters.seasons)) {
  //   calcMonths = [];
  //   filters.seasons.forEach(season => {
  //     calcMonths = [...calcMonths, season * 3 - 2, season * 3 - 1, season * 3];
  //   });
  // }
  // search.month = calcMonths;

  // if (!isEmpty(filters.positions)) {
  //   const cities = filters.positions.filter(p => p.type === 'city');
  //   const cityCodes = cities.map(city => city.cityCode);
  //   search['reportFormOrganizationData.refCityCode'] = cityCodes;

  //   const districts = filters.positions.filter(
  //     p => p.type === 'district' && !cityCodes.includes(p.cityCode)
  //   );
  //   const districtCodes = districts.map(district => district.districtCode);
  //   search['reportFormOrganizationData.refDistrictCode'] = districtCodes;
  // }

  // if (!isEmpty(filters.tezs)) {
  //   search['reportFormOrganizationData.refTypeCode'] = filters.tezs
  //     .filter(t => t.type === 'tez')
  //     .map(t => t.refTypeCode);
  //   search['reportFormOrganizationData.refCentralBudgetGovernorCode'] =
  //     filters.tezs
  //       .filter(t => t.type === 'ttz')
  //       .map(t => t.refCentralBudgetGovernorCode);
  // }

  // if (!isEmpty(filters.organizations)) {
  //   search.orgId = filters.organizations.map(org => org.id);
  // }

  // const params = { search };
  const params = {
    reportFormId,
    columns: filters.headers,
    years: filters.years,
    months: filters.months,
    reportGroupIds: filters.reportGroups || [],
    tez: filters.tezs.filter(t => t.type === 'tez').map(t => t.refTypeCode),
    orgs: filters.organizations.map(org => org.id),
  };
  return params;
};

export const staticReportConvertedList = (list, fields) => {
  const convertedList = [];
  const mustAggrField = (fields || []).find(z => z.aggrFields);

  list.forEach(row => {
    const convertedObj = {};
    (fields || []).forEach(field => {
      convertedObj[field.mon] = row[field.name];
    });
    if (mustAggrField) {
      const mustAggrList = mustAggrField.aggrFields.map(aggr => ({
        ...convertedObj,
        [mustAggrField.mon]: aggr.mon,
        value: row[aggr.name],
      }));
      convertedList.push(...mustAggrList);
    } else {
      convertedList.push(convertedObj);
    }
  });
  // console.log(convertedList);
  return convertedList;
};

export const staticReportDownloadList = (list, fields) =>
  list.map(row => {
    const convertedObj = {};
    (fields || []).forEach(field => {
      if (field.aggrFields) {
        field.aggrFields.forEach(aggr => {
          convertedObj[aggr.mon] = row[aggr.name];
        });
      } else {
        convertedObj[field.mon] = row[field.name];
      }
    });
    return convertedObj;
  });

