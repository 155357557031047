import React, { useEffect } from 'react';
import { Col, Tree } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { Loading } from './Loading';
import styles from './style.module.scss';
import { useAppStore } from '../../../context/Tools';
import {
  getRefCentralBudgetGovernor,
  getRefUlsTez,
} from '../../../service/referenceServices';
import { FILTER_SPAN } from '../utils';

export default function FilterByTEZ() {
  const { tezList, setTezList, filters, setFilters } = useAppStore();

  const { tezs } = filters;

  const getTEZdata = async () => {
    if (!isEmpty(tezList)) {
      return;
    }
    try {
      const { content } = await getRefUlsTez();
      const ttzResult = await getRefCentralBudgetGovernor({
        search: { status: true },
        size: 1000,
      });
      const ttzOptions = ttzResult.content;
      const tezData = content.map(item => ({
        type: 'tez',
        title: item.name,
        key: item.code,
        refTypeId: item.id,
        refTypeCode: item.code,
        children: ttzOptions
          .filter(el => el.code === item.code)
          .map(ttz => ({
            type: 'ttz',
            title: ttz.subName,
            key: `${item.code}-${ttz.subCode}`,
            refCentralBudgetGovernorId: ttz.id,
            refCentralBudgetGovernorCode: ttz.subCode,
          })),
      }));
      setTezList(tezData);
    } catch {
      setTezList([]);
    }
  };

  useEffect(() => {
    getTEZdata();
  }, []);

  return (
    <Col {...FILTER_SPAN} className={styles.subMenu}>
      <div className={styles.subHeader}>
        <div> Төсвийн ерөнхийлөн захирагч</div>
      </div>
      {isNil(tezList) ? (
        <Loading />
      ) : (
        <Tree
          className={styles.menuBox}
          treeData={tezList}
          checkable
          autoExpandParent
          checkStrictly
          selectable={false}
          checkedKeys={tezs.map(tez => tez.key)}
          onCheck={(keys, event) => {
            const checkedTezTtzBoth = [event.node] || [];
            setFilters({ ...filters, tezs: checkedTezTtzBoth });
          }}
        />
      )}
    </Col>
  );
}

