import React, { useContext, useState, useEffect } from 'react';
import {
  Col,
  Empty,
  Image,
  Input,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import { isEmpty } from 'lodash';
import suld from '../../../../img/suld.svg';
import styles from './style.module.scss';
import OrgContext from '../../../../context/OrgContext';
import {
  filterActiveOrg,
  getOrganizationsByFields,
} from '../../../../service/organizationServices';
import { TZ_TYPE } from '../../../../constants/OrgConstant';
import OrgCard from '../../../../components/orgCard/OrgCard';

const { Text } = Typography;

export default function BranchContent({ type, title }) {
  const height = document.getElementById('orgMainInfo').offsetHeight || 700;

  const { orgInfo } = useContext(OrgContext);

  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const search = { ...filterActiveOrg };
    if (type === 'TTZ') {
      search.budgetGovernor = TZ_TYPE.TTZ;
      search.refTypeId = orgInfo.refTypeId;
    } else if (type === 'TShZ') {
      search.budgetGovernor = TZ_TYPE.TShZ;
      search.refTypeId = orgInfo.refTypeId;
      if (orgInfo.budgetGovernor === TZ_TYPE.TTZ) {
        search.refCentralBudgetGovernorId = orgInfo.refCentralBudgetGovernorId;
      } else {
        search.refCentralBudgetGovernorId = null;
      }
    } else if (type === 'childOrg') {
      search.parentId = orgInfo.id;
    }
    const params = { search, size: 10000 };

    setTotal(0);
    setLoading(true);

    if (!params.requestedFields) {
      params.requestedFields = 'id,name,refCentralBudgetGovernorId';
    }
    params.sort = 'name';

    getOrganizationsByFields(params)
      .then(result => {
        if (result) {
          let list = result.content || [];
          if (type === 'TShZ' && orgInfo.budgetGovernor === TZ_TYPE.TEZ) {
            // search.refCentralBudgetGovernorId = null; ingej shuuj chadahgui bga tul...
            list = list.filter(org => !org.refCentralBudgetGovernorId);
          }
          setOrgs(list);
          setTotal(list.length);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setOrgs([]);
      });
  }, [type]);

  return (
    <div
      className={styles.contentDiv}
      style={{ height: height - 86, overflow: 'auto' }}
    >
      <Space
        style={{
          marginBottom: 20,
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Space>
          <Image preview={false} src={suld} alt="logo" width={42} />
          <Text strong>{title}</Text>
          <Text strong>({total})</Text>
        </Space>
        <Space>
          Байгууллагаар хайх:
          <Input
            placeholder="Хайх"
            style={{ borderRadius: '8px' }}
            onChange={val => setFilter(val.target.value)}
          />
        </Space>
      </Space>
      <Row gutter={[10, 10]}>
        {orgs
          .filter(org =>
            org?.name?.toLowerCase().includes(filter.toLowerCase())
          )
          .map((org, index) => (
            <Col key={index} sm={24} md={12} lg={12} xxl={8}>
              {loading ? (
                <Skeleton.Input active block />
              ) : (
                <OrgCard org={org} />
              )}
            </Col>
          ))}
        {!loading && isEmpty(orgs) && (
          <Empty description="Өгөгдөл олдсонгүй." />
        )}
      </Row>
    </div>
  );
}
