import React from 'react';
import { Typography, Row, Col } from 'antd';
import styles from './style.module.scss';
import Help from './Help';

const { Text } = Typography;

export default function AuditCompany({ goToTabOrg }) {
  return (
    <>
      <Row>
        <Col span={21}>
          <Text strong className={styles.sideCompanyHeader}>
            Иргэд гомдлын талаар бидэнд мэдэгдэнэ үү.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <br />
          <Help goToTabOrg={goToTabOrg} />
        </Col>
      </Row>
    </>
  );
}
