import React, { useEffect, useState } from 'react';
import { Layout, Col, Button } from 'antd';
import { MdBookmark } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import Glossary from '../glossary/glossary';
import {
  GOVERNMENT_SPECIAL,
  LEARNING_PAGE,
  ORGANIZATION_PAGE,
  OPEN_DATA,
  PUBLIC_PRIVATE_SECTOR,
  HOME_PAGE,
} from '../../settings/constantRoutes';
import MobileMenu from '../mobileMenu/MobileMenu';
// import logo from '../../img/suld.svg';

const { Header } = Layout;

export default function MainMenu() {
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();

  const showDrawer = () => {
    setVisible(true);
  };
  const handleClick = () => {
    setShowMenu(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const onCloseMenu = () => {
    setShowMenu(false);
  };

  const menus = [
    { path: HOME_PAGE, title: 'Нүүр' },
    // { path: UNITED_BUDGET, title: 'Монгол Улсын нэгдсэн төсөв' },
    { path: ORGANIZATION_PAGE, title: 'Байгууллага' },
    { path: PUBLIC_PRIVATE_SECTOR, title: 'Бэлтгэн нийлүүлэгч' },
    // { path: INVESTMENT, title: 'Хөрөнгө оруулалт' },
    { path: GOVERNMENT_SPECIAL, title: 'Засгийн газрын тусгай сан' },
    { path: OPEN_DATA, title: 'Нээлттэй өгөгдөл' },
    { path: LEARNING_PAGE, title: 'Суралцахуй' },
  ];

  useEffect(() => {
    const path = `/${location.pathname.split('/')[1] || ''}`;
    const findMenu = menus.find(menu => path === menu.path);
    setSelectedMenu(findMenu?.path);
  }, [location]);

  const enterHandler = url => url.includes('http') && window.open(url);

  return (
    <>
      <div className={styles.headerStyling}>
        <Header className={styles.bigHeader}>
          <Glossary visible={visible} onClose={onClose} />
          <MobileMenu
            menus={menus}
            showMenu={showMenu}
            onCloseMenu={onCloseMenu}
          />
          {/* <Button
            type="text"
            style={{
              width: 80,
              height: '100%',
              backgroundColor: 'transparent',
              marginRight: 20,
            }}
            onClick={() => navigate('/')}
          >
            <img src={logo} alt="logo" className="logo" />
          </Button> */}
          <Col span={20} className={styles.header}>
            {/* <div className="mobile-menu" style={{ color: 'white' }}>
              ШИЛЭН ДАНС
            </div> */}
            <div className="navs-options">
              {menus.map(menu => (
                <Link
                  onClick={() => enterHandler(menu.path)}
                  key={menu.path}
                  to={!menu.path.includes('http') && menu.path}
                  className={`${styles.headerText} ${
                    selectedMenu === menu.path ? styles.selectedMenu : ''
                  }`}
                >
                  {menu.title?.toUpperCase()}
                </Link>
              ))}
            </div>
            {/* <Button
              type="ghost"
              onClick={() => enterHandler(LOGIN_URL)}
              style={{
                color: 'white',
                marginRight: 20,
                marginLeft: 20,
              }}
            >
              НЭВТРЭХ
            </Button> */}
          </Col>
          <div className="mobile-menu">
            <MenuOutlined className="menu-icon" onClick={handleClick} />
          </div>
        </Header>
        <div className={styles.glossaryDiv}>
          <Button
            onClick={showDrawer}
            type="primarys"
            icon={<MdBookmark style={{ fontSize: 14 }} />}
            danger
            size="small"
          >
            Нэр томъёоны тайлбар
          </Button>
        </div>
      </div>
    </>
  );
}
