import React from 'react';
import { Form } from 'antd';
import { isNil } from 'lodash';
import { MSG } from '../constants/Constant';
import { PATTERN_MN } from '../constants/Pattern';

export default function FormItemCyrillic(props) {
  const rules = props.rules || [];
  const isRequired = isNil(props.required) ? true : props.required;
  const pattern = props.pattern || PATTERN_MN;

  return (
    <Form.Item
      {...props}
      labelAlign="left"
      rules={[
        ...rules,
        {
          required: isRequired,
          message: MSG.MUST_FILL_FIELD,
        },
        {
          pattern,
          message: MSG.CYRILLIC,
        },
      ]}
    >
      {props.children}
    </Form.Item>
  );
}
