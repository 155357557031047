import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import styles from './style.module.scss';
import { ORGANIZATION_PAGE } from '../../../settings/constantRoutes';

const { Text } = Typography;

function ViewedTitle(props) {
  const { feauturedCorps } = props;
  const feauturedData = feauturedCorps;

  const goToLink = link => {
    window.open(`${ORGANIZATION_PAGE}/${link}`);
  };

  return (
    <Row gutter={[30, 30]}>
      {feauturedData.map((item, index) => (
        <Col key={index} xs={24} sm={12} md={12} lg={8} xl={6}>
          <Col className={styles.cardContent} onClick={() => goToLink(item.id)}>
            <div>
              <Text strong className={styles.leftCardTitle}>
                {item.name}
              </Text>
              <br />
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Divider className={styles.leftCardDivider} />
              </div>
              <Row justify="space-between" className={styles.cardFooter}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  Үзсэн: {item?.count}
                </Col>
                {/* <Col span={16} style={{ textAlign: 'right' }}> */}
                {/* <Text>Үнэлгээ</Text>
                  <Rate allowHalf character={<StarOutlined />} /> */}
                {/* </Col> */}
              </Row>
            </div>
          </Col>
        </Col>
      ))}
    </Row>
  );
}
export default ViewedTitle;

