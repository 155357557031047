import React from 'react';
import { Typography, Input, Form } from 'antd';
import styles from './style.module.scss';
import PhoneNumber from '../../components/PhoneNumber';
import FormItemCyrillic from '../../components/FormItemCyrillic';
import { MSG } from '../../constants/Constant';

const { Text } = Typography;

export default function ContactInfo() {
  return (
    <div>
      <Text strong className={styles.isAnswersHeader}>
        Тантай холбогдох мэдээлэл:
      </Text>
      <br />
      <br />
      <FormItemCyrillic name="name" label="Таны нэр:">
        <Input placeholder="Нэр оруулна уу" />
      </FormItemCyrillic>
      <FormItemCyrillic name="address" label="Хаяг:">
        <Input placeholder="Хаяг оруулна уу" />
      </FormItemCyrillic>
      <Form.Item
        name="email"
        label="Цахим шуудан:"
        rules={[
          {
            type: 'email',
            message: 'Цахим шуудан буруу байна.',
          },
          {
            required: true,
            message: MSG.MUST_FILL_FIELD,
          },
        ]}
      >
        <Input placeholder="Цахим шуудан оруулна уу" />
      </Form.Item>
      <PhoneNumber name="phone" label="Утас №1:" />
      <PhoneNumber name="phone1" label="Утас №2:" />
    </div>
  );
}

