import React, { useState } from 'react';
import { Drawer, Skeleton, Space } from 'antd';
import { MdBookmark } from 'react-icons/md';
import styles from './style.module.scss';
import SearchContent from './searContent/searchContent';
import { getTrainingDictionaries } from '../../service/trainingService';

function Glossary({ visible, onClose }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const createGlossary = () => {
    if (loading === true) {
      return;
    }
    getTrainingDictionaries({ search: { status: true } }).then(data => {
      const dataList = data.content || [];
      dataList.forEach((item, index) => {
        item.index = data.totalPages + index;
      });
      setList(dataList);
      setLoading(true);
    });
  };
  if (visible === true) {
    createGlossary();
  }

  return (
    <Drawer
      className={styles.draverDiv}
      title={
        <Space>
          <MdBookmark className={styles.draverHeaderIcon} />
          <span className={styles.drevarHeaderText}>Нэр томъёоны тайлбар</span>
        </Space>
      }
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      zIndex={2000}
      width={350}
    >
      <Space direction="vertical">
        {loading ? (
          <SearchContent close={onClose} details={list} />
        ) : (
          <Skeleton active={loading} />
        )}
      </Space>
    </Drawer>
  );
}
export default Glossary;
