import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styles from '../united-budget/style.module.scss';
import cal11 from '../../img/calendar/cal1.1.svg';
import cal21 from '../../img/calendar/cal2.1.svg';
import cal31 from '../../img/calendar/cal3.1.svg';
import cal41 from '../../img/calendar/cal4.1.svg';
import cal12 from '../../img/calendar/cal1.2.svg';
import cal22 from '../../img/calendar/cal2.2.svg';
import cal32 from '../../img/calendar/cal3.2.svg';
import cal42 from '../../img/calendar/cal4.2.svg';

export default function Calendar() {
  const [isShow, setIsShow] = useState(0);

  const clickShow1 = () => {
    setIsShow(isShow === 1 ? 0 : 1);
  };
  const clickShow2 = () => {
    setIsShow(isShow === 2 ? 0 : 2);
  };
  const clickShow3 = () => {
    setIsShow(isShow === 3 ? 0 : 3);
  };
  const clickShow4 = () => {
    setIsShow(isShow === 4 ? 0 : 4);
  };

  return (
    <div className={styles.calendarContainer}>
      <div className="sub-title">Төсвийн үе шат, цагалбар</div>
      <Row
        justify="center"
        gutter={10}
        className={isShow === 0 ? styles.calendarDe : styles.calendar}
      >
        {/* Negdugeer heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 1 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b1}`} />
              <div className={`${styles.item} ${styles.i1}`}>
                <div>
                  Эрүүл мэндийн даатгалын сангийн жилийн орлого, зарлагын
                  төсвийг Улсын Их Хурал батална.
                </div>
              </div>
            </Row>
            <div
              className={styles.calendarItem1}
              onClick={clickShow1}
              role="presentation"
            >
              Төлөвлөх
            </div>
            <img src={cal12} alt="" className={styles.arrow} />
          </Row>
          <Row className={styles.logo}>
            <img src={cal11} alt="" />
          </Row>
        </Col>
        {/* Hoyordugaar heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 2 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b2}`} />
              <div className={`${styles.item} ${styles.i2}`}>
                Үндэсний зөвлөл нь эрүүл мэндийн даатгалын сангийн төсвийн
                төслийг жил бүрийн 08 дугаар сарын 15-ны дотор санхүү, төсвийн
                асуудал эрхэлсэн төрийн захиргааны төв байгууллагад хүргэнэ
              </div>
            </Row>
            <div
              className={styles.calendarItem2}
              onClick={clickShow2}
              role="presentation"
            >
              Батлах
            </div>
            <img src={cal22} alt="" className={styles.arrow} />
          </Row>
          <Row className={styles.logo}>
            <img src={cal21} alt="" />
          </Row>
        </Col>
        {/* Guravdugaar heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 3 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b3}`} />
              <div className={`${styles.item} ${styles.i3}`}>
                <div>
                  Санхүү, төсвийн асуудал эрхэлсэн төрийн захиргааны төв
                  байгууллага эрүүл мэндийн даатгалын сангийн төсвийн төслийг
                  Засгийн газарт мэдүүлнэ
                </div>
              </div>
            </Row>
            <div
              className={styles.calendarItem3}
              onClick={clickShow3}
              role="presentation"
            >
              Хэрэгжүүлэх
            </div>
            <img src={cal32} alt="" className={styles.arrow} />
          </Row>
          <Row className={styles.logo}>
            <img src={cal31} alt="" />
          </Row>
        </Col>
        {/* Duruvdugeer heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 4 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b4}`} />
              <div className={`${styles.item} ${styles.i4}`}>
                <div>
                  Засгийн газар эрүүл мэндийн даатгалын сангийн төсвийн төслийг
                  жил бүрийн 10 дугаар сарын 01-ний дотор Улсын Их Хуралд өргөн
                  мэдүүлнэ
                </div>
              </div>
            </Row>
            <div
              className={styles.calendarItem4}
              onClick={clickShow4}
              role="presentation"
            >
              Тайлагнах
            </div>
            <img src={cal42} alt="" className={styles.arrow} />
          </Row>
          <Row className={styles.logo}>
            <img src={cal41} alt="" />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

