import { Skeleton, Space } from 'antd';
import styles from './style.module.scss';

export const Loading = () => (
  <div className={styles.menuBox}>
    {[1, 2, 3, 4, 5, 6].map(i => (
      <Space key={i}>
        <Skeleton.Avatar active size="small" shape="square" />
        <Skeleton.Input active size="small" />
      </Space>
    ))}
  </div>
);

