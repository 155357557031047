import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Space, Image } from 'antd';
import { AiOutlinePhone } from 'react-icons/ai';
import { BsMap } from 'react-icons/bs';
import styles from './style.module.scss';
import { Fallback } from '../../img/FailImg';
import {
  getFooterContacts,
  getFooterLinks,
  getFooterPartners,
} from '../../service/portalService';
import { SORT_ORDER } from '../../constants/Constant';
import { convertCurrencyFormatFraction, getImgSrc } from '../../tools/Tools';
import { getGACount } from '../../service/notificationService';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

const { Text } = Typography;

const params = {
  search: { status: true, isActive: true },
  sortField: 'priority',
  sortOrder: SORT_ORDER.DESC,
  size: 100,
};

export default function PageFooter() {
  const [FooterPartnerData, setFooterPartnerData] = useState([]);
  const [FooterLinksData, setFooterLinksData] = useState([]);
  const [FooterConstactsData, setFooterConstactsData] = useState([]);
  const [GACount, setGACount] = useState([]);

  const NewTabLinks = href => {
    const win = window.open(`${href}`);
    win.focus();
  };

  useEffect(() => {
    getFooterPartners(params).then(data => {
      const PartnersData = data.content || [];
      PartnersData.forEach((item, index) => {
        item.index = data.totalPages + index;
      });
      setFooterPartnerData(PartnersData);
    });

    getFooterLinks(params).then(data => {
      const LinksData = data.content || [];
      LinksData.forEach((item, index) => {
        item.index = data.totalPages + index;
      });
      setFooterLinksData(LinksData);
    });

    getFooterContacts({ search: { status: true } }).then(data => {
      const ContactsData = data.content || [];
      ContactsData.forEach((item, index) => {
        item.index = data.totalPages + index;
      });
      setFooterConstactsData(ContactsData);
    });

    getGACount().then(data => {
      setGACount(data || []);
    });
  }, []);

  const statePartnersImg = selecedData => (
    <Image
      key={selecedData.id}
      src={getImgSrc(selecedData.imgUuid)}
      alt="footerLogo"
      preview={false}
      fallback={Fallback}
    />
  );

  return (
    <Row className={styles.footerDiv} justify="center">
      <Col {...CONTENT_SPAN} xs={19} sm={19}>
        <Row justify="space-between" gutter={[10, 30]}>
          <Col xs={22} sm={22} md={12} lg={6} xl={5} span={5}>
            <Space direction="vertical" size={22}>
              {FooterPartnerData.map(item => statePartnersImg(item))}
            </Space>
          </Col>
          <Col xs={22} sm={22} md={12} lg={7} xl={7} span={7}>
            <Space className={styles.footerLocationHeader}>
              <div>
                <AiOutlinePhone className={styles.footerLocationHeaderIcon} />
              </div>
              <Text>Холбоо барих хаяг:</Text>
            </Space>
            {FooterConstactsData.map(item => (
              <div key={item.id} className={styles.contactText}>
                <p>{item.address}</p>
              </div>
            ))}
            <br />
            <div className={styles.contactText}>
              {FooterConstactsData.map(item => (
                <p key={item.id}>
                  <b>Утас:</b> {item.phone}
                </p>
              ))}
              <p>
                <b>Цахим шуудан:</b> shilendans@mof.gov.mn
              </p>
              <p>
                <b>Факс:</b> 51-267468{' '}
              </p>
              <br />
              <p>
                <b>Сангийн яам © 2023 он. Зохиогчийн эрх хамгаалагдсан.</b>
              </p>
            </div>
          </Col>
          <Col xs={22} sm={22} md={12} lg={6} xl={5} span={5}>
            <Space className={styles.footerLocationHeader}>
              <BsMap className={styles.footerLocationHeaderIcon} />
              <Text>Холбоос:</Text>
            </Space>
            {FooterLinksData.map((item, index) => (
              <div key={index} className={styles.FooterTexts}>
                <Space direction="horizontal" size={40}>
                  <Text
                    type="link"
                    onClick={e => {
                      e.stopPropagation();
                      NewTabLinks(item.link);
                    }}
                  >
                    {item.name} <br />
                  </Text>
                </Space>
              </div>
            ))}
          </Col>
          <Col xs={22} sm={22} md={12} lg={5} xl={5} span={5}>
            <Space className={styles.footerLocationHeader}>
              <BsMap className={styles.footerLocationHeaderIcon} />
              <Text>Google analytics:</Text>
            </Space>
            <div className={styles.FooterTexts}>
              {GACount.map((ga, index) => (
                <div key={index} style={{ display: 'flex' }}>
                  <div style={{ width: 100 }}>{ga.name || ''} :</div>
                  <Text strong>
                    {convertCurrencyFormatFraction(ga.count || 0)}
                  </Text>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
