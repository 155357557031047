import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  HOME_PAGE,
  UNITED_BUDGET,
  LEARNING_PAGE,
  COUNTRY_BUDGET,
  STATE_BUDGET,
  HEALTH_BUDGET,
  ORGANIZATION_PAGE,
  GOVERNMENT_SPECIAL,
  OPEN_DATA,
  SOCIAL_INSURANCE,
  INVESTMENT,
  PUBLIC_PRIVATE_SECTOR,
  PORTAL_NEWS_DETAIL,
} from './settings/constantRoutes';
import 'moment/locale/mn';
import HomePage from './pages/homepage/HomePage';
import UnitedBudget from './pages/united-budget/UnitedBudget';
import OrganizationsFilter from './pages/organizationPage/Organizations_Filter';
import Organization from './pages/organization/Organization';
import GovernmentSpecials from './pages/governmentSpecials/governmentSpecials';
import Supplier from './pages/publicPrivateSector/Supplier';
import OpenData from './pages/opendata/OpenData';
import OpenDataResult from './pages/opendata/OpenDataResult';
import News from './pages/news/News';
import Investment from './pages/investment/Investment';
import ConcessResult from './pages/investment/Concess/ConcessResult';
import LearningPage from './pages/learningPage/learning';
import StateBudget from './pages/statebudget/statebudget';
import HealthInsurance from './pages/healtInsurance/HealtInsurance';
import SocialInsurance from './pages/socialInsurance/SocialInsurance';
import LocalBudget from './pages/localBudget/localBudget';
import NotFound from './pages/notFound/NotFound';

function Routers() {
  return (
    <Routes>
      <Route path={HOME_PAGE} element={<HomePage />} />
      <Route path={UNITED_BUDGET} element={<UnitedBudget />} />
      <Route path={STATE_BUDGET} element={<StateBudget />} />
      <Route path={HEALTH_BUDGET} element={<HealthInsurance />} />
      <Route path={SOCIAL_INSURANCE} element={<SocialInsurance />} />
      <Route path={COUNTRY_BUDGET} element={<LocalBudget />} />
      <Route path={ORGANIZATION_PAGE}>
        <Route index element={<OrganizationsFilter />} />
        <Route path=":orgId" element={<Organization />} />
      </Route>
      <Route path={GOVERNMENT_SPECIAL} element={<GovernmentSpecials />} />
      <Route path={PUBLIC_PRIVATE_SECTOR} element={<Supplier />} />
      <Route path={OPEN_DATA}>
        <Route index element={<OpenData />} />
        <Route path=":reportFormId" element={<OpenDataResult />} />
      </Route>
      <Route path={PORTAL_NEWS_DETAIL}>
        <Route path=":newsId" element={<News />} />
      </Route>
      <Route path={INVESTMENT} element={<Investment />}>
        <Route path=":investmentId" element={<ConcessResult />} />
      </Route>
      <Route path={LEARNING_PAGE} element={<LearningPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Routers;
