/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  CloseCircleOutlined,
  LikeFilled,
  MessageFilled,
} from '@ant-design/icons';
import classnames from 'classnames';
import { Tabs, Tooltip } from 'antd';
import APPCONFIG from '../../pages/homepage/constans/appConfig';
import Company from './Company';
import AuditCompany from './AuditCompany';
import styles from './style.module.scss';
import Submitfeedback from './submitFeedback';

const { TabPane } = Tabs;

function Side() {
  const [isSider, setIsSider] = useState({
    isVisible: APPCONFIG.showCustomizer,
  });
  const [clicktab, setClicktab] = useState();
  const [activeKey, setActiveKey] = useState('org');

  const toggleCustomizer = (e, close) => {
    e && e.preventDefault();
    if (close) {
      setIsSider({ isVisible: false });
    } else {
      setIsSider({ isVisible: !isSider.isVisible });
    }
  };

  const clicker = key => {
    setClicktab(key);
    setIsSider({ isVisible: true });
  };

  return (
    <section
      id="quickview-customizer"
      className={classnames('quickview-wrapper customizer hidden-lg-down', {
        'quickview-open-customizer': isSider.isVisible,
      })}
    >
      <CloseCircleOutlined
        type="close-circle"
        theme="outlined"
        className="customizer-close"
        onClick={e => toggleCustomizer(e, true)}
      />
      <div className="toggle">
        <div
          className="customizerspan-toggle"
          onClick={e => toggleCustomizer(e)}
        >
          <p>Иргэний хяналт</p>
        </div>
        <div className="customizers-toggle">
          <Tooltip title="Гомдол" color="orange" placement="top">
            <MessageFilled className="one" onClick={e => clicker('1', e)} />
          </Tooltip>
          <Tooltip title="Санал" color="cyan" placement="bottom">
            <LikeFilled className="two" onClick={e => clicker('2', e)} />
          </Tooltip>
        </div>
      </div>
      <div className="quickview-inner">
        <div className="divider my-4 divider-solid" />
        <div className={styles.siderContentSecond}>
          <Tabs
            hideAdd={false}
            className="tabs"
            activeKey={clicktab}
            type="card"
          >
            <TabPane tab="Гомдол" key="1">
              <div className={styles.siderContentSecondChild}>
                <Tabs
                  hideAdd={false}
                  className="tabs"
                  type="card"
                  activeKey={activeKey}
                  onChange={key => setActiveKey(key)}
                >
                  <TabPane tab="Байгууллага" key="org">
                    <Company />
                  </TabPane>
                  <TabPane tab="Аудитын байгууллага" key="audit">
                    <AuditCompany goToTabOrg={() => setActiveKey('org')} />
                  </TabPane>
                </Tabs>
              </div>
            </TabPane>
            <TabPane tab="Санал" key="2">
              <Submitfeedback />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </section>
  );
}
export default Side;

