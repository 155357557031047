import { Col, Divider, Image, Row, Space, Typography } from 'antd';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import suld from '../../../img/suld.svg';

const { Text } = Typography;

export default function GovernmentSpecialsContent(props) {
  const { header } = props;

  return (
    <Row
      className={styles.governmentSpecialsContentRow}
      justify="space-between"
    >
      <Space>
        <Image preview={false} src={suld} alt="logo" />
        <Text strong>{header}</Text>
      </Space>
      <Col span={22}>
        <Divider />
      </Col>
      <DownOutlined />
    </Row>
  );
}
