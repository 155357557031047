import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const Confirm = (
  handleOK,
  title = 'Та устгахдаа итгэлтэй байна уу ?',
  content = null
) => {
  Modal.confirm({
    title,
    content,
    icon: <ExclamationCircleOutlined />,
    okButtonProps: {},
    okText: 'Тийм',
    cancelText: 'Үгүй',
    onOk() {
      handleOK && handleOK();
    },
  });
};

export const Success = (action, title = null, content = null) => {
  Modal.success({
    title: (
      <p style={{ color: '#0660b7', fontSize: 20 }}>{title || 'Амжилттай'}</p>
    ),
    content,
    okText: 'Хаах',
    afterClose() {
      action && action();
    },
  });
};

export const Warning = ({ title, content, handleOK }) => {
  Modal.warning({
    title,
    content,
    okText: 'Хаах',
    onOk() {
      handleOK && handleOK();
    },
  });
};

export const Info = content => {
  Modal.info({
    centered: true,
    className: 'info-modal',
    closable: true,
    icon: true,
    width: 300,
    content: (
      <div>
        <p style={{ color: '#0660b7', fontSize: 20 }}>{content.header}</p>
        <span>{content.text}</span>
      </div>
    ),
    okText: content.okText || 'Хаах',
    onOk() {
      content.handleOk && content.handleOk();
    },
    afterClose() {
      content.afterClose && content.afterClose();
    },
  });
};
