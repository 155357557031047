import React, { useState, useEffect, useContext, useRef } from 'react';
import { DatePicker, Popover, Skeleton, Space, Spin, Table, Tabs } from 'antd';
import {
  InfoCircleTwoTone,
  LoadingOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { getReportByUser } from '../../../service/reportServices';
import { getServerDate } from '../../../service/notificationService';
import { EVERY_TIME } from '../../../constants/ReportConstants';
import { DATEFORMAT } from '../../../constants/Constant';
import ReportStatus from './components/ReportStatus';
import TableHeaderData from './components/TableHeaderData';
import style from './style.module.scss';
// import logo1 from '../../../img/organization/logo1.svg';
// import logo2 from '../../../img/organization/logo2.svg';
// import logo3 from '../../../img/organization/logo3.svg';
// import logo4 from '../../../img/organization/logo4.svg';
import OrgContext from '../../../context/OrgContext';
import ReportOrganizationDataEdit from './ReportOrganizationDataEdit';
import OpenDataMenu from '../../opendata/OpenDataMenu';

const { TabPane } = Tabs;

let defaultReportList = [];

const ReportOrganizationData = ({ setHideInfo }) => {
  const { orgInfo } = useContext(OrgContext);

  if (isEmpty(orgInfo)) {
    return <Skeleton active />;
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const paramReportGroup = searchParams.get('group');
  const paramYear = searchParams.get('ry');
  const paramReportFormOrgRecurring = searchParams.get('rfor');

  const orgReportGroups = (orgInfo?.reportGroupDTOS || [])
    .filter(group => group.isActive)
    .sort((a, b) => a.priority - b.priority);

  const cancelRefReportByUser = useRef();

  const [filterByName, setFilterByName] = useState('');
  const [filterByType, setFilterByType] = useState('');
  const [filterByDate, setFilterByDate] = useState('');
  const [filteredReportList, setFilteredReportList] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [orgRecurring, setOrgRecurring] = useState();
  const [selectedReportGroupId, setSelectedReportGroupId] = useState(
    searchParams.get('group')
  );
  const [reportYear, setReportYear] = useState(searchParams.get('ry'));
  // const [reportCount, setReportCount] = useState({});
  const [selectedFormType, setSelectedFormType] = useState();
  const [reportFormTypes, setReportFormTypes] = useState([]);

  const getNumberedList = list => {
    let showList = [...list];
    if (selectedFormType) {
      showList = showList.map(recurring => ({
        ...recurring,
        children: recurring.children.filter(
          report => report.reportFormTypeId === selectedFormType.id
        ),
      }));
      showList = showList.filter(recurring => !isEmpty(recurring.children));
    }
    let number = 1;
    showList.forEach(data => {
      (data.children || []).forEach(report => {
        report.index = number++;
      });
    });
    return showList;
  };

  const goControlPage = () => {
    setOrgRecurring();
    setHideInfo && setHideInfo(true);
    searchParams.delete('rfor');
    searchParams.delete('rf');
    searchParams.delete('rm');
    setSearchParams(searchParams);
  };

  const selectForm = () => {
    if (!paramReportFormOrgRecurring) {
      setOrgRecurring();
      setHideInfo && setHideInfo(true);
      return;
    }
    let find = null;
    defaultReportList.forEach(report => {
      if (!find) {
        find = (report.children || []).find(
          z => `${z.id}` === `${paramReportFormOrgRecurring}`
        );
      }
    });
    if (find) {
      const currMonth = searchParams.get('rm') || 1;
      const findRow = (find.reportFormDatas || []).find(
        row => `${row.month}` === `${currMonth}`
      ) || { month: currMonth };

      const selectedReport = { ...find };
      const beforeDays = selectedReport.beforeDays || 0;
      const reportDate = moment().set({
        year: reportYear,
        month: findRow.month - 1 + Number(selectedReport.openMonth || 0),
        DDD: selectedReport.deadDay || 31,
      });
      const calcBeginDate = new Date(moment(reportDate).add(-beforeDays, 'd'));
      selectedReport.selectedYear = reportYear;
      selectedReport.selectedMonth = findRow.month;
      selectedReport.extentionDays = findRow.extentionDays;
      selectedReport.startDate = moment(calcBeginDate).format(DATEFORMAT);
      selectedReport.deadDate = moment(reportDate).format(DATEFORMAT);
      setOrgRecurring(selectedReport);
      setHideInfo && setHideInfo(false);
    }
  };

  const fetchData = async () => {
    if (!selectedReportGroupId) return;
    setIsTableLoading(true);
    try {
      const params = {
        search: {
          isRequire: true,
          'reportFormGroupRecurring.reportFormGroup.reportGroup.id':
            selectedReportGroupId,
          'reportFormGroupRecurring.reportFormGroup.isActive': true,
          'reportFormGroupRecurring.reportFormGroup.reportForm.status': true,
          'reportFormGroupRecurring.reportFormGroup.reportForm.isActive': true,
        },
        year: reportYear,
      };
      const header = { orgId: orgInfo.id };

      const dataList = await getReportByUser(
        params,
        cancelRefReportByUser,
        header
      );

      defaultReportList = (dataList || [])
        .filter(rep => !isEmpty(rep.reportFormOrganizationRecurrings))
        .map((data, index) => ({
          key: index,
          name: data.name,
          children: data.reportFormOrganizationRecurrings.map(
            (report, childIndex) => {
              const isEvery =
                (data.name || '').toLowerCase() === EVERY_TIME.toLowerCase();
              report.isEvery = isEvery;
              report.recurringName = data.name;
              const maxMonth = report.maxMonth || 1;
              const deadMonth = report.deadMonth || 1;
              const { deadDay } = report;

              const statusOptions = {};

              const recurringsByMonth = report.reportFormDatas || [];
              recurringsByMonth.forEach(row => {
                const reportStatus = ReportStatus(row.type, report, form => {
                  searchParams.set('rfor', `${form.id}`);
                  searchParams.set('rf', `${form.reportFormId}`);
                  searchParams.set('rm', `${row.month}`);
                  setSearchParams(searchParams);
                });
                let msg = reportStatus?.name;
                if (row.extentionDays) {
                  msg = `Тайлангийн хугацаа ${row.extentionDays} хоногоор сунгагдсан байна.`;
                }
                const icon = (
                  <Popover
                    placement="topLeft"
                    content={
                      <Space>
                        <WarningTwoTone twoToneColor="#ffcc00" />
                        {msg}
                      </Space>
                    }
                  >
                    {reportStatus?.icon}
                  </Popover>
                );
                statusOptions[row.month] = icon;
              });
              const convertedReport = {
                ...report,
                key: `${index}-${childIndex}`,
                date: `${deadMonth} сарын ${deadDay}`,
                maxMonth,
                user: report.userFirstname,
                isRequire: (
                  <Popover
                    content={
                      <Space
                        align="start"
                        style={{
                          maxWidth: '300px',
                        }}
                      >
                        <WarningTwoTone twoToneColor="#ffcc00" />
                        {report.isRequireLaw
                          ? 'Хуулиар заавал оруулах тайлан'
                          : 'Байгууллага оруулах тайлан'}
                      </Space>
                    }
                  >
                    <div
                      role="img"
                      style={{
                        height: 30,
                        fontSize: 24,
                        color: report.isRequireLaw ? 'dodgerblue' : 'gold',
                      }}
                    >
                      *
                    </div>
                  </Popover>
                ),
                ...statusOptions,
              };
              if (isEvery) {
                convertedReport.date = '-';
                convertedReport.type = '-';
              }
              return convertedReport;
            }
          ),
        }));

      setFilteredReportList(getNumberedList(defaultReportList));

      const types = [];
      defaultReportList.forEach(recurring => {
        recurring.children.forEach(form => {
          if (!types.find(type => type.id === form.reportFormTypeId)) {
            types.push({
              id: form.reportFormTypeId,
              name: form.reportFormTypeName,
            });
          }
        });
      });
      types.sort((a, b) => a.id - b.id);
      setReportFormTypes(types);
      setSelectedFormType(types[0]);

      selectForm();

      // const lastRow = dataList.pop();
      // setReportCount(lastRow || []);

      setIsTableLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsTableLoading(false);
      }
    }
  };

  const onChangeGroup = groupId => {
    setSelectedReportGroupId(Number(groupId));
    searchParams.set('group', groupId);
    setSearchParams(searchParams);
  };

  const onChangeYear = (e, year) => {
    setReportYear(year);
    searchParams.set('ry', year);
    setSearchParams(searchParams);
  };

  const handleSearchFilter = async (searchInput, key) => {
    const filteredReports = defaultReportList.map(recurring => ({
      ...recurring,
      children: recurring.children.filter(report =>
        report[key].toLowerCase().includes(searchInput.toLowerCase())
      ),
    }));

    switch (key) {
      case 'name':
        setFilterByName(searchInput);
        break;
      case 'type':
        setFilterByType(searchInput);
        break;
      case 'date':
        setFilterByDate(searchInput);
        break;
      default:
        setFilterByName(searchInput);
    }
    setFilteredReportList(getNumberedList(filteredReports));
  };

  const tableHeaderData = TableHeaderData({
    filterByName,
    filterByType,
    filterByDate,
    handleSearchFilter,
  });

  useEffect(() => {
    if (isEmpty(orgReportGroups)) {
      return;
    }
    if (!selectedReportGroupId) {
      setSelectedReportGroupId(orgReportGroups[0].id);
    }
    if (!reportYear) {
      getServerDate().then(serverDate => {
        setReportYear(serverDate.getFullYear());
        searchParams.set('ry', serverDate.getFullYear());
        setSearchParams(searchParams);
      });
    }
  }, []);

  useEffect(() => {
    if (!reportYear) {
      return;
    }
    setFilteredReportList([]);
    fetchData();
  }, [selectedReportGroupId, reportYear]);

  useEffect(() => {
    if (paramReportGroup) {
      setSelectedReportGroupId(paramReportGroup);
    }
  }, [paramReportGroup]);

  useEffect(() => {
    if (paramYear) {
      setReportYear(paramYear);
    }
  }, [paramYear]);

  useEffect(() => {
    selectForm();
  }, [paramReportFormOrgRecurring]);

  useEffect(() => {
    setFilteredReportList(getNumberedList(defaultReportList));
  }, [selectedFormType]);

  const popOverContentDescription = (
    <div className={style.allStatus}>
      {ReportStatus().map(status => (
        <div key={status.id} className={style.status}>
          <span className={style.icon}>{status.icon}</span>
          <span className={style.name}>{status.name}</span>
        </div>
      ))}
    </div>
  );

  const loader = reportFormOrgRecc =>
    reportFormOrgRecc ? (
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          height: '300px',
          padding: '130px 0',
        }}
      >
        <Spin size="large" indicator={<LoadingOutlined />} />
      </div>
    ) : (
      <>
        {/* <Row gutter={[15, 20]} className={style.orgCard}>
            <Col sm={24} md={12} xl={12}>
              <span className={style.orgCardFive}>
                <img src={logo4} alt="logo3" />
                <span className={style.title}>Мэдээллэх болсон</span>
                <Divider type="vertical" className={style.divider} />
                <span className={style.number}>{reportCount.due || 0}</span>
              </span>
            </Col>
            <Col sm={24} md={12} xl={12}>
              <span className={style.orgCardTwo}>
                <img src={logo3} alt="logo2" />
                <span className={style.title}>Хугацаандаа мэдээллэсэн</span>
                <Divider type="vertical" className={style.divider} />
                <span className={style.number}>
                  {reportCount.onTime || 0}
                </span>
              </span>
            </Col>
            <Col sm={24} md={12} xl={8}>
              <span className={style.orgCardThree}>
                <img src={logo2} alt="logo3" />
                <span className={style.title}>
                  Хугацаа хоцорч мэдээллэсэн
                </span>
                <Divider type="vertical" className={style.divider} />
                <span className={style.number}>
                  {reportCount.expired || 0}
                </span>
              </span>
            </Col>
            <Col sm={24} md={12} xl={8}>
              <span className={style.orgCardFour}>
                <img src={logo4} alt="logo4" />
                <span className={style.title}>Мэдээлэл оруулаагүй</span>
                <Divider type="vertical" className={style.divider} />
                <span className={style.number}>
                  {reportCount.unReported || 0}
                </span>
              </span>
            </Col>
            <Col sm={24} md={12} xl={8}>
              <span className={style.orgCardOne}>
                <img src={logo1} alt="logo1" />
                <span className={style.title}>Нийт оруулах мэдээлэл</span>
                <Divider type="vertical" className={style.divider} />
                <span className={style.number}>{reportCount.total || 0}</span>
              </span>
            </Col>
          </Row> */}
        <Tabs
          style={{ padding: 1 }}
          onChange={onChangeGroup}
          activeKey={`${selectedReportGroupId}`}
        >
          {orgReportGroups.map(reportGroup => (
            <TabPane tab={reportGroup.name} key={reportGroup.id}>
              <>
                <OpenDataMenu
                  reportFormTypes={reportFormTypes}
                  selectedFormType={selectedFormType}
                  setSelectedFormType={setSelectedFormType}
                />
                <div className={style.subHeader}>
                  <div className={style.reportYear}>
                    Тайлант он:
                    <DatePicker
                      picker="year"
                      allowClear={false}
                      disabledDate={current =>
                        current.isBefore(moment('2023-01-01'))
                      }
                      value={reportYear ? moment(reportYear, 'YYYY') : null}
                      onChange={onChangeYear}
                    />
                  </div>
                  <Popover
                    placement="bottomLeft"
                    content={popOverContentDescription}
                  >
                    <Space align="start">
                      <InfoCircleTwoTone style={{ fontSize: 20 }} />
                      <span>Тэмдэглэгээний тайлбар</span>
                    </Space>
                  </Popover>
                </div>
                <div className={style.reportDataTable}>
                  <Table
                    rowKey="key"
                    loading={{
                      size: 'large',
                      spinning: isTableLoading,
                      indicator: <LoadingOutlined />,
                    }}
                    columns={tableHeaderData}
                    dataSource={filteredReportList}
                    pagination={false}
                    defaultExpandAllRows
                    expandedRowKeys={(filteredReportList || []).map(
                      recurring => recurring.key
                    )}
                  />
                </div>
              </>
            </TabPane>
          ))}
        </Tabs>
      </>
    );

  return (
    <div className={style.reportFormContainer}>
      <div className="main-title" style={{ marginTop: '20px' }}>
        <span>Шилэн дансны мэдээлэл</span>
      </div>
      {orgRecurring ? (
        <ReportOrganizationDataEdit
          orgRecurring={orgRecurring}
          selectedReportGroupId={selectedReportGroupId}
          back={goControlPage}
        />
      ) : (
        loader(paramReportFormOrgRecurring)
      )}
    </div>
  );
};

export default ReportOrganizationData;
