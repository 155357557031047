import React from 'react';
import { Col, Layout, Row } from 'antd';
import styles from './style.module.scss';
import Charts from './Charts';

import Search from './Search';
import Comment from '../components/Comment/Comment';
import home from '../../img/home.svg';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

const { Content } = Layout;

export default function Supplier() {
  return (
    <Row justify="center">
      <Col {...CONTENT_SPAN}>
        <MetaInfo title="Бэлтгэн нийлүүлэгч - Шилэн данс" />
        <>
          <Row justify="left" style={{ marginTop: '1rem' }}>
            <div className={styles.path}>
              <img src={home} alt="home" className={styles.home} />
              Бэлтгэн нийлүүлэгч
            </div>
          </Row>
          <div className="main-title">
            <span>БЭЛТГЭН НИЙЛҮҮЛЭГЧ</span>
          </div>
          <Layout style={{ background: '#FFF' }}>
            <Content>
              <Charts />
              <Search />
            </Content>
          </Layout>
        </>
        <Comment />
      </Col>
    </Row>
  );
}

