export const FIELD = {
  year: 'Жил',
  month: 'Сар',
  orgName: 'Байгууллагын нэр',
  name1: 'Байгууллагын нэр шинэ',
  refCityCode: 'Аймгийн код',
  refCityName: 'Аймаг/хотын нэр',
  refDistrictCode: '',
  refDistrictName: 'Сум/дүүрэг',
  refIsicClassCode: '',
  refKhorooCode: '',
  refKhorooName: 'Баг/хороо',
  refIsicGroupName: 'ISIC',
  refOrgTypeName: 'Байгууллагын төрөл',
  refTypeCode: 'Төсвийн ерөнхийлөн захирагч',
  refTypeName: 'Төсвийн ерөнхийлөн захирагч',
  refCentralBudgetGovernorName: 'Төсвийн төвлөрүүлэн захирагч',
  registerNo: 'Байгууллагын регистр',
  certificateNo: 'Улсын бүртгэлийн дугаар',
  taxNo: 'Татвар төлөгчийн дугаар',
  reportFormName: 'Тайлангийн нэр',
  reportRecurringName: 'Мэдээллийн давтамж',
  reportRecurringTypeName: '',
};

