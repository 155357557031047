import React from 'react';
import { Form } from 'antd';
import InputMask from 'react-input-mask';
import { PATTERN_PHONE } from '../constants/Pattern';

export default function PhoneNumber({
  name = 'phone',
  label = 'Утасны дугаар',
  pattern = PATTERN_PHONE,
  childProps,
}) {
  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      rules={[{ pattern, message: 'Утасны дугаар буруу байна' }]}
    >
      <InputMask
        className="ant-input"
        mask="99999999"
        maskChar=""
        {...childProps}
      />
    </Form.Item>
  );
}

