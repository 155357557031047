import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { isNil } from 'lodash';
// import styles from './style.module.scss';
// import OneComment from './OneComment';
// import WriteComment from './WriteComment';
import { getComments } from '../../../service/notificationService';
import { useAppStore } from '../../../context/Tools';
import { SORT_ORDER } from '../../../constants/Constant';
import { getPathname } from '../../../tools/Tools';
// import Rating from './Rating';

const pageSize = 5;

export default function Comment() {
  const location = useLocation();

  const { orgId } = useParams();

  const [searchParams] = useSearchParams();
  const reportFormId = searchParams.get('rf');

  const { commentList, setCommentList, setCommentCount } = useAppStore();

  const cancelRef = useRef();

  // const [isShowComment, setIsComment] = useState(false);
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);
  const [showViewOtherComment, setShowViewOtherComment] = useState(null);

  // const clickIsShow = () => {
  //   setIsComment(!isShowComment);
  // };

  const initData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const search = {};
      if (orgId || reportFormId) {
        search.organizationId = orgId;
        search.reportFormId = reportFormId || null; //* **** spring dr sipios search null-r shuuj chadahgui bn*/
      } else {
        search.urlLink = getPathname(location);
      }
      const params = {
        search,
        sortField: 'createdAt',
        sortOrder: SORT_ORDER.DESC,
        size: pageSize,
        page,
      };

      const { content, totalElements } = await getComments(params, cancelRef);

      setCommentCount(totalElements);
      setCommentList([...commentList, ...content]);
      setLoading(false);
      if (showViewOtherComment === null && totalElements > pageSize) {
        setShowViewOtherComment(true);
      }
    } catch {
      setCommentCount(0);
      setCommentList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isNil(page)) {
      initData();
    }
  }, [page]);

  useEffect(() => {
    setPage();
    setCommentList([]);
    setTimeout(() => setPage(0), 0);

    return () => {
      if (cancelRef?.current) {
        cancelRef.current.cancel();
      }
    };
  }, [location]);

  return (
    <>
      {/* <div className={styles.commentColHeader}>
        <Space className={styles.comment}>
          <Text strong className={styles.text}>
            Сэтгэгдэл ({commentCount})
          </Text>
          {isShowComment ? (
            <UpOutlined className={styles.icon} onClick={clickIsShow} />
          ) : (
            <DownOutlined className={styles.icon} onClick={clickIsShow} />
          )}
        </Space>
        <Rating />
      </div> */}
      {/* {isShowComment && (
        <Row gutter={5} className={styles.commentRow}>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 20, order: 2 }}
            lg={{ span: 14, order: 1 }}
            xl={{ span: 12, order: 1 }}
          >
            <WriteComment key="newComment" />
            {showViewOtherComment ? (
              <>
                {commentList.map(comment => (
                  <OneComment key={comment.id} comment={comment} />
                ))}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type="primary"
                    onClick={() => setShowViewOtherComment(false)}
                  >
                    Бусад сэтгэгдлүүдийг харах
                  </Button>
                </div>
              </>
            ) : (
              <InfiniteScroll
                dataLength={commentList.length}
                next={() => setPage(pageNo => pageNo + 1)}
                hasMore={commentList.length < commentCount}
                loader={<Skeleton active avatar paragraph={{ rows: 3 }} />}
                scrollableTarget="scrollableDiv"
              >
                {commentList.map(comment => (
                  <OneComment key={comment.id} comment={comment} />
                ))}
              </InfiniteScroll>
            )}
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 20, order: 1 }}
            lg={{ span: 10, order: 2 }}
            xl={{ span: 10, order: 2 }}
            className={styles.commentInstructions}
          >
            <Col>
              <Text strong type="secondary" className={styles.normalText}>
                АНХААР!
              </Text>
            </Col>
            <Text
              strong
              type="secondary"
              className={styles.normalText}
              style={{ marginBottom: 20 }}
            >
              Та сэтгэгдэл бичихдээ хууль зүйн болон ёс суртахууныг баримтална
              уу. Ёс бус сэтгэгдлийг админ устгах эрхтэй. Мэдээний сэтгэгдэлд
              манай сайт хариуцлага хүлээхгүй.
            </Text>
          </Col>
        </Row>
      )} */}
    </>
  );
}
