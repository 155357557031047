import React from 'react';
import { Button, Row, Space } from 'antd';
import ReactApexChart from 'react-apexcharts';
import styles from './style.module.scss';

export default function DataGraphic() {
  const state = {
    date: {
      series: [
        {
          name: 'Хандалтын тоо сараар',
          data: [260, 320, 150, 500],
        },
      ],
      options: {
        chart: {
          height: 350,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        stroke: {
          size: 2,
          curve: 'smooth',
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr'],
        },
        colors: ['#004a9f'],
      },
    },
  };
  return (
    <div>
      <Row justify="center" className={styles.buttonBar}>
        <Space size="middle">
          <Button>ӨО-ны мөн үеийн</Button>
          <Button className={styles.btn1}>Жилийн төсөв</Button>
          <Button>Төсөв</Button>
        </Space>
      </Row>
      <div className={styles.tableContainer}>
        <Space direction="vertical" size="middle" className={styles.vBar}>
          <Button style={{ width: '100%' }}>Он</Button>
          <Button style={{ width: '100%' }}>Улирал</Button>
          <Button style={{ width: '100%' }}>Үзүүлэлт</Button>
        </Space>
        <div style={{ width: '100%' }}>
          <ReactApexChart
            options={state.date.options}
            series={state.date.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
}

