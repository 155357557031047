/* eslint-disable no-shadow */
import React, { useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');

function ExampleColumnCharts({ chartId, chartData, seriesList }) {
  useLayoutEffect(() => {
    const chart = am4core.create(`${chartId}`, am4charts.XYChart);
    chart.responsive.enabled = true;
    chart.data = chartData;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.tooltipText = '{valueY}';
    const label = categoryAxis.renderer.labels.template;
    label.wrap = true;
    label.maxWidth = 110;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.above = true;
    valueAxis.adjustLabelPrecision = false;

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.start = chartData.length > 6 ? 10 : 0;
    chart.scrollbarX.thumb.minWidth = 450;
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    seriesList.forEach(s => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = s.name;
      series.dataFields.valueY = s.name;
      series.dataFields.categoryX = `category`;
      series.columns.template.tooltipText = ` ${s.name}: {valueY} сая төгрөг`;
      series.columns.template.width = am4core.percent(30);
      series.columns.template.maxHeight = 70;

      const valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = `{valueY}`;
      valueLabel.label.dy = -10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;
    });

    // chart.scrollbarX.startGrip.disabled = true;
    // chart.scrollbarX.endGrip.disabled = true;
    // chart.scrollbarX.height = 40
    // chart.scrollbarX.paddingBottom = 30;
    // chart.scrollbarX.start = 1
    // const scrollbarX = new am4charts.XYChartScrollbar();
  }, [chartData, chartId]);

  return (
    <>
      <div
        id={chartId}
        style={{
          width: '100%',
          height: '470px',
          display: 'flex',
        }}
      />
    </>
  );
}
export default ExampleColumnCharts;
