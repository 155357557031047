import React, { useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Input,
  Divider,
  Radio,
  Button,
  Form,
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import styles from './style.module.scss';
import { postFeedback } from '../../service/feedbackService';
import validateMessages from '../../tools/validateMessage';
import ContactInfo from './ContactInfo';
import FormItemCyrillic from '../../components/FormItemCyrillic';
import { Success } from '../../components/Confirm';
import { FFEDBACKTYPE, WHOM } from '../../constants/Constant';

const { Text } = Typography;
const { TextArea } = Input;
export default function Submitfeedback() {
  const [form] = Form.useForm();
  const [isShow, setIsShow] = useState(false);
  const [isAnswers, setIsAnswers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClickShow = () => {
    setIsShow(!isShow);
  };
  const onIsAnswers = e => {
    if (e.target.value === true) {
      setIsAnswers(true);
    }
    if (e.target.value === false) {
      setIsAnswers(false);
    }
  };

  const submitForm = ({
    name,
    address,
    email,
    phone,
    phone1,
    subject,
    description,
    isAnswer,
  }) => {
    setIsLoading(true);
    const saveData = {
      type: FFEDBACKTYPE.FEEDBACK,
      whom: WHOM.ADMIN,
      address,
      phone,
      phone1,
      email,
      name,
      subject,
      description,
      isAnswer: isAnswer || false,
    };
    postFeedback(saveData)
      .then(() => {
        Success(null, 'Амжилттай илгээлээ.');
        form.resetFields();
        setIsAnswers(false);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Row>
        <Col span={21}>
          <Text strong className={styles.sideCompanyHeader}>
            Бидэнд саналаа ирүүлээрэй
          </Text>
        </Col>
        <Col span={3} className={styles.companyCol}>
          <InfoCircleFilled
            className={styles.companyicons}
            onClick={onClickShow}
          />
        </Col>
      </Row>
      {isShow && (
        <Row>
          <Col>
            <br />
            <Text strong className={styles.isShowTitles}>
              Системийг хөгжүүлэх, ашиглахтай холбоотой санал, хүсэлтээ Сангийн
              яамны нэгдсэн админд илгээнэ үү. Та саналаа илгээхийн өмнө юуны
              өмнө систем ашиглахтай холбоотой заавар, мэдээллийг &quot;Гарын
              авлага&quot; хэсгээс авна уу.
            </Text>

            <Divider style={{ margin: '8px 0px' }} />
          </Col>
        </Row>
      )}
      <br />
      <Form
        form={form}
        onFinish={submitForm}
        layout="vertical"
        validateMessages={validateMessages}
      >
        <FormItemCyrillic name="subject" label="Утга:">
          <Input />
        </FormItemCyrillic>
        <FormItemCyrillic name="description" label="Санал:">
          <TextArea rows={3} />
        </FormItemCyrillic>
        <br />
        <Text strong> Та хариу авах уу? </Text>
        <Form.Item
          name="isAnswer"
          rules={[
            {
              required: true,
              message: 'Та хариу авах уу?',
            },
          ]}
        >
          <Radio.Group
            onChange={onIsAnswers}
            className={styles.replyRadioButton}
          >
            <Radio value> Тийм </Radio>
            <Radio value={false}> Үгүй </Radio>
          </Radio.Group>
        </Form.Item>

        {isAnswers && <ContactInfo />}
        <br />
        <Row gutter={12} justify="center">
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Илгээх
          </Button>
        </Row>
      </Form>
    </>
  );
}
