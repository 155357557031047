import React, { useState } from 'react';
import { Row, Col, Modal } from 'antd';
import styles from './style.module.scss';
import Contents from './content/Content';
import YoutubeEmbed from '../components/youtubeEmded/YoutubeEmbed';
import learning from '../../img/learningBanner.svg';
import MetaInfo from '../../components/MetaInfo';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

function LearningPage() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <MetaInfo title="Суралцахуй - Шилэн данс" />
      <Modal
        title="Заавар"
        visible={isVisible}
        centered
        className={styles.modal}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsVisible(false)}
        cancelText="Хаах"
      >
        <YoutubeEmbed embedId="PanChFWdWr0" />
      </Modal>
      <Row className={styles.banner} justify="center" align="middle">
        <Col xs={0} sm={0} md={8} lg={7} className={styles.img}>
          <img src={learning} alt="img" height={270} />
        </Col>
        <Col xs={19} sm={19} md={11} lg={12}>
          <div
            className="banner-title"
            style={{ textAlign: 'center', maxWidth: '100%' }}
          >
            Суралцахуй
          </div>
          <div>
            Өнөөдөр төсөв гэж юу болох? Төсвийн орлого, төсвийн зарлага гэж юу
            вэ? Төсвийн тэнцэл, төсвийн ашиг, төсвийн алдагдал гэж юу болох?
            зэрэг олон асуултынхаа хариултыг олж аваарай. Улсын төсвийн талаар
            мэдлэгээ сайжруулснаар иргэн та төсөвт тавих хяналт, оролцоогоо
            нэмэгдүүлж чадах юм.
          </div>
          <Row justify="center">
            <Col
              xs={24}
              sm={12}
              className={styles.videoLink}
              onClick={() => setIsVisible(true)}
            >
              Нэвтэрч орох заавар
            </Col>
            <Col
              xs={24}
              sm={12}
              className={styles.videoLink}
              onClick={() => setIsVisible(true)}
            >
              Мэдээлэл оруулах заавар
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center" className={styles.learningPageRow}>
        <Col {...CONTENT_SPAN}>
          <Contents />
        </Col>
      </Row>
    </>
  );
}
export default LearningPage;

