import React, { useRef } from 'react';
import { Carousel, Row, Col } from 'antd';
import styles from './style.module.scss';
import banner from '../../img/bannerUnitedBudget.png';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

export default function Banner({ title }) {
  const carousel = useRef();

  return (
    <div className={styles.bannerContainer}>
      {/* <AiOutlineLeftCircle
        className="carousel-left"
        onClick={previous}
        style={{ color: 'blue' }}
      /> */}
      <Carousel
        dots={false}
        ref={ref => {
          carousel.current = ref;
        }}
        autoplay={false}
      >
        <div>
          <img src={banner} alt="banner" style={{ width: '100%' }} />
          {title}
        </div>
      </Carousel>
      {/* <AiOutlineRightCircle
        className="carousel-right"
        onClick={nextPage}
        style={{ color: 'blue' }}
      /> */}
      <Row justify="center" className={styles.info}>
        <Col {...CONTENT_SPAN}>
          <Row className={styles.title24}>
            2021 оны нийт батлагдсан төсвийн үзүүлэлт
          </Row>
          <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row className={styles.title20}>Монгол Улсын нэгдсэн төсөв:</Row>
              <Row className={styles.description}>12,900,000.0 сая төгрөг</Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row className={styles.title20}>
                Ирээдүйн өв санд хуримтлуулах:
              </Row>
              <Row className={styles.description}>976,960.0 сая төгрөг</Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Row className={styles.title20}>
                Тогтворжуулалтын санд хуримтлуулах:
              </Row>
              <Row className={styles.description}>976,960.0 сая төгрөг</Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

