import React, { useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Space,
  Card,
  Button,
  Divider,
  Dropdown,
  Menu,
  Select,
  Image,
  message,
  Table,
  Radio,
} from 'antd';
import {
  BarChartOutlined,
  DownloadOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  TableOutlined,
  AlignCenterOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import styles from './style.module.scss';
import ExampleTable from './ExampleTable';
import ExampleColumnCharts from './ExampleColCharts';
import emptyImg from '../../../img/emptyPage.svg';

import { useAppStore } from '../../../context/Tools';
import { getBudgetPerformanceIndicatorByYear } from '../../../service/reportServices';
import { SORT_ORDER } from '../../../constants/Constant';
import { BUDGET_INDICATORS } from '../../../constants/ReportConstants';

const { Option } = Select;
const { Text } = Typography;
const { Column } = Table;

export default function UrDungiinUzuulelt({ budgetType, title }) {
  const { serverDate } = useAppStore();

  const seriesList = [
    { field: 'annualBudget', name: 'Жилийн төсөв' },
    { field: 'approvedBudget', name: 'Батлагдсан төсөв' },
    { field: 'budgetExecution', name: 'Төсвийн гүйцэтгэл' },
  ];

  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYears, setSelectedYears] = useState([
    serverDate.getFullYear(),
  ]);
  const [indicator, setIndicator] = useState(1);

  const [chartDatas, setChartDatas] = useState();
  const [tableDatas, setTableDatas] = useState();
  const children = [];

  for (let i = 2016; i <= serverDate.getFullYear(); i++) {
    children.push(
      <Option key={i} value={i}>
        {i}
      </Option>
    );
  }

  const handleChange = yearList => {
    setSelectedYears(yearList);
  };

  const onChange = e => {
    setIndicator(e.target.value);
  };

  const prepareData = list => {
    const chartList = [];
    const tableList = [];

    list.forEach(row => {
      const findIndicator = (
        row.reportBudgetPerformanceIndicatorList || []
      ).find(
        z =>
          z.refBudgetTypeIndicatorsId === indicator &&
          z.refBudgetTypeId === budgetType &&
          z.status
      );
      const chartObj = { category: `${row.year}` };
      seriesList.forEach(series => {
        chartObj[series.name] = findIndicator
          ? findIndicator[series.field] || 0
          : 0;

        tableList.push({
          year: row.year,
          category: series.name,
          indicator: findIndicator ? findIndicator[series.field] || 0 : 0,
        });
      });
      chartList.push(chartObj);
    });

    setChartDatas(chartList);
    setTableDatas(tableList);
  };

  const getData = async () => {
    if (selectedYears.length === 0 || selectedYears.length > 3) {
      return message.info('3 болон түүнээс цөөн он сонгоно уу.');
    }
    if (!indicator) {
      return message.info('Үзүүлэлт сонгоно уу.');
    }
    setIsLoading(true);
    const params = {
      search: { status: true, year: selectedYears },
      sortField: 'year',
      sortOrder: SORT_ORDER.ASC,
    };

    const result = await getBudgetPerformanceIndicatorByYear(params);

    prepareData(result.content || []);
    setIsLoading(false);
  };

  useEffect(getData, []);

  const column = (
    <>
      <Column
        title="№"
        key="index"
        width={35}
        render={(value, item, index) => index + 1}
      />
      <Column title="Он" dataIndex="year" key="year" width={100} />
      <Column
        title="Үзүүлэлтүүд"
        dataIndex="category"
        key="category"
        width={100}
      />
      <Column
        title={BUDGET_INDICATORS.find(i => i.id === indicator)?.name}
        dataIndex="indicator"
        key="indicator"
        width={100}
      />
    </>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<FileImageOutlined />}>
        JPG
      </Menu.Item>
      <Menu.Item key="3" icon={<FilePdfOutlined />}>
        Excel
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="sub-title">{title}</div>
      <Row className={styles.filterRow}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={7}
          xl={6}
          className={styles.bigCardstyling}
        >
          <Card
            title={
              <Space size={6}>
                <AlignCenterOutlined style={{ fontSize: 14 }} />{' '}
                <Text className={styles.localIndicatorText} strong>
                  Шүүлтүүр
                </Text>
              </Space>
            }
            extra={<HomeOutlined />}
            style={{ height: '100%' }}
          >
            <Space direction="vertical">
              <Text className={styles.lightTextColor}>Он сонгох:</Text>
              <Divider style={{ margin: '4px 0px 11px 0px' }} />
            </Space>
            <Row className={styles.yearSelect}>
              <Select
                mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder="Он сонгох"
                value={selectedYears}
                onChange={handleChange}
              >
                {children}
              </Select>
            </Row>
            <br />

            <Col
              xs={15}
              sm={23}
              md={23}
              lg={23}
              xl={21}
              style={{ padding: '0px 0px', marginRight: '0px' }}
            >
              <Text className={styles.lightTextColor}>Төрөл сонгох:</Text>
              <Divider style={{ margin: '4px 0px 11px 0px' }} />
              <Radio.Group onChange={onChange} value={indicator}>
                <Space direction="vertical">
                  {BUDGET_INDICATORS.map(indr => (
                    <Radio key={indr.id} value={indr.id}>
                      {indr.name}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Col>
            <br />
            <Row justify="center" className={styles.filterCardButtonRow}>
              <Button type="primary" loading={isLoading} onClick={getData}>
                Шүүх
              </Button>
            </Row>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={17}
          xl={18}
          align="center"
          className={styles.contentRow}
        >
          <Card>
            <Row justify="end">
              <Space className={styles.downloadText}>
                <Space size={15}>
                  <TableOutlined
                    className="icon"
                    onClick={() => setIsShow(true)}
                  />
                  <BarChartOutlined
                    className="icon"
                    onClick={() => setIsShow(false)}
                  />
                </Space>
                <Dropdown overlay={menu}>
                  <Button type="text">
                    <DownloadOutlined className="icon" />
                  </Button>
                </Dropdown>
              </Space>
            </Row>
            <Row justify="center">
              {isEmpty(chartDatas) ? (
                <Image
                  src={emptyImg}
                  alt="empty"
                  preview={false}
                  width="100%"
                  height={300}
                />
              ) : (
                <>
                  {isShow ? (
                    <ExampleTable columns={column} datasource={tableDatas} />
                  ) : (
                    <ExampleColumnCharts
                      chartId={`urDungiinUzuulelt${budgetType}`}
                      chartData={chartDatas}
                      seriesList={seriesList}
                    />
                  )}
                </>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
}
