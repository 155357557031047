import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { isEmpty } from 'lodash';

am4core.useTheme(am4themesAnimated);
am4core.addLicense('ch-custom-attribution');

export default function TreeCharts(props) {
  const { TreeChartsId, TreeChartsData, isDynamic } = props;

  const getData = () => {
    if (isDynamic) {
      const someData = (TreeChartsData || []).map(parent => ({
        key: parent.key,
        number: parent.number,
        name: parent.name,
        value: parent.value,
        children: [],
      }));
      return someData;
    }
    return TreeChartsData;
  };

  // am4core.ready(() => {});

  const findDataByKey = (root, key) =>
    root.key === key
      ? root
      : root.children?.reduce(
          (result, n) => result || findDataByKey(n, key),
          undefined
        );

  const findDataItem = (dataContext, dataItems) => {
    if (!dataItems) {
      return;
    }
    for (let i = 0; i < dataItems?.length; i++) {
      const dataItem = dataItems.getIndex(i);
      if (dataItem.dataContext === dataContext) {
        return dataItem;
      }
      if (dataItem.hasChildren) {
        const child = findDataItem(dataContext, dataItem.children);
        if (child) {
          return child;
        }
      }
    }
  };

  useEffect(() => {
    const chart = am4core.create(`${TreeChartsId}`, am4charts.TreeMap);
    chart.data = getData();
    chart.maxLevels = 1;

    /* Set color step */
    chart.colors.step = 1;
    /* Define data fields */
    chart.dataFields.value = 'value';
    chart.dataFields.id = 'key';
    chart.dataFields.name = 'name';
    chart.dataFields.children = 'children';
    chart.dataFields.color = 'color';
    chart.hoverOnFocus;
    // chart.currentLevel = 400;
    chart.navigationBar = new am4charts.NavigationBar();
    chart.navigationBar.fontSize = 12;
    chart.homeText = isEmpty(TreeChartsData) ? 'Мэдээлэл байхгүй.' : 'ЭХЛЭЛ';

    const length = 10;
    for (let index = 0; index < length; index++) {
      const level = chart.seriesTemplates.create(`${index}`);
      const levelColumn = level.columns.template;
      level.tooltip.animationDuration = 0;
      level.color = 'color';
      level.strokeOpacity = 1;
      levelColumn.fillOpacity = 1;
      levelColumn.strokeWidth = 4;
      level.tooltip.pointerOrientation = 'vertical';
      level.tooltip.label.textAlign = 'middle';
      level.tooltip.getFillFromObject = false;
      level.tooltip.background.fill = am4core.color('#ffffff');
      level.tooltip.label.fill = am4core.color('#00000');
      level.tooltip.label.fontSize = 16;
      level.tooltip.label.wrap = true;
      level.columns.template.tooltipText =
        '[bold]{name} [/]\n Дүн:[#004a9f,bold,30px] {value} төгрөг [/] \n Гүйцэтгэл: [#004a9f,bold,30px] {percent_25_24}% [/] \n Эзлэх хувь: [#004a9f,bold,30px] {percent}% [/]';
      level.tooltip.dy = -15;
      const levelBullet = level.bullets.push(new am4charts.LabelBullet());
      levelBullet.locationY = 0.5;
      levelBullet.locationX = 0.5;
      levelBullet.label.text = '{name}';
      levelBullet.label.fill = am4core.color('#fff');
      levelBullet.label.fontSize = 14;
      levelBullet.label.wrap = true;
      levelBullet.hoverOnFocus = 'true';

      levelColumn.events.on('hit', ev => {
        if (!isDynamic) {
          return;
        }
        // const data = ev.target.dataItem.dataContext;
        // const children = [{ key: `111`, name: `1212`, value: 2000 }];
        // children.forEach(child => {
        //   const newChildDataItem = new am4charts.TreeMapDataItem();
        //   newChildDataItem.id = child.key;
        //   newChildDataItem.name = child.name;
        //   newChildDataItem.value = child.value;
        //   newChildDataItem.color = data.color;
        //   ev.target.dataItem.dataContext.children.insert(newChildDataItem);
        // });
        // chart.validateData();
        // chart.invalidateData();

        const { dataContext } = ev.target.dataItem.dataContext;

        if (isEmpty(dataContext.children)) {
          let findData = null;
          TreeChartsData.forEach(item => {
            if (!findData) {
              findData = findDataByKey(item, dataContext.key);
            }
          });
          if (findData && !isEmpty(findData.children)) {
            const childs = findData.children.map(item => ({
              key: item.key,
              number: item.number,
              name: item.name,
              value: item.value,
            }));
            dataContext.children = isEmpty(childs) ? null : childs;

            chart.invalidateData();
            chart.events.once('datavalidated', () => {
              const updatedDataItem = findDataItem(
                dataContext,
                chart.dataItems
              );
              if (updatedDataItem) {
                chart.zoomToChartDataItem(updatedDataItem);
              }
            });
          }
        }
      });
    }
  }, [TreeChartsId, TreeChartsData]);

  return (
    <div
      id={TreeChartsId}
      style={{
        width: '100%',
        height: '500px',
        display: 'flex',
        hover: { color: 'red' },
      }}
    />
  );
}

