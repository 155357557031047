import React from 'react';
import { Row, Col, Select, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import search from '../../../img/investment/search.svg';
import home from '../../../img/investment/home.svg';
import brush from '../../../img/investment/brush.svg';
import filter from '../../../img/investment/filter.svg';
import government from '../../../img/investment/local-government.svg';
import map from '../../../img/investment/map-marked.svg';
import styles from './style.module.scss';
import { concessData } from '../data';
import { CONTENT_SPAN } from '../../../constants/SystemConstants';

export default function Search() {
  const navigate = useNavigate();
  const { Option } = Select;
  const orgOptions = (
    <Select className={styles.options} placeholder="сонгох">
      <Option value="Байгууллага 1">Байгууллага 1</Option>
      <Option value="Байгууллага 2">Байгууллага 2</Option>
    </Select>
  );
  const angilalOptions = (
    <Select className={styles.angilalOptions} placeholder="сонгох">
      <Option value="Ангилал 1">Ангилал 1</Option>
      <Option value="Ангилал 2">Ангилал 2</Option>
    </Select>
  );

  const handleConcess = concessId => {
    navigate(concessId);
  };
  const clearFilter = () => {};

  return (
    <Row justify="center" className={styles.searchContainer}>
      <Col {...CONTENT_SPAN}>
        <Row>
          <Col span={6}>
            <Row className={styles.angilal}>
              <span>Хайлт хийх:</span>
              <span>{angilalOptions}</span>
            </Row>
            <div className={styles.filterContainer}>
              <div className={styles.subHeader}>
                <img src={search} alt="search" />
                <div className={styles.title}>Дэлгэрэнгүй хайлт</div>
                <img src={home} alt="home" />
              </div>
              <div className={styles.filter}>
                <div>Аймаг, Нийслэл: </div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Сум, Дүүрэг:</div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Газарзүйн байршил: </div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Эх үүсвэр:</div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Салбар:</div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Дэд салбар:</div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Концессын төрөл:</div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <div>Концессын гэрээний төлөв:</div>
                <div className={styles.subFilter}>{orgOptions}</div>
                <Row justify="space-between" className={styles.row}>
                  <Button onClick={clearFilter} className={styles.btnClear}>
                    <img src={brush} alt="clear" />
                    Цэвэрлэх
                  </Button>
                  <Button type="primary" className={styles.btnFilter}>
                    <img src={filter} alt="filter" />
                    Шүүх
                  </Button>
                </Row>
              </div>
            </div>
          </Col>
          <Col span={18} className={styles.results}>
            {concessData.map(item => (
              <div className={styles.result}>
                <Row className={styles.concessName}>{item.name}</Row>
                <Row>
                  <Col span={16}>
                    <div className={styles.concessDetail}>
                      <img src={map} alt="map" />
                      <div className={styles.detail}>{item.address}</div>
                    </div>
                    <div className={styles.concessDetail}>
                      <img src={government} alt="government" />
                      <div className={styles.detail}>{item.tez}</div>
                    </div>
                  </Col>
                  <Col span={8} className={styles.btn}>
                    <Button
                      type="primary"
                      onClick={() => {
                        handleConcess(item.id);
                      }}
                    >
                      Дэлгэрэнгүй
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

