/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
import { Card, Col, Row, Space, Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import mapDataAsia from '../../localBudget/localTez/data/mapData';
import styles from './style.module.scss';
import imges from './images/Group1.svg';
import imges1 from './images/Group2.svg';
import imges2 from './images/Group4.svg';
import imges3 from './images/Group5.svg';

const { Text } = Typography;

require('highcharts/modules/map')(Highcharts);

function MongoliaMapHealth() {
  const [informarioin, setinformation] = useState();
  const data = [
    ['mn-da', 0],
    ['mn-ub', 1],
    ['mn-hg', 2],
    ['mn-uv', 3],
    ['mn-dg', 4],
    ['mn-og', 5],
    ['mn-hn', 6],
    ['mn-bh', 7],
    ['mn-ar', 8],
    ['mn-dz', 9],
    ['mn-ga', 10],
    ['mn-hd', 11],
    ['mn-bo', 12],
    ['mn-bu', 13],
    ['mn-er', 14],
    ['mn-sl', 15],
    ['mn-oh', 16],
    ['mn-du', 17],
    ['mn-to', 18],
    ['mn-gs', 19],
    ['mn-dd', 20],
    ['mn-sb', 21],
  ];

  const mapOptions = {
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    chart: {
      height: '600px',
    },

    colors: ['#F5F5F5'],
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoomTo: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },
    tooltip: {
      animation: true,
      backgroundColor: '#ffff',
      useHTML: true,
      formatter() {
        const html =
          "<div style='width:100%;text-align:start; direction:ltr'>" +
          `<img  src=${imges} alt="logo" border="0" height="15" width="20">` +
          "<span style='color:#9ed9b4'>5.7 тэрбум ₮</span>&nbsp;" +
          `<img  src=${imges2} alt="logo" border="0" height="22" width="22">` +
          "<span style='color:#9ed9b4'>89.3 мян.хүн</span> <br>" +
          '<br/>' +
          `<img  src=${imges3} alt="logo" border="0" height="15" width="20">` +
          "<span style='color:#004a9f'>5.7 тэрбум ₮</span> &nbsp;" +
          `<img  src=${imges1} alt="logo" border="0" height="18" width="20">` +
          "<span style='color:#004a9f'>127.6 мян.хүн</span> <br>" +
          // "<div class='vl'></div>" +
          '</div>';
        return html;
      },

      shape: 'square',
      borderColor: '#ffff',
      style: {
        fontSize: '15px',
      },
    },
    plotOptions: {
      map: {
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: '13px',
            color: '#395B82',
            fill: 'white',
            stroke: 'white',
            strokeWidth: '0px',
            strokelineJoin: 'unset',
            hover: {
              color: 'white',
            },
          },
        },
      },
      series: {
        point: {
          events: {
            click() {
              // const text = `<b>Clicked point</b><br>Series: ${this.series.name}<br>Point: ${this.name} (${this.value}/km²)`;
              // const { chart } = this.series;
              // if (!chart.clickLabel) {
              //   chart.clickLabel = chart.renderer
              //     .label(text, 0, 250)
              //     .css({
              //       width: '180px',
              //     })
              //     .add();
              // } else {
              //   chart.clickLabel.attr({
              //     text,
              //   });
              // }
              // if (this.name === 'Улаанбаатар') {
              //   console.log('pizduu');
              // }
              setinformation(this.name);
            },
          },
        },
      },
    },
    series: [
      {
        mapData: mapDataAsia,
        showInLegend: false,
        name: 'Монгол улс',
        borderColor: '#9C9C9C',
        nullColor: 'rgba(200, 200, 200, 0.3)',
        data,
        states: {
          hover: {
            color: '#9ed9b4',
          },
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
      },
    ],
  };
  return (
    <>
      <Row justify="center" className={styles.insuranceMapRow}>
        <div style={{ width: '100%' }}>
          <HighchartsReact
            options={mapOptions}
            constructorType="mapChart"
            highcharts={Highcharts}
          />
        </div>
      </Row>
      <Row justify="center">
        <Space size={30} style={{ flexWrap: 'wrap' }}>
          <Space>
            <img src={imges} alt="logo" className={styles.descLogo} />
            <Text strong className={styles.greenTextDescription}>
              ЭМД-ын сангийн орлого
            </Text>
          </Space>
          <Space>
            <img src={imges3} alt="logo" className={styles.descLogo} />
            <Text strong className={styles.blueTextDescription}>
              ЭМД-ын сангийн зарлага
            </Text>
          </Space>
          <Space>
            <img src={imges2} alt="logo" className={styles.descLogo} />
            <Text strong className={styles.greenTextDescription}>
              ЭМД-ын шимтгэл төлөгч иргэд
            </Text>
          </Space>
          <Space>
            <img src={imges1} alt="logo" className={styles.desOddLogo} />
            <Text strong className={styles.blueTextDescription}>
              ЭМД-ын үйлчилгээг хүртэгч иргэд
            </Text>
          </Space>
        </Space>
      </Row>
    </>
  );
}
export default MongoliaMapHealth;
