import React from 'react';
import SearchBar from './SearchBar';

const TableHeaderData = ({
  filterByName,
  filterByType,
  filterByDate,
  handleSearchFilter,
}) => {
  const headerData = [
    {
      title: '№',
      dataIndex: 'number',
      key: 'number',
      children: [
        {
          title: '',
          dataIndex: 'index',
          key: 'index',
          width: 40,
        },
      ],
    },
    {
      title: 'Тайлангийн нэр',
      dataIndex: 'reportName',
      key: 'reportName',
      children: [
        {
          title: (
            <SearchBar
              type="name"
              keyWord={filterByName}
              setKeyword={handleSearchFilter}
            />
          ),
          dataIndex: 'name',
          key: 'name',
        },
      ],
    },
    {
      title: 'Мэдээллийн төрөл',
      dataIndex: 'reportType',
      key: 'reportType',
      children: [
        {
          title: (
            <SearchBar
              type="type"
              keyWord={filterByType}
              setKeyword={handleSearchFilter}
            />
          ),
          dataIndex: 'type',
          key: 'type',
        },
      ],
    },
    {
      title: 'Мэдээлэл оруулах хугацаа',
      dataIndex: 'reportDate',
      key: 'reportDate',
      children: [
        {
          title: (
            <SearchBar
              type="date"
              keyWord={filterByDate}
              setKeyword={handleSearchFilter}
            />
          ),
          dataIndex: 'legalTimeDescription',
          key: 'legalTimeDescription',
        },
      ],
    },
    {
      title: 'Заавал эсэх',
      dataIndex: 'reportIsRequire',
      key: 'reportIsRequire',
      children: [
        {
          title: '',
          dataIndex: 'isRequire',
          key: 'isRequire',
          align: 'center',
        },
      ],
    },
    {
      title: '1',
      dataIndex: '1',
      key: '1',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '2',
      dataIndex: '2',
      key: '2',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '3',
      dataIndex: '3',
      key: '3',
      className: 'season',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '4',
      dataIndex: '4',
      key: '4',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '5',
      dataIndex: '5',
      key: '5',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '6',
      dataIndex: '6',
      key: '6',
      className: 'season',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '7',
      dataIndex: '7',
      key: '7',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '8',
      dataIndex: '8',
      key: '8',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '9',
      dataIndex: '9',
      key: '9',
      className: 'season',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '10',
      dataIndex: '10',
      key: '10',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '11',
      dataIndex: '11',
      key: '11',
      width: '3.5%',
      align: 'center',
    },
    {
      title: '12',
      dataIndex: '12',
      key: '12',
      className: 'season',
      width: '3.5%',
      align: 'center',
    },
  ];

  return headerData;
};

export default TableHeaderData;

