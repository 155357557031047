export const HOME_PAGE = '/';
export const UNITED_BUDGET = '/result';
export const LEARNING_PAGE = '/learning';
export const COUNTRY_BUDGET = '/country';
export const STATE_BUDGET = '/stateBudget';
export const HEALTH_BUDGET = '/healtInsurance';
export const ORGANIZATION_PAGE = '/organization';
export const GOVERNMENT_SPECIAL = '/governmentSpecial';
export const OPEN_DATA = '/openData';
export const SOCIAL_INSURANCE = '/socialInsurance';
export const INVESTMENT = '/investment';
export const PUBLIC_PRIVATE_SECTOR = '/supplier';
export const PORTAL_NEWS_DETAIL = '/news';
