import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import Banner from './InvestmentBanner';
import Cards from './InvestmentCards';
import Country from './Country/Country';
import Concess from './Concess/Concess';
import Local from './Local/Local';
import Comment from '../components/Comment/Comment';
import home from '../../img/home.svg';
import { CONTENT_SPAN } from '../../constants/SystemConstants';

export default function Investment() {
  const [menu, setMenu] = useState(1);
  let title = '';
  let content = '';

  if (menu === 1) {
    title = 'Монгол Улсын төсвийн хөрөнгө оруулалт';
    content = <Country />;
  } else if (menu === 2) {
    title = 'Орон нутгийн хөрөнгө оруулалт';
    content = <Local />;
  } else {
    title = 'Концесс';
    content = <Concess />;
  }
  return (
    <>
      <Banner />
      <div className="sub-title">{title}</div>
      <Cards setMenu={setMenu} menu={menu} />
      <Row justify="center">
        <Col {...CONTENT_SPAN} className={styles.path}>
          <img src={home} alt="home" className={styles.home} />
          <span>Хөрөнгө оруулалт / </span>{' '}
          <span className={styles.endPath}>{title}</span>
        </Col>
      </Row>
      {content}
      <Comment />
    </>
  );
}

