import styles from './style.module.scss';

export default function Banner2() {
  return (
    <svg
      width="600"
      height="338"
      viewBox="0 0 600 338"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.bannerSvg}
    >
      <g id="2222">
        <path
          id="Path 3381"
          d="M511.543 214.525C512.084 214.392 512.588 214.142 513.022 213.793C513.455 213.443 513.807 213.003 514.052 212.503C514.297 212.004 514.429 211.456 514.44 210.899C514.451 210.343 514.339 209.791 514.113 209.282L523.996 200.039L517.159 198.396L508.822 207.425C507.992 207.861 507.35 208.586 507.019 209.463C506.688 210.341 506.69 211.309 507.026 212.185C507.361 213.06 508.007 213.783 508.839 214.214C509.672 214.645 510.634 214.756 511.543 214.525V214.525Z"
          fill="#FFB6B6"
        />
        <path
          id="Path 3382"
          d="M516.648 207.08L512.039 200.58L529.351 182.675L533.371 154.452C533.57 153.057 534.073 151.723 534.846 150.544C535.618 149.366 536.64 148.372 537.84 147.633C539.04 146.893 540.388 146.428 541.788 146.268C543.188 146.108 544.606 146.259 545.941 146.709V146.709C548.352 147.524 550.341 149.263 551.472 151.543C552.602 153.824 552.782 156.459 551.972 158.872L541.716 189.3L516.648 207.08Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3383"
          d="M549.152 327.338H544.036L541.602 307.603H549.153L549.152 327.338Z"
          fill="#FFB6B6"
        />
        <path
          id="Path 3384"
          d="M551.906 337.052H548.274L547.626 333.624L545.966 337.052H536.337C535.875 337.052 535.425 336.905 535.053 336.632C534.681 336.358 534.406 335.973 534.269 335.532C534.131 335.091 534.139 334.618 534.289 334.181C534.44 333.745 534.727 333.368 535.107 333.106L542.796 327.796V324.331L550.884 324.814L551.906 337.052Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3387"
          d="M551.31 320.31H540.735V282.658L534.189 258.111C529.491 240.557 528.243 222.258 530.513 204.228L530.522 204.154L530.579 204.106C530.747 203.965 547.501 190.112 558.098 200.706C565.241 207.849 558.529 233.534 558.153 234.944V279.222L551.31 320.31Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3388"
          d="M551.284 142.778L551.228 142.722L537.746 142.927L530.546 159.821C523.883 170.17 530.254 176.056 530.701 176.989L529.427 189.689L528.607 197.881L527.289 211.071L558.189 200.977L554.752 187.209L558.724 167.35C559.606 162.958 559.388 158.417 558.09 154.13C556.792 149.843 554.454 145.944 551.284 142.779V142.778Z"
          fill="#E4E4E4"
        />
        <g id="leg" className={styles.leg}>
          <path
            id="Path 3385"
            d="M580.717 322.129L576.223 324.574L564.653 308.403L571.286 304.794L580.717 322.129Z"
            fill="#FFB6B6"
          />
          <path
            id="Path 3386"
            d="M587.777 329.349L584.588 331.084L582.381 328.384L582.562 332.184L574.104 336.784C573.699 337.005 573.233 337.09 572.776 337.028C572.318 336.966 571.893 336.758 571.562 336.437C571.23 336.115 571.01 335.696 570.934 335.241C570.858 334.786 570.929 334.318 571.138 333.906L575.354 325.566L573.698 322.522L581.033 319.08L587.777 329.349Z"
            fill="#2F2E41"
          />
          <path
            id="Path 3389"
            d="M545 282.853L566.721 313.406L575.053 307.482L556.913 270.203L545 282.853Z"
            fill="#2F2E41"
          />
        </g>
        <path
          id="Ellipse 508"
          d="M543.225 139.494C550.099 139.494 555.671 133.922 555.671 127.048C555.671 120.174 550.099 114.602 543.225 114.602C536.351 114.602 530.779 120.174 530.779 127.048C530.779 133.922 536.351 139.494 543.225 139.494Z"
          fill="#FFB6B6"
        />
        <path
          id="Path 3390"
          opacity="0.1"
          d="M555.23 175.199L541.655 190.863L528.608 197.88L529.428 189.688L537.111 185.374L555.23 175.199Z"
          fill="black"
        />
        <path
          id="Path 3391"
          d="M534.508 150.373L533.34 145.256C532.956 143.544 532.919 141.772 533.231 140.045C533.543 138.318 534.197 136.67 535.155 135.2C536.035 133.814 536.526 132.216 536.575 130.575C536.625 128.933 536.231 127.309 535.436 125.872C534.849 124.711 533.957 123.731 532.856 123.037C531.755 122.344 530.486 121.963 529.185 121.935H528.874V121.624C528.874 117.161 532.352 114.011 535.586 113.83C535.694 113.303 535.924 112.809 536.258 112.388C536.592 111.967 537.02 111.63 537.508 111.405C540.246 109.826 545.54 109.484 549.539 111.588C549.613 110.908 549.87 110.261 550.282 109.715C550.694 109.17 551.247 108.746 551.88 108.488C552.537 108.26 553.245 108.219 553.924 108.371C553.982 107.252 554.444 106.192 555.224 105.388C555.888 104.831 556.675 104.439 557.52 104.244C558.365 104.05 559.244 104.059 560.085 104.27C563.107 104.952 565.789 106.685 567.651 109.161C569.458 111.583 570.973 114.208 572.167 116.984C573.339 119.715 574.833 122.297 576.619 124.673C578.398 127.026 580.958 128.666 583.839 129.297C585.252 129.579 586.716 129.444 588.053 128.908C589.39 128.372 590.542 127.458 591.368 126.278L591.649 125.822L591.905 126.293C592.661 127.851 592.919 129.605 592.641 131.315C592.364 133.025 591.567 134.608 590.357 135.848C589.134 137.047 587.657 137.956 586.036 138.508C584.415 139.06 582.69 139.241 580.99 139.037C577.616 138.622 574.425 137.277 571.771 135.153C568.749 132.884 566.881 130.376 566.217 127.698C566.031 126.87 565.897 126.03 565.817 125.185C565.611 123.414 565.417 121.742 564.328 120.511C563.264 119.505 561.931 118.828 560.491 118.562L560.227 118.491C560.179 118.989 560.022 119.471 559.769 119.903C559.515 120.335 559.171 120.706 558.759 120.991C558.512 121.14 558.242 121.244 557.959 121.3C559.517 127.569 558.877 132.368 556.059 135.568C551.295 140.968 542.141 139.859 541.753 139.809L541.495 139.776L541.482 139.517C541.447 138.821 541.362 138.128 541.227 137.444C539.916 140.603 535.505 148.957 534.915 149.799L534.508 150.373Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3392"
          d="M94.97 170.47C94.6063 170.949 94.1421 171.342 93.61 171.622C93.0778 171.902 92.4907 172.062 91.8901 172.091C91.2894 172.12 90.6898 172.016 90.1334 171.788C89.5771 171.56 89.0775 171.212 88.67 170.77L75.27 176.6L76.576 169.117L89.105 164.728C89.8936 164.094 90.8874 163.771 91.8981 163.82C92.9088 163.87 93.8664 164.288 94.5896 164.995C95.3128 165.703 95.7514 166.652 95.8224 167.661C95.8934 168.67 95.592 169.671 94.975 170.473L94.97 170.47Z"
          fill="#A0616A"
        />
        <path
          id="Path 3393"
          d="M86.025 172.807L54.8939 186.68L54.8269 186.656L19.342 174.011C16.6987 172.63 14.6835 170.29 13.7089 167.471C12.7344 164.653 12.8743 161.568 14.1 158.849V158.849C14.7793 157.347 15.7691 156.006 17.0041 154.913C18.2392 153.821 19.6916 153.003 21.2657 152.513C22.8397 152.023 24.4998 151.871 26.1366 152.069C27.7733 152.266 29.3497 152.808 30.762 153.659L57.169 169.567L82.7759 166.248L86.025 172.807Z"
          fill="#3F3D56"
        />
        <path
          id="Path 3394"
          d="M99.068 81.496C87.298 55.462 87.732 22.463 105.999 0.496002C112.11 30.9589 128.799 58.2784 153.114 77.621C162.514 85.047 173.751 92.327 176.32 104.03C177.92 111.312 175.653 119.015 171.736 125.359C167.819 131.703 162.358 136.921 156.959 142.059L156.353 143.819C132.863 127.559 110.838 107.53 99.068 81.496Z"
          fill="#F0F0F0"
        />
        <path
          id="Path 3395"
          d="M106.507 0.811002C102.266 24.0762 105.463 48.0863 115.644 69.431C117.754 74.065 120.581 78.3375 124.022 82.091C127.491 85.6401 131.848 88.1953 136.639 89.491C141.082 90.791 145.776 91.6 149.826 93.955C151.881 95.191 153.652 96.8474 155.022 98.8157C156.392 100.784 157.33 103.02 157.776 105.376C159.039 111.219 158.127 117.167 157.033 122.952C155.818 129.376 154.455 136.029 156.215 142.487C156.428 143.269 155.202 143.555 154.989 142.773C151.927 131.538 157.881 120.457 157.048 109.161C156.66 103.89 154.681 98.704 150.161 95.65C146.209 92.979 141.369 92.138 136.861 90.863C132.169 89.6683 127.841 87.3429 124.255 84.09C120.634 80.5238 117.647 76.3675 115.421 71.799C110.245 61.5939 106.706 50.6385 104.933 39.334C102.838 26.4436 102.964 13.2899 105.305 0.441999C105.45 -0.358001 106.653 0.0240079 106.505 0.814008L106.507 0.811002Z"
          fill="white"
        />
        <path
          id="Path 3396"
          d="M111.961 62.753C108.148 63.9749 104.025 63.8017 100.328 62.2643C96.6305 60.7268 93.6003 57.9253 91.778 54.36C91.412 53.637 92.518 53.034 92.884 53.76C94.5747 57.0834 97.3978 59.6929 100.844 61.1176C104.289 62.5422 108.131 62.6881 111.675 61.529C112.445 61.278 112.727 62.506 111.961 62.755V62.753Z"
          fill="white"
        />
        <path
          id="Path 3397"
          d="M134.3 89.129C138.11 81.8847 139.05 73.4704 136.932 65.564C136.722 64.781 137.948 64.495 138.158 65.278C140.347 73.5098 139.351 82.2642 135.369 89.794C134.989 90.511 133.922 89.843 134.299 89.129H134.3Z"
          fill="white"
        />
        <path
          id="Path 3398"
          d="M104.456 26.851C106.03 26.6856 107.538 26.132 108.845 25.2397C110.152 24.3474 111.217 23.1445 111.944 21.739C112.315 21.017 113.382 21.686 113.014 22.404C112.203 23.9532 111.026 25.2803 109.584 26.2694C108.142 27.2585 106.48 27.8794 104.743 28.078C104.581 28.1103 104.412 28.0797 104.272 27.9924C104.132 27.9051 104.03 27.7677 103.987 27.608C103.95 27.4455 103.979 27.275 104.067 27.1334C104.155 26.9919 104.295 26.8908 104.457 26.852L104.456 26.851Z"
          fill="white"
        />
        <path
          id="Path 3399"
          d="M202.734 0.753998C202.515 1.211 202.297 1.66901 202.083 2.13501C199.175 8.29899 196.773 14.689 194.9 21.242C194.747 21.742 194.6 22.26 194.462 22.767C190.118 38.6031 188.751 55.1081 190.432 71.443C191.107 77.9424 192.275 84.3809 193.927 90.703C196.209 99.434 199.377 108.719 198.662 117.437C198.598 118.347 198.477 119.253 198.301 120.148L158.549 144.429C158.431 144.439 158.318 144.458 158.2 144.468L156.657 145.51C156.549 145.19 156.444 144.856 156.336 144.535C156.273 144.35 156.218 144.159 156.155 143.973C156.116 143.848 156.076 143.722 156.034 143.611C156.021 143.569 156.008 143.527 156 143.495C155.957 143.384 155.929 143.277 155.892 143.175C155.299 141.308 154.719 139.434 154.151 137.551C154.145 137.542 154.145 137.542 154.151 137.527C149.857 123.176 146.723 108.341 145.94 93.501C145.917 93.055 145.887 92.601 145.882 92.142C145.566 85.4509 145.798 78.7453 146.575 72.092C147.012 68.4503 147.633 64.8329 148.436 61.254C150.528 52.0108 154.061 43.1543 158.906 35.009C168.566 18.773 183.306 5.80901 201.357 1.09801C201.814 0.975007 202.266 0.859998 202.734 0.753998Z"
          fill="#F0F0F0"
        />
        <path
          id="Path 3400"
          d="M202.953 1.30901C185.559 17.3312 173.656 38.4266 168.934 61.599C167.829 66.5693 167.515 71.6828 168.002 76.751C168.637 81.672 170.577 86.3334 173.622 90.251C176.388 93.961 179.647 97.437 181.464 101.751C182.361 103.975 182.777 106.364 182.686 108.761C182.595 111.157 181.998 113.507 180.935 115.657C178.425 121.082 174.116 125.282 169.759 129.243C164.922 133.643 159.827 138.132 157.345 144.348C157.045 145.101 155.893 144.59 156.194 143.838C160.513 133.023 171.939 127.761 178.075 118.238C180.938 113.796 182.48 108.464 180.71 103.304C179.162 98.792 175.804 95.204 172.971 91.473C169.944 87.6935 167.888 83.2302 166.984 78.473C166.24 73.4459 166.357 68.3289 167.33 63.341C169.341 52.0765 173.111 41.1984 178.502 31.105C184.59 19.5513 192.61 9.12426 202.215 0.275016C202.81 -0.271984 203.543 0.752012 202.952 1.29601L202.953 1.30901Z"
          fill="white"
        />
        <path
          id="Path 3401"
          d="M170.016 54.048C166.236 52.7279 163.048 50.1072 161.021 46.6537C158.995 43.2002 158.262 39.1389 158.954 35.195C159.097 34.395 160.343 34.582 160.2 35.38C159.549 39.0512 160.233 42.8341 162.126 46.0458C164.02 49.2576 166.999 51.6868 170.526 52.895C171.292 53.158 170.778 54.308 170.016 54.046V54.048Z"
          fill="white"
        />
        <path
          id="Path 3402"
          d="M171.971 88.557C179.375 85.0668 185.191 78.9142 188.26 71.326C188.56 70.574 189.715 71.085 189.412 71.836C186.204 79.728 180.138 86.12 172.425 89.736C171.69 90.08 171.24 88.904 171.971 88.561V88.557Z"
          fill="white"
        />
        <path
          id="Path 3403"
          d="M185.637 20.865C186.993 21.6805 188.531 22.1464 190.112 22.2207C191.693 22.2951 193.267 21.9756 194.694 21.291C195.425 20.938 195.874 22.115 195.148 22.466C193.568 23.2147 191.829 23.5652 190.082 23.4867C188.335 23.4083 186.635 22.9034 185.128 22.016C184.979 21.9442 184.863 21.8184 184.803 21.664C184.743 21.5096 184.745 21.3384 184.807 21.185C184.875 21.0328 185.001 20.9137 185.156 20.8536C185.312 20.7935 185.485 20.7972 185.638 20.864L185.637 20.865Z"
          fill="white"
        />
        <path
          id="Path 3404"
          d="M355.154 95.171C343.384 69.137 343.818 36.138 362.085 14.171C368.196 44.6339 384.885 71.9534 409.2 91.296C418.6 98.722 429.837 106.002 432.406 117.705C434.006 124.987 431.739 132.69 427.822 139.034C423.905 145.378 418.444 150.596 413.045 155.734L412.439 157.494C388.949 141.234 366.924 121.205 355.154 95.171Z"
          fill="#F0F0F0"
        />
        <path
          id="Path 3405"
          d="M362.593 14.485C358.352 37.7502 361.549 61.7603 371.73 83.105C373.84 87.739 376.667 92.0114 380.108 95.765C383.577 99.3141 387.934 101.869 392.725 103.165C397.168 104.465 401.862 105.274 405.912 107.629C407.967 108.865 409.738 110.521 411.108 112.49C412.478 114.458 413.416 116.694 413.862 119.05C415.125 124.893 414.213 130.841 413.119 136.626C411.904 143.05 410.541 149.703 412.301 156.161C412.514 156.943 411.288 157.229 411.075 156.447C408.013 145.212 413.967 134.131 413.134 122.835C412.746 117.564 410.766 112.378 406.247 109.324C402.295 106.653 397.455 105.812 392.947 104.537C388.255 103.342 383.927 101.017 380.341 97.764C376.72 94.1977 373.733 90.0414 371.507 85.473C366.331 75.2679 362.792 64.3125 361.019 53.008C358.924 40.1176 359.05 26.9639 361.391 14.116C361.536 13.316 362.739 13.698 362.591 14.488L362.593 14.485Z"
          fill="white"
        />
        <path
          id="Path 3406"
          d="M368.047 76.427C364.234 77.6489 360.111 77.4757 356.414 75.9383C352.717 74.4008 349.686 71.5993 347.864 68.034C347.498 67.311 348.604 66.708 348.97 67.434C350.661 70.7574 353.484 73.3669 356.93 74.7916C360.375 76.2162 364.217 76.3622 367.761 75.203C368.532 74.952 368.813 76.18 368.047 76.429V76.427Z"
          fill="white"
        />
        <path
          id="Path 3407"
          d="M390.386 102.803C394.196 95.5587 395.136 87.1444 393.018 79.238C392.807 78.455 394.034 78.169 394.244 78.952C396.433 87.1838 395.437 95.9382 391.455 103.468C391.075 104.185 390.008 103.517 390.385 102.803H390.386Z"
          fill="white"
        />
        <path
          id="Path 3408"
          d="M360.542 40.525C362.116 40.3596 363.624 39.806 364.931 38.9137C366.238 38.0214 367.303 36.8185 368.03 35.413C368.401 34.691 369.468 35.36 369.1 36.078C368.289 37.6271 367.112 38.9542 365.67 39.9433C364.228 40.9324 362.566 41.5534 360.829 41.752C360.667 41.7843 360.498 41.7537 360.358 41.6664C360.218 41.5791 360.116 41.4417 360.073 41.282C360.036 41.1195 360.065 40.949 360.153 40.8074C360.241 40.6659 360.381 40.5648 360.543 40.526L360.542 40.525Z"
          fill="white"
        />
        <path
          id="Path 3409"
          d="M458.82 14.429C458.601 14.886 458.383 15.344 458.169 15.81C455.261 21.974 452.859 28.364 450.986 34.917C450.833 35.417 450.686 35.935 450.548 36.442C446.204 52.2781 444.837 68.7831 446.518 85.118C447.193 91.6173 448.361 98.0559 450.013 104.378C452.294 113.109 455.463 122.394 454.748 131.112C454.684 132.022 454.563 132.928 454.387 133.823L414.635 158.104C414.517 158.114 414.404 158.133 414.286 158.143L412.743 159.185C412.635 158.865 412.53 158.531 412.422 158.21C412.359 158.025 412.304 157.834 412.241 157.648C412.202 157.523 412.163 157.397 412.12 157.286C412.107 157.244 412.094 157.202 412.086 157.17C412.043 157.059 412.015 156.952 411.978 156.85C411.385 154.983 410.805 153.109 410.236 151.226C410.231 151.217 410.231 151.217 410.236 151.202C405.942 136.851 402.808 122.016 402.025 107.176C402.002 106.73 401.972 106.276 401.967 105.817C401.651 99.1259 401.883 92.4203 402.66 85.767C403.097 82.1253 403.718 78.5079 404.521 74.929C406.613 65.6858 410.146 56.8292 414.991 48.684C424.651 32.448 439.391 19.484 457.442 14.773C457.9 14.65 458.352 14.535 458.82 14.429Z"
          fill="#F0F0F0"
        />
        <path
          id="Path 3410"
          d="M459.039 14.983C441.645 31.0052 429.742 52.1006 425.02 75.273C423.915 80.2433 423.601 85.3567 424.088 90.425C424.723 95.346 426.663 100.007 429.708 103.925C432.474 107.635 435.733 111.111 437.55 115.425C438.447 117.649 438.863 120.038 438.772 122.435C438.681 124.831 438.084 127.181 437.021 129.331C434.512 134.756 430.202 138.956 425.845 142.917C421.008 147.317 415.913 151.806 413.431 158.022C413.131 158.775 411.979 158.264 412.28 157.512C416.599 146.697 428.025 141.435 434.161 131.912C437.024 127.47 438.566 122.138 436.796 116.978C435.248 112.466 431.89 108.878 429.057 105.147C426.03 101.367 423.974 96.9042 423.07 92.147C422.326 87.1199 422.443 82.0028 423.416 77.015C425.427 65.7505 429.197 54.8724 434.588 44.779C440.677 33.2257 448.697 22.799 458.301 13.95C458.896 13.403 459.629 14.427 459.038 14.971L459.039 14.983Z"
          fill="white"
        />
        <path
          id="Path 3411"
          d="M426.102 67.723C422.322 66.4031 419.133 63.7824 417.107 60.3289C415.08 56.8754 414.347 52.814 415.039 48.87C415.182 48.07 416.428 48.257 416.285 49.055C415.634 52.7263 416.318 56.5093 418.211 59.7211C420.105 62.9329 423.085 65.362 426.612 66.57C427.378 66.833 426.864 67.983 426.102 67.721V67.723Z"
          fill="white"
        />
        <path
          id="Path 3412"
          d="M428.057 102.232C435.461 98.7417 441.277 92.5891 444.346 85.001C444.646 84.249 445.801 84.759 445.497 85.511C442.289 93.403 436.223 99.7951 428.51 103.411C427.777 103.751 427.327 102.575 428.057 102.232Z"
          fill="white"
        />
        <path
          id="Path 3413"
          d="M441.723 34.539C443.079 35.3545 444.617 35.8204 446.198 35.8947C447.779 35.9691 449.353 35.6496 450.78 34.965C451.511 34.612 451.96 35.789 451.234 36.14C449.654 36.8888 447.915 37.2393 446.168 37.1609C444.421 37.0824 442.72 36.5774 441.214 35.69C441.065 35.6182 440.949 35.4924 440.889 35.338C440.829 35.1837 440.831 35.0124 440.893 34.859C440.961 34.7072 441.087 34.5885 441.242 34.5286C441.398 34.4687 441.57 34.4725 441.723 34.539V34.539Z"
          fill="white"
        />
        <path
          id="Path 3414"
          d="M117.112 266.242H419.928V90.93C419.926 89.6164 419.404 88.3571 418.475 87.4284C417.546 86.4996 416.286 85.9773 414.973 85.976H122.067C120.753 85.9773 119.494 86.4996 118.565 87.4284C117.636 88.3571 117.114 89.6164 117.112 90.93V266.242Z"
          fill="#CACACA"
        />
        <path
          id="Rectangle 2157"
          d="M410.572 92.828H126.468V254.119H410.572V92.828Z"
          fill="white"
        />
        <path
          id="Path 3415"
          d="M116.849 261.501V283.135C116.849 284.946 117.568 286.683 118.849 287.964C120.13 289.245 121.867 289.964 123.678 289.964H235.445V332.659H233.96C233.725 332.659 233.499 332.752 233.333 332.919C233.166 333.085 233.073 333.311 233.073 333.546V336.516C233.073 336.751 233.166 336.977 233.333 337.143C233.499 337.31 233.725 337.403 233.96 337.403H303.343C303.578 337.403 303.804 337.31 303.97 337.143C304.137 336.977 304.23 336.751 304.23 336.516V333.546C304.23 333.311 304.137 333.085 303.97 332.919C303.804 332.752 303.578 332.659 303.343 332.659H301.858V289.961H413.626C415.437 289.961 417.174 289.242 418.455 287.961C419.736 286.68 420.455 284.943 420.455 283.132V261.501H116.849Z"
          fill="#3F3D56"
        />
        <g id="data1" className={styles.data1}>
          <path
            id="Path 3416"
            d="M214.811 172.592H145.576C145.093 172.591 144.631 172.4 144.29 172.058C143.948 171.717 143.757 171.255 143.756 170.772V142.68C143.757 142.197 143.948 141.735 144.29 141.394C144.631 141.052 145.093 140.861 145.576 140.86H214.81C215.292 140.861 215.755 141.052 216.096 141.394C216.438 141.735 216.629 142.197 216.63 142.68V170.772C216.629 171.254 216.437 171.716 216.096 172.057C215.755 172.398 215.293 172.59 214.811 172.591V172.592Z"
            fill="#006CB3"
          />
          <path
            id="Path 3417"
            d="M208.218 186.069H152.168C151.877 186.075 151.588 186.023 151.318 185.916C151.048 185.809 150.801 185.65 150.594 185.446C150.386 185.243 150.221 185 150.108 184.732C149.995 184.464 149.937 184.176 149.937 183.886C149.937 183.595 149.995 183.307 150.108 183.039C150.221 182.771 150.386 182.528 150.594 182.325C150.801 182.121 151.048 181.962 151.318 181.855C151.588 181.748 151.877 181.696 152.168 181.702H208.217C211.03 181.662 211.063 186.109 208.218 186.069Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 3418"
            d="M208.218 196.077H152.168C151.877 196.083 151.588 196.031 151.318 195.924C151.048 195.817 150.801 195.658 150.594 195.454C150.386 195.251 150.221 195.008 150.108 194.74C149.995 194.472 149.937 194.184 149.937 193.894C149.937 193.603 149.995 193.315 150.108 193.047C150.221 192.779 150.386 192.536 150.594 192.333C150.801 192.129 151.048 191.97 151.318 191.863C151.588 191.756 151.877 191.704 152.168 191.71H208.217C211.03 191.67 211.063 196.117 208.218 196.077Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 3419"
            d="M208.218 206.086H152.168C151.877 206.092 151.588 206.04 151.318 205.933C151.048 205.826 150.801 205.667 150.594 205.463C150.386 205.26 150.221 205.017 150.108 204.749C149.995 204.481 149.937 204.193 149.937 203.902C149.937 203.612 149.995 203.324 150.108 203.056C150.221 202.788 150.386 202.545 150.594 202.342C150.801 202.138 151.048 201.979 151.318 201.872C151.588 201.765 151.877 201.713 152.168 201.719H208.217C211.03 201.679 211.063 206.126 208.218 206.086Z"
            fill="#E4E4E4"
          />
        </g>
        <g id="data2" className={styles.data2}>
          <path
            id="Path 3420"
            d="M303.138 172.592H233.903C233.42 172.591 232.958 172.4 232.617 172.058C232.275 171.717 232.084 171.255 232.083 170.772V142.68C232.084 142.197 232.275 141.735 232.617 141.394C232.958 141.052 233.42 140.861 233.903 140.86H303.137C303.62 140.861 304.082 141.052 304.423 141.394C304.765 141.735 304.956 142.197 304.957 142.68V170.772C304.956 171.254 304.764 171.716 304.423 172.057C304.082 172.398 303.62 172.59 303.138 172.591V172.592Z"
            fill="#006CB3"
          />
          <path
            id="Path 3421"
            d="M296.545 186.069H240.495C239.924 186.057 239.381 185.821 238.981 185.413C238.581 185.005 238.358 184.457 238.358 183.885C238.358 183.314 238.581 182.766 238.981 182.358C239.381 181.95 239.924 181.714 240.495 181.702H296.544C299.361 181.662 299.39 186.109 296.545 186.069Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 3422"
            d="M296.545 196.077H240.495C239.924 196.065 239.381 195.829 238.981 195.421C238.581 195.013 238.358 194.465 238.358 193.893C238.358 193.322 238.581 192.774 238.981 192.366C239.381 191.958 239.924 191.722 240.495 191.71H296.544C299.361 191.67 299.39 196.117 296.545 196.077Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 3423"
            d="M296.545 206.086H240.495C240.204 206.092 239.915 206.04 239.645 205.933C239.375 205.826 239.128 205.667 238.921 205.463C238.713 205.26 238.548 205.017 238.435 204.749C238.322 204.481 238.264 204.193 238.264 203.902C238.264 203.612 238.322 203.324 238.435 203.056C238.548 202.788 238.713 202.545 238.921 202.342C239.128 202.138 239.375 201.979 239.645 201.872C239.915 201.765 240.204 201.713 240.495 201.719H296.544C299.361 201.679 299.39 206.126 296.545 206.086Z"
            fill="#E4E4E4"
          />
        </g>
        <g id="data3" className={styles.data3}>
          <path
            id="Path 3424"
            d="M391.464 172.592H322.23C321.747 172.591 321.285 172.4 320.944 172.058C320.602 171.717 320.41 171.255 320.41 170.772V142.68C320.41 142.197 320.602 141.735 320.944 141.394C321.285 141.052 321.747 140.861 322.23 140.86H391.464C391.946 140.861 392.409 141.052 392.75 141.394C393.092 141.735 393.283 142.197 393.284 142.68V170.772C393.283 171.255 393.092 171.717 392.75 172.058C392.409 172.4 391.946 172.591 391.464 172.592V172.592Z"
            fill="#006CB3"
          />
          <path
            id="Path 3425"
            d="M384.872 186.069H328.822C328.531 186.075 328.242 186.023 327.972 185.916C327.702 185.809 327.455 185.65 327.248 185.446C327.04 185.243 326.875 185 326.762 184.732C326.649 184.464 326.591 184.176 326.591 183.886C326.591 183.595 326.649 183.307 326.762 183.039C326.875 182.771 327.04 182.528 327.248 182.325C327.455 182.121 327.702 181.962 327.972 181.855C328.242 181.748 328.531 181.696 328.822 181.702H384.871C387.688 181.662 387.717 186.109 384.872 186.069Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 3426"
            d="M384.872 196.077H328.822C328.531 196.083 328.242 196.031 327.972 195.924C327.702 195.817 327.455 195.658 327.248 195.454C327.04 195.251 326.875 195.008 326.762 194.74C326.649 194.472 326.591 194.184 326.591 193.894C326.591 193.603 326.649 193.315 326.762 193.047C326.875 192.779 327.04 192.536 327.248 192.333C327.455 192.129 327.702 191.97 327.972 191.863C328.242 191.756 328.531 191.704 328.822 191.71H384.871C387.688 191.67 387.717 196.117 384.872 196.077Z"
            fill="#E4E4E4"
          />
          <path
            id="Path 3427"
            d="M384.872 206.086H328.822C328.531 206.092 328.242 206.04 327.972 205.933C327.702 205.826 327.455 205.667 327.248 205.463C327.04 205.26 326.875 205.017 326.762 204.749C326.649 204.481 326.591 204.193 326.591 203.902C326.591 203.612 326.649 203.324 326.762 203.056C326.875 202.788 327.04 202.545 327.248 202.342C327.455 202.138 327.702 201.979 327.972 201.872C328.242 201.765 328.531 201.713 328.822 201.719H384.871C387.688 201.679 387.717 206.126 384.872 206.086Z"
            fill="#E4E4E4"
          />
        </g>
        <path
          id="Path 3428"
          d="M509.774 141.24L509.963 176.451L510.735 176.407L511.468 189.443L510.035 189.521L510.075 197.353L511.222 197.33L511.454 210.377L510.148 210.401L510.77 325.301C510.784 328.31 509.603 331.201 507.487 333.34C505.371 335.479 502.493 336.691 499.485 336.71L372.561 337.398C369.552 337.412 366.661 336.231 364.522 334.115C362.383 332 361.171 329.122 361.152 326.113L360.152 142.05C360.138 139.041 361.319 136.15 363.435 134.011C365.551 131.872 368.428 130.66 371.437 130.641L498.361 129.953C501.37 129.94 504.262 131.121 506.401 133.237C508.541 135.353 509.754 138.231 509.774 141.24V141.24Z"
          fill="#3F3D56"
        />
        <path
          id="Path 3429"
          d="M499.665 329.473L372.295 330.164C371.367 330.169 370.476 329.805 369.816 329.152C369.157 328.5 368.784 327.613 368.778 326.685L367.778 141.399C367.773 140.471 368.137 139.58 368.79 138.921C369.442 138.261 370.329 137.888 371.257 137.882L498.627 137.191C499.555 137.186 500.446 137.55 501.105 138.203C501.765 138.855 502.138 139.742 502.144 140.67L503.144 325.956C503.148 326.884 502.785 327.775 502.132 328.434C501.48 329.094 500.592 329.467 499.665 329.473V329.473Z"
          fill="white"
        />
        <path
          id="Ellipse 509"
          d="M434.434 134.739C434.967 134.739 435.399 134.307 435.399 133.774C435.399 133.241 434.967 132.809 434.434 132.809C433.901 132.809 433.469 133.241 433.469 133.774C433.469 134.307 433.901 134.739 434.434 134.739Z"
          fill="white"
        />
        <path
          id="Path 3430"
          d="M472.317 189.342H398.599C398.085 189.341 397.593 189.137 397.229 188.774C396.866 188.41 396.662 187.918 396.661 187.404V157.493C396.662 156.979 396.866 156.487 397.229 156.123C397.593 155.76 398.085 155.556 398.599 155.555H472.317C472.831 155.556 473.323 155.76 473.687 156.123C474.05 156.487 474.254 156.979 474.255 157.493V187.402C474.254 187.916 474.05 188.408 473.687 188.772C473.323 189.135 472.831 189.339 472.317 189.34V189.342Z"
          fill="#006CB3"
        />
        <path
          id="Path 3431"
          d="M465.298 203.692H405.618C405.001 203.692 404.41 203.447 403.974 203.011C403.538 202.575 403.293 201.984 403.293 201.367C403.293 200.75 403.538 200.159 403.974 199.723C404.41 199.287 405.001 199.042 405.618 199.042H465.297C468.297 198.999 468.326 203.734 465.298 203.692Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3432"
          d="M465.298 214.35H405.618C405.313 214.35 405.01 214.29 404.728 214.173C404.446 214.056 404.19 213.885 403.974 213.669C403.758 213.453 403.587 213.197 403.47 212.915C403.353 212.633 403.293 212.33 403.293 212.025C403.293 211.72 403.353 211.417 403.47 211.135C403.587 210.853 403.758 210.597 403.974 210.381C404.19 210.165 404.446 209.994 404.728 209.877C405.01 209.76 405.313 209.7 405.618 209.7H465.297C468.297 209.657 468.326 214.392 465.298 214.35Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3433"
          d="M465.298 225.006H405.618C405.001 225.006 404.41 224.761 403.974 224.325C403.538 223.889 403.293 223.298 403.293 222.681C403.293 222.064 403.538 221.473 403.974 221.037C404.41 220.601 405.001 220.356 405.618 220.356H465.297C468.297 220.313 468.326 225.05 465.298 225.006Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3434"
          d="M472.317 276.135H398.599C398.085 276.134 397.593 275.93 397.229 275.567C396.866 275.203 396.662 274.711 396.661 274.197V244.286C396.662 243.772 396.866 243.28 397.229 242.916C397.593 242.553 398.085 242.349 398.599 242.348H472.317C472.831 242.349 473.323 242.553 473.687 242.916C474.05 243.28 474.254 243.772 474.255 244.286V274.197C474.254 274.711 474.05 275.203 473.687 275.567C473.323 275.93 472.831 276.134 472.317 276.135Z"
          fill="#006CB3"
        />
        <path
          id="Path 3435"
          d="M465.298 290.485H405.618C405.001 290.485 404.41 290.24 403.974 289.804C403.538 289.368 403.293 288.777 403.293 288.16C403.293 287.543 403.538 286.952 403.974 286.516C404.41 286.08 405.001 285.835 405.618 285.835H465.297C468.297 285.792 468.326 290.527 465.298 290.485Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3436"
          d="M465.298 301.142H405.618C405.001 301.142 404.41 300.897 403.974 300.461C403.538 300.025 403.293 299.434 403.293 298.817C403.293 298.2 403.538 297.609 403.974 297.173C404.41 296.737 405.001 296.492 405.618 296.492H465.297C468.297 296.449 468.326 301.184 465.298 301.142Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3437"
          d="M465.298 311.799H405.618C405.001 311.799 404.41 311.554 403.974 311.118C403.538 310.682 403.293 310.091 403.293 309.474C403.293 308.857 403.538 308.266 403.974 307.83C404.41 307.394 405.001 307.149 405.618 307.149H465.297C468.297 307.108 468.326 311.841 465.298 311.799Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3438"
          d="M144.779 328.358L144.534 226.888H145.459L145.414 208.224H144.489L144.413 177.068C144.41 175.904 144.178 174.753 143.73 173.679C143.283 172.605 142.628 171.629 141.803 170.808C140.978 169.988 140 169.337 138.924 168.894C137.847 168.452 136.695 168.225 135.531 168.228L69.591 168.387C68.4273 168.39 67.2756 168.622 66.2016 169.07C65.1276 169.517 64.1523 170.172 63.3314 170.997C62.5106 171.822 61.8602 172.8 61.4174 173.876C60.9747 174.953 60.7482 176.105 60.7509 177.269L60.8029 198.992L59.33 199.029L59.655 212.078L60.834 212.049L60.841 214.928L59.586 214.959L59.9109 228.008L60.8719 227.984L61.115 328.561C61.1156 329.725 61.3461 330.878 61.7932 331.953C62.2403 333.028 62.8952 334.004 63.7204 334.825C64.5456 335.647 65.5248 336.297 66.6019 336.739C67.679 337.181 68.8327 337.406 69.9969 337.401L135.936 337.242C138.286 337.234 140.537 336.295 142.195 334.629C143.853 332.963 144.782 330.708 144.779 328.358V328.358Z"
          fill="#3F3D56"
        />
        <path
          id="Path 3439"
          d="M67.985 333.339C67.1627 333.044 66.4512 332.502 65.9474 331.788C65.4436 331.074 65.1718 330.223 65.169 329.349L64.795 176.46C64.7937 175.902 64.9022 175.35 65.1144 174.834C65.3265 174.319 65.6382 173.85 66.0315 173.455C66.4249 173.059 66.8922 172.746 67.4069 172.531C67.9215 172.316 68.4734 172.205 69.031 172.204L83.061 172.17L82.887 172.598C82.5855 173.342 82.4717 174.149 82.5555 174.947C82.6392 175.745 82.9181 176.511 83.3674 177.176C83.8168 177.841 84.4228 178.385 85.1321 178.761C85.8415 179.137 86.6323 179.332 87.435 179.33L118.571 179.255C119.345 179.187 120.093 178.937 120.753 178.527C121.413 178.117 121.967 177.557 122.371 176.893C122.769 176.24 123.008 175.501 123.066 174.739C123.125 173.976 123.003 173.21 122.709 172.503L122.533 172.075L137.025 172.04C138.151 172.039 139.231 172.484 140.029 173.278C140.826 174.072 141.277 175.15 141.281 176.276L141.634 322.145C141.637 325.132 140.456 327.998 138.349 330.115C136.242 332.232 133.382 333.427 130.395 333.438L69.417 333.585C68.9291 333.586 68.4447 333.503 67.985 333.339V333.339Z"
          fill="white"
        />
        <path
          id="Ellipse 510"
          d="M92.005 175.793C92.5379 175.793 92.97 175.361 92.97 174.828C92.97 174.295 92.5379 173.863 92.005 173.863C91.472 173.863 91.04 174.295 91.04 174.828C91.04 175.361 91.472 175.793 92.005 175.793Z"
          fill="white"
        />
        <g id="box" className={styles.box}>
          <path
            id="Path 3440"
            d="M129.32 222.781H79.22C78.8708 222.781 78.536 222.642 78.289 222.395C78.0421 222.148 77.9033 221.813 77.903 221.464V201.137C77.9033 200.788 78.0421 200.453 78.289 200.206C78.536 199.959 78.8708 199.82 79.22 199.82H129.32C129.669 199.82 130.004 199.959 130.251 200.206C130.498 200.453 130.637 200.788 130.637 201.137V221.464C130.637 221.813 130.498 222.148 130.251 222.395C130.004 222.642 129.669 222.781 129.32 222.781Z"
            fill="#006CB3"
          />
        </g>
        <path
          id="Path 3441"
          d="M124.55 232.532H83.993C83.5739 232.532 83.172 232.366 82.8757 232.069C82.5794 231.773 82.413 231.371 82.413 230.952C82.413 230.533 82.5794 230.131 82.8757 229.835C83.172 229.538 83.5739 229.372 83.993 229.372H124.55C124.959 229.387 125.347 229.559 125.631 229.854C125.916 230.149 126.075 230.542 126.075 230.952C126.075 231.362 125.916 231.755 125.631 232.05C125.347 232.345 124.959 232.517 124.55 232.532V232.532Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3442"
          d="M124.55 239.774H83.993C83.5739 239.774 83.172 239.608 82.8757 239.311C82.5794 239.015 82.413 238.613 82.413 238.194C82.413 237.775 82.5794 237.373 82.8757 237.077C83.172 236.78 83.5739 236.614 83.993 236.614H124.55C124.959 236.629 125.347 236.801 125.631 237.096C125.916 237.391 126.075 237.784 126.075 238.194C126.075 238.604 125.916 238.997 125.631 239.292C125.347 239.587 124.959 239.759 124.55 239.774Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3443"
          d="M124.55 247.016H83.993C83.5739 247.016 83.172 246.85 82.8757 246.553C82.5794 246.257 82.413 245.855 82.413 245.436C82.413 245.017 82.5794 244.615 82.8757 244.319C83.172 244.022 83.5739 243.856 83.993 243.856H124.55C124.959 243.871 125.347 244.043 125.631 244.338C125.916 244.633 126.075 245.026 126.075 245.436C126.075 245.846 125.916 246.239 125.631 246.534C125.347 246.829 124.959 247.001 124.55 247.016Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3444"
          d="M129.32 283.694H79.22C78.8708 283.694 78.536 283.555 78.289 283.308C78.0421 283.061 77.9033 282.726 77.903 282.377V262.05C77.9033 261.701 78.0421 261.366 78.289 261.119C78.536 260.872 78.8708 260.733 79.22 260.733H129.32C129.669 260.733 130.004 260.872 130.251 261.119C130.498 261.366 130.637 261.701 130.637 262.05V282.377C130.637 282.726 130.498 283.061 130.251 283.308C130.004 283.555 129.669 283.694 129.32 283.694Z"
          fill="#006CB3"
        />
        <path
          id="Path 3445"
          d="M124.55 293.446H83.993C83.7855 293.446 83.58 293.405 83.3883 293.326C83.1966 293.246 83.0224 293.13 82.8757 292.983C82.729 292.836 82.6126 292.662 82.5332 292.471C82.4538 292.279 82.413 292.074 82.413 291.866C82.413 291.659 82.4538 291.453 82.5332 291.261C82.6126 291.07 82.729 290.895 82.8757 290.749C83.0224 290.602 83.1966 290.486 83.3883 290.406C83.58 290.327 83.7855 290.286 83.993 290.286H124.55C124.959 290.301 125.347 290.473 125.631 290.768C125.916 291.063 126.075 291.456 126.075 291.866C126.075 292.276 125.916 292.669 125.631 292.964C125.347 293.259 124.959 293.431 124.55 293.446Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3446"
          d="M124.55 300.688H83.993C83.7855 300.688 83.58 300.647 83.3883 300.568C83.1966 300.488 83.0224 300.372 82.8757 300.225C82.729 300.079 82.6126 299.904 82.5332 299.713C82.4538 299.521 82.413 299.315 82.413 299.108C82.413 298.901 82.4538 298.695 82.5332 298.503C82.6126 298.312 82.729 298.137 82.8757 297.991C83.0224 297.844 83.1966 297.728 83.3883 297.648C83.58 297.569 83.7855 297.528 83.993 297.528H124.55C124.959 297.543 125.347 297.715 125.631 298.01C125.916 298.305 126.075 298.698 126.075 299.108C126.075 299.518 125.916 299.911 125.631 300.206C125.347 300.501 124.959 300.673 124.55 300.688V300.688Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3447"
          d="M124.55 307.93H83.993C83.5739 307.93 83.172 307.764 82.8757 307.467C82.5794 307.171 82.413 306.769 82.413 306.35C82.413 305.931 82.5794 305.529 82.8757 305.233C83.172 304.936 83.5739 304.77 83.993 304.77H124.55C124.959 304.785 125.347 304.957 125.631 305.252C125.916 305.547 126.075 305.94 126.075 306.35C126.075 306.76 125.916 307.153 125.631 307.448C125.347 307.743 124.959 307.915 124.55 307.93Z"
          fill="#E4E4E4"
        />
        <path
          id="Path 3448"
          d="M17.332 331.843H22.776L25.365 310.843H17.331L17.332 331.843Z"
          fill="#A0616A"
        />
        <path
          id="Path 3449"
          d="M15.94 330.07H17.472L23.454 327.637L26.66 330.069C28.4718 330.069 30.2094 330.789 31.4906 332.07C32.7718 333.351 33.4917 335.088 33.492 336.9V337.122H15.94V330.07Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3450"
          d="M38.427 328.418L43.736 329.618L50.887 309.708L43.05 307.938L38.427 328.418Z"
          fill="#A0616A"
        />
        <path
          id="Path 3451"
          d="M37.464 326.377L38.959 326.714L45.33 325.659L47.922 328.738C48.7973 328.935 49.6251 329.303 50.3582 329.821C51.0913 330.338 51.7153 330.995 52.1945 331.754C52.6738 332.512 52.9989 333.358 53.1514 334.242C53.3038 335.126 53.2806 336.032 53.083 336.907L53.034 337.123L35.913 333.257L37.464 326.377Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3452"
          d="M10.438 203.487C7.88601 211.187 8.156 219.54 11.051 228.507L12.289 278.996C12.289 278.996 10.445 312.106 16.396 323.757H25.027L29.3 275.973L29.837 243.9L48.337 270.958L36.557 322.975L47.18 323.925L66.306 267.474L42.706 212.707L10.438 203.487Z"
          fill="#2F2E41"
        />
        <path
          id="Path 3453"
          d="M44.251 214.323L12.866 209.303C12.0429 209.039 11.2867 208.601 10.6492 208.017C10.0117 207.433 9.50794 206.719 9.17259 205.922C8.83725 205.126 8.67824 204.266 8.70644 203.402C8.73464 202.538 8.9494 201.691 9.33599 200.918L10.759 198.071L10.206 168.161C10.1394 164.27 11.0986 160.431 12.9871 157.028C14.8756 153.626 17.6269 150.781 20.964 148.78L24.025 146.95L25.572 143.022L39.834 143.37L39.876 148.522L44.984 156.667V156.701L44.663 201.032L43.255 205.607L45.384 210.928L44.251 214.323Z"
          fill="#3F3D56"
        />
        <g id="head" className={styles.head}>
          <path
            id="Ellipse 511"
            d="M33.7163 141.068C40.8734 141.068 46.6753 135.266 46.6753 128.109C46.6753 120.952 40.8734 115.15 33.7163 115.15C26.5593 115.15 20.7573 120.952 20.7573 128.109C20.7573 135.266 26.5593 141.068 33.7163 141.068Z"
            fill="#A0616A"
          />
          <path
            id="Path 3454"
            d="M20.2224 130.937C19.9229 129.096 19.9259 127.218 20.2314 125.378C20.5111 123.216 21.2419 121.137 22.3764 119.276C23.511 117.414 25.024 115.812 26.8174 114.573C27.5049 114.051 28.3219 113.726 29.1803 113.634C29.6094 113.598 30.0389 113.698 30.4074 113.921C30.7758 114.144 31.0642 114.478 31.2314 114.875C31.4476 114.335 31.7721 113.846 32.1849 113.437C32.5977 113.028 33.09 112.707 33.6313 112.496C34.7186 112.08 35.8877 111.922 37.0463 112.036C39.2921 112.221 41.4459 113.011 43.279 114.321C45.1122 115.632 46.5564 117.414 47.4583 119.479C47.8043 120.301 48.7714 117.264 49.2094 118.041C49.6124 118.849 50.3156 119.466 51.1684 119.762C52.0274 120.002 52.3763 123.542 52.7153 122.717C52.9777 123.327 53.0814 123.994 53.0167 124.655C52.9521 125.316 52.7212 125.95 52.3456 126.497C51.97 127.045 51.4619 127.489 50.8686 127.788C50.2753 128.086 49.6161 128.23 48.9523 128.205C48.0848 128.093 47.2249 127.928 46.3773 127.712C44.8208 127.516 43.2463 127.896 41.9511 128.781C40.6559 129.666 39.7297 130.995 39.3474 132.517C39.1108 131.632 38.733 130.791 38.2283 130.027C37.9722 129.647 37.6289 129.333 37.2271 129.113C36.8253 128.892 36.3766 128.77 35.9184 128.758C35.4803 128.808 35.0571 128.947 34.6748 129.167C34.2925 129.387 33.9592 129.683 33.6953 130.036C33.1253 130.706 32.6954 131.487 32.1214 132.154C30.4354 134.109 28.1543 138.668 25.7513 138.336C23.8563 138.069 20.7494 133.157 20.2224 130.937Z"
            fill="#2F2E41"
          />
        </g>
        <g id="hand" className={styles.hand}>
          <path
            id="Path 3455"
            d="M82.313 204.864C81.7614 205.104 81.164 205.22 80.5628 205.203C79.9617 205.187 79.3714 205.039 78.8335 204.77C78.2957 204.501 77.8234 204.117 77.4499 203.646C77.0765 203.175 76.8109 202.627 76.672 202.042L62.129 200.573L66.929 194.684L80.004 196.984C81.0021 196.816 82.0271 197.02 82.8849 197.558C83.7427 198.095 84.3737 198.928 84.6584 199.899C84.9432 200.87 84.862 201.912 84.4301 202.828C83.9982 203.743 83.2457 204.468 82.315 204.866L82.313 204.864Z"
            fill="#A0616A"
          />
          <path
            id="Path 3456"
            d="M73.368 202.528L39.429 199.401L39.382 199.347L14.612 170.965C12.9821 168.468 12.3685 165.442 12.8968 162.506C13.4252 159.571 15.0555 156.949 17.454 155.177V155.177C18.7811 154.199 20.3003 153.513 21.9117 153.164C23.523 152.815 25.19 152.812 26.8028 153.154C28.4155 153.496 29.9376 154.176 31.2688 155.148C32.6 156.121 33.7101 157.364 34.526 158.797L49.782 185.586L73.741 195.213L73.368 202.528Z"
            fill="#3F3D56"
          />
        </g>
        <path
          id="Path 3457"
          d="M598.63 337.898H1.73798C1.54172 337.898 1.3535 337.82 1.21472 337.681C1.07594 337.542 0.997986 337.354 0.997986 337.158C0.997986 336.962 1.07594 336.774 1.21472 336.635C1.3535 336.496 1.54172 336.418 1.73798 336.418H598.628C598.824 336.418 599.012 336.496 599.151 336.635C599.29 336.774 599.368 336.962 599.368 337.158C599.368 337.354 599.29 337.542 599.151 337.681C599.012 337.82 598.824 337.898 598.628 337.898H598.63Z"
          fill="#CCCCCC"
        />
        <g id="woman-hand" className={styles.womanHand}>
          <path
            id="Path 3458"
            d="M494.825 205.613C495.379 205.666 495.939 205.597 496.464 205.411C496.988 205.225 497.466 204.926 497.863 204.535C498.259 204.144 498.565 203.671 498.759 203.148C498.953 202.626 499.03 202.068 498.985 201.513L511.369 196.061L505.461 192.248L494.602 198.013C493.674 198.15 492.829 198.622 492.227 199.34C491.625 200.059 491.307 200.973 491.334 201.91C491.361 202.847 491.731 203.742 492.374 204.425C493.016 205.107 493.887 205.53 494.821 205.613H494.825Z"
            fill="#FFB6B6"
          />
          <path
            id="Path 3459"
            d="M502.107 200.276L499.907 192.619L522.168 181.45L535.3 156.15C535.948 154.899 536.864 153.805 537.983 152.948C539.102 152.09 540.395 151.49 541.772 151.189C543.149 150.887 544.576 150.893 545.95 151.205C547.324 151.518 548.613 152.129 549.725 152.995V152.995C551.731 154.562 553.032 156.861 553.345 159.387C553.657 161.913 552.955 164.46 551.392 166.469L531.645 191.795L502.107 200.276Z"
            fill="#E4E4E4"
          />
        </g>
      </g>
    </svg>
  );
}

