import React, { useState, useEffect, useRef } from 'react';
import { Modal, Table } from 'antd';
import { getOrganizations } from '../../../service/organizationServices';

export default function DetailedSearch({ isVisible, close }) {
  const cancelRef = useRef();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [datasource, setDatasource] = useState([]);

  const getData = async () => {
    const params = {};
    const { content } = await getOrganizations(params, cancelRef);
    setDatasource(content);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
  ];

  return (
    <Modal
      title="Байгууллага"
      visible={isVisible}
      centered
      width={1000}
      okText="Сонгох"
      cancelText="Хаах"
      onCancel={close}
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys,
          onChange: keys => {
            setSelectedRowKeys(keys);
          },
        }}
        columns={columns}
        dataSource={datasource}
      />
    </Modal>
  );
}

