import { Table } from 'antd';
import React from 'react';
import styles from './style.module.scss';

const { Column } = Table;
export default function DataTables(props) {
  const { budgetData } = props;

  const column = (
    <>
      <Column
        title="№"
        width={70}
        key="index"
        render={(value, item, index) => 0 * 10 + index + 1}
        align="center"
      />
      <Column
        title="Аймаг"
        dataIndex="aimagName"
        key="aimagName"
        align="left"
      />
      <Column title="Төсвийн дүн" dataIndex="budget" key="budget" />
      <Column
        title="Төсвийн гүйцэтгэл"
        dataIndex="performance"
        key="performance"
      />
      <Column title="Гүйцэтгэлийн хувь" dataIndex="percent" key="percent" />
    </>
  );
  return (
    <>
      <div className={styles.localIncometable}>
        <Table
          className="table-striped-rows"
          dataSource={budgetData}
          size="small"
          bordered
          pagination={{ pageSize: 25 }}
          scroll={{ y: 400 }}
          summary={pageData => {
            pageData.forEach(() => {});
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell>Нийт:</Table.Summary.Cell>
                  <Table.Summary.Cell />
                  <Table.Summary.Cell />
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              </>
            );
          }}
        >
          {column}
        </Table>
      </div>
    </>
  );
}

