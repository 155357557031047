import { Card, Col, Image, notification, Row, Space, Typography } from 'antd';
import React from 'react';
import styles from './style.module.scss';
import suld from '../../../../img/suld.svg';
import { ORGANIZATION_PAGE } from '../../../../settings/constantRoutes';

const { Text } = Typography;

export default function ContentCards(props) {
  const { el } = props;

  const handleExample = orgId => {
    if (orgId) {
      window.open(`${ORGANIZATION_PAGE}/${orgId}`);
    } else {
      notification.info({
        message: 'Байгууллага холбогдоогүй байна.',
      });
    }
  };

  return (
    <Row justify="start" gutter={[10, 16]}>
      {el.map(item => (
        <Col xs={23} sm={12} lg={8} xl={6}>
          <Card
            className={styles.contentCardCol}
            onClick={() => handleExample(item.orgId)}
            hoverable
          >
            <Space size={20}>
              <span className={styles.text} strong>
                {item.name.charAt(0)}
              </span>
              <Image preview={false} src={suld} />
              <Text strong>{item.name}</Text>
            </Space>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

