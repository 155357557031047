import React, { useState } from 'react';
import { Row, Col } from 'antd';
import styles from './style.module.scss';
import cal11 from '../../img/calendar/cal1.1.svg';
import cal21 from '../../img/calendar/cal2.1.svg';
import cal31 from '../../img/calendar/cal3.1.svg';
import cal41 from '../../img/calendar/cal4.1.svg';
import cal12 from '../../img/calendar/cal1.2.svg';
import cal22 from '../../img/calendar/cal2.2.svg';
import cal32 from '../../img/calendar/cal3.2.svg';
import cal42 from '../../img/calendar/cal4.2.svg';
// import UnitedSectionTwoTableReporting from './UnitedSectionTwoTableReporting';

export default function Calendar() {
  const [isShow, setIsShow] = useState(0);
  // const [table, setTable] = useState('DEFAULT');

  const clickShow1 = () => {
    setIsShow(isShow === 1 ? 0 : 1);
    // setTable('DEFAULT');
  };
  const clickShow2 = () => {
    setIsShow(isShow === 2 ? 0 : 2);
    // setTable('PROCESS');
  };
  const clickShow3 = () => {
    setIsShow(isShow === 3 ? 0 : 3);
    // setTable('PROVE');
  };
  const clickShow4 = () => {
    setIsShow(isShow === 4 ? 0 : 4);
    // setTable('REPORTING');
  };

  return (
    <div className={styles.calendarContainer}>
      <div className="sub-title">Төсвийн үе шат, цагалбар</div>
      <Row
        justify="center"
        gutter={10}
        className={isShow === 0 ? styles.calendarDe : styles.calendar}
      >
        {/* Negdugeer heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 1 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b1}`} />
              <div className={`${styles.item} ${styles.i1}`}>
                <div>
                  <b>IV.15-VI.01</b> Дунд хугацааны төсвийн хүрээний мэдэгдлийг
                  боловсруулж УИХ-аар батлуулна
                </div>
                <div>
                  <b>VI.10-VII.01</b> Төсвийн хязгаарын саналыг боловсруулж
                  Засгийн газраар батлуулна
                </div>
                <div>
                  <b>VII.25-X.01</b> Төсвийн төслийн саналыг боловсруулж УИХ-д
                  өргөн барина
                </div>
              </div>
            </Row>
            <div
              className={styles.calendarItem1}
              onClick={clickShow1}
              role="presentation"
            >
              Төлөвлөх
            </div>
            <img src={cal12} alt="" className={styles.arrow} />
          </Row>
          <Row className={isShow === 1 ? styles.active : styles.deactive}>
            <div className={`${styles.item} ${styles.i1}`}>
              <b>ИТХ-д төсвийн төслөө өргөн барина</b>
              <div>
                <b>XI.25</b> Аймаг нийслэлийн засаг дарга
              </div>
              <div>
                <b>XII.10</b> Сум дүүргийн Засаг дарга
              </div>
            </div>
          </Row>
          <Row className={styles.logo}>
            <img src={cal11} alt="" />
          </Row>
        </Col>
        {/* Hoyordugaar heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 2 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b2}`} />
              <div className={`${styles.item} ${styles.i2}`}>
                <b>XI.15-ны дотор УИХ-аар хэлэлцэн батална</b>
                <ul>
                  <li>Улсын төсөв</li>
                  <li>Нийгмийн даатгалын сангийн төсөв</li>
                  <li>ЭМД-ын сангийн төсөв</li>
                </ul>
              </div>
            </Row>
            <div
              className={styles.calendarItem2}
              onClick={clickShow2}
              role="presentation"
            >
              Батлах
            </div>
            <img src={cal22} alt="" className={styles.arrow} />
          </Row>
          <Row className={isShow === 2 ? styles.active : styles.deactive}>
            <div className={`${styles.item} ${styles.i2}`}>
              <b>Тухайн шатны жилийн төсвийн төслийг ИТХ хэлэлцэн батална</b>
              <div>
                <b>XII.05</b> аймаг нийслэлийн төсөв
              </div>
              <div>
                <b>XII.20</b> сум дүүргийн төсөв
              </div>
            </div>
          </Row>
          <Row className={styles.logo}>
            <img src={cal21} alt="" />
          </Row>
        </Col>
        {/* Guravdugaar heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 3 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b3}`} />
              <div className={`${styles.item} ${styles.i3}`}>
                <div>
                  Тухайн оны 1 дүгээр сарын 1-нээс эхлэн төсөв зарцуулах эрх
                  нээгдэж, 12 дугаар сарын 31-ний өдрөөр дуусгавар болдог
                </div>
              </div>
            </Row>
            <div
              className={styles.calendarItem3}
              onClick={clickShow3}
              role="presentation"
            >
              Хэрэгжүүлэх
            </div>
            <img src={cal32} alt="" className={styles.arrow} />
          </Row>
          <Row className={isShow === 3 ? styles.active : styles.deactive}>
            <div className={`${styles.item} ${styles.i3}`}>
              <b>Нэгдсэн төсвийн сарын гүйцэтгэлийн мэдээ</b>
              <ul>
                <li>Сар бүрийн 08-ны дотор нэгтгэн гаргана</li>
              </ul>
            </div>
          </Row>
          <Row className={styles.logo}>
            <img src={cal31} alt="" />
          </Row>
        </Col>
        {/* Duruvdugeer heseg */}
        <Col xs={24} sm={24} md={12} xl={6} className={styles.calendarItem}>
          <Row>
            <Row className={isShow === 4 ? styles.active : styles.deactive}>
              <div className={`${styles.bullet} ${styles.b4}`} />
              <div className={`${styles.item} ${styles.i4}`}>
                <div>
                  <b>Сангийн яам V.10-ны дотор</b> Нэгдсэн төсвийн гүйцэтгэл,
                  Засгийн газрын санхүүгийн нэгтгэсэн тайланг төрийн аудитын төв
                  байгууллагад хүргүүлнэ
                </div>
                <div>
                  <b>Төрийн аудитын төв байгууллага 1 сарын дотор</b> Аудит хийж
                  Засгийн газар, УИХ-д хүргүүлнэ
                </div>
              </div>
            </Row>
            <div
              className={styles.calendarItem4}
              onClick={clickShow4}
              role="presentation"
            >
              Тайлагнах
            </div>
            <img src={cal42} alt="" className={styles.arrow} />
          </Row>
          <Row className={isShow === 4 ? styles.active : styles.deactive}>
            <div className={`${styles.item} ${styles.i4}`}>
              <div>
                <b>Засгийн газар</b> Аудитын дүгнэлт гарснаас хойш ажлын 7
                өдрийн дотор УИХ-д өргөн мэдүүлнэ
              </div>
              <div>
                <b>УИХ</b> хаврын чуулганаар хэлэлцүүлэн, улсын төсвийн
                гүйцэтгэлийг батална
              </div>
            </div>
          </Row>
          <Row className={styles.logo}>
            <img src={cal41} alt="" />
          </Row>
        </Col>
      </Row>
      {/* husnegtiin heseg */}
      {/* {props.table && (
          <div style={{ overflow: 'auto', marginTop: '10px' }}>
            {table === 'DEFAULT' && <UnitedSectionTwoTable />}
            {table === 'PROCESS' && <UnitedSectionTwoTableProcess />}
            {table === 'PROVE' && <UnitedSectionTwoTableProve />}
            {table === 'REPORTING' && <UnitedSectionTwoTableReporting />}
          </div>
        )} */}
    </div>
  );
}

