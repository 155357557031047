import { Card, Col, Radio, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import contentBanner from '../assets/new1Banner.png';
import styles from './style.module.scss';
import CompanyBigSearch from '../components/companyBigSearch/CompanyBigSearch';
import OrgContext from '../../../context/OrgContext';

const { Text } = Typography;

export default function CompanyFilter({ searchType, setSearchType }) {
  const { setMain } = useContext(OrgContext);

  const onRadioChange = e => {
    setSearchType(e.target.value);
    setMain({
      refOrgTypeId: null,
      refCityId: null,
      refDistrictId: null,
      refTypeId: null,
      activityCode: null,
      registerNo: null,
      budgetGovernor: null,
      name: null,
    });
  };

  return (
    <div
      style={{ backgroundImage: `url(${contentBanner})` }}
      className={styles.companyFilterDiv}
    >
      <Row justify="center">
        <Text className={styles.headerText}>
          Байгууллагын дэлгэрэнгүй хайлт
        </Text>
      </Row>
      <Row justify="center">
        <Col span={18} className={styles.companyFilterCard}>
          <Row>
            <CompanyBigSearch setSearchType={setSearchType} />
            <Radio.Group
              value={searchType}
              onChange={onRadioChange}
              name="radiogroup"
            >
              <Row
                gutter={[12, 12]}
                justify="start"
                className={styles.smallFilterContainer}
              >
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <Card>
                    <Radio value="byTEZ">
                      <Text>Дэлгэрэнгүй хайлт</Text>
                      {/* <Text>Төсвийн Ерөнхийлөн Захирагч</Text> */}
                    </Radio>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <Card>
                    <Radio value="byMap">
                      <Text>Газарзүйн байршил</Text>
                    </Radio>
                  </Card>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}>
                  <Card>
                    <Radio value="byOrgType">
                      <Text>Байгууллагын төрөл</Text>
                    </Radio>
                  </Card>
                </Col> */}
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                  <Card>
                    <Radio value="byInactiveOrg">
                      <Text>Татан буугдсан</Text>
                    </Radio>
                  </Card>
                </Col>
              </Row>
            </Radio.Group>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
