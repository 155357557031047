import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { GOOGLE_MAP_KEY } from '../../../constants/SystemConstants';
import OrgContext from '../../../context/OrgContext';
import flag from '../assets/iconmonstr-location-13.svg';

export default function Location({ orgInfo }) {
  const { stateParentOrg } = useContext(OrgContext);

  const MapWrapper = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{
          lat: Number(orgInfo.latitude),
          lng: Number(orgInfo.longitude),
        }}
        defaultOptions={{
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
          styles: [
            {
              featureType: 'water',
              stylers: [
                {
                  saturation: 43,
                },
                {
                  lightness: -11,
                },
                {
                  hue: '#0088ff',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry.fill',
              stylers: [
                {
                  hue: '#ff0000',
                },
                {
                  saturation: -100,
                },
                {
                  lightness: 99,
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#808080',
                },
                {
                  lightness: 54,
                },
              ],
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ece2d9',
                },
              ],
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ccdca1',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#767676',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'poi',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'landscape.natural',
              elementType: 'geometry.fill',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#b8cb93',
                },
              ],
            },
            {
              featureType: 'poi.park',
              stylers: [
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'poi.sports_complex',
              stylers: [
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'poi.medical',
              stylers: [
                {
                  visibility: 'on',
                },
              ],
            },
            {
              featureType: 'poi.business',
              stylers: [
                {
                  visibility: 'simplified',
                },
              ],
            },
          ],
        }}
      >
        {!isEmpty(stateParentOrg) &&
          stateParentOrg.map((data, key) => (
            <Marker
              key={key}
              position={{
                lat: Number(data.latitude),
                lng: Number(data.longitude),
              }}
              style={{ height: '2xpx', width: '2px', color: 'blue' }}
              label={data.name}
            />
          ))}
        {orgInfo && (
          <Marker
            position={{
              lat: Number(orgInfo.latitude),
              lng: Number(orgInfo.longitude),
            }}
            style={{ height: '10px', width: '10px', color: 'red' }}
            icon={flag}
            label={orgInfo.name}
          />
        )}
      </GoogleMap>
    ))
  );
  return (
    <>
      <MapWrapper
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&language=mn`}
        loadingElement={<div style={{ height: `740px` }} />}
        containerElement={<div style={{ height: `740px` }} />}
        mapElement={<div style={{ height: `740px` }} />}
        language="mn"
      />
    </>
  );
}
