import styles from './style.module.scss';

export default function Banner1() {
  return (
    <svg
      width="327"
      height="269"
      viewBox="0 0 327 269"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.bannerSvg}
    >
      <g id="opendata1 1" clipPath="url(#clip0_1:110)">
        <g id="flower-top">
          <path
            id="Path 3480"
            d="M57.022 54.529C64.822 45.35 77.471 39.566 89.136 42.565C78.6306 50.386 71.2301 61.6709 68.244 74.423C67.11 79.345 66.362 84.938 62.365 88.023C59.7602 89.8446 56.5552 90.5964 53.412 90.123C50.3029 89.6395 47.2685 88.7597 44.383 87.505L43.603 87.592C45.564 75.711 49.225 63.708 57.022 54.529Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3481"
            d="M89.106 42.817C79.4761 45.3954 70.9035 50.9428 64.606 58.671C63.2209 60.3106 62.1028 62.1583 61.293 64.146C60.5666 66.1077 60.3788 68.2281 60.749 70.287C61.2099 72.1957 61.4372 74.1534 61.426 76.117C61.3256 77.1228 61.0137 78.096 60.5108 78.9729C60.008 79.8497 59.3254 80.6104 58.508 81.205C56.4474 82.6149 54.1244 83.5963 51.677 84.091C49.01 84.791 46.229 85.467 44.086 87.302C43.826 87.524 43.496 87.108 43.756 86.886C47.485 83.693 52.781 83.964 56.935 81.61C57.9289 81.1203 58.7975 80.4098 59.4745 79.5327C60.1515 78.6557 60.619 77.6354 60.841 76.55C61.141 74.563 60.594 72.567 60.267 70.619C59.8762 68.6156 59.982 66.5471 60.575 64.594C61.2809 62.5715 62.3258 60.6839 63.665 59.012C66.6202 55.1997 70.1563 51.8758 74.144 49.162C78.6779 46.0396 83.7125 43.7157 89.03 42.291C89.359 42.203 89.43 42.729 89.105 42.817H89.106Z"
            fill="white"
          />
          <path
            id="Path 3482"
            d="M66.489 56.062C65.3369 54.8297 64.66 53.2281 64.5793 51.543C64.4985 49.8579 65.019 48.1989 66.048 46.862C66.257 46.592 66.686 46.905 66.477 47.175C65.5154 48.4185 65.0301 49.9647 65.1086 51.5346C65.1871 53.1045 65.8242 54.5946 66.905 55.736C67.139 55.98 66.722 56.309 66.489 56.062Z"
            fill="white"
          />
          <path
            id="Path 3483"
            d="M60.466 69.33C63.9131 69.4756 67.2884 68.3165 69.919 66.084C70.179 65.863 70.509 66.279 70.249 66.5C67.5072 68.8183 63.992 70.0175 60.405 69.858C60.063 69.843 60.125 69.315 60.465 69.331L60.466 69.33Z"
            fill="white"
          />
          <path
            id="Path 3484"
            d="M78.814 46.73C79.1611 47.3 79.6443 47.7749 80.2203 48.112C80.7963 48.4491 81.4471 48.6377 82.114 48.661C82.456 48.672 82.393 49.199 82.054 49.188C81.3176 49.1583 80.5996 48.9488 79.9629 48.5776C79.3261 48.2065 78.7899 47.6851 78.401 47.059C78.3594 47.003 78.3406 46.9333 78.3486 46.864C78.3566 46.7947 78.3907 46.731 78.444 46.686C78.4993 46.6437 78.5689 46.6246 78.638 46.6328C78.7071 46.641 78.7703 46.6759 78.814 46.73Z"
            fill="white"
          />
          <path
            id="Path 3485"
            d="M106.48 79.47C106.266 79.47 106.053 79.47 105.836 79.47C102.963 79.4734 100.095 79.7102 97.26 80.178C97.04 80.211 96.818 80.247 96.6 80.286C89.7817 81.4846 83.2453 83.9389 77.323 87.524C74.9686 88.9516 72.7261 90.5562 70.615 92.324C67.7 94.767 64.733 97.649 61.282 98.948C60.923 99.0876 60.5556 99.2049 60.182 99.299L43.763 88.53C43.738 88.487 43.71 88.447 43.685 88.404L43.01 88.004C43.11 87.904 43.221 87.804 43.323 87.704C43.382 87.646 43.445 87.591 43.504 87.534C43.545 87.496 43.585 87.459 43.62 87.423L43.658 87.389C43.693 87.353 43.728 87.323 43.758 87.289C44.3627 86.7263 44.9723 86.167 45.587 85.611C45.587 85.611 45.587 85.611 45.596 85.606C50.2388 81.3255 55.373 77.6109 60.891 74.54C61.057 74.451 61.225 74.357 61.398 74.273C63.8904 72.9464 66.4871 71.8256 69.162 70.922C70.6284 70.4319 72.1186 70.0164 73.627 69.677C77.5262 68.8076 81.5379 68.5569 85.515 68.934C93.443 69.687 101.042 72.968 106.09 78.995C106.223 79.16 106.353 79.312 106.48 79.47Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3486"
            d="M106.306 79.654C97.065 75.9151 86.8808 75.1832 77.2 77.562C75.1067 78.0371 73.1014 78.8393 71.258 79.939C69.4971 81.0681 68.0707 82.6482 67.127 84.515C66.346 86.3163 65.349 88.0161 64.158 89.577C63.4722 90.3198 62.6372 90.9093 61.7076 91.3067C60.778 91.7041 59.7748 91.9005 58.764 91.883C56.2697 91.7679 53.8239 91.1527 51.572 90.074C49.024 89.024 46.394 87.892 43.578 88.067C43.237 88.088 43.224 87.558 43.565 87.536C48.465 87.236 52.53 90.636 57.265 91.258C58.3533 91.4654 59.4746 91.4211 60.5431 91.1285C61.6117 90.836 62.5992 90.3029 63.43 89.57C64.87 88.17 65.63 86.241 66.542 84.488C67.4361 82.6533 68.7658 81.0654 70.415 79.863C72.1961 78.6719 74.1669 77.7927 76.243 77.263C80.8987 75.9982 85.7243 75.4735 90.543 75.708C96.0429 75.9449 101.462 77.1209 106.565 79.185C106.881 79.313 106.622 79.777 106.309 79.65L106.306 79.654Z"
            fill="white"
          />
          <path
            id="Path 3487"
            d="M80.272 76.613C80.0934 74.9347 80.5173 73.2475 81.4678 71.8528C82.4183 70.458 83.8336 69.4466 85.461 68.999C85.791 68.91 85.945 69.418 85.615 69.507C84.0988 69.921 82.7805 70.8634 81.8981 72.1641C81.0157 73.4648 80.6273 75.0381 80.803 76.6C80.841 76.939 80.31 76.951 80.272 76.613Z"
            fill="white"
          />
          <path
            id="Path 3488"
            d="M67.476 83.581C70.1401 85.7723 73.5321 86.8792 76.976 86.681C77.317 86.661 77.33 87.192 76.989 87.212C73.4042 87.412 69.8758 86.2529 67.108 83.966C66.844 83.748 67.208 83.366 67.474 83.581H67.476Z"
            fill="white"
          />
          <path
            id="Path 3489"
            d="M95.733 76.582C95.6667 77.2458 95.7663 77.9157 96.0229 78.5315C96.2795 79.1472 96.685 79.6896 97.203 80.11C97.469 80.325 97.103 80.71 96.837 80.495C96.2667 80.0278 95.8195 79.4281 95.5345 78.7483C95.2495 78.0684 95.1353 77.3292 95.202 76.595C95.2024 76.5252 95.2294 76.4582 95.2775 76.4076C95.3257 76.3571 95.3913 76.3268 95.461 76.323C95.5313 76.3216 95.5993 76.3481 95.6502 76.3966C95.7011 76.4451 95.7309 76.5117 95.733 76.582V76.582Z"
            fill="white"
          />
          <path
            id="Ellipse 518"
            d="M109.221 88.745C109.641 88.745 109.981 88.4047 109.981 87.985C109.981 87.5653 109.641 87.225 109.221 87.225C108.801 87.225 108.461 87.5653 108.461 87.985C108.461 88.4047 108.801 88.745 109.221 88.745Z"
            fill="white"
          />
        </g>
        <g id="flower-top2">
          <path
            id="Path 3460"
            d="M265.513 26.966C267.598 18.466 265.613 8.548 258.881 2.956C258.748 12.4732 255.254 21.6368 249.016 28.826C246.6 31.59 243.625 34.413 243.508 38.083C243.543 40.3944 244.465 42.6038 246.083 44.255C247.692 45.8793 249.503 47.2908 251.471 48.455L251.752 48.955C257.918 42.737 263.429 35.468 265.513 26.966Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3461"
            d="M258.746 3.08C261.33 9.84962 261.714 17.2612 259.846 24.262C259.47 25.7761 258.858 27.2215 258.032 28.545C257.186 29.8083 256.017 30.8221 254.647 31.481C253.327 32.0224 252.077 32.7222 250.925 33.565C250.375 34.0526 249.935 34.6508 249.633 35.3205C249.33 35.9902 249.173 36.7162 249.171 37.451C249.218 39.2654 249.63 41.0518 250.381 42.704C251.266 44.5226 251.725 46.5193 251.723 48.542C251.703 48.79 252.088 48.807 252.108 48.559C252.4 45.003 249.985 41.999 249.602 38.55C249.467 37.7561 249.515 36.9418 249.744 36.1695C249.972 35.3971 250.373 34.6872 250.918 34.094C252.04 33.1707 253.302 32.4321 254.656 31.906C256.002 31.283 257.175 30.3399 258.073 29.159C258.964 27.881 259.63 26.4607 260.044 24.959C261.03 21.5948 261.481 18.0964 261.381 14.592C261.289 10.5917 260.513 6.63664 259.087 2.89799C258.999 2.66699 258.658 2.84799 258.745 3.07699L258.746 3.08Z"
            fill="white"
          />
          <path
            id="Path 3462"
            d="M260.578 22.044C261.795 22.1983 263.027 21.915 264.055 21.2445C265.082 20.5741 265.838 19.5602 266.187 18.384C266.257 18.146 265.887 18.026 265.82 18.265C265.497 19.3608 264.793 20.3051 263.835 20.9275C262.877 21.5499 261.728 21.8093 260.596 21.659C260.349 21.624 260.333 22.011 260.578 22.044Z"
            fill="white"
          />
          <path
            id="Path 3463"
            d="M255.33 31.241C253.776 29.2728 253.021 26.7912 253.215 24.291C253.234 24.043 252.849 24.026 252.83 24.274C252.632 26.8762 253.423 29.4573 255.045 31.502C255.2 31.697 255.484 31.436 255.33 31.242V31.241Z"
            fill="white"
          />
          <path
            id="Path 3464"
            d="M260.824 10.808C260.341 10.8464 259.855 10.7641 259.412 10.5686C258.968 10.3731 258.58 10.0705 258.282 9.688C258.13 9.488 257.846 9.75299 257.997 9.94799C258.328 10.3692 258.757 10.7028 259.247 10.9199C259.737 11.1369 260.273 11.2307 260.807 11.193C260.857 11.1931 260.906 11.1743 260.943 11.1402C260.98 11.1061 261.003 11.0592 261.007 11.009C261.009 10.9582 260.991 10.9087 260.957 10.8711C260.922 10.8335 260.875 10.8108 260.824 10.808Z"
            fill="white"
          />
          <path
            id="Path 3465"
            d="M229.757 8.46C229.849 8.586 229.94 8.711 230.031 8.84C231.253 10.5335 232.335 12.3233 233.267 14.192C233.341 14.335 233.414 14.482 233.485 14.627C235.682 19.1534 237.02 24.0483 237.43 29.063C237.592 31.0594 237.601 33.0652 237.458 35.063C237.258 37.821 236.827 40.795 237.532 43.381C237.602 43.6528 237.689 43.92 237.793 44.181L251.129 49.264C251.165 49.264 251.2 49.264 251.236 49.256L251.759 49.483C251.773 49.383 251.786 49.274 251.801 49.171C251.81 49.112 251.815 49.051 251.824 48.992C251.824 48.952 251.834 48.912 251.84 48.876C251.84 48.863 251.84 48.849 251.84 48.839C251.847 48.803 251.849 48.769 251.855 48.739C251.929 48.143 251.999 47.5457 252.064 46.947C252.064 46.947 252.064 46.947 252.064 46.94C252.607 42.3821 252.607 37.7759 252.064 33.218C252.046 33.082 252.029 32.943 252.005 32.805C251.724 30.772 251.278 28.7653 250.671 26.805C250.335 25.7326 249.945 24.6779 249.502 23.645C248.353 20.9782 246.792 18.5087 244.876 16.327C241.055 11.978 235.886 8.9 230.187 8.494C230.047 8.477 229.904 8.467 229.757 8.46Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3466"
            d="M229.722 8.64C235.86 12.4902 240.63 18.1768 243.352 24.892C243.964 26.3272 244.345 27.8499 244.483 29.404C244.568 30.9223 244.245 32.4357 243.548 33.787C242.82 35.0144 242.243 36.3256 241.831 37.692C241.686 38.4123 241.694 39.1551 241.856 39.8719C242.018 40.5886 242.329 41.2631 242.77 41.851C243.9 43.271 245.304 44.4493 246.899 45.316C248.701 46.2348 250.27 47.5526 251.486 49.169C251.619 49.379 251.937 49.161 251.804 48.951C249.896 45.936 246.159 44.992 243.777 42.468C243.191 41.9154 242.74 41.2361 242.457 40.4822C242.174 39.7282 242.067 38.9195 242.145 38.118C242.484 36.7045 243.047 35.3542 243.812 34.118C244.512 32.8099 244.88 31.3504 244.886 29.867C244.828 28.3109 244.506 26.776 243.933 25.328C242.695 22.0479 240.949 18.9827 238.759 16.245C236.276 13.1055 233.274 10.414 229.883 8.287C229.673 8.155 229.51 8.505 229.718 8.636L229.722 8.64Z"
            fill="white"
          />
          <path
            id="Path 3467"
            d="M242.603 22.679C243.668 22.0693 244.481 21.1009 244.898 19.9468C245.314 18.7928 245.307 17.5282 244.877 16.379C244.789 16.147 244.424 16.272 244.512 16.505C244.914 17.5743 244.92 18.7521 244.53 19.8258C244.14 20.8995 243.379 21.7983 242.384 22.36C242.168 22.482 242.384 22.8 242.602 22.678L242.603 22.679Z"
            fill="white"
          />
          <path
            id="Path 3468"
            d="M243.949 33.183C241.523 32.5471 239.426 31.0203 238.076 28.907C237.942 28.697 237.624 28.916 237.758 29.125C239.167 31.3215 241.353 32.9059 243.879 33.562C244.12 33.624 244.189 33.245 243.95 33.183H243.949Z"
            fill="white"
          />
          <path
            id="Path 3469"
            d="M236.035 13.56C235.673 13.8811 235.236 14.1074 234.765 14.2186C234.294 14.3297 233.803 14.3223 233.335 14.197C233.095 14.132 233.026 14.511 233.264 14.576C233.782 14.7126 234.326 14.7202 234.848 14.5982C235.369 14.4762 235.853 14.2282 236.257 13.876C236.298 13.8459 236.325 13.8016 236.335 13.7518C236.344 13.7021 236.334 13.6507 236.307 13.608C236.278 13.5661 236.233 13.5373 236.183 13.528C236.133 13.5186 236.081 13.5294 236.039 13.558L236.035 13.56Z"
            fill="white"
          />
          <path
            id="Ellipse 512"
            d="M247.876 66.15C256.736 66.15 263.919 58.9673 263.919 50.107C263.919 41.2467 256.736 34.064 247.876 34.064C239.016 34.064 231.833 41.2467 231.833 50.107C231.833 58.9673 239.016 66.15 247.876 66.15Z"
            fill="#006CB3"
          />
          <path
            id="Path 3471"
            d="M245.684 58.457C245.407 58.4571 245.134 58.3926 244.886 58.2687C244.638 58.1447 244.422 57.9648 244.256 57.743L239.879 51.907C239.611 51.5275 239.501 51.0583 239.573 50.5992C239.645 50.1401 239.894 49.7273 240.266 49.4484C240.637 49.1696 241.103 49.0468 241.564 49.1061C242.025 49.1654 242.445 49.4021 242.734 49.766L245.597 53.584L252.951 42.553C253.217 42.1678 253.624 41.9027 254.084 41.8147C254.544 41.7268 255.02 41.823 255.409 42.0828C255.799 42.3425 256.071 42.7449 256.167 43.2032C256.262 43.6615 256.174 44.1391 255.921 44.533L247.167 57.663C247.009 57.9004 246.796 58.0963 246.546 58.2345C246.296 58.3726 246.017 58.4489 245.732 58.457H245.684Z"
            fill="white"
          />
        </g>
        <g id="iphone" className={styles.iphone}>
          <path
            id="Path 3472"
            d="M210.037 106.843L185.298 38.018L185.925 37.792L181.375 25.133L180.748 25.359L173.155 4.228C172.871 3.43873 172.435 2.71306 171.871 2.09241C171.307 1.47177 170.626 0.968313 169.867 0.610801C169.109 0.25329 168.287 0.048725 167.449 0.008781C166.612 -0.031163 165.774 0.0942926 164.985 0.377998L120.255 16.454C119.466 16.7376 118.74 17.1739 118.119 17.738C117.499 18.3021 116.995 18.9829 116.638 19.7416C116.28 20.5002 116.076 21.3219 116.036 22.1596C115.996 22.9974 116.121 23.8348 116.405 24.624L121.705 39.358L120.714 39.74L124.095 48.519L124.889 48.213L125.589 50.166L124.745 50.491L128.126 59.27L128.773 59.021L153.294 127.238C153.576 128.028 154.012 128.755 154.576 129.376C155.14 129.998 155.821 130.502 156.58 130.859C157.339 131.217 158.161 131.421 158.999 131.461C159.837 131.5 160.675 131.373 161.464 131.088L206.188 115.012C207.78 114.437 209.08 113.254 209.801 111.723C210.523 110.192 210.608 108.437 210.037 106.843Z"
            fill="#3F3D56"
          />
          <path
            id="Path 3473"
            d="M159.112 128.821C158.482 128.819 157.868 128.624 157.353 128.262C156.838 127.899 156.448 127.386 156.234 126.794L118.959 23.094C118.685 22.3302 118.725 21.4887 119.071 20.7545C119.417 20.0204 120.04 19.4537 120.804 19.179L130.32 15.758L130.306 16.091C130.282 16.6689 130.4 17.244 130.65 17.7655C130.9 18.287 131.275 18.739 131.741 19.0816C132.207 19.4242 132.75 19.647 133.322 19.7302C133.895 19.8135 134.479 19.7547 135.023 19.559L156.142 11.968C156.652 11.7343 157.099 11.384 157.448 10.9454C157.798 10.5067 158.039 9.99207 158.152 9.443C158.264 8.903 158.247 8.34417 158.102 7.81205C157.957 7.27993 157.688 6.78959 157.318 6.38101L157.095 6.13301L166.924 2.6C167.688 2.32652 168.529 2.36718 169.263 2.71305C169.997 3.05893 170.564 3.68179 170.839 4.445L206.403 103.382C207.128 105.409 207.02 107.642 206.101 109.589C205.183 111.537 203.529 113.04 201.503 113.77L160.144 128.637C159.811 128.757 159.461 128.819 159.107 128.819L159.112 128.821Z"
            fill="white"
          />
          <path
            id="Ellipse 513"
            d="M155.468 55.271C162.148 55.271 167.564 49.8554 167.564 43.175C167.564 36.4946 162.148 31.079 155.468 31.079C148.788 31.079 143.372 36.4946 143.372 43.175C143.372 49.8554 148.788 55.271 155.468 55.271Z"
            fill="#006CB3"
          />
          <path
            id="Path 3474"
            d="M185.441 60.764L143.553 75.825C143.422 75.8741 143.282 75.8966 143.142 75.8913C143.002 75.8859 142.865 75.8529 142.738 75.794C142.611 75.7351 142.496 75.6516 142.402 75.5483C142.307 75.4449 142.234 75.3239 142.187 75.1921C142.139 75.0603 142.119 74.9204 142.126 74.7805C142.133 74.6406 142.168 74.5035 142.228 74.3772C142.289 74.2508 142.374 74.1378 142.478 74.0445C142.583 73.9513 142.705 73.8797 142.837 73.834L184.725 58.777C184.856 58.7279 184.996 58.7054 185.136 58.7107C185.276 58.716 185.413 58.7491 185.54 58.808C185.667 58.8669 185.782 58.9504 185.876 59.0537C185.971 59.157 186.044 59.2781 186.091 59.4099C186.139 59.5417 186.159 59.6816 186.152 59.8215C186.145 59.9614 186.11 60.0985 186.05 60.2248C185.989 60.3512 185.904 60.4642 185.8 60.5575C185.695 60.6507 185.573 60.7223 185.441 60.768V60.764Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3475"
            d="M173.002 70.311L159.223 75.262C158.96 75.3528 158.672 75.3364 158.421 75.2162C158.17 75.0961 157.976 74.8819 157.882 74.62C157.788 74.3581 157.801 74.0697 157.918 73.8172C158.035 73.5647 158.246 73.3685 158.507 73.271L172.285 68.318C172.416 68.2689 172.556 68.2464 172.696 68.2517C172.836 68.2571 172.973 68.2901 173.1 68.349C173.227 68.4078 173.342 68.4914 173.436 68.5947C173.531 68.6981 173.604 68.8191 173.651 68.9509C173.699 69.0827 173.719 69.2226 173.712 69.3625C173.705 69.5024 173.67 69.6395 173.61 69.7658C173.549 69.8922 173.464 70.0052 173.36 70.0985C173.255 70.1917 173.133 70.2633 173.001 70.309L173.002 70.311Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3476"
            d="M176.279 79.428L162.501 84.381C162.37 84.4301 162.23 84.4526 162.09 84.4473C161.95 84.442 161.813 84.4089 161.686 84.35C161.559 84.2911 161.444 84.2076 161.35 84.1043C161.255 84.001 161.182 83.8799 161.135 83.7481C161.087 83.6163 161.067 83.4764 161.074 83.3365C161.081 83.1966 161.116 83.0595 161.176 82.9332C161.237 82.8068 161.322 82.6938 161.426 82.6005C161.531 82.5073 161.653 82.4357 161.785 82.39L175.563 77.437C175.694 77.3879 175.834 77.3654 175.974 77.3707C176.114 77.3761 176.251 77.4091 176.378 77.468C176.505 77.5269 176.62 77.6104 176.714 77.7137C176.809 77.8171 176.882 77.9381 176.929 78.0699C176.977 78.2017 176.997 78.3416 176.99 78.4815C176.983 78.6214 176.948 78.7585 176.888 78.8848C176.827 79.0112 176.742 79.1242 176.638 79.2175C176.533 79.3107 176.411 79.3823 176.279 79.428V79.428Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3477"
            d="M177.957 84.098L164.179 89.051C163.916 89.1419 163.628 89.1254 163.377 89.0052C163.126 88.8851 162.932 88.6709 162.838 88.409C162.744 88.1472 162.757 87.8587 162.874 87.6062C162.991 87.3537 163.202 87.1575 163.463 87.06L177.241 82.107C177.504 82.0162 177.792 82.0326 178.043 82.1528C178.294 82.273 178.488 82.4871 178.582 82.749C178.676 83.0109 178.663 83.2993 178.546 83.5518C178.429 83.8043 178.218 84.0005 177.957 84.098V84.098Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3478"
            d="M182.344 72.028L153.108 82.536C152.977 82.5851 152.837 82.6076 152.697 82.6023C152.557 82.5969 152.42 82.5639 152.293 82.505C152.166 82.4462 152.051 82.3626 151.957 82.2593C151.862 82.1559 151.789 82.0349 151.742 81.9031C151.694 81.7713 151.674 81.6314 151.681 81.4915C151.688 81.3516 151.723 81.2145 151.783 81.0882C151.844 80.9618 151.929 80.8488 152.033 80.7555C152.138 80.6623 152.26 80.5907 152.392 80.545L181.625 70.037C181.756 69.9879 181.896 69.9654 182.036 69.9707C182.176 69.9761 182.313 70.0091 182.44 70.068C182.567 70.1269 182.682 70.2104 182.776 70.3137C182.871 70.4171 182.944 70.5381 182.991 70.6699C183.039 70.8017 183.059 70.9417 183.052 71.0815C183.045 71.2214 183.01 71.3585 182.95 71.4848C182.889 71.6112 182.804 71.7242 182.7 71.8175C182.595 71.9107 182.473 71.9823 182.341 72.028H182.344Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3479"
            d="M176.456 95.433L171.456 97.233C171.232 97.322 170.992 97.365 170.75 97.3595C170.509 97.3539 170.271 97.2999 170.052 97.2006C169.832 97.1013 169.634 96.9588 169.47 96.7815C169.306 96.6043 169.18 96.3959 169.098 96.1688C169.016 95.9416 168.981 95.7004 168.995 95.4595C169.008 95.2185 169.07 94.9827 169.177 94.7661C169.283 94.5495 169.432 94.3565 169.614 94.1986C169.797 94.0407 170.009 93.9211 170.239 93.847L175.239 92.047C175.684 91.9035 176.167 91.9384 176.586 92.1444C177.005 92.3505 177.328 92.7115 177.486 93.1511C177.644 93.5908 177.625 94.0746 177.433 94.5004C177.24 94.9262 176.89 95.2607 176.456 95.433Z"
            fill="#006CB3"
          />
          <path
            id="Ellipse 514"
            d="M137.033 16.097C137.417 16.097 137.729 15.7854 137.729 15.401C137.729 15.0166 137.417 14.705 137.033 14.705C136.649 14.705 136.337 15.0166 136.337 15.401C136.337 15.7854 136.649 16.097 137.033 16.097Z"
            fill="white"
          />
        </g>
        <g id="flower-bottom">
          <path
            id="Path 3490"
            d="M291.037 230.944C299.537 233.029 309.455 231.044 315.047 224.312C305.528 224.18 296.362 220.686 289.172 214.447C286.408 212.031 283.585 209.056 279.915 208.939C277.605 208.976 275.397 209.899 273.748 211.517C272.124 213.126 270.712 214.937 269.548 216.905L269.048 217.186C275.266 223.348 282.535 228.859 291.037 230.944Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3491"
            d="M314.923 224.176C308.153 226.76 300.742 227.144 293.741 225.276C292.227 224.9 290.782 224.289 289.458 223.463C288.195 222.617 287.181 221.448 286.522 220.078C285.981 218.758 285.281 217.508 284.438 216.356C283.95 215.806 283.352 215.366 282.683 215.064C282.013 214.761 281.287 214.604 280.552 214.602C278.738 214.649 276.951 215.061 275.299 215.812C273.48 216.697 271.484 217.156 269.461 217.154C269.213 217.134 269.196 217.519 269.443 217.539C272.999 217.831 276.003 215.416 279.452 215.033C280.246 214.898 281.06 214.946 281.833 215.174C282.605 215.403 283.315 215.804 283.908 216.349C284.831 217.471 285.57 218.733 286.096 220.087C286.719 221.433 287.662 222.606 288.843 223.504C290.121 224.395 291.541 225.061 293.043 225.475C296.407 226.462 299.906 226.913 303.41 226.813C307.41 226.721 311.365 225.945 315.104 224.519C315.335 224.431 315.154 224.09 314.924 224.177L314.923 224.176Z"
            fill="white"
          />
          <path
            id="Path 3492"
            d="M295.959 226.009C295.805 227.226 296.088 228.458 296.758 229.486C297.429 230.513 298.443 231.269 299.619 231.618C299.857 231.688 299.977 231.318 299.738 231.251C298.642 230.928 297.698 230.224 297.076 229.266C296.453 228.308 296.194 227.159 296.344 226.027C296.377 225.78 295.991 225.764 295.959 226.009Z"
            fill="white"
          />
          <path
            id="Path 3494"
            d="M307.195 226.255C307.157 225.772 307.239 225.286 307.434 224.843C307.63 224.399 307.932 224.011 308.315 223.713C308.515 223.561 308.25 223.277 308.055 223.428C307.634 223.759 307.3 224.188 307.083 224.678C306.866 225.168 306.772 225.704 306.81 226.238C306.81 226.288 306.829 226.337 306.863 226.374C306.897 226.411 306.944 226.434 306.994 226.438C307.045 226.44 307.094 226.422 307.132 226.388C307.169 226.353 307.192 226.306 307.195 226.255V226.255Z"
            fill="white"
          />
          <path
            id="Path 3495"
            d="M309.543 195.187C309.418 195.279 309.292 195.37 309.163 195.461C307.47 196.683 305.68 197.765 303.811 198.697C303.668 198.771 303.521 198.845 303.376 198.915C298.85 201.112 293.955 202.45 288.94 202.86C286.944 203.021 284.938 203.031 282.94 202.888C280.182 202.688 277.207 202.257 274.622 202.961C274.35 203.031 274.083 203.118 273.822 203.222L268.745 216.559C268.745 216.595 268.745 216.63 268.753 216.666L268.526 217.189L268.838 217.231C268.897 217.24 268.958 217.246 269.017 217.254C269.057 217.254 269.097 217.264 269.133 217.27H269.17C269.206 217.277 269.24 217.279 269.27 217.285C269.865 217.359 270.463 217.429 271.062 217.494C271.062 217.494 271.062 217.494 271.069 217.494C275.627 218.037 280.233 218.037 284.791 217.494C284.927 217.476 285.066 217.459 285.204 217.435C287.237 217.155 289.244 216.708 291.204 216.101C292.276 215.765 293.331 215.375 294.364 214.932C297.031 213.783 299.5 212.222 301.682 210.306C306.031 206.485 309.109 201.316 309.515 195.617C309.527 195.477 309.536 195.334 309.543 195.187Z"
            fill="#F0F0F0"
          />
          <path
            id="Path 3496"
            d="M309.363 195.153C305.513 201.291 299.826 206.06 293.111 208.783C291.676 209.395 290.153 209.776 288.599 209.914C287.081 209.999 285.568 209.676 284.217 208.979C282.99 208.251 281.678 207.674 280.312 207.262C279.592 207.117 278.849 207.125 278.132 207.287C277.415 207.449 276.741 207.76 276.153 208.201C274.733 209.331 273.555 210.735 272.688 212.33C271.769 214.132 270.451 215.701 268.835 216.917C268.625 217.05 268.843 217.368 269.053 217.235C272.068 215.327 273.012 211.59 275.536 209.208C276.089 208.622 276.768 208.171 277.522 207.888C278.276 207.605 279.084 207.498 279.886 207.576C281.299 207.915 282.65 208.478 283.886 209.243C285.194 209.943 286.654 210.311 288.137 210.317C289.693 210.259 291.228 209.937 292.676 209.364C295.956 208.126 299.021 206.38 301.759 204.19C304.897 201.708 307.588 198.707 309.714 195.318C309.846 195.108 309.496 194.945 309.365 195.153H309.363Z"
            fill="white"
          />
          <path
            id="Path 3497"
            d="M295.324 208.033C295.934 209.098 296.902 209.911 298.056 210.328C299.21 210.744 300.475 210.737 301.624 210.307C301.856 210.219 301.731 209.854 301.498 209.942C300.429 210.344 299.251 210.35 298.177 209.96C297.104 209.57 296.205 208.809 295.643 207.814C295.521 207.598 295.203 207.814 295.325 208.032L295.324 208.033Z"
            fill="white"
          />
        </g>
        <path
          id="Ellipse 515"
          d="M281.351 117.321C281.735 117.321 282.047 117.009 282.047 116.625C282.047 116.241 281.735 115.929 281.351 115.929C280.967 115.929 280.655 116.241 280.655 116.625C280.655 117.009 280.967 117.321 281.351 117.321Z"
          fill="white"
        />
        <g id="ipad" className={styles.ipad}>
          <path
            id="Path 3498"
            d="M284.82 209.38C285.456 206.954 286.983 204.857 289.096 203.507C289.305 203.373 289.087 203.055 288.878 203.189C286.681 204.598 285.097 206.784 284.441 209.31C284.379 209.551 284.758 209.62 284.82 209.381V209.38Z"
            fill="white"
          />
          <path
            id="Path 3500"
            d="M325.106 149.225L312.141 171.042L312.634 171.302L308.244 179.626L307.33 179.142L304.445 183.994L305.162 184.406L300.462 192.55L299.647 192.08L257.347 263.275C256.238 265.138 254.434 266.485 252.333 267.02C250.231 267.555 248.003 267.235 246.138 266.129L167.482 219.398C165.619 218.289 164.271 216.485 163.736 214.384C163.201 212.282 163.522 210.054 164.628 208.189L232.396 94.14C233.505 92.2766 235.309 90.9295 237.41 90.3944C239.512 89.8593 241.74 90.18 243.605 91.286L322.25 138.016C324.113 139.126 325.46 140.929 325.995 143.03C326.531 145.131 326.211 147.359 325.106 149.225V149.225Z"
            fill="#3F3D56"
          />
          <path
            id="Path 3501"
            d="M248.932 261.725L170.01 214.83C169.436 214.488 169.021 213.932 168.856 213.284C168.691 212.637 168.789 211.95 169.13 211.375L237.347 96.567C237.689 95.9928 238.245 95.5776 238.892 95.4126C239.54 95.2477 240.227 95.3463 240.802 95.687L319.724 142.581C320.298 142.923 320.713 143.479 320.878 144.127C321.043 144.774 320.945 145.461 320.604 146.036L252.387 260.845C252.045 261.419 251.489 261.834 250.841 261.999C250.194 262.164 249.507 262.066 248.932 261.725V261.725Z"
            fill="white"
          />
          <path
            id="Path 3502"
            d="M277.045 199.563L211.165 160.418C210.75 160.171 210.449 159.769 210.331 159.3C210.212 158.832 210.284 158.335 210.531 157.92C210.778 157.505 211.18 157.205 211.649 157.086C212.117 156.967 212.614 157.039 213.029 157.286L278.909 196.431C279.12 196.55 279.305 196.711 279.453 196.903C279.602 197.095 279.71 197.314 279.773 197.549C279.835 197.783 279.85 198.027 279.816 198.268C279.783 198.508 279.702 198.739 279.578 198.947C279.454 199.156 279.29 199.338 279.095 199.482C278.9 199.626 278.678 199.73 278.442 199.787C278.207 199.844 277.962 199.854 277.723 199.816C277.483 199.777 277.254 199.691 277.048 199.563H277.045Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3503"
            d="M250.74 193.497L229.067 180.621C228.663 180.369 228.373 179.969 228.26 179.506C228.148 179.043 228.221 178.554 228.464 178.144C228.708 177.734 229.102 177.436 229.563 177.314C230.023 177.192 230.513 177.254 230.928 177.489L252.598 190.365C252.809 190.484 252.994 190.645 253.142 190.837C253.291 191.029 253.399 191.248 253.462 191.483C253.524 191.717 253.539 191.961 253.505 192.202C253.472 192.442 253.391 192.673 253.267 192.881C253.143 193.09 252.979 193.272 252.784 193.416C252.589 193.56 252.367 193.664 252.131 193.721C251.896 193.778 251.651 193.788 251.412 193.75C251.172 193.711 250.943 193.625 250.737 193.497H250.74Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3504"
            d="M242.22 207.836L220.55 194.96C220.339 194.841 220.154 194.68 220.006 194.488C219.857 194.296 219.749 194.077 219.686 193.842C219.624 193.608 219.609 193.364 219.643 193.123C219.676 192.883 219.757 192.652 219.881 192.443C220.005 192.235 220.169 192.053 220.364 191.909C220.559 191.765 220.781 191.661 221.017 191.604C221.252 191.546 221.497 191.537 221.736 191.575C221.976 191.614 222.205 191.7 222.411 191.828L244.081 204.704C244.292 204.823 244.477 204.984 244.625 205.176C244.774 205.368 244.882 205.587 244.945 205.822C245.007 206.056 245.022 206.3 244.988 206.541C244.955 206.781 244.874 207.012 244.75 207.22C244.626 207.429 244.462 207.611 244.267 207.755C244.072 207.899 243.85 208.003 243.614 208.06C243.379 208.117 243.134 208.127 242.895 208.089C242.655 208.05 242.426 207.964 242.22 207.836Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3505"
            d="M237.856 215.18L216.186 202.304C215.975 202.185 215.79 202.024 215.642 201.832C215.493 201.64 215.385 201.421 215.322 201.186C215.26 200.952 215.245 200.708 215.279 200.467C215.312 200.227 215.393 199.996 215.517 199.788C215.641 199.579 215.805 199.397 216 199.253C216.195 199.109 216.417 199.005 216.653 198.948C216.888 198.891 217.133 198.881 217.372 198.919C217.612 198.958 217.841 199.044 218.047 199.172L239.717 212.048C239.928 212.167 240.113 212.328 240.261 212.52C240.41 212.712 240.518 212.931 240.581 213.166C240.643 213.4 240.658 213.644 240.624 213.885C240.591 214.125 240.51 214.356 240.386 214.564C240.262 214.773 240.098 214.955 239.903 215.099C239.708 215.243 239.486 215.347 239.25 215.404C239.015 215.461 238.77 215.471 238.531 215.433C238.291 215.394 238.062 215.308 237.856 215.18V215.18Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3506"
            d="M258.693 207.787L212.717 180.47C212.313 180.218 212.023 179.818 211.91 179.355C211.798 178.891 211.871 178.403 212.114 177.993C212.358 177.583 212.752 177.285 213.213 177.163C213.673 177.041 214.163 177.103 214.578 177.338L260.554 204.656C260.76 204.778 260.939 204.94 261.082 205.131C261.226 205.323 261.33 205.541 261.389 205.773C261.448 206.004 261.461 206.246 261.426 206.482C261.392 206.719 261.312 206.947 261.19 207.153C261.067 207.358 260.906 207.538 260.714 207.681C260.523 207.824 260.305 207.928 260.073 207.987C259.841 208.046 259.6 208.059 259.363 208.025C259.126 207.991 258.899 207.91 258.693 207.788V207.787Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3507"
            d="M249.928 242.7L242.06 238.025C241.389 237.589 240.912 236.91 240.731 236.13C240.549 235.351 240.676 234.531 241.085 233.843C241.493 233.155 242.153 232.651 242.924 232.438C243.696 232.225 244.52 232.318 245.224 232.699L253.092 237.374C253.763 237.81 254.239 238.489 254.421 239.269C254.603 240.048 254.476 240.868 254.067 241.556C253.659 242.245 252.999 242.748 252.228 242.961C251.456 243.174 250.632 243.081 249.928 242.7V242.7Z"
            fill="#006CB3"
          />
          <path
            id="Ellipse 516"
            d="M264.61 166.792C273.517 166.792 280.738 159.571 280.738 150.664C280.738 141.757 273.517 134.536 264.61 134.536C255.703 134.536 248.482 141.757 248.482 150.664C248.482 159.571 255.703 166.792 264.61 166.792Z"
            fill="#006CB3"
          />
          <path
            id="Path 3516"
            d="M290.515 268.318H0.533997C0.392504 268.318 0.256804 268.262 0.156754 268.162C0.0567029 268.062 0.000488281 267.926 0.000488281 267.784C0.000488281 267.643 0.0567029 267.507 0.156754 267.407C0.256804 267.307 0.392504 267.251 0.533997 267.251H290.515C290.656 267.251 290.792 267.307 290.892 267.407C290.992 267.507 291.048 267.643 291.048 267.784C291.048 267.926 290.992 268.062 290.892 268.162C290.792 268.262 290.656 268.318 290.515 268.318Z"
            fill="#CACACA"
          />
        </g>
        <g id="pc" className={styles.pc}>
          <path
            id="Path 3508"
            d="M0.192001 216.201H218.442V89.846C218.441 88.8992 218.064 87.9915 217.395 87.3221C216.725 86.6526 215.818 86.2761 214.871 86.275H3.761C2.81424 86.2761 1.90655 86.6526 1.23709 87.3221C0.567628 87.9915 0.191061 88.8992 0.190002 89.846L0.192001 216.201Z"
            fill="#CACACA"
          />
          <path
            id="Rectangle 2158"
            d="M211.699 91.214H6.93298V207.464H211.699V91.214Z"
            fill="white"
          />
          <path
            id="Path 3509"
            d="M0 212.78V228.372C0 229.677 0.518581 230.929 1.44164 231.852C2.36469 232.775 3.61662 233.294 4.92201 233.294H85.477V264.066H84.407C84.3231 264.066 84.24 264.083 84.1625 264.115C84.0849 264.147 84.0145 264.194 83.9552 264.253C83.8958 264.312 83.8488 264.383 83.8167 264.46C83.7845 264.538 83.768 264.621 83.768 264.705V266.845C83.768 266.929 83.7845 267.012 83.8167 267.09C83.8488 267.167 83.8958 267.238 83.9552 267.297C84.0145 267.356 84.0849 267.403 84.1625 267.435C84.24 267.467 84.3231 267.484 84.407 267.484H134.415C134.499 267.484 134.582 267.467 134.66 267.435C134.737 267.403 134.808 267.356 134.867 267.297C134.926 267.238 134.973 267.167 135.005 267.09C135.037 267.012 135.054 266.929 135.054 266.845V264.705C135.054 264.621 135.037 264.538 135.005 264.46C134.973 264.383 134.926 264.312 134.867 264.253C134.808 264.194 134.737 264.147 134.66 264.115C134.582 264.083 134.499 264.066 134.415 264.066H133.345V233.297H213.9C215.205 233.297 216.457 232.778 217.38 231.855C218.303 230.932 218.822 229.68 218.822 228.375V212.78H0Z"
            fill="#3F3D56"
          />
          <path
            id="Path 3510"
            d="M182.069 130.092H85.1C84.4887 130.092 83.9024 129.849 83.4701 129.417C83.0379 128.985 82.795 128.398 82.795 127.787C82.795 127.176 83.0379 126.589 83.4701 126.157C83.9024 125.725 84.4887 125.482 85.1 125.482H182.069C182.68 125.482 183.267 125.725 183.699 126.157C184.131 126.589 184.374 127.176 184.374 127.787C184.374 128.398 184.131 128.985 183.699 129.417C183.267 129.849 182.68 130.092 182.069 130.092Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3511"
            d="M149.533 140.498H117.633C117.022 140.498 116.435 140.255 116.003 139.823C115.571 139.391 115.328 138.804 115.328 138.193C115.328 137.582 115.571 136.995 116.003 136.563C116.435 136.131 117.022 135.888 117.633 135.888H149.533C150.144 135.888 150.731 136.131 151.163 136.563C151.595 136.995 151.838 137.582 151.838 138.193C151.838 138.804 151.595 139.391 151.163 139.823C150.731 140.255 150.144 140.498 149.533 140.498Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3512"
            d="M149.533 161.603H117.633C117.022 161.603 116.435 161.36 116.003 160.928C115.571 160.496 115.328 159.909 115.328 159.298C115.328 158.687 115.571 158.1 116.003 157.668C116.435 157.236 117.022 156.993 117.633 156.993H149.533C150.144 156.993 150.731 157.236 151.163 157.668C151.595 158.1 151.838 158.687 151.838 159.298C151.838 159.909 151.595 160.496 151.163 160.928C150.731 161.36 150.144 161.603 149.533 161.603V161.603Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3513"
            d="M149.533 172.413H117.633C117.022 172.413 116.435 172.17 116.003 171.738C115.571 171.306 115.328 170.719 115.328 170.108C115.328 169.497 115.571 168.91 116.003 168.478C116.435 168.046 117.022 167.803 117.633 167.803H149.533C150.144 167.803 150.731 168.046 151.163 168.478C151.595 168.91 151.838 169.497 151.838 170.108C151.838 170.719 151.595 171.306 151.163 171.738C150.731 172.17 150.144 172.413 149.533 172.413Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3514"
            d="M167.42 150.902H99.748C99.1367 150.902 98.5504 150.659 98.1181 150.227C97.6859 149.795 97.443 149.208 97.443 148.597C97.443 147.986 97.6859 147.399 98.1181 146.967C98.5504 146.535 99.1367 146.292 99.748 146.292H167.421C168.032 146.292 168.619 146.535 169.051 146.967C169.483 147.399 169.726 147.986 169.726 148.597C169.726 149.208 169.483 149.795 169.051 150.227C168.619 150.659 168.032 150.902 167.421 150.902H167.42Z"
            fill="#E6E6E6"
          />
          <path
            id="Path 3515"
            d="M180.455 194.546H168.871C167.832 194.546 166.835 194.133 166.1 193.398C165.364 192.663 164.952 191.666 164.952 190.626C164.952 189.587 165.364 188.59 166.1 187.855C166.835 187.12 167.832 186.707 168.871 186.707H180.452C181.492 186.707 182.488 187.12 183.224 187.855C183.959 188.59 184.372 189.587 184.372 190.626C184.372 191.666 183.959 192.663 183.224 193.398C182.488 194.133 181.492 194.546 180.452 194.546H180.455Z"
            fill="#006CB3"
          />
          <path
            id="Ellipse 517"
            d="M54.608 166.099C65.7421 166.099 74.768 157.073 74.768 145.939C74.768 134.805 65.7421 125.779 54.608 125.779C43.4739 125.779 34.448 134.805 34.448 145.939C34.448 157.073 43.4739 166.099 54.608 166.099Z"
            fill="#006CB3"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1:110">
          <rect width="326.25" height="268.319" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

