import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchBarStyle = {
  width: '100%',
  background: '#fff',
  border: 'none',
  padding: '0',
  color: 'black',
};

const IconStyle = {
  color: '#004a9f',
};

const SearchBar = ({ keyword, setKeyword, type }) => (
  <Input
    prefix={<SearchOutlined style={IconStyle} />}
    style={SearchBarStyle}
    value={keyword}
    placeholder="Хайлт хийх"
    onChange={e => setKeyword(e.target.value, type)}
  />
);

export default SearchBar;

