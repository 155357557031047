import React from 'react';
import { Divider, Typography } from 'antd';
import styles from './style.module.scss';

const { Text } = Typography;
function RelatedText(props) {
  const { item, relatedChange } = props;

  return (
    <>
      <div className={styles.relatedtext}>
        <Text strong>{item.trainingDictionaryCharacter.name}</Text>
        <Divider value={item.name} />
        <Text value={item.name} onClick={relatedChange}>
          {item.name}
        </Text>
      </div>
    </>
  );
}
export default RelatedText;
