import React from 'react';
import { message, Dropdown, Menu } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { ExportOutlined } from '@ant-design/icons';
import imgExcel from '../../../../img/excel.svg';

export default function FileAction({
  selectedReportForm,
  columns,
  dataSource,
}) {
  const exportExcel = () => {
    const excelDatas = dataSource;
    let excelCols = [];

    excelCols.push({
      title: '№',
      dataIndex: 'rowNo',
      key: 'rowNo',
      width: '40p',
    });
    excelCols = [...excelCols, ...columns];
    const excel = new Excel();
    try {
      excel
        .addSheet('sheet1')
        .addColumns(excelCols)
        .addDataSource(excelDatas)
        .saveAs(`${selectedReportForm.name}.xlsx`);
    } catch (error) {
      message.warn(error);
    }
  };

  const menu = (
    <Menu onClick={exportExcel}>
      <Menu.Item key="export" icon={<ExportOutlined />}>
        Экспорт
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown.Button
      type="link"
      overlay={menu}
      placement="bottomLeft"
      icon={<img src={imgExcel} height={36} />}
    />
  );
}

