import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import styles from './style.module.scss';
import ViewedTitle from './ViewedTitle';
import desktop from '../../../img/Flag/icon 01.svg';
import phone from '../../../img/Flag/icon 02.svg';
import tablet from '../../../img/Flag/tablet.svg';
import { getGATopAccess } from '../../../service/notificationService';

const { Text } = Typography;

function NumberAccesses() {
  const [feauturedCorps, setFeauturedCorps] = useState([]);

  useEffect(() => {
    const param = {
      beginAt: '2023-01',
      endAt: '2024-04',
      typeId: 1,
      size: 8,
    };

    getGATopAccess(param).then(data => {
      const content = data.content || [];
      setFeauturedCorps(content);
    });
  }, []);

  const data = {
    day: '8,193',
    dayDiff: '32',
    week: '19,714',
    weekDiff: '32',
    year: '103,029',
    yearDiff: '-10',
    total: '5,283,144',
    comTotal: '3,283,144',
    mobileTotal: '1,283,144',
    tabletTotal: '1,283,144',
  };

  return (
    <>
      <div className="main-title">
        <span>Хамгийн их хандалттай байгууллагууд</span>
      </div>
      <Card className={styles.requestInformation} bordered={false}>
        <ViewedTitle feauturedCorps={feauturedCorps} />
      </Card>
      <Row
        style={{
          marginTop: '20px',
          display: 'none',
          justifyContent: 'space-between',
        }}
      >
        <Col span={11} className={styles.numberCardTotal}>
          <div
            className={styles.total}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            Нийт хандалт
          </div>
          <div
            className={styles.totalNumber}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {data.total}
          </div>
        </Col>
        <Col span={4} className={styles.device}>
          <img src={desktop} alt="des" height={40} />
          <Row justify="center">
            <Text className={styles.text} strong>
              Компьютер
            </Text>
          </Row>
          <Row justify="center">
            <Text className={styles.secondText} strong>
              {data.comTotal}
            </Text>
          </Row>
        </Col>
        <Col span={4} className={styles.device}>
          <img src={phone} alt="phone" height={40} />
          <Row justify="center">
            <Text className={styles.text} strong>
              Гар утас
            </Text>
          </Row>
          <Row justify="center">
            <Text className={styles.secondText} strong>
              {data.mobileTotal}
            </Text>
          </Row>
        </Col>
        <Col span={4} className={styles.device}>
          <img src={tablet} alt="tablet" height={40} />
          <Row justify="center">
            <Text className={styles.text} strong>
              Таблет
            </Text>
          </Row>
          <Row justify="center">
            <Text className={styles.secondText} strong>
              {data.tabletTotal}
            </Text>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default NumberAccesses;

